import React from "react";
import { Fullscreen, FullscreenExit } from "@mui/icons-material";
import ButtonIconContainer from "../../../ButtonIconContainer/ButtonIconContainer";

type ButtonFullscreenProps = {
  isFullscreen: boolean;
  onClickFullscreen: () => void;
};

export default function ButtonFullscreen({
  isFullscreen,
  onClickFullscreen,
  ...rest
}: ButtonFullscreenProps): JSX.Element {
  return (
    <ButtonIconContainer
      {...rest}
      ariaLabel={isFullscreen ? "exit fullscreen" : "enter fullscreen"}
      tooltip="Fullscreen (f)"
      onClick={onClickFullscreen}
      id="button_fullscreen"
    >
      {isFullscreen ? <FullscreenExit /> : <Fullscreen />}
    </ButtonIconContainer>
  );
}
