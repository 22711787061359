import {
  from,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  NormalizedCacheObject,
  ApolloLink,
} from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import { setContext } from "@apollo/client/link/context";
import typeDefs from "./typeDefs";
import typePolicies from "./typePolicies";
import { onError } from "apollo-link-error";
import ApolloLinkTimeout from "apollo-link-timeout";
import { ErrorReporter, ErrorSeverity } from "../hooks/useErrorHandler";
import { Sans } from "../util/sdk";
import { timeoutSeconds } from "../util/constants";

const authLink = setContext(async (_, { headers }) => {
  const tokens = await Sans.sdk.core.api.tokens.freshTokens();
  return {
    credentials: "include",
    headers: {
      ...headers,
      "X-ACCESS-TOKEN": tokens?.id ? `Bearer ${tokens.id}` : "",
    },
  };
});

export function createClient(errorHandler: ErrorReporter): ApolloClient<NormalizedCacheObject> {
  const httpLink = createHttpLink({ uri: process.env.REACT_APP_GRAPHQL_URI });
  const timeoutLink = new ApolloLinkTimeout(timeoutSeconds * 1000);
  const retryLink = new RetryLink({ attempts: { max: 3 } });

  const errorLink = onError(({ graphQLErrors, networkError, response, operation }) => {
    if (operation.operationName === "SendTelemetry") return;

    let message: string;
    if (graphQLErrors?.length) {
      message = graphQLErrors
        .reduce((unique, error) => {
          if (!unique.includes(error.message)) {
            unique.push(error.message);
          }
          return unique;
        }, [] as string[])
        .join("\n");
      message = `Server error: ${message}`;
    } else if (networkError) {
      message = `Network error: ${networkError.message}`;
    } else {
      message = "Unknown error";
    }

    let severity = ErrorSeverity.LOW;

    if (response) {
      response.errors = null as never;
      if (operation.operationName === "CourseQuery")
        severity = response?.data ? ErrorSeverity.MEDIUM : ErrorSeverity.HIGH;
    } else if (operation.operationName === "CourseQuery") {
      severity = ErrorSeverity.HIGH;
    }

    errorHandler(severity, message, { ...operation });
  });

  const link = from([
    authLink,
    errorLink as unknown as ApolloLink,
    retryLink,
    timeoutLink,
    httpLink,
  ]);

  return new ApolloClient({
    typeDefs,
    defaultOptions: {
      query: { errorPolicy: "all" },
      mutate: { errorPolicy: "all" },
    },
    cache: new InMemoryCache({
      typePolicies,
    }),
    link,
    connectToDevTools: true,
  });
}
