import { Button, Link, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const InstructorContainer = styled("div")({
  textAlign: "left",
  paddingLeft: 0,
  flex: 1,

  ["#panel1a-header"]: {
    padding: 0,
    ["h3"]: {
      fontSize: "14px",
      fontWeight: "600",
    },
  },

  ".MuiAccordionSummary-root": {
    justifyContent: "flex-start",
  },

  ".MuiAccordionDetails-root": {
    padding: 0,
  },

  ".MuiAccordionSummary-content": {
    flexGrow: 0,
    margin: 0,
  },

  "@container container (width < 600px)": {
    width: "100%",

    ".MuiAccordionSummary-content": {
      flexGrow: 1,
    },
  },
});

export const InstructorGrid = styled(Stack)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  gap: theme.spacing(3),
  width: "100%",

  "@container container (width < 500px)": {
    flexDirection: "column",
    alignItems: "flex-start",
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  fontSize: "0.875rem",
  fontWeight: 600,
  lineHeight: "150%",
  color: theme.palette.text.titlePrimary,
  "& svg": {
    marginRight: "0.75rem",
  },
  "& span": {
    color: theme.palette.text.titleGrey,
  },
}));

export const BioContainer = styled("div")(() => ({
  display: "flex",
  gap: "16px",
  alignItems: "center",
  marginBottom: "16px",
  "& img": {
    width: "48px",
    height: "48px",
    borderRadius: "48px",
  },
}));

export const ResourceContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  "& ul": {
    listStyle: "none",
    padding: 0,
    margin: 0,
  },
});

export const StyledName = styled("p")(({ theme }) => ({
  fontWeight: 400,
  fontSize: "1rem",
  lineHeight: "150%",
  color: theme.palette.text.primary,
  marginTop: 0,
  marginBottom: 0,
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  fontWeight: 600,
  fontSize: "1rem",
  lineHeight: "150%",
  textDecorationColor: theme.palette.text.link,
  color: theme.palette.text.link,
  cursor: "pointer",
  letterSpacing: "-0.32px",
  textDecoration: "underline",
  ["&:hover"]: {
    textDecorationColor: theme.palette.text.linkHover,
    color: theme.palette.text.linkHover,
  },
}));

export const ViewResourcesButton = styled(Button)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "0.75rem",
  lineHeight: "150%",
  border: "none",
  backgroundColor: "transparent",
  padding: 0,
  textDecorationColor: theme.palette.text.link,
  color: theme.palette.text.link,
  ["&:hover"]: {
    textDecorationColor: theme.palette.text.linkHover,
    color: theme.palette.text.linkHover,
  },
}));

export const ResourceItem = styled("li")(({ theme }) => ({
  display: "flex",
  alignSelf: "center",
  color: theme.custom.palette.semantic.blue,
  fontWeight: 600,
  fontSize: "0.875rem",
  lineHeight: "24px",
  letterSpacing: "-0.28px",
  "&:not(:last-of-type)": {
    marginBottom: "1.2rem",
  },
  "& a": {
    color: theme.custom.palette.semantic.blue,
  },
  "& svg": {
    marginRight: "0.875rem",
  },
}));

export const InstructorButton = styled(Button)(({ theme }) => ({
  fontSize: "1rem",
  fontWeight: 600,
  lineHeight: "24px",
  color: theme.custom.palette.semantic.blue,
}));
