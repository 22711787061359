import moment, { Moment } from "moment-timezone";
import { InvalidDateTimeException } from "./exceptions";

export function getSecondsFromTimestamp(timestamp?: string): number {
  const matches = /(\d{2}):(\d{2}):(\d{2})/.exec(timestamp ?? "00:00:00");
  if (!matches) return 0;
  const [, hours, minutes, seconds] = matches.map((str) => Number(str));
  return hours * 3600 + minutes * 60 + seconds;
}

export function getTimestampFromSeconds(seconds: number): string {
  return new Date(seconds * 1000).toISOString().substr(11, 8);
}

export function getReadableFromSeconds(
  seconds: number,
  abbreviate = true,
  showSeconds = false,
): string {
  if (seconds < 60) return getTimeDescription(seconds, "second", abbreviate);

  const [hours, minutes, inSeconds] = [
    Math.floor(seconds / 3600),
    Math.floor((seconds / 60) % 60),
    Math.floor(seconds % 60),
  ];

  const strings: string[] = [];
  if (hours) strings.push(getTimeDescription(hours, "hour", abbreviate));
  strings.push(getTimeDescription(minutes, "minute", abbreviate));
  if (showSeconds && inSeconds) strings.push(getTimeDescription(inSeconds, "second", abbreviate));
  return strings.join(" ");
}

export function getTimeDescription(time: number, timespan: string, abbreviate = true): string {
  let description;
  if (abbreviate) description = timespan[0];
  else description = ` ${timespan}${time > 1 ? "s" : ""}`;
  return `${time}${description}`;
}

function validateTime(dateTime: string): Moment {
  const time = moment(dateTime);

  if (!time.isValid()) {
    throw new InvalidDateTimeException();
  }

  return time;
}

export function toDateAtTimeWithTz(dateTime: string): string {
  const time = validateTime(dateTime);
  time.tz(moment.tz.guess());

  const localDateTimeWithTz = time.format("MMM D, YYYY [at] h:mma z");
  const utcTimeWithTz = time.utc().format("h:mma z");

  return `${localDateTimeWithTz}/${utcTimeWithTz}`;
}

function timeDifference(targetDate: Moment): string {
  const now = moment();
  const diffInDays = targetDate.diff(now, "days");
  const diffInHours = Math.abs(targetDate.diff(now, "hours"));
  const diffInMinutes = Math.abs(targetDate.diff(now, "minutes"));

  if (diffInMinutes <= 58) {
    return "< 1 hour";
  } else if (diffInMinutes === 59) {
    return "1 hour";
  } else if (diffInHours <= 22) {
    return `${diffInHours + 1} hours`;
  } else if (diffInHours === 23) {
    return `1 day`;
  } else {
    return `${diffInDays + 1} days`;
  }
}

export function expiresIn(dateTime: string): string {
  const time = validateTime(dateTime);

  if (time.isBefore(moment())) {
    return `Expired ${timeDifference(time)} ago`;
  } else {
    return `Expires in ${timeDifference(time)}`;
  }
}
