import React, { useCallback, useState } from "react";
import {
  DialogContent,
  DialogActions,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  DialogTitle,
  Alert,
} from "@mui/material";
import { TicketDialog, ContentWrapper, StyledButton } from "./QCTicketModal.elements";
import FormGroup from "@mui/material/FormGroup";
import { ChevronRight, DragIndicator } from "@mui/icons-material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useQCTicketQuery } from "./QCTicket.queries";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import { Resizable } from "react-resizable";
import "react-resizable/css/styles.css";

interface QCTicketModalProps {
  open: boolean;
  onClose: () => void;
}

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper style={{ maxWidth: "95%", paddingBottom: "16px", overflow: "hidden" }} {...props} />
    </Draggable>
  );
}

export const QCTicketModal = ({ open = false, onClose }: QCTicketModalProps) => {
  const [category, setCategory] = useState("");
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [categoryMissing, setCategoryMissing] = useState(false);
  const [subjectMissing, setSubjectMissing] = useState(false);
  const [contentMissing, setContentMissing] = useState(false);
  const [highPriority, setHighPriority] = useState(false);
  const { categories, sendTicket, ticketNumber } = useQCTicketQuery();
  const [height, setHeight] = useState(300);
  const [width, setWidth] = useState(500);

  const close = useCallback(
    (event, reason) => {
      if (reason && reason == "backdropClick") return;

      setCategory("");
      setSubject("");
      setContent("");
      setCategoryMissing(false);
      setSubjectMissing(false);
      setContentMissing(false);
      setHighPriority(false);
      onClose();
    },
    [onClose],
  );

  const createTicket = useCallback(async () => {
    if (!(category && subject && content)) {
      if (!category) setCategoryMissing(true);
      if (!subject) setSubjectMissing(true);
      if (!content) setContentMissing(true);
    } else {
      await sendTicket(category, subject, content, highPriority);
      setTimeout(() => close(), 1000);
    }
  }, [category, subject, content, highPriority, close, sendTicket]);

  const resize = (event) => {
    setHeight(height + event.movementY);
    setWidth(width + event.movementX);
  };

  return (
    <TicketDialog
      open={open}
      onClose={close}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <Resizable height={height} width={width} handle={ChevronRight} onResize={resize}>
        <>
          <DialogTitle sx={{ cursor: "move" }} id="draggable-dialog-title">
            <DragIndicator sx={{ marginBottom: "-5px" }} fontSize="medium" />
            Submit QC Ticket
          </DialogTitle>
          <DialogContent>
            <ContentWrapper
              data-testid="resize-container"
              height={`${height}px`}
              width={`${width}px`}
            >
              <FormGroup>
                <InputLabel id="category-label" error={categoryMissing}>
                  Category
                </InputLabel>
                <Select
                  labelId="category-label"
                  id="category-select"
                  placeholder="Select a category"
                  value={category}
                  label="Age"
                  fullWidth
                  onChange={(e) => {
                    setCategory(e.target.value);
                    setCategoryMissing(false);
                  }}
                  error={categoryMissing}
                >
                  {categories?.map((category) => (
                    <MenuItem key={category} value={category}>
                      {category}
                    </MenuItem>
                  ))}
                </Select>
                <TextField
                  autoFocus
                  margin="normal"
                  id="ticket-subject"
                  label="Subject"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={subject}
                  onChange={(e) => {
                    setSubject(e.target.value);
                    setSubjectMissing(false);
                  }}
                  error={subjectMissing}
                />
                <TextField
                  margin="normal"
                  id="name"
                  label="Description"
                  type="email"
                  fullWidth
                  variant="standard"
                  value={content}
                  onChange={(e) => {
                    setContent(e.target.value);
                    setContentMissing(false);
                  }}
                  error={contentMissing}
                  multiline
                  maxRows={5}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value={highPriority}
                      onChange={(e) => setHighPriority(e.target.checked)}
                    />
                  }
                  label="High Priority"
                />
              </FormGroup>
              {ticketNumber && <Alert>Created ticket Number: {ticketNumber}</Alert>}
            </ContentWrapper>
          </DialogContent>
          <DialogActions sx={{ padding: 2 }}>
            <StyledButton variant="outlined" onClick={close}>
              Cancel
            </StyledButton>
            <StyledButton variant="contained" onClick={() => createTicket()}>
              Create Ticket
            </StyledButton>
          </DialogActions>
        </>
      </Resizable>
    </TicketDialog>
  );
};
