import { styled } from "@mui/material/styles";
import { Tabs, Typography } from "@mui/material";
import { Search } from "@mui/icons-material";

export const CourseWrapper = styled("div")(({ theme }) => ({
  height: "100%",
  width: "100%",
  overflow: "auto",
  display: "flex",
  flexDirection: "column",
  position: "absolute",
  [theme.breakpoints.down("md")]: {
    position: "initial",
  },
}));

export const CourseHeader = styled("div")({
  display: "flex",
  "& .courseName": {
    flexGrow: 1,
  },
  "& .search": {
    marginTop: "-5px",
  },
});

export const CourseTitle = styled(Typography)({
  textAlign: "left",
  fontSize: "1.5em",
});

export const CourseContent = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  overflowY: "auto",
  overflowX: "hidden",
  "::-webkit-scrollbar": {
    width: "5px",
  },
  "::-webkit-scrollbar-track": {
    background: theme.custom.palette.semantic.grey,
  },
  "::-webkit-scrollbar-thumb": {
    background: theme.palette.primary.main,
    borderRadius: "10px",
  },
  "::-webkit-scrollbar:window-inactive": {
    visibility: "hidden",
  },
}));

export const CourseTabs = styled(Tabs)(({ theme }) => ({
  minHeight: "35px",
  margin: "8px",
  height: "35px",
  [theme.breakpoints.down("md")]: {
    minHeight: "40px",
    height: "40px",
  },
  [theme.breakpoints.down("sm")]: {
    minHeight: "35px",
    height: "35px",
  },
  "& .MuiTabs-flexContainer": {
    gap: "12px",
    [theme.breakpoints.down("md")]: {
      gap: "30px",
    },
  },
  "& .MuiTabs-indicator": {
    backgroundColor: theme.custom.palette.semantic.blue,
  },
  button: {
    color: theme.palette.text.primary,
    padding: "10px 0",
    fontSize: "1em",
    fontWeight: 600,
    lineHeight: "22px",
    minWidth: 0,
    svg: {
      color: theme.custom.palette.blueGrey[400],
    },
    "&.MuiTab-root.Mui-selected": {
      color: theme.custom.palette.semantic.blue,

      svg: {
        color: theme.custom.palette.semantic.blue,
      },
    },
    "&.MuiTab-labelIcon": {
      minHeight: 0,
      display: "flex",
      flexDirection: "row",
      gap: "10px",
      "& .MuiTab-iconWrapper": {
        marginBottom: 0,
      },
    },
  },
}));

export const StyledSearchIcon = styled(Search)(({ theme }) => ({
  margin: "12px 8px",
  fontSize: "15px",
  [theme.breakpoints.down("md")]: {
    margin: "16px",
  },
}));

export const SearchField = styled("input")(({ theme }) => ({
  width: "100%",
  borderRadius: "4px",
  fontSize: "0.75rem",
  fontWeight: 400,
  lineHeight: "14px",
  padding: "11px 10px 11px 30px",
  boxShadow: "none",
  [theme.breakpoints.down("md")]: {
    fontSize: "0.625rem",
    padding: "7px 10px 7px 30px",
  },
}));

export const SearchContainer = styled("div")(({ theme }) => ({
  padding: "8px",
  position: "relative",
  "& svg": {
    position: "absolute",
    left: "10px",
    top: "7px",
    color: theme.custom.palette.semantic.inputIconColor,
  },
  [theme.breakpoints.down("md")]: {
    padding: "16px",
  },
}));

export const SearchResultText = styled("p")(({ theme }) => ({
  color: theme.custom.palette.semantic.blue,
  fontSize: "0.75rem",
  fontWeight: 700,
  lineHeight: "14px",
  textAlign: "left",
  padding: "8px 24px",
}));
