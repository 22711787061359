import React, { useEffect, useState } from "react";
import { Alert, Snackbar } from "@mui/material";

export const NoAudioToast = ({ show }: { show: boolean }): JSX.Element => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (show) {
      setOpen(true);
    }
  }, [show]);

  return (
    <Snackbar
      open={open}
      autoHideDuration={10000}
      onClose={() => setOpen(false)}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert severity="info" onClose={() => setOpen(false)}>
        This slide intentionally has no audio.
      </Alert>
    </Snackbar>
  );
};
