import { ApolloCache, makeVar, useApolloClient, useReactiveVar } from "@apollo/client";
import gql from "graphql-tag";
import { print } from "graphql";
import { useAppState } from "./useAppState";
import { getTimestampFromSeconds } from "../util/time";
import { useCallback } from "react";
import { SaveProgress, SaveProgress_saveProgress } from "./__generated__/SaveProgress";
import { Sans } from "../util/sdk";
import { useTestMode } from "./useTestMode";
import { useCourseProgress } from "./useCourseProgress";
import { CourseQuery_instance_progress_lastView } from "../pages/Player/__generated__/CourseQuery";

export const featureName = "progressView";
export const openActionType = "open";
export const noCourseError = "no course";

export const SAVE_PROGRESS_QUERY = gql`
  mutation SaveProgress(
    $instanceId: String!
    $courseId: Int!
    $slideId: String!
    $resumeTime: String!
    $dateTimeViewed: DateTime!
  ) {
    saveProgress(
      instanceId: $instanceId
      courseId: $courseId
      slideId: $slideId
      resumeTime: $resumeTime
      dateTimeViewed: $dateTimeViewed
    ) {
      slideId
      resumeTime
      dateTimeViewed
    }
  }
`;

interface SlideProgress {
  slideId: string;
  resumeTime: string;
  dateTimeViewed: string;
}

function updateProgress(
  slideViews: (CourseQuery_instance_progress_lastView | SaveProgress_saveProgress)[] = [],
  cache: ApolloCache<object>,
): void {
  for (const slideView of slideViews) {
    cache.writeFragment({
      id: cache.identify({ __typename: "Slide", id: slideView.slideId }),
      fragment: gql`
        fragment SlideLastView on Slide {
          lastView {
            slideId
            resumeTime
            dateTimeViewed
          }
        }
      `,
      data: {
        lastView: {
          slideId: slideView.slideId,
          resumeTime: slideView.resumeTime,
          dateTimeViewed: slideView.dateTimeViewed,
        },
      },
    });
  }
}

const slideProgressVar = makeVar<SlideProgress | undefined>(undefined);

export function useProgress() {
  const {
    appState: { courseId, slideId, instanceId },
    course,
  } = useAppState();
  const {
    progress,
    openCert,
    saveCompletion,
    saveCourseProgress,
    expiration,
    completion,
    syncCourseSlideViews,
  } = useCourseProgress(courseId ?? undefined, course?.id, instanceId ?? undefined);
  const { testMode } = useTestMode();

  const client = useApolloClient();

  const slideProgress = useReactiveVar(slideProgressVar);

  const saveProgress = useCallback(
    async (moduleRefresh: boolean, seconds: number): Promise<void> => {
      if (!slideId || testMode) return;

      const resumeTime = getTimestampFromSeconds(seconds);
      const dateTimeViewed = new Date().toISOString();

      const lastView = {
        slideId,
        resumeTime,
        dateTimeViewed,
      } as CourseQuery_instance_progress_lastView;
      const [views] = await Promise.all([
        client.mutate<SaveProgress>({
          mutation: SAVE_PROGRESS_QUERY,
          variables: {
            progress,
            instanceId,
            courseId,
            slideId,
            resumeTime,
            dateTimeViewed,
          },
        }),
        saveCourseProgress(moduleRefresh, lastView),
      ]);
      updateProgress(views.data?.saveProgress, client.cache);
      syncCourseSlideViews();
    },
    [
      courseId,
      slideId,
      client,
      instanceId,
      progress,
      saveCourseProgress,
      testMode,
      syncCourseSlideViews,
    ],
  );

  const saveProgressServiceWorker = useCallback(async (): Promise<void> => {
    if (
      courseId &&
      slideProgress &&
      "serviceWorker" in navigator &&
      navigator.serviceWorker.controller
    ) {
      const tokens = await Sans.sdk.core.api.tokens.freshTokens();
      navigator.serviceWorker.controller.postMessage({
        action: "saveProgress",
        url: process.env.REACT_APP_GRAPHQL_URI,
        token: tokens.id,
        query: print(SAVE_PROGRESS_QUERY),
        variables: { ...slideProgress, courseId, instanceId },
      });
    }
  }, [slideProgress, courseId, instanceId]);

  const syncLocalSlideProgress = useCallback(
    (slideId: string, seconds: number) => {
      const resumeTime = getTimestampFromSeconds(seconds);
      const dateTimeViewed = new Date().toISOString();

      slideProgressVar({
        slideId,
        resumeTime,
        dateTimeViewed,
      });

      updateProgress(
        [
          {
            slideId,
            resumeTime,
            dateTimeViewed,
          } as CourseQuery_instance_progress_lastView,
        ],
        client.cache,
      );
    },
    [client],
  );

  const progressForModule = useCallback(
    (moduleId: string) => {
      return progress?.moduleProgress.find((module) => module.id === moduleId);
    },
    [progress],
  );

  const progressForSection = useCallback(
    (sectionId: string) => {
      const sectionModules = course?.sections.find((section) => section.id === sectionId)?.modules;
      const slides = sectionModules?.reduce(
        (acc, module) => {
          const progress = progressForModule(module.id);

          if (progress) {
            acc.viewedSlides += progress.viewedSlides;
            acc.totalSlides += progress.totalSlides;
          }
          return acc;
        },
        { viewedSlides: 0, totalSlides: 0 },
      );

      return {
        ...slides,
        percentSlides: slides?.totalSlides ? (slides.viewedSlides / slides.totalSlides) * 100 : 0,
      };
    },
    [progressForModule, course?.sections],
  );

  return {
    saveProgress,
    saveCourseProgress,
    syncLocalSlideProgress,
    saveProgressServiceWorker,
    progress,
    openCert,
    saveCompletion,
    expiration,
    completion,
    progressForModule,
    progressForSection,
  };
}
