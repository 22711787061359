import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const Container = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "left",
  alignItems: "flex-start",
  height: "100%",
  position: "relative",
  paddingTop: "56.25%",
}));

export const ImageWrapper = styled("button")(({ theme }) => ({
  background: "none",
  borderStyle: "none",

  img: {
    display: "block",
    width: "100%",
  },

  "&.selected img": {
    border: `4px solid ${theme.palette.primary.main}`,
  },
}));

export const InnerContainer = styled("div")(() => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "90%",
  overflow: "scroll",
}));
