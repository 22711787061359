export interface Translations {
  translations: Translation;
}

export interface Translation {
  Outline: string;
  Coursebook: string;
  Notes: string;
  Questions: string;
  Search: string;
  Bookmarks: string;
}

// default
export const enUS: Translations = {
  translations: {
    Outline: "Outline",
    Coursebook: "Coursebook",
    Notes: "Notes",
    Questions: "Questions",
    Search: "Search",
    Bookmarks: "Bookmarks",
  },
};

export const deDE: Translations = {
  translations: {
    Outline: "Kursübersicht",
    Coursebook: "Kursbuch",
    Notes: "Aufzeichnungen",
    Questions: "Fragen",
    Search: "Suche",
    Bookmarks: "Lesezeichen",
  },
};
