import { gql, makeVar, useApolloClient, useMutation, useReactiveVar } from "@apollo/client";
import { useCallback, useMemo } from "react";
import { useErrorHandler } from "./useErrorHandler";
import { useTestMode } from "./useTestMode";
import { emptyProgress } from "../util/fakeCourse";
import { CompletionReason } from "../__generated__/globalTypes";
import { SaveCompletion } from "./__generated__/SaveCompletion";
import { base64ToObjectURL } from "../util/base64";
import {
  InstanceProgressFragment,
  InstanceProgressFragment_progress,
} from "./__generated__/InstanceProgressFragment";
import { CourseProgressFragment } from "./__generated__/CourseProgressFragment";
import { useAppState } from "./useAppState";
import { SaveCourseProgress } from "./__generated__/SaveCourseProgress";
import { CourseQuery_instance_progress_lastView } from "../pages/Player/__generated__/CourseQuery";
import { getSecondsFromTimestamp } from "../util/time";
import { SlideViewsQuery } from "./__generated__/SlideViewsQuery";

export const noCourseError = "no course";

export const GET_CERT_QUERY = gql`
  query GetCert($courseUuid: String!) {
    legacyCert(courseUuid: $courseUuid)
  }
`;

export const LOCAL_COURSE_PROGRESS_FRAGMENT = gql`
  fragment CourseProgressFragment on Course {
    sections {
      modules {
        id
        name
        fullNumber
        type
        quizScore
        duration
        progress @client {
          viewedSlides
        }
      }
    }
  }
`;

export const LOCAL_COURSE_COMPLETION_FRAGMENT = gql`
  fragment CourseCompletionFragment on Course {
    completion {
      dateTimeCompleted
      reason
    }
  }
`;

export const LOCAL_INSTANCE_PROGRESS_FRAGMENT = gql`
  fragment InstanceProgressFragment on TrainingInstance {
    progress {
      instanceId
      viewedSeconds
      totalSeconds
      viewedSlides
      totalSlides
      totalQuizzes
      passedQuizzes
      complete
      hoursRemaining @client
      percentSlides @client
      percentQuizzes @client
      lastView {
        slideId
        resumeTime
        dateTimeViewed
      }
      moduleProgress {
        id
        name
        type
        fullNumber
        viewedSlides
        totalSlides
        quizScore
      }
    }
  }
`;

export const TRAINING_EXPIRATION_FRAGMENT = gql`
  fragment TrainingExpirationFragment on RosterUser {
    expiration @client {
      expirationText
      expirationDate
      isExpired
    }
  }
`;

export const INSTANCE_EXPIRATION_FRAGMENT = gql`
  fragment InstanceExpirationFragment on TrainingInstance {
    expiration @client {
      expirationText
      expirationDate
      isExpired
    }
  }
`;

export const SAVE_COMPLETION_QUERY = gql`
  mutation SaveCompletion($courseUuid: String!, $trainingId: Int!, $reason: CompletionReason!) {
    saveCompletion(courseUuid: $courseUuid, trainingId: $trainingId, reason: $reason) {
      reason
    }
  }
`;

export const START_COURSE_MUTATION = gql`
  mutation StartCourseMutation($courseId: Int!) {
    startCourse(courseId: $courseId) {
      id
      userType
      registrationDeadline
      expires
      orderId
      created
      certification {
        name
        initials
        imageUrl
      }
      giacAvailable
      giacPurchased
      shippingTracking {
        deliveryStatus
        trackingNumber
      }
      trainingInstance {
        id
        expirationDateTime
        userId
        progress {
          instanceId
          viewedSeconds
          totalSeconds
          viewedSlides
          totalSlides
          totalQuizzes
          passedQuizzes
          complete
          moduleProgress {
            id
            name
            type
            fullNumber
            viewedSlides
            totalSlides
            quizScore
          }
        }
      }
      course {
        id
        trainingId
        name
        hasMyLabs
        quickstartGuideUrl
        shortName
        completion {
          dateTimeCompleted
          reason
        }
        licenses {
          title
          url
        }
        handouts {
          title
          url
        }
      }
    }
  }
`;

export const SAVE_COURSE_QUERY = gql`
  mutation SaveCourseProgress($courseProgress: CourseProgressInput!) {
    saveCourseProgress(courseProgress: $courseProgress) {
      userId
      instanceId
    }
  }
`;

export const SLIDE_VIEWS_QUERY = gql`
  query SlideViewsQuery($courseId: Int!) {
    course(courseId: $courseId) {
      sections {
        modules {
          slides {
            id
            moduleId
            duration
            lastView {
              slideId
              resumeTime
              dateTimeViewed
            }
          }
        }
      }
    }
  }
`;

const progressVar = makeVar<{
  [instanceId: string]: InstanceProgressFragment_progress | undefined;
}>({});

// eslint-disable-next-line sonarjs/cognitive-complexity
export function useCourseProgress(
  courseId?: number,
  courseUuid?: string,
  instanceId?: string,
  rosterUserId?: string,
) {
  const { testMode } = useTestMode();
  const { throwError } = useErrorHandler();
  const { module, refreshModule } = useAppState();

  const client = useApolloClient();
  const {
    userId,
    appState: { slide, moduleId },
  } = useAppState();

  const progress = useReactiveVar(progressVar);

  const expiration = useMemo(() => {
    if (rosterUserId) {
      const trainingExpirationFragment = client.readFragment({
        id: `RosterUser:${rosterUserId}`,
        fragment: TRAINING_EXPIRATION_FRAGMENT,
      });

      return trainingExpirationFragment?.expiration;
    } else if (instanceId) {
      const instanceExpirationFragment = client.readFragment({
        id: `TrainingInstance:${instanceId}`,
        fragment: INSTANCE_EXPIRATION_FRAGMENT,
      });

      return instanceExpirationFragment?.expiration;
    }
  }, [client, rosterUserId, instanceId]);

  const completion = useMemo(() => {
    if (!instanceId) return;

    const instanceProgressFragment = client.readFragment<InstanceProgressFragment>({
      id: `TrainingInstance:${instanceId}`,
      fragment: LOCAL_INSTANCE_PROGRESS_FRAGMENT,
    });

    const instanceProgress = instanceProgressFragment?.progress;
    return instanceProgress?.complete;
  }, [client, instanceId]);

  const saveCompletion = useCallback(
    async (reason: CompletionReason): Promise<void> => {
      if (!courseId) return throwError(noCourseError);

      await client.mutate<SaveCompletion>({
        mutation: SAVE_COMPLETION_QUERY,
        variables: {
          trainingId: courseId,
          courseUuid,
          reason,
        },
        update: (cache) =>
          cache.modify({
            id: `Course:{"trainingId":${courseId}}`,
            fields: {
              completion: () => ({ dateTimeCompleted: new Date(), reason }),
              complete: () => true,
            },
          }),
      });
    },
    [courseId, client, throwError, courseUuid],
  );

  const syncCourseSlideViews = useCallback(async () => {
    const queryResult = client.cache.readQuery<SlideViewsQuery>({
      query: SLIDE_VIEWS_QUERY,
      variables: { courseId },
      returnPartialData: true,
    });

    const slides =
      queryResult?.course.sections
        .flatMap((section) => section.modules)
        .flatMap((module) => module.slides) ?? [];

    const [courseViewedSeconds, courseViewedSlides] = slides.reduce(
      (acc, slide) => {
        const [seconds, slides] = acc;
        return [
          slide.lastView ? seconds + getSecondsFromTimestamp(slide.duration) : seconds,
          slide.lastView ? slides + 1 : slides,
        ];
      },
      [0, 0],
    );

    client.cache.modify({
      id: `CourseProgress:{"instanceId":"${instanceId}"}`,
      fields: {
        viewedSeconds: () => courseViewedSeconds,
        viewedSlides: () => courseViewedSlides,
      },
    });
  }, [client, courseId, instanceId]);

  const getCourseProgress = useCallback(
    (lastView?: CourseQuery_instance_progress_lastView) => {
      const courseProgressFragment = client.readFragment<CourseProgressFragment>({
        id: `Course:{"trainingId":${courseId}}`,
        fragment: LOCAL_COURSE_PROGRESS_FRAGMENT,
      });

      const courseSections = courseProgressFragment?.sections;

      const instanceProgressFragment = client.readFragment<InstanceProgressFragment>({
        id: `TrainingInstance:${instanceId}`,
        fragment: LOCAL_INSTANCE_PROGRESS_FRAGMENT,
      });

      const instanceProgress = instanceProgressFragment?.progress;

      if (!instanceProgress) return;

      if (!module) return instanceProgress;

      const slide = client.readFragment({
        id: `Slide:${lastView?.slideId}`,
        fragment: gql`
          fragment SlideProgress on Slide {
            duration
            lastView {
              slideId
              resumeTime
              dateTimeViewed
            }
          }
        `,
      });

      const newView = slide && !slide.lastView;

      return {
        ...instanceProgress,
        viewedSeconds:
          instanceProgress.viewedSeconds + (newView ? getSecondsFromTimestamp(slide.duration) : 0),
        passedQuizzes:
          courseSections
            ?.flatMap((section) => section.modules)
            .reduce((acc, module) => {
              return module?.quizScore ? acc + (module.quizScore >= 80 ? 1 : 0) : acc;
            }, 0) ?? instanceProgress.passedQuizzes,
        viewedSlides: instanceProgress.viewedSlides + (newView ? 1 : 0),
        lastView: lastView ?? instanceProgress.lastView,
        moduleProgress: instanceProgress.moduleProgress.map((moduleProgress) => {
          const courseProgressModule = courseSections
            ?.flatMap((section) => section.modules)
            .find((module) => module.id === moduleProgress.id);
          const viewedSlides =
            courseProgressModule?.progress.viewedSlides || moduleProgress.viewedSlides;
          return {
            ...moduleProgress,
            viewedSlides:
              module && moduleId === moduleProgress.id
                ? viewedSlides + (newView ? 1 : 0)
                : viewedSlides,
            quizScore:
              courseSections
                ?.flatMap((section) => section.modules)
                .find((module) => module.id === moduleProgress.id)?.quizScore ??
              moduleProgress.quizScore,
          };
        }),
        sections: undefined,
      };
    },
    [client, courseId, moduleId, instanceId, module],
  );

  const saveCourseProgress = useCallback(
    async (
      moduleRefresh: boolean,
      lastView?: CourseQuery_instance_progress_lastView,
    ): Promise<void> => {
      if (!courseId || !instanceId) return;

      const progress = getCourseProgress(lastView);

      if (!progress) return;

      if (!progress.complete) {
        const percentSlides = Math.ceil((progress.viewedSlides / progress.totalSlides) * 100);
        const percentQuizzes = Math.ceil((progress.passedQuizzes / progress.totalQuizzes) * 100);
        if (percentSlides >= 80) {
          await saveCompletion(CompletionReason.SLIDES_VIEWED);
          progress.complete = true;
        } else if (percentQuizzes >= 80) {
          await saveCompletion(CompletionReason.QUIZZES_PASSED);
          progress.complete = true;
        }
      }

      progressVar({
        ...progressVar(),
        [instanceId]: progress,
      });

      client.writeFragment({
        id: `TrainingInstance:${instanceId}`,
        fragment: LOCAL_INSTANCE_PROGRESS_FRAGMENT,
        data: {
          __typename: "TrainingInstance",
          progress: progress,
        },
      });

      client.cache.modify({
        id: `Slide:${slide?.id}`,
        fields: { lastView: () => lastView },
      });

      if (moduleRefresh) refreshModule();

      await client.mutate<SaveCourseProgress>({
        mutation: SAVE_COURSE_QUERY,
        variables: {
          courseProgress: {
            ...progress,
            courseId,
            instanceId,
            userId,
            lastView: { ...progress.lastView, __typename: undefined },
            total: undefined,
            viewed: undefined,
            percent: undefined,
            hoursRemaining: undefined,
            percentQuizzes: undefined,
            percentSlides: undefined,
            __typename: undefined,
            moduleProgress: progress.moduleProgress.map((module) => ({
              ...module,
              percentSlides: undefined,
              __typename: undefined,
            })),
          },
          instanceId,
          courseId,
        },
      });
    },
    [
      courseId,
      client,
      instanceId,
      userId,
      slide?.id,
      getCourseProgress,
      refreshModule,
      saveCompletion,
    ],
  );

  const openCert = useCallback(async () => {
    if (!courseId) return throwError(noCourseError);

    const {
      data: { legacyCert },
    } = await client.query({ query: GET_CERT_QUERY, variables: { courseUuid } });

    window.open(base64ToObjectURL(legacyCert), "_blank");
  }, [throwError, client, courseUuid, courseId]);

  const [startCourse] = useMutation(START_COURSE_MUTATION);

  const updateProgressState = useCallback(async () => {
    if (testMode) return progressVar(emptyProgress);
    if (!courseId || !instanceId) return;

    const progress = getCourseProgress();

    client.writeFragment({
      id: `TrainingInstance:${instanceId}`,
      fragment: LOCAL_INSTANCE_PROGRESS_FRAGMENT,
      data: {
        __typename: "TrainingInstance",
        progress: progress,
      },
    });

    progressVar({
      ...progressVar(),
      [instanceId]: progress,
    });
  }, [client, courseId, instanceId, testMode, getCourseProgress]);

  return {
    progress: instanceId ? progress[instanceId] : emptyProgress,
    expiration,
    completion,
    openCert,
    updateProgressState,
    startCourse,
    saveCompletion,
    saveCourseProgress,
    getCourseProgress,
    syncCourseSlideViews,
  };
}
