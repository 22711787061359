import { primary, blue, blueGrey, greyscale, success, error, white, black } from "./palette";
import { hexToRgba } from "../util/hexToRgba";

export const darkPalette = {
  palette: {
    background: {
      default: "#16181F",
    },
    text: {
      primary: white,
      secondary: greyscale[400],
      link: blue[300],
      linkHover: blue[500],
      disabled: greyscale[400],
      error: error[500],
      success: success[500],
      highContrast: white,
      book: white,
      title: blue[300],
      titleGrey: white,
      titlePrimary: primary.light,
    },
  },
  components: {
    MuiPaper: { styleOverrides: { root: { background: "#252830" } } },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: white,
        },
      },
    },
    MuiLoadingButton: {
      styleOverrides: { root: { color: blue[200], borderColor: blue[200] } },
    },
    MuiCssBaseline: {
      styleOverrides: `
        input {
          background: ${greyscale[700]};
          color: ${white};
          border: 1px solid ${blue[300]};
        },
        input::placeholder {
          color: ${white};
        }
      `,
    },
  },
  custom: {
    palette: {
      semantic: {
        blue: blue[300],
        blueGrey: blue[200],
        grey: greyscale[800],
        greyLight: greyscale[300],
        greyDark: greyscale[200],
        greyExpired: greyscale[500],
        rowAlternatingBg: greyscale[800],
        rowActiveBg: primary.light,
        sliderThumbOuterShadow: hexToRgba(white, 0.16),
        modalBg: "#252830",
        modalTitleColor: greyscale[200],
        modalHeaderColor: white,
        menuBg: "#353944",
        menuHoverBg: white,
        inputIconColor: white,
        inputBg: greyscale[700],
        inputBgOpaque: hexToRgba(greyscale[700], 0.35),
        accordionBorder: "transparent",
        accordionBg: blueGrey[600],
        iconFailedColor: greyscale[50],
        iconSomewhatColor: greyscale[50],
        iconHoverColor: greyscale[500],
        iconColor: greyscale[700],
        linearProgressBg: greyscale[700],
        rule: hexToRgba(greyscale[800], 0.5),
        darkBg: greyscale[800],
        wrapperBgDark: hexToRgba(black, 0.1),
        readMessage: greyscale[700],
        buttonContainedBg: blue[300],
        buttonContainedBorder: blue[300],
        buttonContainedColor: "#16181F",
        buttonContainedIcon: "#16181F",
        buttonContainedFocusBg: blue[200],
        buttonContainedFocusBorder: blue[100],
        buttonContainedFocusColor: "#16181F",
        buttonContainedFocusIcon: "#16181F",
        buttonContainedHoverBg: blue[200],
        buttonContainedHoverBorder: blue[200],
        buttonContainedHoverColor: "#16181F",
        buttonContainedHoverIcon: "#16181F",
        buttonContainedDisabledBg: blueGrey[100],
        buttonContainedDisabledBorder: blueGrey[100],
        buttonContainedDisabledColor: greyscale[600],
        buttonContainedDisabledIcon: greyscale[600],
        buttonOutlinedBg: "transparent",
        buttonOutlinedBorder: blue[300],
        buttonOutlinedColor: blue[300],
        buttonOutlinedIcon: blue[300],
        buttonOutlinedFocusBg: blue[700],
        buttonOutlinedFocusBorder: blue[300],
        buttonOutlinedFocusColor: blue[300],
        buttonOutlinedFocusIcon: blue[300],
        buttonOutlinedHoverBg: blue[700],
        buttonOutlinedHoverBorder: blue[300],
        buttonOutlinedHoverColor: blue[300],
        buttonOutlinedHoverIcon: blue[300],
        buttonOutlinedDisabledBg: "transparent",
        buttonOutlinedDisabledBorder: blueGrey[400],
        buttonOutlinedDisabledColor: blueGrey[400],
        buttonOutlinedDisabledIcon: blueGrey[400],
        buttonIconColor: white,
        buttonIconHoverColor: blue[300],
        buttonIconFocusColor: blue[200],
        buttonIconDisabledColor: blueGrey[400],
        buttonIconSecondaryColor: white,
        buttonIconSecondaryHoverColor: blue[300],
        buttonIconSecondaryFocusColor: blue[200],
      },
    },
    gradients: {
      rowBgFade: `linear-gradient(to top, #252830, ${hexToRgba("#252830", 0)})`,
      rowAlternatingBgFade: `linear-gradient(to top, ${greyscale[800]}, ${hexToRgba(
        greyscale[800],
        0,
      )})`,
    },
  },
};
