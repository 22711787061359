import React, { useContext } from "react";
import { Campaign, Help } from "@mui/icons-material";
import { useMessages } from "../../hooks/useMessages";
import { MessagesModal } from "./MessagesModal";
import { UserMenu } from "./UserMenu";
import { HeaderLogo } from "./HeaderLogo";
import { registrationUrl } from "../../util/registrationUrl";
import {
  TypeWrapper,
  ToolbarWrapper,
  CourseName,
  MessageButton,
  HeaderWrapper,
  ToolbarInner,
  ImageWrapper,
  HeaderSkeleton,
  HeaderButton,
} from "./Header.elements";
import { QCTicketModal } from "../QCTicket/QCTicketModal";
import { Alert, Tooltip } from "@mui/material";
import { useUserInfo } from "@sans-tesseract/sdk-react";
import { HasbotContext } from "../../context/hasBot-context";
import { useQCTicketModal } from "../../hooks/useQCTicketModal";
import { CourseContext } from "../../context/course-context";
import MuiSkeleton from "@mui/material/Skeleton";
import Avatar from "@mui/material/Avatar";
import { Recaption } from "./Recaption";

export function Header({ library = false }: { library?: boolean }): JSX.Element | null {
  const course = useContext(CourseContext);
  const { setModalOpen, unreadCount } = useMessages(library);
  const userInfo = useUserInfo() as unknown as Record<string, string> | undefined;
  const { setShowDialog } = useContext(HasbotContext);
  const isDemoCourse = course?.courseType === "Demo" ? true : false;
  const isQCCourse = course?.courseType === "QC" ? true : false;
  const tabIndex = 1;
  const { modalOpen, openModal, closeModal } = useQCTicketModal();

  const handleRegisterClick = () => {
    if (course?.shortName) {
      window.location.href = registrationUrl(course.shortName);
    }
  };

  return (
    <HeaderWrapper role="header" aria-labelledby="course_title" id="header" data-testid="header">
      {course || library ? (
        <>
          <ToolbarWrapper variant="dense">
            <ToolbarInner>
              <ImageWrapper>
                <HeaderLogo />
              </ImageWrapper>
              <TypeWrapper>
                <CourseName variant="h5" component="h1" id="course_title">
                  {course && !library ? (
                    <>
                      <span className="ondemand-course-name__text">{course.name}</span>
                      <span className="ondemand-course-number__text">{course.shortName}</span>
                    </>
                  ) : (
                    library && (
                      <span className="ondemand-course-library">My OnDemand Course Library</span>
                    )
                  )}
                </CourseName>
              </TypeWrapper>
            </ToolbarInner>
            <ToolbarInner>
              {!library && (
                <MessageButton
                  tabIndex={tabIndex}
                  variant="outlined"
                  startIcon={<Help />}
                  onClick={() => setShowDialog(true)}
                  id="ask-question-btn"
                  data-testid="ask-question-btn"
                >
                  <span className="message-text">Ask a Question</span>
                </MessageButton>
              )}
              <Tooltip title={unreadCount > 0 ? "" : "No Notifications"}>
                <MessageButton
                  tabIndex={isDemoCourse ? tabIndex + 1 : tabIndex}
                  variant="outlined"
                  startIcon={<Campaign />}
                  onClick={() => setModalOpen(true)}
                  id="message-btn"
                  data-testid="message-btn"
                  className="notificationsButton"
                >
                  <span className="message-text">Notifications&nbsp;</span>
                  <span hidden={unreadCount === 0} className="message-length">
                    {unreadCount > 0 && `(${unreadCount})`}
                  </span>
                </MessageButton>
              </Tooltip>
              {course && (
                <>
                  {isDemoCourse && course.shortName && (
                    <MessageButton
                      tabIndex={tabIndex}
                      variant="outlined"
                      onClick={() => handleRegisterClick()}
                      id="register-btn"
                    >
                      <span className="message-text">Register Now</span>
                    </MessageButton>
                  )}

                  {isQCCourse && (
                    <>
                      <HeaderButton
                        tabIndex={tabIndex}
                        variant="outlined"
                        onClick={() => openModal()}
                        id="qc-ticket-btn"
                      >
                        <span id="submit-ticket" className="message-text">
                          Submit Ticket
                        </span>
                      </HeaderButton>
                      <Recaption />
                    </>
                  )}
                </>
              )}
              {userInfo?.assumedBy && (
                <Alert id="impersonation-alert" severity="info" sx={{ marginRight: "1em" }}>
                  You are currently impersonating {userInfo.given_name} {userInfo.family_name}
                </Alert>
              )}
              <UserMenu library={library} />
            </ToolbarInner>
          </ToolbarWrapper>
          <MessagesModal isLibrary={library} />
          <QCTicketModal open={modalOpen} onClose={() => closeModal()} />
        </>
      ) : (
        <HeaderSkeleton data-testid="headerSkeleton">
          <MuiSkeleton animation="wave" variant="text" sx={{ height: 32, width: "50%" }} />
          <MuiSkeleton animation="wave" variant="circular">
            <Avatar />
          </MuiSkeleton>
        </HeaderSkeleton>
      )}
    </HeaderWrapper>
  );
}
