/* eslint-disable sonarjs/no-duplicate-string */
import { styled } from "@mui/material/styles";
import { Box, CircularProgress } from "@mui/material";

export const Container = styled(Box)(() => ({
  position: "relative",
  height: "100%",
  width: "100%",
  backgroundColor: "black",
  display: "flex",
  flexFlow: "column",
  zIndex: 0,
}));

export const Spinner = styled(CircularProgress)(() => ({
  marginTop: "10em",
  marginBottom: "10em",
  position: "absolute",
  zIndex: 1,
}));

export const PlayerContainer = styled("div")(({ theme }) => ({
  position: "relative",
  width: "100%",
  flex: "1 1 100%",

  "&.fullscreen": {
    height: "100%",
  },

  "& #video_player": {
    position: "relative",
    paddingTop: 0,
    width: "100% !important",
    height: "100% !important",
  },

  "& #video_player video": {
    width: "100% !important",
    height: "100% !important",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",

    [theme.breakpoints.down("md")]: {
      position: "initial",
      transform: "none",
      left: 0,
      top: 0,
    },
  },

  "&.fullscreen #video_player": {
    ["@media all and (orientation:landscape)"]: {
      paddingTop: "0",
    },
  },

  "&.fullscreen #video_player video": {
    width: "100% !important",

    ["@media all and (orientation:portrait)"]: {
      height: "auto !important",
      top: "50%",
      left: "0",
      transform: "translate(0, -50%)",
    },
  },
}));

export const CaptionsContainer = styled("div")(() => ({
  flex: "1 1 auto",
}));
