import React from "react";
import { StyledButton } from "./Error.elements";
import { ReactComponent as Bugs } from "./bugs.svg";
import { telemetryChannelName } from "../../util/constants";
import { useAnalyticsEvents } from "@atlaskit/analytics-next";
import { Heading, Message, Wrapper } from "./BigError.elements";

export function BigError({ message }: { message: string }): JSX.Element {
  const { createAnalyticsEvent } = useAnalyticsEvents();

  const reload = () => {
    createAnalyticsEvent({
      feature: "error",
      action: "reload",
    }).fire(telemetryChannelName);
    location.reload();
  };

  return (
    <Wrapper>
      <Bugs style={{ width: "50%", margin: "auto", height: "auto" }} />
      <Heading>Oh no!</Heading>
      <p>Something went wrong.</p>
      <div>
        <StyledButton aria-label="Try Again" onClick={reload}>
          Try Again
        </StyledButton>
      </div>
      <Message>{message}</Message>
    </Wrapper>
  );
}
