import { LinearProgress, Link, linearProgressClasses } from "@mui/material";
import { styled } from "@mui/material/styles";

export const ProgressContainer = styled("div")({
  padding: "16px 16px 0px 16px",
});

export const ProgressHorizontalLine = styled("hr")(({ theme }) => ({
  borderTop: "1px solid",
  borderColor: theme.custom.palette.semantic.rule,
  margin: "1em -1em 0em -1em",
}));

export const ProgressHeader = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  textAlign: "left",
  marginBottom: "0.6875em",
  alignItems: "center",
  "& .progress-percent h2": {
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
    fontSize: "1.125rem",
    lineHeight: "22px",
  },
  "& > a": {
    fontWeight: 600,
    fontSize: "0.75rem",
    lineHeight: "16px",
    color: theme.palette.text.link,
  },
}));

export const ProgressTextWrapper = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  fontSize: "1.5em",
  fontWeight: 500,
});

export const ProgressText = styled("p")(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 400,
  fontSize: "0.875rem",
  lineHeight: "150%",
  margin: "0px",
}));

export const CourseExpiration = styled("div")(({ theme }) => ({
  display: "flex",
  textAlign: "left",
  gap: theme.spacing(1),
  "& p": {
    color: theme.palette.text.primary,
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: "150%",
  },
}));

export const ProgressBar = styled(LinearProgress)(({ theme }) => ({
  marginTop: "0.3em",
  marginBottom: "0.5em",
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.custom.palette.semantic.linearProgressBg,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: "6px",
    backgroundColor: theme.custom.palette.semantic.rowActiveBg,
  },
}));

export const DetailsLink = styled(Link)(({ theme }) => ({
  border: 0,
  padding: 0,
  fontSize: "1.1em",
  cursor: "pointer",
  fontWeight: 600,
  lineHeight: "16px",
  textDecorationColor: theme.palette.text.link,
  color: theme.palette.text.link,
  "&:hover": {
    background: "transparent",
    textDecoration: "underline",
    color: theme.palette.text.linkHover,
  },
}));
