import React, { useEffect } from "react";
import { Stack } from "@mui/material";
import { useAppState } from "../../hooks/useAppState";
import {
  LandingPageWrapper,
  Wrapper,
  StyledTextButton,
  StyledTypography,
} from "./QuizLanding.elements";
import { QuizButton } from "./Quiz.elements";
import { quizPracticeLink } from "../../util/constants";
import { useQuizQuery } from "./Quiz.queries";

interface QuizLandingProps {
  beginQuiz: () => void;
  sectionName?: string;
  disabled?: boolean;
}

const QuizLanding = ({ beginQuiz, disabled }: QuizLandingProps) => {
  const { module, nextModule } = useAppState();
  const { runQuestionCount, questionCount } = useQuizQuery();

  const returnToCourse = () => nextModule();

  const quizInProgress = module?.quizInProgress;
  const alreadyTaken = module?.quizScore;

  useEffect(() => {
    if (module) {
      runQuestionCount();
    }
  }, [module, runQuestionCount]);

  const correctAnswers = questionCount && Math.ceil(questionCount * 0.8);

  return (
    <LandingPageWrapper>
      <Wrapper>
        <StyledTypography variant="h5">
          This quiz will reinforce what you’ve learned in this OnDemand course.
          <br />
          {questionCount && (
            <>
              It will be {questionCount} questions and a passing score is 80% ({correctAnswers}{" "}
              correct answers)
            </>
          )}
        </StyledTypography>
        <StyledTypography variant="body1">
          Please visit{" "}
          <a href={quizPracticeLink} rel="noreferrer" target="_blank" id="quiz-practice-link">
            {quizPracticeLink}
          </a>{" "}
          to review your practice test options.
        </StyledTypography>
        <Stack justifyContent="center" alignItems="center">
          <QuizButton
            id={(quizInProgress ? "resume" : alreadyTaken ? "retake" : "begin") + "-quiz-btn"}
            variant="contained"
            onClick={beginQuiz}
            disabled={disabled}
            sx={{ mb: 4 }}
          >
            {quizInProgress ? "Resume" : alreadyTaken ? "Retake" : "Begin"}
          </QuizButton>
          <StyledTextButton
            id="landing-return-to-course-btn"
            variant="text"
            onClick={returnToCourse}
          >
            Return to course
          </StyledTextButton>
        </Stack>
      </Wrapper>
    </LandingPageWrapper>
  );
};

export default QuizLanding;
