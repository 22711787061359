/* eslint-disable sonarjs/no-duplicate-string */
import React, { useCallback, useState } from "react";
import { DialogTitle, Divider, IconButton, Link, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { moduleType } from "../../__generated__/globalTypes";
import {
  StyledDialog,
  StyledDialogContent,
  CompletionCert,
  ModuleProgress,
  ProgressContainer,
  QuizScore,
  DownloadCertButton,
} from "./ProgressModal.elements";
import { useAppState } from "../../hooks/useAppState";
import ProgressOverview from "./ProgressOverview";
import { useProgressModal } from "../../hooks/useProgressModal";
import { useCourseProgress } from "../../hooks/useCourseProgress";
import DetailedProgressBar from "./DetailedProgressBar";
import { getFirstModuleIndex } from "../../util/course";

export function ProgressModal(): JSX.Element | null {
  const { setModule } = useAppState();

  const { modalOpen, modalParams, closeModal } = useProgressModal();

  const { progress, openCert, expiration } = useCourseProgress(
    modalParams.courseId,
    modalParams.courseUuid,
    modalParams.instanceId,
  );

  const [downloadingCert, setDownloadingCert] = useState(false);

  const downloadCert = useCallback(async () => {
    setDownloadingCert(true);
    await openCert();
    setDownloadingCert(false);
  }, [openCert]);

  if (!progress) {
    return null;
  }

  const handleClick = (
    e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLSpanElement>,
    id: string | null,
  ): void => {
    e.preventDefault();
    if (id) {
      closeModal();
      setModule(id);
    }
  };

  const { moduleProgress } = progress;
  const firstModuleIndex = getFirstModuleIndex(moduleProgress);

  return (
    <StyledDialog
      open={modalOpen}
      onClose={() => closeModal()}
      maxWidth="md"
      aria-labelledby="progress_modal_title"
    >
      <DialogTitle id="progress_modal_title" aria-label="course progress modal">
        Progress
        <IconButton
          id="progress_modal_close"
          data-testid="progress_modal_close"
          aria-label="close modal"
          autoFocus
          onClick={() => closeModal()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <StyledDialogContent>
        <CompletionCert>
          <h3 className="title">Certificate of Completion</h3>
          {progress.complete ? (
            <DownloadCertButton loading={downloadingCert} onClick={() => downloadCert()}>
              Download
            </DownloadCertButton>
          ) : (
            <p className="content">
              Once you complete the course, your certificate can be accessed here.
            </p>
          )}
        </CompletionCert>
        <ProgressContainer>
          <ProgressOverview title="Overall Progress" progress={progress} expiration={expiration} />
        </ProgressContainer>
        <Divider />
        <ProgressContainer id="progress_modal_list">
          <Typography className="section-progress-title" variant="h5" component="h3">
            Section Progress
          </Typography>
          {moduleProgress
            .filter((progress) => [moduleType.CONTENT, moduleType.QUIZ].includes(progress.type))
            .map((module, idx) => (
              <ModuleProgress id={"module_list_item_" + idx} key={idx}>
                <Link href="" onClick={(e) => handleClick(e, module.id)}>
                  <p className={module.type === moduleType.QUIZ ? "section-quiz" : "section-name"}>
                    {module.type !== moduleType.QUIZ
                      ? `${module.fullNumber} ${module.name}`
                      : `Section ${Math.abs(parseInt(module.fullNumber || "0"))} Quiz`}
                  </p>
                </Link>
                {module.type === moduleType.QUIZ ? (
                  module.quizScore ? (
                    <QuizScore pass={module.quizScore >= 80}>
                      Best score: {module.quizScore}
                    </QuizScore>
                  ) : null
                ) : (
                  <DetailedProgressBar
                    compact={false}
                    isExpired={false}
                    isQuizProgress={false}
                    complete={module.viewedSlides ?? 0}
                    total={module.totalSlides ?? 0}
                  />
                )}
              </ModuleProgress>
            ))}
        </ProgressContainer>
      </StyledDialogContent>
    </StyledDialog>
  );
}
