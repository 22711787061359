/* eslint-disable */
import { styled } from "@mui/material/styles";
import { Dialog, DialogContent, LinearProgress, linearProgressClasses } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { paddedBox } from "../../styles/shared";

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  [".MuiDialog-paper"]: {
    background: theme.custom.palette.semantic.modalBg,
  },
  ["& h2"]: {
    paddingBottom: 0,
    fontWeight: 600,
    textAlign: "left",
    fontSize: "1.5rem",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.125rem",
      marginBottom: theme.spacing(1),
    },
    "& button": {
      color: theme.palette.text.titlePrimary,
    },
  },
  "& .MuiPaper-root": {
    borderRadius: "16px",
  },
}));

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "45rem",
  width: "50rem",
  [theme.breakpoints.down("md")]: {
    height: "100%",
    width: "100%",
  },
}));

export const CompletionCert = styled("div")(({ theme }) => ({
  // https://github.com/mui/material-ui/issues/30569#issuecomment-1144502392
  ...(paddedBox({ theme, centerText: true }) as any),

  [theme.breakpoints.down("sm")]: {
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
    borderRadius: "16px",
  },

  [">.title"]: {
    fontSize: "1.25rem",
    fontWeight: 600,
    lineHeight: "24px",
    margin: "0px",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },

  [">.content"]: {
    fontSize: "1rem",
    margin: "0px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
    },
  },
}));

type ProgressProps = {
  margin?: string;
  compact?: boolean;
};

export const ModuleProgress = styled("div", {
  shouldForwardProp: (props) => props !== "margin" && props !== "compact",
})<ProgressProps>(({ theme, margin, compact = false }) => ({
  width: compact ? "100%" : "initial",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: compact ? "0.15rem 0" : "0.5rem 0",
  fontWeight: 400,
  fontSize: "0.875rem",
  margin: margin ? margin : "",
  lineHeight: "150%",
  color: theme.palette.text.primary,
  "& a": {
    color: theme.palette.text.link,
    textDecorationColor: theme.palette.text.link,
    "&:hover": {
      color: theme.palette.text.linkHover,
      textDecorationColor: theme.palette.text.linkHover,
    },
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.625rem",
  },
  "& .section-quiz": {
    fontWeight: 600,
    padding: "0.125rem 0 0.25rem",
    margin: "0px",
  },
  "& .section-name": {
    margin: "0px",
  },
  "& p.section-quiz, p.section-name": {
    cursor: "pointer",
  },
  "& p.progress": {
    marginRight: "10px",
  },
}));

export const QuizScore = styled("p")<{ pass: boolean }>(({ theme, pass }) => ({
  fontSize: "0.75rem",
  fontWeight: 600,
  color: pass ? theme.palette.success[500] : theme.palette.error[500],
}));

export const ProgressContainer = styled("div")(({ theme }) => ({
  padding: `${theme.spacing(1)} 0`,
  "& h3": {
    fontSize: "1.125rem",
    fontWeight: 600,
    lineHeight: "24px",
    textAlign: "left",
    color: theme.custom.palette.semantic.modalTitleColor,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
    },
  },
}));

export const DetailedProgressBarContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "compact",
})<{ compact: boolean }>(({ theme, compact }) => ({
  width: "100px",
  maxWidth: compact ? "auto" : "100px",
  flex: compact ? 1 : "auto",
  [">.text"]: {
    color: theme.custom.palette.semantic.blue,
    fontSize: "0.75rem",
    fontWeight: 600,
    textAlign: "left",
    margin: "0px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.625rem",
    },
  },
}));

export const ProgressBar = styled(LinearProgress)(({ theme }) => ({
  height: "8px",
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.custom.palette.semantic.linearProgressBg,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: "6px",
    backgroundColor: theme.custom.palette.semantic.blue,
  },
}));

export const DownloadCertButton = styled(LoadingButton)(({ theme }) => ({
  fontSize: "1rem",
  textDecoration: "underline",
  padding: "0 0.3rem",
  color: theme.custom.palette.semantic.blueGrey,
}));
