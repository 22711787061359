/* eslint-disable sonarjs/cognitive-complexity */
import { useAnalyticsEvents } from "@atlaskit/analytics-next";
import { ArrowBack, ArrowDropDown, ArrowDropUp, Check, Close } from "@mui/icons-material";
import { Grid, Tooltip } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { render } from "react-dom";
import { Item, ItemsApp } from "../../../types/learnosity";
import { useAppState } from "../../hooks/useAppState";
import { ErrorSeverity, useErrorHandler } from "../../hooks/useErrorHandler";
import {
  Assessment,
  AssessmentWrapper,
  PercentCircle,
  SeeWhyStyledButton,
  QuestionHeader,
  QuizBox,
  QuizButton,
  QuizResultsActions,
  UuidContainer,
} from "./Quiz.elements";
import { useQuizQuery } from "./Quiz.queries";
import QuizLanding from "./QuizLanding";
import { StyledTextButton } from "./QuizLanding.elements";
import { sanitize } from "../../util/html";
import IconButton from "@mui/material/IconButton";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import CheckIcon from "@mui/icons-material/Check";
import { copyToClipboard } from "../../util/copyToClipboard";

const passingPercent = 80;

type QuizAnswerStatus = "unanswered" | "correct" | "incorrect";
type QuizAnswerStatusMap = { [id: string]: QuizAnswerStatus };

export const UuidWrapper = ({
  uuid,
  copied,
  onCopy,
}: {
  uuid: string;
  copied: boolean;
  onCopy: () => void;
}) => {
  return (
    <UuidContainer>
      <p>ID: {uuid}</p>
      <Tooltip title="Copy to clipboard.">
        <IconButton
          aria-label={copied ? "Copied" : "Copy to clipboard"}
          role="button"
          onClick={onCopy}
          color="primary"
        >
          {copied ? <CheckIcon /> : <FileCopyIcon />}
        </IconButton>
      </Tooltip>
    </UuidContainer>
  );
};

export function setSeeWhyContent(itemMetadata: any) {
  const seeWhyText = itemMetadata[0][1].questions[0].metadata.sample_answer;
  const bookPage = itemMetadata[0][1].questions[0].metadata.book_page.split("-");
  const bookPageText = `Book ${bookPage[0]} Page ${bookPage[1]}`;

  return `
    <strong>Explanation</strong>
    <p>${seeWhyText} -- ${bookPageText}</p>
  `;
}

export const SeeWhyButton = ({
  showExplanation,
  toggleExplanation,
}: {
  showExplanation: boolean;
  toggleExplanation: () => void;
}) => (
  <SeeWhyStyledButton
    variant="text"
    endIcon={
      showExplanation ? (
        <ArrowDropDown aria-label="Hide explanation" />
      ) : (
        <ArrowDropUp aria-label="Show explanation" />
      )
    }
    onClick={toggleExplanation}
  >
    See why
  </SeeWhyStyledButton>
);

export const Quiz = (): JSX.Element | null => {
  const { nextModule, section, module } = useAppState();
  const { reportError } = useErrorHandler();
  const { init, initialize, saveQuizScore } = useQuizQuery();
  const [loaded, setLoaded] = useState(false);
  const [ready, setReady] = useState(false);
  const assessment = useRef<HTMLDivElement>(null);
  const itemsApp = useRef<ItemsApp>();
  const [answers, setAnswers] = useState<QuizAnswerStatusMap>({});
  const [explanation, setExplanation] = useState<string>("");
  const [showExplanation, setShowExplanation] = useState(false);
  const [currentItem, setCurrentItem] = useState<Item>();
  const [submitted, setSubmitted] = useState(false);
  const [finished, setFinished] = useState(false);
  const [quizPassed, setQuizPassed] = useState(false);
  const [showLanding, setShowLanding] = useState(true);
  const [emptyFormSubmitted, setEmptyFormSubmitted] = useState(false);
  const [bookReference, setBookReference] = useState<string>("");
  const [quizReset, setQuizReset] = useState(false);
  const [questionUuid, setQuestionUuid] = useState<string>("");
  const [copied, setCopied] = useState(false);
  const { createAnalyticsEvent } = useAnalyticsEvents();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const debug = urlParams.get("debug") === "true";

  useEffect(() => {
    if (!loaded) {
      initialize();
    }
  }, [loaded, initialize]);

  const total = useMemo(() => {
    return Object.keys(answers).length;
  }, [answers]);

  const index = useMemo(() => {
    const answered = Object.values(answers).filter((answer) => answer !== "unanswered").length;
    return submitted || answered >= total ? answered : answered + 1;
  }, [answers, submitted, total]);

  const correctToPass = useMemo(() => {
    return Math.ceil((total * passingPercent) / 100);
  }, [total]);

  const correct = useMemo(() => {
    return Object.values(answers).filter((answer) => answer === "correct").length;
  }, [answers]);

  const incorrect = useMemo(() => {
    return Object.values(answers).filter((answer) => answer === "incorrect").length;
  }, [answers]);

  const itemCorrect = useMemo(() => {
    if (!ready || !currentItem || !itemsApp || !submitted) return;
    return itemsApp.current?.getItemScores?.()[currentItem.reference]?.score === 1;
  }, [itemsApp, currentItem, ready, submitted]);

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://olt-content.sans.org/learnosity/items/?v2024.1.LTS";
    script.async = true;
    script.onload = () => setLoaded(true);

    document.body.appendChild(script);
    return () => {
      itemsApp.current?.reset();
      itemsApp.current = undefined;
      setLoaded(false);
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (!itemsApp.current || !ready) return;

    // This is not working on retake
    const itemScores = itemsApp.current.getItemScores();

    const answers: QuizAnswerStatusMap = {};
    for (const [id, item] of Object.entries(itemScores)) {
      let status: QuizAnswerStatus = "unanswered";
      if (item.score === 1) {
        status = "correct";
      } else if (item.score === 0) {
        status = "incorrect";
      }

      answers[id] = status;
    }

    setAnswers(answers);
  }, [ready]);

  const validateAndDisable = useCallback((item: Item) => {
    if (!itemsApp.current) return;
    setExplanation(item.questions[0].metadata.sample_answer);
    const bookPage = item.questions[0].metadata.book_page.split("-");
    setBookReference(`Book ${bookPage[0]} Page ${bookPage[1]}`);
    const question = item.questions[0];
    const questionObject = itemsApp.current.question(question.response_id);
    questionObject.disable();
    questionObject.validate();

    return questionObject.isValid();
  }, []);

  const onQuizSubmitted = useCallback(() => {
    saveQuizScore(Math.round((correct / total) * 100));

    if (!itemsApp.current) return;
    setQuizPassed(correct >= correctToPass);
    for (const item of Object.values(itemsApp.current.getItems())) {
      validateAndDisable(item);
    }
    const itemElems = [...document.getElementsByClassName("lrn-assess-item")];
    const items = Object.entries(itemsApp.current.getItems());

    for (const idx in itemElems) {
      const item = itemElems[idx];
      const refId = item.getAttribute("data-reference");
      const itemMetadata = items.filter(([id]) => id === refId);

      item.setAttribute(
        "style",
        "z-index: 10; opacity: 1; position: relative; overflow: visible; visibility: visible; display: block;",
      );

      const seeWhyWrapper = document.createElement("div");
      seeWhyWrapper.className = "seeWhyWrapper";
      seeWhyWrapper.innerHTML = setSeeWhyContent(itemMetadata);

      const widget = item.getElementsByClassName("lrn_widget")[0];
      const div = document.createElement("div");

      widget.prepend(div);
      widget.append(seeWhyWrapper);

      render(
        <QuestionHeader
          index={Number(idx) + 1}
          total={total}
          correct={item.getElementsByClassName("lrn_correct").length > 0}
        />,
        div,
      );
    }
    setFinished(true);
  }, [validateAndDisable, correct, total, correctToPass, saveQuizScore]);

  const finish = useCallback(() => {
    if (!itemsApp.current) return;

    itemsApp.current.submit({
      success: () => onQuizSubmitted(),
      error: (error) => reportError(ErrorSeverity.LOW, "Failed to submit quiz", { ...error }),
    });
  }, [onQuizSubmitted, reportError]);

  const submit = useCallback(() => {
    if (!itemsApp.current) return;

    setEmptyFormSubmitted(false);
    const item = itemsApp.current.getCurrentItem();

    if (item.attempt_status === "not_attempted") {
      return setEmptyFormSubmitted(true);
    }

    const score = validateAndDisable(item) ? "correct" : "incorrect";
    setAnswers((prev) => ({ ...prev, [item.reference]: score }));
    itemsApp.current.save();
    setSubmitted(true);
  }, [validateAndDisable]);

  const next = useCallback(() => {
    if (!itemsApp.current) return;
    itemsApp.current.items().next();
    setShowExplanation(false);
    setSubmitted(false);
    setCopied(false);
  }, [itemsApp]);

  const onItemChanged = useCallback(
    (itemsApp) => {
      if (quizReset) setQuizReset(false);
      const item = itemsApp.current.getCurrentItem();

      setQuestionUuid(item.questions[0].metadata.widget_reference || "");

      if (debug) {
        const answer = item.questions[0].options[0].label;
        const activeItem = Array.from(document.getElementsByClassName("lrn-assess-item")).filter(
          (s) => window.getComputedStyle(s).getPropertyValue("opacity") != "0",
        );
        const answerLabels = activeItem[0].getElementsByClassName("lrn_contentWrapper");
        for (const label of answerLabels) {
          if (label.innerHTML === answer) {
            label.innerHTML = sanitize("<b>" + label.innerHTML + "</b>");
          }
        }
      }

      setCurrentItem(item);

      if (item.attempt_status === "fully_attempted") {
        if (!item.is_last_item) itemsApp.current?.items().next();
        else finish();
      }
    },
    [finish, debug, quizReset],
  );

  const initQuiz = useCallback(() => {
    if (loaded && init && !itemsApp.current) {
      const { LearnosityItems } = window;
      const newItemsApp = LearnosityItems.init(init, "learnosity_assess", {
        readyListener: () => setReady(true),
        errorListener: (error) => {
          if (!itemsApp)
            reportError(ErrorSeverity.MEDIUM, "Failed to initialize quiz", { ...error });
          else if (loaded) reportError(ErrorSeverity.LOW, error.message);
        },
      });

      itemsApp.current = newItemsApp;
      itemsApp.current.on("item:changed", () => onItemChanged(itemsApp));
    }
  }, [loaded, init, reportError, onItemChanged]);

  const beginQuiz = useCallback(() => {
    setShowLanding(false);
    initQuiz();

    if (module?.quizScore) {
      createAnalyticsEvent({
        feature: "quizView",
        action: "retake",
      }).fire("telemetry");
    } else {
      createAnalyticsEvent({
        feature: "quizView",
        action: "open",
      }).fire("telemetry");
    }
  }, [initQuiz, module, createAnalyticsEvent]);

  const openSeeWhy = useCallback(
    () => setShowExplanation((prevState) => !prevState),
    [setShowExplanation],
  );

  const resetQuiz = useCallback(() => {
    itemsApp?.current?.reset();
    itemsApp.current = undefined;

    setAnswers({});
    setReady(false);
    setQuizReset(true);
    setFinished(false);
    setSubmitted(false);
    setQuizPassed(false);
    setCurrentItem(undefined);
    initialize();
  }, [initialize]);

  const handleCopy = useCallback((text) => {
    copyToClipboard(text, (success) => {
      setCopied(success);
      setTimeout(() => setCopied(false), 3000);
    });
  }, []);

  useEffect(() => {
    if (quizReset && init) {
      beginQuiz();
      setQuizReset(false);
    }
  }, [quizReset, init, beginQuiz]);

  useEffect(() => {
    showExplanation &&
      createAnalyticsEvent({
        feature: "quizView",
        action: "expandExplaination",
      }).fire("telemetry");
  }),
    [showExplanation];

  useEffect(
    () => () =>
      createAnalyticsEvent({
        feature: "quizView",
        action: "close",
      }).fire("telemetry"),
    [createAnalyticsEvent],
  );

  const returnToCourseButton = (
    <StyledTextButton
      id="return-to-course-btn"
      data-testid="return-to-course-btn"
      className="return"
      onClick={() => nextModule()}
    >
      <ArrowBack />
      Return to Course
    </StyledTextButton>
  );

  return showLanding ? (
    <QuizLanding beginQuiz={beginQuiz} sectionName={section?.name} disabled={!init} />
  ) : (
    <>
      <QuizBox>
        <AssessmentWrapper>
          {!ready ? (
            <div style={{ textAlign: "center" }}>Loading...</div>
          ) : !finished ? (
            <div id="quizQuestionInfo">
              <div id="quizQuestionsCount">
                <div id="questionCounter">
                  Question {index} of {total}
                </div>
                <div id="questionsCorrect">{correct} Correct</div>
                <div id="questionsIncorrect">{incorrect} Incorrect</div>
              </div>
              {!finished && (
                <div id="passingScore">
                  Passing score is <b>{passingPercent}%</b> ({correctToPass} correct answers)
                </div>
              )}
            </div>
          ) : (
            <div id="quizSummary">
              <div>{quizPassed ? "You passed the quiz!" : "You did not pass this quiz."}</div>
              <div>
                You needed {passingPercent}% ({correctToPass} correct answers)
              </div>
              <div>You scored</div>
              <PercentCircle className={quizPassed ? "passed" : "failed"}>
                {Math.round((correct / total) * 100)}%
              </PercentCircle>
            </div>
          )}
          <Assessment ref={assessment} id="learnosity_assess" />
          <div id="quizFooter">
            {emptyFormSubmitted && <p id="emptyFormMessage">Please choose an answer.</p>}

            {ready && !finished && (
              <>
                {submitted && (
                  <div id="explanation">
                    <div id="answerStatus">
                      <div id="correctText" className={itemCorrect ? "correct" : "incorrect"}>
                        {itemCorrect ? <Check /> : <Close />}
                        That is {itemCorrect ? "" : "in"}correct.
                      </div>
                      <SeeWhyButton
                        showExplanation={showExplanation}
                        toggleExplanation={openSeeWhy}
                      />
                    </div>
                    {showExplanation && (
                      <>
                        <div
                          id="explanationText"
                          dangerouslySetInnerHTML={{
                            __html: sanitize(`${explanation} ${bookReference}`),
                          }}
                        />
                      </>
                    )}
                  </div>
                )}
                <Grid container justifyContent="space-between" alignItems="center">
                  {returnToCourseButton}
                  {!submitted ? (
                    <QuizButton
                      id="submit-question"
                      onClick={() => submit()}
                      color="primary"
                      variant="contained"
                    >
                      Submit Answer
                    </QuizButton>
                  ) : !currentItem?.is_last_item ? (
                    <QuizButton
                      id="next-question"
                      onClick={() => next()}
                      color="primary"
                      variant="contained"
                    >
                      Next Question
                    </QuizButton>
                  ) : !finished ? (
                    <QuizButton
                      id="finish-quiz"
                      onClick={() => finish()}
                      color="primary"
                      variant="contained"
                    >
                      Finish
                    </QuizButton>
                  ) : null}
                </Grid>
                <UuidWrapper uuid={questionUuid} copied={copied} onCopy={handleCopy} />
              </>
            )}
          </div>
          {finished && (
            <QuizResultsActions>
              {returnToCourseButton}
              <QuizButton id="retake-quiz" onClick={resetQuiz} color="primary" variant="contained">
                Retake
              </QuizButton>
            </QuizResultsActions>
          )}
        </AssessmentWrapper>
      </QuizBox>
    </>
  );
};
