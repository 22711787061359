import React, { useState, MouseEventHandler, useCallback, useEffect, useContext } from "react";
import { Box, Menu, MenuItem } from "@mui/material";
import { Container, NotesButton, ButtonWrapper } from "./Notes.elements";
import Download from "@mui/icons-material/Download";
import { useNotes } from "./Notes.queries";
import { EditNote } from "./EditNote";
import AlertModal from "../Alerts/AlertModal";
import { IndividualNote } from "./IndividualNote";
import { Note } from "@mui/icons-material";
import { useSearch } from "../Search/Search.queries";
import { NotesContext, NotesContextType } from "../../context/notes-context";
import { useAppState } from "../../hooks/useAppState";
import { moduleType } from "../../__generated__/globalTypes";

export function Notes({ active }: { active: boolean }): JSX.Element | null {
  const { notes, exportNotes, deleteNote, editNote, editing, getNotes } = useNotes();
  const [exporting, setExporting] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { setShowSearchResults } = useSearch();
  const { setEditingName } = useContext(NotesContext) as NotesContextType;
  const {
    slide,
    module,
    appState: { slideId },
    course,
  } = useAppState();

  useEffect(() => {
    getNotes();
  }, [getNotes, course]);

  const open = Boolean(anchorEl);

  async function runExport(downloadAsCSV: boolean) {
    setAnchorEl(null);
    setExporting(true);
    await exportNotes(downloadAsCSV);
    setExporting(false);
  }

  const handleEditClick = (id?: string) => () => editNote(id);

  const handleClose = () => setAnchorEl(null);

  const handleDownloadPDF = () => runExport(false);

  const handleDownloadCSV = () => runExport(true);

  const cancelDelete = () => setShowConfirmModal(false);

  const handleClickDownloadMenu: MouseEventHandler<HTMLButtonElement> = (event) =>
    setAnchorEl(event.currentTarget);

  const handleClickAddNote = useCallback(() => {
    if (!slideId) {
      return;
    }
    setShowSearchResults(false);
    editNote(slideId);
    setEditingName(`${slide?.fullNumber} ${slide?.name}`);
  }, [slideId, setShowSearchResults, editNote, setEditingName, slide]);

  const handleDelete = useCallback(() => {
    slideId && deleteNote(slideId);
    setShowConfirmModal(false);
  }, [slideId, deleteNote]);

  const getNoteBtnText = notes.some((note) => note.slideId === slideId) ? "Edit Note" : "Add Note";
  const isDisabled = module?.type !== moduleType.CONTENT && module?.type !== undefined;
  const tabIndex = 2;

  return (
    <>
      <AlertModal
        aria-role="dialog"
        open={showConfirmModal}
        confirm={{ label: "Delete", onClick: handleDelete }}
        cancel={{ onClick: cancelDelete }}
        message="Are you sure you want to delete this note?"
      />
      {active && editing ? (
        <EditNote slideId={editing} handleClose={handleEditClick(undefined)} />
      ) : null}
      {active && !editing && (
        <Container>
          <ButtonWrapper direction="row">
            <NotesButton
              className="download-notes-button"
              variant="outlined"
              startIcon={<Download />}
              onClick={handleClickDownloadMenu}
              disabled={!notes.length}
              loading={exporting}
              aria-label="Download notes"
              tabIndex={tabIndex}
            >
              Download My Notes
            </NotesButton>
            <NotesButton
              className="add-note-button"
              variant="outlined"
              startIcon={<Note />}
              aria-label={getNoteBtnText}
              onClick={handleClickAddNote}
              disabled={isDisabled}
              tabIndex={tabIndex}
            >
              {getNoteBtnText}
            </NotesButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
              <MenuItem id="note_download_pdf_button" onClick={handleDownloadPDF}>
                Download as PDF
              </MenuItem>
              <MenuItem id="note_download_csv_button" onClick={handleDownloadCSV}>
                Download as CSV
              </MenuItem>
            </Menu>
          </ButtonWrapper>
          <Box>
            {notes.map((note, index) => {
              return (
                <IndividualNote
                  key={note.slideId}
                  note={note}
                  index={index}
                  slideName={note.slideName}
                  setShowConfirmModal={setShowConfirmModal}
                />
              );
            })}
          </Box>
        </Container>
      )}
    </>
  );
}
