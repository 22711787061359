import { TypePolicy } from "@apollo/client";
import { lastView, sumProgress } from "../../util/cache";

export default {
  merge: true,
  keyFields: ["trainingId"],
  fields: {
    progress: {
      read: (_, { readField }) => sumProgress("sections", readField),
    },
    lastView: {
      read: (_, { readField }) => lastView("sections", readField),
    },
  },
} as TypePolicy;
