import React from "react";
import { Confidence } from "../Confidence/Confidence";
import { Quiz } from "../Quiz/Quiz";
import { Survey } from "../Survey/Survey";
import { Video } from "../Video/Video";
import { useViewport, View } from "./useViewport";
import { SlideThumbnails } from "../SlideThumbnails/SlideThumbnails";
import { styled } from "@mui/material/styles";
import { TestView } from "../Coursebook/TestView";

const ViewportContainer = styled("div")(({ theme }) => ({
  background:
    theme.palette.mode === "dark" ? theme.palette.background.default : theme.custom.palette.light,
  height: "100% !important",
}));

export const Viewport = (): JSX.Element => {
  const { view } = useViewport();

  const viewElements = {
    [View.Video]: <Video />,
    [View.Confidence]: <Confidence />,
    [View.Slides]: <SlideThumbnails />,
    [View.Quiz]: <Quiz />,
    [View.Survey]: <Survey />,
    [View.Test]: <TestView />,
  };

  return (
    <ViewportContainer role="main" aria-label="Main content">
      {viewElements[view]}
    </ViewportContainer>
  );
};
