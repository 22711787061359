import { Skeleton, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiSkeleton from "@mui/material/Skeleton";

export const ButtonSkeletonWrapper = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    display: "none",
  },
}));

export const HeaderingSkeleton = styled(Skeleton)(() => ({
  fontSize: "1.375rem",
  flex: 1,
}));

export const ButtonSkeleton = styled(Skeleton)(() => ({
  height: 40,
  width: 100,
}));

export const InstructorWrapper = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  gap: theme.spacing(1),

  "@container container (width < 600px)": {
    flexDirection: "column",
    gap: theme.spacing(3),
    alignItems: "flex-end",
  },
}));

export const ViewportSkeleton = styled(MuiSkeleton)(({ theme }) => ({
  width: "100%",
  height: "100%",

  [theme.breakpoints.down("md")]: {
    paddingTop: "56.25%",
    width: "100%",
  },
}));

export const ModuleSkeleton = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),
  width: "100%",
  maxWidth: "100%",
  height: "100%",

  ["& :nth-of-type(1)"]: {
    width: "100%",
    flex: "2 1 40%",
  },
  ["& :nth-of-type(2)"]: {
    width: "100%",
    flex: "3 1 60%",
  },
}));
