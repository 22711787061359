import React, { useState } from "react";
import { Collapse } from "@mui/material";
import { VolumeUp, VolumeOff } from "@mui/icons-material";
import { Container, VolumeSliderContainer, VolumeSlider } from "./ButtonVolume.elements";
import ButtonIconContainer from "../../../ButtonIconContainer/ButtonIconContainer";

type ButtonVolumeProps = {
  volume: number;
  muted: boolean;
  onClickMuteVolume: () => void;
  onChangeVolumeSlider: (event: Event, value: number | number[]) => void;
};

export default function ButtonVolume({
  volume,
  muted,
  onClickMuteVolume,
  onChangeVolumeSlider,
}: ButtonVolumeProps): JSX.Element {
  const [isVolumeSliderShown, setIsVolumeSliderShown] = useState(false);

  const handleMouseEnterVolumeSlider = () => {
    setIsVolumeSliderShown(true);
  };

  const handleMouseLeaveVolumeSlider = () => {
    setIsVolumeSliderShown(false);
  };

  const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  const tabIndex = 1;

  return (
    <Container
      onFocus={handleMouseEnterVolumeSlider}
      onMouseEnter={handleMouseEnterVolumeSlider}
      onMouseLeave={handleMouseLeaveVolumeSlider}
    >
      <ButtonIconContainer
        ariaLabel={`volume, press enter to ${volume === 0 || muted ? "unmute" : "mute"}`}
        onClick={onClickMuteVolume}
        tooltip="Volume (up/down arrows)"
        id="button_volume"
        tabIndex={tabIndex}
      >
        {muted ? <VolumeOff /> : <VolumeUp />}
      </ButtonIconContainer>
      <Collapse
        timeout={{ appear: 300, enter: 300, exit: 2000 }}
        in={isVolumeSliderShown}
        orientation="horizontal"
      >
        {!iOS && (
          <VolumeSliderContainer>
            <VolumeSlider
              aria-label={`Volume slider value: ${volume}`}
              aria-valuetext={`${Math.round(volume * 100)}`}
              value={volume}
              color="primary"
              onChange={onChangeVolumeSlider}
              min={0}
              max={1}
              step={0.00000001}
              size="small"
              tabIndex={tabIndex}
              id="volume_slider"
            />
          </VolumeSliderContainer>
        )}
      </Collapse>
    </Container>
  );
}
