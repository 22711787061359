import * as TelemetryDataAccess from "./TelemetryDataAccess";
import TelemetryException, { errorMsg } from "./TelemetryException";

export type Telemetry = {
  feature: string;
  action: string;
  lo_uuid: string;
  slide_uuid?: string;
  student_id: number;
  course_id: number;
  student_session: string;
  player_version: string;
  autoplay: boolean;
  volumepercent: number;
  mute: boolean;
  playbackspeed: number;
  videoresolution: string;
  player_modality: string;
  mediatime?: number;
};

type TelemetryModel = {
  save(): Promise<Telemetry>;
};

const telemetryModel = (telemetry: Telemetry): Telemetry & TelemetryModel => {
  const {
    course_id,
    feature,
    action,
    lo_uuid,
    slide_uuid,
    student_id,
    student_session,
    player_version,
    autoplay,
    volumepercent,
    mute,
    playbackspeed,
    videoresolution,
    player_modality,
    mediatime,
  } = telemetry;

  const save = async (): Promise<Telemetry> => {
    try {
      return await TelemetryDataAccess.store(telemetry);
    } catch (_e) {
      throw new TelemetryException(errorMsg);
    }
  };

  return {
    save,
    course_id,
    feature,
    action,
    lo_uuid,
    slide_uuid,
    student_id,
    student_session,
    player_version,
    autoplay,
    volumepercent,
    mute,
    playbackspeed,
    videoresolution,
    player_modality,
    mediatime,
  };
};

export default telemetryModel;
