/* eslint-disable sonarjs/no-duplicate-string */
import { LinearProgress, linearProgressClasses, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const OutlineWrapper = styled("div")({
  textAlign: "left",
  paddingTop: "0.5rem",
});

export const SectionWrapper = styled(Box)(({ theme }) => ({
  marginBottom: "0.5em",
  "& .MuiAccordion-root": {
    border: "none",
    borderRadius: "0",
    boxShadow: "none",
    borderBottom: `1px solid ${theme.custom.palette.semantic.accordionBorder}`,
    backgroundColor: theme.custom.palette.semantic.accordionBg,
    "&:before": {
      display: "none",
    },
    "&.Mui-expanded": {
      margin: 0,
      paddingBottom: "1rem",
    },
    "& .MuiAccordionSummary-root": {
      padding: 0,
      "&.Mui-expanded": {
        minHeight: "48px",
      },
      "& .MuiAccordionSummary-content": {
        margin: 0,
        h4: {
          margin: 0,
        },
      },
      "& .MuiAccordionSummary-expandIconWrapper": {
        "& svg path": {
          fill: theme.palette.text.titleGrey,
        },
      },
      "& .Mui-expanded": {
        margin: 0,
      },
    },
    "& .MuiAccordionDetails-root": {
      padding: 0,
    },
  },
}));

export const Section = styled("div")(({ theme }) => ({
  padding: "0px 24px",
  fontSize: "0.625rem",
  fontWeight: 700,
  lineHeight: "14px",
  display: "flex",
  gap: "15px",
  textTransform: "uppercase",
  color: theme.custom.palette.semantic.blue,
}));

export const SectionTitle = styled("h3")({
  fontWeight: 600,
  fontSize: "1rem",
  lineHeight: "22px",
  margin: "8px 24px 12px 24px",
});

export const Module = styled("button")(({ theme }) => ({
  padding: "8px 24px",
  display: "flex",
  flex: "1 1 auto",
  width: "100%",
  alignItems: "center",
  background: "none",
  border: "none",
  font: "inherit",
  cursor: "pointer",
  outline: "inherit",
  textAlign: "left",
  fontWeight: 300,
  color: theme.custom.palette.semantic.greyDark,
  fontSize: "0.9rem",
  justifyContent: "space-between",
  [`& > div.title`]: {
    width: "80%",
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: "19px",
  },
  [`&:focus`]: {
    outline: `1px solid ${theme.custom.palette.semantic.rowActiveBg}`,
  },
  [`& svg`]: {
    color: theme.custom.palette.semantic.iconColor,
  },
  [`& svg.passed, & svg.viewed`]: {
    color: theme.custom.palette.success[500],
  },
  [`& svg.failed`]: {
    color: theme.custom.palette.semantic.iconFailedColor,
  },
  [`&.selected`]: {
    color: "white",
    background: theme.palette.primary.main,

    "& svg": {
      color: "white",
    },
  },
  [`&:hover`]: {
    cursor: "pointer",
    color: theme.palette.text.primary,
    background: "rgba(0,88,219, 0.2)",

    "& svg": {
      color: theme.custom.palette.semantic.iconHoverColor,
    },
    "& svg.passed, & svg.viewed": {
      color: theme.custom.palette.success[500],
    },
    "& svg.failed": {
      color: theme.custom.palette.semantic.iconFailedColor,
    },
  },
}));

export const ProgressBar = styled(LinearProgress)(({ theme }) => ({
  height: "8px",
  borderRadius: "6px",
  alignSelf: "center",
  width: "48px",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.custom.palette.semantic.linearProgressBg,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: "6px",
    backgroundColor: theme.custom.palette.semantic.blue,
  },
}));

export const ConfidenceRating = styled("button")(({ theme }) => ({
  borderStyle: "none",
  background: "none",
  cursor: "pointer",
  display: "inline-flex",
  color: theme.custom.palette.semantic.greyDark,

  [`&:hover, &:focus`]: {
    textDecoration: "underline",
  },

  [`& svg`]: {
    marginLeft: "5px",
    marginRight: "5px",
    fontSize: "1.3em",
  },
  [`&.yes svg`]: {
    color: theme.custom.palette.success[500],
  },
  [`&.no svg`]: {
    color: theme.custom.palette.semantic.iconFailedColor,
  },
  [`&.somewhat svg`]: {
    color: theme.custom.palette.semantic.iconSomewhatColor,
  },
}));

export const Duration = styled(Box)(() => ({
  display: "inline-flex",
  alignItems: "center",

  [`& p`]: {
    whiteSpace: "nowrap",
  },

  [`& svg`]: {
    marginLeft: "5px",
    fontSize: "1.3em",
  },
}));
