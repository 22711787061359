import { Quill } from "quill";
import { ColorType } from "./ColorPicker";
import fontColorContrast from "better-font-color-contrast";

export const getFormat = (editor: Quill) => {
  const selection = editor.getSelection() as any;
  const format = editor.getFormat(selection?.index, selection?.length);

  return { format, index: selection?.index, length: selection?.length };
};

export const formatTextSelection = (
  editor: Quill,
  format: "background" | "color",
  color: ColorType,
) => {
  const { index, length } = getFormat(editor);
  editor.formatText(index, length, format, color);
};

export const setContrastColor = (editor: Quill) => {
  const {
    format: { background },
    index,
    length,
  } = getFormat(editor);
  const textColor = fontColorContrast(background as string);

  editor.formatText(index, length, "color", background ? textColor : false);
};
