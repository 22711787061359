import React, { useState } from "react";
import { CoursebookModal } from "./CoursebookModal";
import { Coursebook } from "./Coursebook";
import { OpenInFull } from "@mui/icons-material";
import { usePersistedSettings } from "../../hooks/usePersistedSettings";
import { ExpandButton } from "./Coursebook.elements";

export function CoursebookSidebar({ active }: { active: boolean }): JSX.Element | null {
  const { coursebookFontSize, setCoursebookFontSize } = usePersistedSettings();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedFontSize, setSelectedFontSize] = useState<number>(coursebookFontSize || 14);

  const handleChangeFontSize = (fontSize: number): void => {
    setSelectedFontSize(fontSize);
    setCoursebookFontSize(fontSize);
  };

  if (!active) return null;

  return (
    <>
      <Coursebook
        titleSize={2}
        fontSize={selectedFontSize}
        handleChangeFontSize={handleChangeFontSize}
        customControl={
          <ExpandButton
            size="small"
            onClick={() => setModalOpen(true)}
            variant="outlined"
            startIcon={<OpenInFull />}
            sx={{ paddingX: 2 }}
          >
            Expand
          </ExpandButton>
        }
      />
      <CoursebookModal
        open={modalOpen}
        setOpen={setModalOpen}
        handleChangeFontSize={handleChangeFontSize}
        fontSize={selectedFontSize}
      />
    </>
  );
}
