import { gql, makeVar, useApolloClient, useReactiveVar } from "@apollo/client";
import { useCallback } from "react";
import { SlideFragment } from "./__generated__/SlideFragment";
import { generateSlide } from "../util/fakeCourse";
import { GetTestBooks } from "./__generated__/GetTestBooks";
import { useErrorHandler } from "./useErrorHandler";
import { ErrorSeverity } from "./useErrorHandler";

export const GET_TEST_BOOKS_QUERY = gql`
  query GetTestBooks($path: String!, $token: String!) {
    dropboxBookTexts(path: $path, accessToken: $token)
  }
`;

export const GET_DROPBOX_TOKEN_QUERY = gql`
  query GetDropboxToken($code: String!) {
    dropboxToken(accessCode: $code)
  }
`;

export const GET_DROPBOX_SESSION_ACTIVE = gql`
  query GetDropboxSession($token: String!) {
    dropboxSessionActive(accessToken: $token)
  }
`;

const testModeVar = makeVar(false);
const testBooksVar = makeVar<string[]>([]);
const testSlidesVar = makeVar<{ [id: string]: SlideFragment }>({});
export const accessTokenVar = makeVar(localStorage.getItem("dropboxToken"));
const booksPathVar = makeVar("");
const modalOpenVar = makeVar(false);
const loadingVar = makeVar(false);
const loadedVar = makeVar(false);

export function useTestMode() {
  const testMode = useReactiveVar(testModeVar);
  const testBooks = useReactiveVar(testBooksVar);
  const testSlides = useReactiveVar(testSlidesVar);
  const accessToken = useReactiveVar(accessTokenVar);
  const booksPath = useReactiveVar(booksPathVar);
  const modalOpen = useReactiveVar(modalOpenVar);
  const loading = useReactiveVar(loadingVar);
  const loaded = useReactiveVar(loadedVar);

  const client = useApolloClient();
  const { reportError } = useErrorHandler();

  const getAccessToken = useCallback(
    async (code: string) => {
      const { data } = await client.query({
        query: GET_DROPBOX_TOKEN_QUERY,
        variables: { code },
      });
      return data.dropboxToken;
    },
    [client],
  );

  const checkSessionActive = useCallback(async () => {
    let sessionActive = !!accessToken;

    if (accessToken) {
      const { data } = await client.query({
        query: GET_DROPBOX_SESSION_ACTIVE,
        variables: { token: accessToken },
      });
      sessionActive = data.dropboxSessionActive;
    }

    if (!sessionActive) {
      accessTokenVar("");
      localStorage.removeItem("dropboxToken");
    }

    return sessionActive;
  }, [client, accessToken]);

  const getTestBooks = useCallback(
    async (booksPath: string) => {
      if (!accessToken) throw new Error("Missing access token");
      const { data } = await client.query<GetTestBooks>({
        query: GET_TEST_BOOKS_QUERY,
        variables: {
          path: booksPath,
          token: accessToken,
        },
        fetchPolicy: "no-cache",
      });
      return data.dropboxBookTexts;
    },
    [client, accessToken],
  );

  const loadTestBooks = useCallback(
    async (path: string) => {
      const prevPath = booksPath;
      const prevLoaded = loaded;
      try {
        loadedVar(false);
        loadingVar(true);

        booksPathVar(path);
        const books = await getTestBooks(path);
        testBooksVar(books);

        const slides = books.map((_book, idx) => generateSlide(idx + 1));
        if (!Object.keys(testSlides).length || Object.keys(testSlides).length !== slides.length)
          testSlidesVar(
            slides.reduce((acc, slide) => {
              acc[slide.id] = slide;
              return acc;
            }, {} as { [uuid: string]: SlideFragment }),
          );

        loadedVar(true);
      } catch (error) {
        booksPathVar(prevPath);
        loadedVar(prevLoaded);
        reportError(ErrorSeverity.LOW, "Failed to load test books", { error });
      } finally {
        loadingVar(false);
      }
    },
    [getTestBooks, booksPath, reportError, loaded, testSlides],
  );

  const refreshTestBooks = useCallback(
    async () => loadTestBooks(booksPath),
    [loadTestBooks, booksPath],
  );

  const loadAccessToken = useCallback(async () => {
    const code = new URLSearchParams(window.location.search).get("code");
    if (!accessToken && code) {
      const token = await getAccessToken(code);
      accessTokenVar(token);
      localStorage.setItem("dropboxToken", token);
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, [getAccessToken, accessToken]);

  const setTestMode = useCallback(
    async (testMode: boolean) => {
      testModeVar(testMode);
      if (testMode) loadAccessToken();
    },
    [loadAccessToken],
  );

  const setModalOpen = modalOpenVar;

  return {
    testMode,
    setTestMode,
    testBooks,
    loadTestBooks,
    testSlides,
    setModalOpen,
    modalOpen,
    checkSessionActive,
    booksPath,
    refreshTestBooks,
    loading,
    loaded,
  };
}
