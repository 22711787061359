import { useApolloClient } from "@apollo/client";
import gql from "graphql-tag";
import { useCallback, useEffect, useState } from "react";
import { useAppState } from "../../hooks/useAppState";
import { CourseQuery_instance } from "./__generated__/CourseQuery";
import courseQueryResult from "../../__mocks__/courseQueryResult";
import { useTestMode } from "../../hooks/useTestMode";
import { CourseFragment } from "../../hooks/__generated__/CourseFragment";
import { TrainingInstanceFragment } from "../../hooks/__generated__/TrainingInstanceFragment";
import { useCourseProgress } from "../../hooks/useCourseProgress";
import { FullCourseQuery } from "./__generated__/FullCourseQuery";
import { getFirstSectionIndex } from "../../util/course";

export const COURSE_QUERY = gql`
  query CourseQuery($courseId: Int!) {
    course(courseId: $courseId) {
      id
      courseType
      shortName
      name
      trainingId
      availableResolutions
      fullVersion
      version
      complete
      messages {
        text
        link
      }
      sections {
        id
        name
        number
        surveyUrl
        modules {
          id
          name
          type
          sectionId
          fullNumber
          duration
          slides {
            id
            moduleId
            name
            number
            fullNumber
            duration
          }
        }
      }
    }
    instance(courseId: $courseId) {
      id
      expirationDateTime
      userId
      progress {
        instanceId
        viewedSeconds
        totalSeconds
        viewedSlides
        totalSlides
        totalQuizzes
        passedQuizzes
        complete
        lastView {
          slideId
          resumeTime
          dateTimeViewed
        }
        moduleProgress {
          id
          name
          type
          fullNumber
          viewedSlides
          totalSlides
          quizScore
        }
      }
    }
  }
`;

export const FULL_COURSE_QUERY = gql`
  query FullCourseQuery($courseId: Int!) {
    course(courseId: $courseId) {
      trainingId
      contributors {
        id
        bio {
          firstName
          lastName
        }
        profile {
          name
          image
          text
          link
        }
      }
      resources {
        title
        resourceHost
        linkJson
      }
      messages {
        text
        link
      }
      sections {
        id
        modules {
          id
          quizScore
          confidenceRating {
            moduleId
            rating
          }
          slides {
            id
            notes {
              slideId
              content
              updated
            }
            bookmarks {
              slideId
              timestamp
            }
          }
        }
      }
    }
  }
`;

export function useCourseQuery(): CourseFragment | undefined {
  const { testMode } = useTestMode();
  const {
    appState: { courseId, moduleId, slideId, instanceId, course, instance },
    setSlide,
    setInstance,
    setModule,
    setCourse,
  } = useAppState();
  const client = useApolloClient();
  const { updateProgressState } = useCourseProgress(
    courseId ?? undefined,
    course?.id,
    instanceId ?? undefined,
  );

  const [queryInstance, setQueryInstance] = useState<TrainingInstanceFragment>();
  const [queryCourse, setQueryCourse] = useState<CourseFragment>();
  const [courseData, setCourseData] = useState<CourseQuery_course>();
  const [fullCourseData, setFullCourseData] = useState<FullCourseQuery>();
  const [loading, setLoading] = useState(false);

  const queryFullCourse = useCallback(async () => {
    try {
      setLoading(true);
      const result = await client.query({
        query: FULL_COURSE_QUERY,
        variables: { courseId },
        errorPolicy: "all",
      });
      setFullCourseData(result.data);
      setCourse(courseId!);
      updateProgressState();
      setLoading(false);
    } catch (e) {
      console.error("ERROR", e);
    }
  }, [courseId, updateProgressState, client, setCourse]);

  const queryPartCourse = useCallback(async () => {
    try {
      setLoading(true);
      const {
        data: { course, instance },
      } = await client.query({
        query: COURSE_QUERY,
        variables: { courseId },
      });
      setCourseData(course);
      setCourse(course.trainingId);
      setInstance(instance.id);
      setLoading(false);
    } catch (e) {
      console.error("ERROR", e);
    }
  }, [courseId, setCourse, setInstance, client]);

  useEffect(() => {
    if (testMode) {
      setCourse();
    } else if (courseId && !testMode && !loading) {
      if (!course) {
        queryPartCourse();
      } else if (!course.contributors) {
        queryFullCourse();
      }
    }
  }, [courseId, testMode, queryFullCourse, queryPartCourse, course, loading]);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    if (testMode && !slideId) {
      setQueryCourse(course);
      const firstSectionIndex = getFirstSectionIndex(courseQueryResult.data.course.sections);
      const firstModuleId = courseQueryResult.data.course.sections[firstSectionIndex].modules[0].id;
      setModule(firstModuleId);
    } else if (course && course.id !== queryCourse?.id) {
      setQueryCourse(course);
    } else if (course && !moduleId && !slideId && instance) {
      if (instance?.progress?.lastView) {
        setSlide(
          instance.progress.lastView.slideId,
          instance?.progress?.lastView.resumeTime ?? undefined,
        );
      } else {
        const firstSectionIndex = getFirstSectionIndex(course.sections);
        const firstModuleId = course.sections[firstSectionIndex].modules[0].id;
        setModule(firstModuleId);
      }
    }

    if (testMode) {
      setQueryInstance(courseQueryResult.data.instance as unknown as CourseQuery_instance);
    } else if (instance && instance !== queryInstance) {
      setQueryInstance(instance);
      setInstance(instance.id);
    }
  }, [
    courseData,
    setInstance,
    setSlide,
    setModule,
    queryInstance,
    moduleId,
    slideId,
    testMode,
    fullCourseData,
    course,
    instance,
    queryCourse?.id,
  ]);

  return course;
}
