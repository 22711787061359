import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const Wrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  width: "100%",
}));

export const Heading = styled("h1")(() => ({
  fontSize: "3em",
}));

export const Message = styled("p")(() => ({
  color: "#ccc",
  marginTop: "auto",
  marginBottom: "1em",
}));
