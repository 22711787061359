import { styled } from "@mui/material/styles";
import { Typography, IconButton, Popover, Button } from "@mui/material";
import { paddedBox } from "../../styles/shared";

export const BookContainer = styled("div")(({ theme }) => ({
  textAlign: "left",
  userSelect: "none",
  fontSize: "0.9em",
  padding: "16px 24px",
  ["span"]: {
    color: theme.palette.text.book,
    lineHeight: "1.5",
  },

  img: {
    display: "inline-block",
    maxWidth: "100%",
    height: "auto",
  },
}));

export const HeaderContainer = styled("div")<{ isModal: boolean }>(({ isModal, theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: isModal ? "flex-start" : "space-between",
  marginBottom: theme.spacing(2),
  gap: theme.spacing(3),
}));

export const HeaderLeftContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const HeaderRightContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
});

export const StyledButton = styled(IconButton)(({ theme }) => ({
  padding: 0,
  height: "100%",

  color: theme.custom.palette.semantic.buttonIconColor,
  ["&:hover"]: {
    color: theme.custom.palette.semantic.buttonIconHoverColor,
  },
}));

export const StyledPlayButton = styled(StyledButton)(({ theme }) => ({
  color: theme.custom.palette.semantic.buttonIconColor,
  ["&:hover"]: {
    color: theme.custom.palette.semantic.buttonIconHoverColor,
  },
}));

export const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "fontSize",
})<{ component: React.ReactNode; fontSize?: number }>(({ theme, fontSize }) => ({
  color: theme.custom.palette.semantic.blue,
  fontWeight: 600,
  fontSize: fontSize !== undefined ? `${fontSize}px` : "16px",
}));

export const StyledBodyTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "fontSize",
})<{ component: React.ReactNode; fontSize?: number }>(({ theme, fontSize }) => ({
  color: theme.palette.grey[500],
  fontWeight: 400,
  fontSize: fontSize !== undefined ? `${fontSize}px` : "14px",
  margin: "revert",

  ["a"]: {
    overflowWrap: "break-word",
    textDecoration: "none",
    color: theme.custom.palette.semantic.blue,
  },

  ["p, span"]: {
    color: theme.palette.text.book,
    overflowWrap: "break-word",
  },

  ".codeBlock": {
    ...paddedBox({ theme }),

    p: {
      margin: 0,
    },
  },

  ".indented": {
    marginLeft: `${theme.spacing(2.5)} !important`,
  },

  ".tableWrapper": {
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.grey["700"],
    },

    "::-webkit-scrollbar": {
      height: "5px",
    },
  },

  'p[style*="background"]': {
    background: "none !important",
  },
}));

export const StyledPopover = styled(Popover)(({ theme }) => ({
  marginTop: theme.spacing(1),
  [".MuiPaper-root"]: {
    overflow: "visible",
    boxShadow: theme.custom.shadows[5],
    ["&:before"]: {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      left: 14,
      width: 10,
      height: 10,
      background: theme.custom.palette.semantic.modalBg,
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
}));

export const ExpandButton = styled(Button)(({ theme }) => ({
  color: theme.custom.palette.semantic.buttonOutlinedColor,
  borderColor: theme.custom.palette.semantic.buttonOutlinedBorder,

  ["svg"]: {
    color: theme.custom.palette.semantic.buttonOutlinedColor,
  },

  "&:hover": {
    color: theme.custom.palette.semantic.buttonOutlinedHoverColor,
    borderColor: theme.custom.palette.semantic.buttonOutlinedHoverBorder,
    ["svg"]: {
      color: theme.custom.palette.semantic.buttonOutlinedHoverColor,
    },
  },

  "&:focus": {
    color: theme.custom.palette.semantic.buttonOutlinedFocusColor,
    borderColor: theme.custom.palette.semantic.buttonOutlinedFocusBorder,
    ["svg"]: {
      color: theme.custom.palette.semantic.buttonOutlinedFocusColor,
    },
  },
}));
