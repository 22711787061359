import { styled } from "@mui/material/styles";
import { Box, Stack, Typography, IconButton } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

export const Container = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

export const BookmarkWrapper = styled(Box)(({ theme }) => ({
  padding: "16px 24px",
  "&:nth-of-type(2n+1)": {
    backgroundColor: theme.custom.palette.semantic.rowAlternatingBg,
  },
}));

export const BookmarkContainer = styled(Stack)(() => ({
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-start",
}));

export const BookmarkTitle = styled(Typography)(({ theme }) => ({
  textAlign: "left",
  color: theme.palette.text.title,
  cursor: "pointer",
  fontSize: "0.875rem",
  fontWeight: 600,
}));

export const BookmarkIconContainer = styled(Stack)(() => ({
  flexDirection: "row",
  display: "flex",
}));

export const BookmarkBody = styled(Typography)(({ theme }) => ({
  fontSize: "0.875rem",
  textAlign: "left",
  color: theme.palette.text.primary,
  fontWeight: 400,
}));

export const ButtonWrapper = styled(Stack)(({ theme }) => ({
  paddingRight: "24px",
  paddingLeft: "24px",
  gap: "8px",
  justifyContent: "flex-start",
  [theme.breakpoints.down("md")]: {
    justifyContent: "flex-end",
  },
}));

export const BookmarkIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.custom.palette.semantic.buttonIconColor,
  ["> svg"]: {
    fontSize: "16px",
  },
  ["&:hover"]: {
    color: theme.custom.palette.semantic.buttonIconHoverColor,
  },
  ["&:focus"]: {
    color: theme.custom.palette.semantic.buttonIconFocusColor,
  },
}));

export const BookmarkButton = styled(LoadingButton)(({ theme }) => ({
  fontSize: "0.625rem",
  marginBottom: theme.spacing(2),
  background: "transparent",
  border: `1px solid ${theme.custom.palette.semantic.buttonOutlinedBorder}`,
  color: theme.custom.palette.semantic.buttonOutlinedColor,
  ["> svg"]: {
    fontSize: "16px",
    color: theme.custom.palette.semantic.buttonOutlinedIcon,
  },
  ["&:hover"]: {
    background: theme.custom.palette.semantic.buttonOutlinedHoverBg,
    border: `1px solid ${theme.custom.palette.semantic.buttonOutlinedHoverBorder}`,
    color: theme.custom.palette.semantic.buttonOutlinedHoverColor,
  },
  ["&:hover > svg"]: {
    color: theme.custom.palette.semantic.buttonOutlinedHoverIcon,
  },
  ["&:focus"]: {
    background: theme.custom.palette.semantic.buttonOutlinedFocusBg,
    border: `1px solid ${theme.custom.palette.semantic.buttonOutlinedFocusBorder}`,
    color: theme.custom.palette.semantic.buttonOutlinedFocusColor,
  },
  ["&:focus > svg"]: {
    color: theme.custom.palette.semantic.buttonOutlinedFocusIcon,
  },
}));
