import React, { useState, useEffect, useRef } from "react";
import { useBook } from "./Coursebook.queries";
import {
  BookContainer,
  StyledTypography,
  StyledButton,
  StyledBodyTypography,
  StyledPopover,
  HeaderContainer,
  HeaderLeftContainer,
  HeaderRightContainer,
} from "./Coursebook.elements";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import { useAppState } from "../../hooks/useAppState";
import { FontSlider } from "./FontSlider";
import { Skeleton } from "@mui/material";
import { sanitize } from "../../util/html";

export function Coursebook({
  titleSize,
  fontSize,
  handleChangeFontSize,
  customControl,
  isModal = false,
}: {
  titleSize: number;
  fontSize: number;
  handleChangeFontSize(fontSize: number): void;
  customControl: JSX.Element;
  isModal?: boolean;
}): JSX.Element | null {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { bookText, loading } = useBook();
  const open = Boolean(anchorEl);
  const { slide, section } = useAppState();

  const text = new DOMParser().parseFromString(bookText, "text/html");

  text.querySelectorAll("img").forEach((img) => {
    const src = new URL(img.src).pathname;
    img.src = `https://${process.env.REACT_APP_BASE_IMG_URL}/${slide?.moduleId}/notes${src}`;
  });

  text.querySelectorAll("a").forEach((a) => {
    a.setAttribute("target", "_blank");
    a.setAttribute("rel", "noopener noreferrer");
  });

  text.querySelectorAll("div").forEach((div) => {
    const hasCodeBlocks = div.querySelectorAll(':scope > p[style*="background"]').length;
    const isIndented = div.querySelectorAll('p[style*="margin-left:1.0in"]');

    isIndented.forEach((el) => el.classList.add("indented"));
    hasCodeBlocks && div.classList.add("codeBlock");
  });

  text.querySelectorAll("table").forEach((table) => {
    const parent = table.parentNode as HTMLElement;
    const wrapper = document.createElement("div");

    wrapper.style.overflowX = "auto";
    wrapper.classList.add("tableWrapper");

    parent.insertBefore(wrapper, table);
    wrapper.appendChild(table);
  });

  const bookTextWithMedia = new XMLSerializer().serializeToString(text);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const evalCopy =
    '<span>If the course evaluation did not open, please go to <a href="' +
    section?.surveyUrl +
    '" target="_blank" rel="noreferrer">' +
    section?.surveyUrl +
    "</a> to complete the course evaluation.</span>";

  const coursebookBeginRef = useRef<null | HTMLDivElement>(null);

  const scrollToTop = () => {
    const top = window.pageYOffset || document.documentElement.scrollTop;
    const left = window.pageXOffset || document.documentElement.scrollLeft;
    coursebookBeginRef.current?.scrollIntoView();
    window.scrollTo(top, left);
  };

  useEffect(() => {
    scrollToTop();
  }, [bookTextWithMedia]);

  return (
    <BookContainer data-testid="coursebook" ref={coursebookBeginRef}>
      {loading ? (
        <>
          <Skeleton variant="rectangular" width={"80%"} height={30} sx={{ borderRadius: 1 }} />
          <Skeleton
            variant="rectangular"
            width={"100%"}
            height={408}
            sx={{ mt: 2, borderRadius: 1 }}
          />
        </>
      ) : (
        <>
          <HeaderContainer isModal={isModal}>
            <HeaderLeftContainer>
              <StyledButton aria-label="Adjust font size" onClick={handleClick}>
                <TextFieldsIcon />
              </StyledButton>
              <StyledPopover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                role="tooltip"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <FontSlider handleChangeFontSize={handleChangeFontSize} fontSize={fontSize} />
              </StyledPopover>
            </HeaderLeftContainer>
            <HeaderRightContainer>{customControl}</HeaderRightContainer>
          </HeaderContainer>
          {slide?.fullNumber && slide?.name && (
            <StyledTypography component="h3" fontSize={fontSize + titleSize}>
              {slide?.fullNumber}: {slide?.name}
            </StyledTypography>
          )}
          {bookTextWithMedia ? (
            <StyledBodyTypography
              component="p"
              fontSize={fontSize}
              dangerouslySetInnerHTML={{
                __html: sanitize(bookTextWithMedia, { ADD_ATTR: ["target"] }),
              }}
            />
          ) : (
            <>
              <StyledBodyTypography
                component="p"
                fontSize={fontSize}
                dangerouslySetInnerHTML={{
                  __html: sanitize(
                    "<span>If your browser allows pop-ups for sans.org, the course evaluation should open automatically in a new window or tab when this slide loads.</span>",
                  ),
                }}
              />
              <StyledBodyTypography
                component="p"
                fontSize={fontSize}
                dangerouslySetInnerHTML={{
                  __html: sanitize(evalCopy),
                }}
              />
            </>
          )}
        </>
      )}
    </BookContainer>
  );
}
