import React, { Component, ErrorInfo, ReactNode } from "react";
import { BigError } from "./BigError";
import { reportErrorToNR } from "../../util/reportErrorToNR";

export class ErrorBoundary extends Component<{
  children: ReactNode;
  reloadAction?: () => void;
}> {
  public state = { hasError: false, message: "", open: true };

  constructor(props: { children: ReactNode; reloadAction?: () => void }) {
    super(props);
  }

  static getDerivedStateFromError(): { hasError: boolean } {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    const { componentStack } = errorInfo;
    reportErrorToNR(error, { componentStack });
    this.setState({ message: error.message });
  }

  render(): ReactNode {
    return this.state.hasError ? <BigError message={this.state.message} /> : this.props.children;
  }
}
