import React from "react";
import { StyledColorOption, StyledColorPicker, StyledColorWrapper } from "./ColorPicker.elements";
import { StyledButtons } from "./EditNote.elements";

export type ColorType = string | false;
interface ColorPickerProps {
  onColorClick: (color: ColorType) => void;
  colors: readonly string[];
  active: string | false;
}

const ColorPicker = ({ colors = [], onColorClick, active }: ColorPickerProps) => {
  const handleColorClick = (color: ColorType) => () => {
    onColorClick(color);
  };

  return (
    <StyledColorPicker gap={2}>
      <StyledButtons variant="outlined" size="small" onClick={handleColorClick(false)} fullWidth>
        No Color
      </StyledButtons>
      <StyledColorWrapper>
        {colors.map((color) => {
          const isActive = color.toLowerCase() === (active as string)?.toLowerCase?.();

          return (
            <StyledColorOption
              key={color}
              color={color}
              active={isActive}
              onClick={handleColorClick(color)}
              aria-label={color}
            ></StyledColorOption>
          );
        })}
      </StyledColorWrapper>
    </StyledColorPicker>
  );
};

export default ColorPicker;
