import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Button, Dialog, DialogContent } from "@mui/material";
import { ReactComponent as ServerDown } from "./server-down.svg";
import { ErrorSeverity, useErrorHandler } from "../../hooks/useErrorHandler";

const StyledDialog = styled(Dialog)({
  textAlign: "center",
});

export function ErrorModal(): JSX.Element {
  const { error } = useErrorHandler(ErrorSeverity.MEDIUM);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (error) {
      setOpen(true);
      setMessage(error);
    }
  }, [error]);

  return (
    <StyledDialog open={open} onClose={() => setOpen(false)} maxWidth="md">
      <DialogContent
        style={{ padding: "2rem", display: "flex", flexDirection: "column", height: "45rem" }}
      >
        <ServerDown style={{ width: "60%", margin: "auto", height: "auto" }} />
        <h1 style={{ fontSize: "3em" }}>Oh no!</h1>
        <div>Something went wrong.</div>
        <div>
          <Button
            color="primary"
            variant="contained"
            style={{ margin: "2em" }}
            onClick={() => location.reload()}
          >
            Refresh page
          </Button>
        </div>
        <div style={{ color: "#ccc", marginTop: "auto" }}>{message}</div>
      </DialogContent>
    </StyledDialog>
  );
}
