import React, { useState, useContext } from "react";
import { ResizeCallbackData } from "react-resizable";
import classNames from "classnames";
import { IconButton } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { CourseContext } from "../../context/course-context";
import { usePersistedSettings } from "../../hooks/usePersistedSettings";
import { useAppState } from "../../hooks/useAppState";

import {
  Handle,
  StyledResizable,
  ChildrenWrapper,
  OpenSidebarButton,
} from "./ResizeWrapper.elements";

const defaultWidth = {
  sm: 375,
  lg: 420,
};

const handleWidth = 4;

const ResizeWrapper: React.FC = ({ children }) => {
  const { sidebarWidth, setSidebarWidth } = usePersistedSettings();

  const viewportWidth = window.innerWidth;
  const minWidth = defaultWidth[viewportWidth > 1440 ? "lg" : "sm"];

  const [hover, setHover] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [resizeWidth, setResizeWidth] = useState(sidebarWidth || minWidth);

  const course = useContext(CourseContext);
  const { module } = useAppState();

  const handleResize = (_: React.SyntheticEvent, { size }: ResizeCallbackData) =>
    setResizeWidth(size.width);

  const toggleSideBar = () => setCollapsed((prevState) => !prevState);

  const handleHover = (value: boolean) => () => setHover(value);

  const handleResizeStop = () => setSidebarWidth(resizeWidth);

  const resizeableClassnames = classNames({
    collapsed,
  });

  const toggleSidebarButton =
    module && course ? (
      <IconButton
        onClick={toggleSideBar}
        aria-label={`${collapsed ? "Open sidebar" : "Close sidebar"}`}
      >
        <ChevronLeftIcon />
      </IconButton>
    ) : null;

  return (
    <>
      {collapsed && (
        <OpenSidebarButton onClick={toggleSideBar}>
          <ArrowForwardIcon />
        </OpenSidebarButton>
      )}
      <StyledResizable
        className={resizeableClassnames}
        height={window.innerHeight}
        width={resizeWidth}
        onResize={handleResize}
        minConstraints={[minWidth, 0]}
        maxConstraints={[viewportWidth - minWidth, 0]}
        onResizeStop={handleResizeStop}
        handle={
          <Handle
            hover={hover}
            width={handleWidth}
            onMouseEnter={handleHover(true)}
            onMouseLeave={handleHover(false)}
          />
        }
      >
        <ChildrenWrapper width={resizeWidth}>
          {toggleSidebarButton}
          {children}
        </ChildrenWrapper>
      </StyledResizable>
    </>
  );
};

export default ResizeWrapper;
