import { OperationVariables, useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { MyCoursesQuery } from "./__generated__/MyCoursesQuery";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ErrorSeverity, useErrorHandler } from "../../hooks/useErrorHandler";

export const COURSES_QUERY = gql`
  query MyCoursesQuery($startAfter: RosterUserStartAfter) {
    myCourses(startAfter: $startAfter, showExpired: true, includeProgress: true) {
      rosterUsers {
        id
        userType
        registrationDeadline
        expires
        orderId
        created
        certification {
          name
          initials
          imageUrl
        }
        giacAvailable
        giacPurchased
        shippingTracking {
          deliveryStatus
          trackingNumber
        }
        trainingInstance {
          id
          expirationDateTime
          userId
          progress {
            instanceId
            viewedSeconds
            totalSeconds
            viewedSlides
            totalSlides
            totalQuizzes
            passedQuizzes
            complete
            lastView {
              slideId
              resumeTime
              dateTimeViewed
            }
            moduleProgress {
              id
              name
              type
              fullNumber
              viewedSlides
              totalSlides
              quizScore
            }
          }
        }
        course {
          id
          trainingId
          name
          hasMyLabs
          quickstartGuideUrl
          shortName
          licenses {
            title
            url
          }
          handouts {
            title
            url
          }
        }
      }
      totalCount
    }
  }
`;

export const ALL_MESSAGES = gql`
  query AllMessages {
    messages {
      text
      link
    }
  }
`;

export function useMyCoursesQuery() {
  const { reportError } = useErrorHandler();

  const [courses, setCourses] = useState<MyCoursesQuery["myCourses"]["rosterUsers"]>([]);
  const [runQuery, { data, error, loading }] = useLazyQuery<MyCoursesQuery>(COURSES_QUERY, {
    errorPolicy: "all",
    variables: {},
    onError: (error) => {
      reportError(ErrorSeverity.HIGH, "Failed to fetch courses", { errors: error });
    },
  });

  const count = useMemo(() => data?.myCourses.totalCount ?? 0, [data]);

  const loadMore = useCallback(() => {
    const variables: OperationVariables = {};
    const lastCourse = courses?.[courses.length - 1];
    if (lastCourse) {
      const { id, userType, expires } = lastCourse;
      const startAfterId = Number(id.split(":")[1]);
      variables.startAfter = { id: startAfterId, expires, userType };
    }
    runQuery({ variables });
  }, [courses, runQuery]);

  useEffect(() => {
    runQuery();
  }, [runQuery]);

  useEffect(() => {
    if (data?.myCourses.rosterUsers) {
      setCourses((prev) => [...prev, ...data.myCourses.rosterUsers]);
    }
  }, [data]);

  const hasMore = useMemo(() => courses.length < count, [courses, count]);

  return {
    courses,
    count,
    hasMore,
    error,
    loading,
    loadMore,
  };
}
