import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

export const StyledButton = styled(Button)(({ theme }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main,
  color: "white",
  margin: "2em",
  fontWeight: 600,
  fontSize: "1rem",
  lineHeight: "24px",
  boxShadow: "none",
  textTransform: "none",
  borderWidth: "2px",
  borderStyle: "solid",
  borderColor:
    theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main,
  "&:hover": {
    boxShadow: "none",
    background: theme.palette.primary.dark,
    borderColor: theme.palette.primary.dark,
  },
  "&:disabled": {
    color: "unset",
    background: "unset",
  },
  "&.outlined": {
    color: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main,
    backgroundColor: "white",
  },
  "&.outlined:hover": {
    color: theme.palette.primary.dark,
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "0.875rem",
    height: "42px",
    minWidth: "56px",
  },
  [theme.breakpoints.up("md")]: {
    marginBottom: "0.25rem",
  },
}));
