/* eslint-disable sonarjs/no-duplicate-string */
import React, { useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { CourseExpiration, ProgressText } from "./Progress.elements";
import { CalendarToday } from "@mui/icons-material";
import { ModuleProgress } from "./ProgressModal.elements";

import { getTimeDescription } from "../../util/time";
import { InstanceProgressFragment_progress } from "../../hooks/__generated__/InstanceProgressFragment";
import { InstanceExpirationFragment_expiration } from "../../hooks/__generated__/InstanceExpirationFragment";
import DetailedProgressBar from "./DetailedProgressBar";

type ProgressOverviewProps = {
  title: string;
  progress: InstanceProgressFragment_progress;
  expiration: InstanceExpirationFragment_expiration;
  compact?: boolean;
  openProgress?: () => void;
};

const ProgressOverview = ({
  compact = false,
  title,
  progress,
  expiration,
  openProgress,
}: ProgressOverviewProps) => {
  const theme = useTheme();
  const { percentSlides, viewedSlides, totalSlides, totalQuizzes, passedQuizzes } = progress;

  const remainingTime = (
    <div className="row label">
      <ProgressText sx={{ fontWeight: compact ? "bold" : "initial" }}>
        {`~${getTimeDescription(
          progress.hoursRemaining,
          compact ? " hour" : "hr",
          false,
        )} remaining`}
      </ProgressText>
    </div>
  );

  const headingColorProp = theme.custom.palette.semantic.blue;

  const headingProps = {
    compact: {
      color: expiration?.isExpired ? "#515D6A" : headingColorProp,
      fontWeight: "600",
    },
    normal: {
      variant: "h4",
      component: "h3",
    },
  } as const;

  const moduleProgress = useMemo(() => {
    const ModuleProgressFunction = ({
      title,
      complete = 0,
      total = 0,
      percent = 0,
    }: {
      title: string;
      complete?: number;
      total?: number;
      percent?: number;
    }) => {
      const isQuizProgress = title.toLowerCase().includes("quiz");

      return (
        <ModuleProgress id="module-progress" compact={compact}>
          <ProgressText className="row label progress" sx={{ width: compact ? "25%" : "auto" }}>
            {title} {compact && `${complete}/${total} (${Math.ceil(percent)}%)`}
          </ProgressText>
          <DetailedProgressBar
            compact={!!compact}
            complete={complete}
            total={total}
            percent={percent}
            isExpired={expiration?.isExpired}
            isQuizProgress={isQuizProgress}
          />
        </ModuleProgress>
      );
    };

    ModuleProgressFunction.displayName = "ModuleProgressFunction";

    return ModuleProgressFunction;
  }, [compact, expiration?.isExpired]);

  return (
    <div>
      <Stack alignItems="center" justifyContent="space-between" direction="row">
        <Typography
          onClick={openProgress}
          {...headingProps[compact ? "compact" : "normal"]}
          sx={{ cursor: openProgress && "pointer" }}
        >
          {title}
        </Typography>
        {compact && remainingTime}
      </Stack>

      <ModuleProgress compact={compact} margin={!compact ? "-15px 0px" : ""}>
        {!compact && remainingTime}

        {moduleProgress({
          title: "Slides Viewed",
          complete: viewedSlides,
          total: totalSlides,
          percent: percentSlides,
        })}
      </ModuleProgress>

      <ModuleProgress compact={compact}>
        {!compact && (
          <div className="row expiration">
            <CourseExpiration>
              <CalendarToday fontSize="small" />
              <Typography>
                <div>{expiration?.expirationText}</div>
                <div>{expiration?.expirationDate}</div>
              </Typography>
            </CourseExpiration>
          </div>
        )}

        {!!totalQuizzes &&
          moduleProgress({
            title: "Quizzes Passed",
            complete: passedQuizzes,
            total: totalQuizzes,
            percent: (passedQuizzes / totalQuizzes) * 100,
          })}
      </ModuleProgress>
    </div>
  );
};

export default ProgressOverview;
