import { styled, Link, Typography, Button, Paper } from "@mui/material";

export const StyledFooter = styled("footer")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  padding: theme.spacing(2),
  fontSize: "0.875rem",
  marginTop: "1em",

  ["& a"]: {
    color: theme.custom.palette.semantic.blue,
  },
}));

export const HelpLink = styled(Link)(({ theme }) => ({
  color: theme.custom.palette.semantic.blue,
}));

export const TypeContainer = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

export const PaperContainer = styled(Paper)(({ theme }) => ({
  padding: "1em",
  borderRadius: "8px",
  marginTop: theme.spacing(3),
  border:
    theme.palette.mode === "dark"
      ? "1px solid " + theme.palette.grey[700]
      : "1px solid " + theme.custom.palette.blueGrey[100],
  boxShadow: "none",
}));

export const LoadMoreButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.custom.palette.semantic.buttonContainedBg,
  color: theme.custom.palette.semantic.buttonContainedColor,
  border: "1px solid " + theme.custom.palette.semantic.buttonContainedBorder,

  ["&:hover"]: {
    backgroundColor: theme.custom.palette.semantic.buttonContainedHoverBg,
    color: theme.custom.palette.semantic.buttonContainedHoverColor,
    border: "1px solid " + theme.custom.palette.semantic.buttonContainedHoverBorder,
  },

  ["&:focus"]: {
    backgroundColor: theme.custom.palette.semantic.buttonContainedFocusBg,
    color: theme.custom.palette.semantic.buttonContainedFocusColor,
    border: "1px solid " + theme.custom.palette.semantic.buttonContainedFocusBorder,
  },
}));
