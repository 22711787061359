import { styled } from "@mui/material/styles";
import { Grid, Paper, Typography, Button, Divider } from "@mui/material";

export const ContentContainer = styled(Grid)(({ theme }) => ({
  minHeight: "430px",
  height: "100%",
  maxWidth: "100%",
  position: "relative",
  flexWrap: "nowrap",

  [theme.breakpoints.down("md")]: {
    flexDirection: "column-reverse",
    height: "auto",
    minHeight: "unset",
  },
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    width: "100%",
    margin: "0 auto",
  },
  "&.skeleton": {
    height: "100%",
  },
}));

export const GridViewport = styled(Grid)({
  flex: 1,

  "&.skeleton": {
    flex: 5,
  },
  "&.fullview": {
    position: "absolute",
    maxWidth: "100%",
    width: "100%",
    backgroundColor: "white",
    zIndex: 100,
    left: 0,
  },
  "& > div": {
    height: "100%",
  },
});

export const StyledPaper = styled(Paper)(({ theme }) => ({
  containerType: "inline-size",
  containerName: "container",
  backgroundImage: "unset",
  backgroundColor:
    theme.palette.mode === "dark" ? theme.custom.palette.grey[800] : theme.custom.palette.light,
  boxShadow: theme.custom.shadows[3],
  borderRadius: 0,
  padding: theme.spacing(3),
  minWidth: "375px",
  [theme.breakpoints.down("lg")]: {
    minWidth: "320px",
  },
  "&.skeleton": {
    flex: 1,
  },
}));

export const StyledPaperCourse = styled(StyledPaper)(({ theme }) => ({
  overflow: "hidden",
  borderRadius: 0,
  padding: "0px",
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.up("md")]: {
    paddingLeft: 0,
  },
}));

export const StyledButtonContainer = styled(Grid)(({ theme }) => ({
  gap: theme.spacing(1),
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  flexShrink: 0,
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  backgroundColor: theme.custom.palette.semantic.buttonContainedBg,
  color: theme.custom.palette.semantic.buttonContainedColor,
  border: `1px solid ${theme.custom.palette.semantic.buttonContainedBorder}`,
  boxSizing: "border-box",
  fontWeight: 600,
  fontSize: "1rem",
  lineHeight: "24px",
  "&:hover": {
    backgroundColor: theme.custom.palette.semantic.buttonContainedHoverBg,
    color: theme.custom.palette.semantic.buttonContainedHoverColor,
    border: `1px solid ${theme.custom.palette.semantic.buttonContainedHoverBorder}`,
  },
  "&:focus": {
    backgroundColor: theme.custom.palette.semantic.buttonContainedFocusBg,
    color: theme.custom.palette.semantic.buttonContainedFocusColor,
    border: `1px solid ${theme.custom.palette.semantic.buttonContainedFocusBorder}`,
  },
  "&:disabled": {
    backgroundColor: theme.custom.palette.semantic.buttonContainedDisabledBg,
    color: theme.custom.palette.semantic.buttonContainedDisabledColor,
    border: `1px solid ${theme.custom.palette.semantic.buttonContainedDisabledBorder}`,
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "0.875rem",
    height: "42px",
    minWidth: "56px",
    "& .button-text": {
      display: "none",
    },
    "& .MuiButton-startIcon": {
      margin: "0px",
    },
  },
  [theme.breakpoints.up("md")]: {
    marginBottom: "0.25rem",
    marginRight: 0,
  },
  [theme.breakpoints.up("lg")]: {
    marginBottom: 0,
  },
}));

export const StyledTextButton = styled(Button)(({ theme }) => ({
  textDecoration: "underline",
  textDecorationColor: theme.palette.primary.main,
  color: theme.custom.palette.semantic.blue,
  paddingRight: "0px",
  fontWeight: 600,
  fontSize: "0.875rem",
  lineHeight: "19px",
  "&:hover": {
    background: "transparent",
    textDecoration: "underline",
    color: theme.palette.primary.dark,
  },
}));

type StyledTypographyProps = {
  component: React.ElementType;
};
export const StyledTypography = styled(Typography)<StyledTypographyProps>(({ theme }) => ({
  fontWeight: 700,
  fontSize: "1.375rem",
  lineHeight: "35.41px",
  textAlign: "left",
  [theme.breakpoints.down("md")]: {
    fontSize: "1.125rem",
    lineHeight: "24.51px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem",
    lineHeight: "21.79px",
  },
}));

export const InlineContainer = styled("div")(() => ({
  display: "inline-flex",
  width: "100%",
}));

export const CourseWrapper = styled("div")({
  flexGrow: 1,
  position: "relative",
  width: "100%",
});

export const ModuleDivider = styled(Divider)(({ theme }) => ({
  margin: "24px 0",
  borderColor: theme.palette.grey[100],
}));

export const ContentLeft = styled(Grid)(({ theme }) => ({
  "&.MuiGrid-item": {
    [theme.breakpoints.up("md")]: {
      padding: 0,
      maxWidth: "320px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: 0,
      maxWidth: "375px",
    },
  },
}));

export const ContentRight = styled(Grid)(({ theme }) => ({
  position: "relative",

  "&.MuiGrid-item": {
    [theme.breakpoints.up("md")]: {
      padding: 0,
    },
  },
}));

export const ContentSkeleton = styled("div")(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),
  padding: theme.spacing(3),
  width: "100%",

  minHeight: "300px",

  ["& :nth-of-type(1)"]: {
    flex: 2,
  },
  ["& :nth-of-type(2)"]: {
    flex: 10,
  },
}));
