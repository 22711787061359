import React from "react";
import { getFormattedSeconds, getDateTime } from "./utils";

type DurationProps = {
  seconds: number;
};

export default function Duration({ seconds }: DurationProps): JSX.Element {
  return <time dateTime={getDateTime(seconds)}>{getFormattedSeconds(seconds)}</time>;
}
