import { IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Resizable } from "react-resizable";

export const StyledResizable = styled(Resizable)(({ theme }) => ({
  borderRight: `1px solid ${theme.palette.grey[theme.palette.mode === "dark" ? 600 : 100]}`,

  "&.collapsed": {
    display: "none !important",
  },
}));

export const Handle = styled("div")<{ hover: boolean; width: number }>(
  ({ hover, width, theme }) => ({
    height: "100%",
    background: theme.palette.primary.main,
    position: "absolute",
    zIndex: 99999,
    cursor: "ew-resize",
    opacity: hover ? 1 : 0,
    top: 0,
    width: width,
    right: -width - 1,
  }),
);

export const OpenSidebarButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  left: 0,
  top: theme.spacing(5),
  zIndex: 99999,
  border: "1px solid white",
  color: "white",
  borderLeft: "none",
  borderRadius: 0,
}));

export const ChildrenWrapper = styled("div")<{ width: number }>(({ width, theme }) => ({
  width: width,
  minWidth: width,
  display: "flex",
  flexDirection: "column",
  background: theme.palette.mode === "dark" ? "#252830" : "#fff",
  alignItems: "flex-end",
}));
