import { makeVar, useReactiveVar } from "@apollo/client";
import { useCallback, useEffect, useMemo } from "react";
import { useAnalyticsEvents } from "@atlaskit/analytics-next";
import {
  PlaylistPlay,
  MenuBook,
  StickyNote2,
  Bookmarks as BookmarksIcon,
} from "@mui/icons-material";
import useTranslation from "../../hooks/useTranslation";
import { Outline } from "../Outline/Outline";
import { CoursebookSidebar } from "../Coursebook/CoursebookSidebar";
import { Notes } from "../Notes/Notes";
import { Bookmarks } from "../Bookmarks/Bookmarks";
import { usePersistedSettings } from "../../hooks/usePersistedSettings";
import { telemetryChannelName } from "../../util/constants";
import { useTestMode } from "../../hooks/useTestMode";

const tabsVar = makeVar(0);

export const icons = {
  PlaylistPlay,
  MenuBook,
  StickyNote2,
  Bookmarks: BookmarksIcon,
};

export const featureName = "tab";
export const changeActionType = "change";

export function useTabs(): {
  tabIdx: number;
  changeTab: (newTabIdx: number) => void;
  tabs: {
    label: string;
    component: (props: { active: boolean }) => JSX.Element | null;
    icon: keyof typeof icons | null;
  }[];
} {
  const { translate } = useTranslation();
  const { courseTab, setCourseTab } = usePersistedSettings();
  const { testMode } = useTestMode();

  const tabs: {
    label: string;
    component: (props: { active: boolean }) => JSX.Element | null;
    icon: keyof typeof icons | null;
  }[] = useMemo(
    () => [
      {
        label: translate("Outline"),
        component: Outline,
        icon: "PlaylistPlay",
      },
      {
        label: translate("Coursebook"),
        component: CoursebookSidebar,
        icon: "MenuBook",
      },
      {
        label: translate("Notes"),
        component: Notes,
        icon: "StickyNote2",
      },
      {
        label: translate("Bookmarks"),
        component: Bookmarks,
        icon: "Bookmarks",
      },
    ],
    [translate],
  );

  useEffect(() => {
    tabsVar(testMode ? 1 : courseTab);
  }, [courseTab, testMode]);

  const tabIdx = useReactiveVar(tabsVar);
  const { createAnalyticsEvent } = useAnalyticsEvents();
  const changeTab = useCallback(
    (newTabIdx: number) => {
      if (testMode) return;
      tabsVar(newTabIdx);
      setCourseTab(newTabIdx);

      createAnalyticsEvent({
        feature: featureName,
        action: changeActionType,
      }).fire(telemetryChannelName);
    },
    [createAnalyticsEvent, setCourseTab, testMode],
  );

  return { tabIdx, changeTab, tabs };
}
