import { Alert, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ErrorSeverity, useErrorHandler } from "../../hooks/useErrorHandler";

export const ErrorToast = (): JSX.Element => {
  const { error } = useErrorHandler(ErrorSeverity.LOW);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (error) {
      setOpen(true);
      setMessage(error);
    }
  }, [error]);

  return (
    <Snackbar
      open={open}
      autoHideDuration={60000}
      onClose={() => setOpen(false)}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert
        severity="error"
        onClose={() => setOpen(false)}
        sx={{ backgroundColor: "#435165", color: "white", [" svg"]: { color: "white" } }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};
