import { useQuery, gql } from "@apollo/client";
import {
  setVolume,
  setAutoplay,
  setCaptions,
  setPlayback,
  setQuality,
  setCoursebookFontSize,
  setCourseTab,
  setMuted,
  setDismissedMessages,
  setMode,
  ModeType,
  setSidebarWidth,
} from "../apollo/typePolicies/LocalStorage";
import { VideoResolution } from "../__generated__/globalTypes";

export const SETTINGS_QUERY = gql`
  query getPlayerSettings {
    volume @client
    autoplay @client
    captions @client
    playback @client
    quality @client
    coursebookFontSize @client
    courseTab @client
    muted @client
    dismissedMessages @client
    mode @client
    sidebarWidth @client
  }
`;

type UsePersistedSettings = {
  autoplay: boolean;
  volume: number;
  captions: boolean;
  playback: number;
  quality: VideoResolution | "auto";
  coursebookFontSize: number;
  courseTab: number;
  muted: boolean;
  dismissedMessages: string[];
  mode: ModeType;
  sidebarWidth: number;
  setAutoplay: (isEnabled: boolean) => void;
  setVolume: (vol: number) => void;
  setCaptions: (caps: boolean) => void;
  setPlayback: (rate: number) => void;
  setQuality: (qual: string) => void;
  setCoursebookFontSize: (fontSize: number) => void;
  setCourseTab: (courseTab: number) => void;
  setMuted: (isMuted: boolean) => void;
  setDismissedMessages: (messages: string[]) => void;
  setMode: (value: ModeType) => void;
  setSidebarWidth: (value: number) => void;
};

export function usePersistedSettings(): UsePersistedSettings {
  const { error, data } = useQuery(SETTINGS_QUERY);

  if (error) {
    throw new Error("Failed to get settings from local storage.");
  }

  const {
    volume,
    autoplay,
    captions,
    playback,
    quality,
    coursebookFontSize,
    courseTab,
    muted,
    dismissedMessages,
    mode,
    sidebarWidth,
  } = data;

  return {
    volume,
    setVolume,
    autoplay,
    setAutoplay,
    captions,
    setCaptions,
    playback,
    setPlayback,
    quality,
    setQuality,
    coursebookFontSize,
    setCoursebookFontSize,
    courseTab,
    setCourseTab,
    muted,
    setMuted,
    dismissedMessages,
    setDismissedMessages,
    mode,
    setMode,
    sidebarWidth,
    setSidebarWidth,
  };
}
