import { styled } from "@mui/material/styles";
import { Box, Popover, Slider } from "@mui/material";

export const ControlsBottomContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  containerType: "inline-size",

  "& .condensed": {
    display: "none",
  },

  "@container (width < 550px)": {
    ".additional": {
      display: "none",
    },

    ".condensed": {
      display: "flex",
    },
  },
}));

export const AdditionalControls = styled(Popover)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  containerType: "inline-size",
}));

export const ControlsBottomBackground = styled(Box)(({ theme }) => ({
  background: theme.custom.gradients.blackToTransparent,
  backgroundColor: "transparent",
  position: "absolute",
  bottom: "0",
  left: "0",
  width: "100%",
  height: "200%",
  zIndex: -1,
}));

export const ButtonsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  zIndex: theme.zIndex.modal,
  overflowX: "clip",
}));

export const ProgressBar = styled(Slider)(({ theme }) => ({
  color: theme.palette.grey[50],
  height: "4px",
  padding: "5px 0",

  ".MuiSlider-rail": {
    opacity: 0.28,
  },

  ".MuiSlider-rail, .MuiSlider-track": {
    transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
    transform: "scaleY(1) translateY(-50%)",
    transformOrigin: "top",
  },

  ".MuiSlider-thumb": {
    width: 8,
    height: 8,
    top: "50%",
    transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
    transform: "scale(1) translate(-50%, -50%)",
    transformOrigin: "top",

    "&:before": {
      boxShadow: theme.custom.shadows[6],
    },

    "&:hover, &.Mui-focusVisible": {
      boxShadow: `0px 0px 0px 8px ${theme.custom.palette.semantic.sliderThumbOuterShadow}`,
    },

    "&.Mui-active": {
      width: 15,
      height: 15,
    },
  },

  "&:hover": {
    ".MuiSlider-rail, .MuiSlider-track": {
      transform: "scaleY(2) translateY(-50%)",
    },

    "& .MuiSlider-thumb": {
      transform: "scale(2) translate(-50%, -50%)",
    },
  },
}));

export const LeftSideControls = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

export const RightSideControls = styled(Box)(() => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
}));

export const AdditionalControlsPopover = styled(Popover)(({ theme }) => ({
  svg: {
    fill: theme.custom.palette.white,
  },

  ".MuiPaper-root": {
    background: theme.palette.grey[800],
  },

  ".settings": {
    display: "none",
  },
}));
