import { Alert, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { HeaderButton } from "./Header.elements";
import { useCaptioner } from "../../hooks/useCaptioner";
import { useAppState } from "../../hooks/useAppState";
import { Sans } from "../../util/sdk";

export const Recaption = (): JSX.Element | null => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("message");
  const { caption, captionsStarted, captionsError } = useCaptioner();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const {
    appState: { moduleId },
    slideIdx: { index: slideIdx },
  } = useAppState();

  useEffect(() => {
    const checkAuthorized = async () => {
      const authorized = await Sans.sdk.isAuthorized("od-caption-editor");
      setIsAuthorized(authorized);
    };
    checkAuthorized();
  }, []);

  useEffect(() => {
    if (captionsError) {
      setOpen(true);
      setMessage("Error captioning slide");
    }
  }, [captionsError]);

  useEffect(() => {
    if (captionsStarted) {
      setOpen(true);
      setMessage("Slide captions started");
    }
  }, [captionsStarted]);

  if (!moduleId || slideIdx === undefined || !isAuthorized) return null;

  return (
    <>
      <HeaderButton
        tabIndex={1}
        variant="outlined"
        onClick={() => caption(moduleId, slideIdx + 1)}
        id="qc-ticket-btn"
      >
        <span id="recaption-slide" className="message-text">
          Recaption
        </span>
      </HeaderButton>
      <Snackbar
        open={open}
        autoHideDuration={60000}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          severity={captionsError ? "error" : "success"}
          onClose={() => setOpen(false)}
          sx={{ backgroundColor: "#435165", color: "white", [" svg"]: { color: "white" } }}
        >
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};
