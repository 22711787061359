import React, { useRef, useState } from "react";
import ReactQuill from "react-quill";

type ReactQuillType = InstanceType<typeof ReactQuill>;
export interface NotesContextType {
  editingName?: string;
  setEditingName: React.Dispatch<React.SetStateAction<string>>;
  isEditorFocused: boolean;
  setIsEditorFocused: React.Dispatch<React.SetStateAction<boolean>>;
  editorRef: React.RefObject<ReactQuillType>;
}

export const NotesContext = React.createContext<NotesContextType>({} as NotesContextType);

export const NotesProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [editingName, setEditingName] = useState("Edit Note");
  const [isEditorFocused, setIsEditorFocused] = useState(false);

  const editorRef = useRef<any>(null);

  return (
    <NotesContext.Provider
      value={{ editingName, setEditingName, isEditorFocused, setIsEditorFocused, editorRef }}
    >
      {children}
    </NotesContext.Provider>
  );
};
