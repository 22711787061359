import * as localization from "@mui/material/locale";
import * as translations from "./translations";

export const enUS: localization.Localization & translations.Translations = {
  ...localization["enUS"],
  ...translations["enUS"],
};

export const deDE: localization.Localization & translations.Translations = {
  ...localization["deDE"],
  ...translations["deDE"],
};
