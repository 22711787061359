import { styled } from "@mui/material/styles";
import { Slider, Box } from "@mui/material";

export const Container = styled(Box)(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  marginRight: theme.spacing(1),
}));

export const VolumeSliderContainer = styled(Box)(({ theme }) => ({
  marginBottom: "10px",
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  width: 100,
  display: "inline-flex",
  alignItems: "center",
}));

export const VolumeSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.grey[50],
  height: 4,
  padding: 0,
  "& .MuiSlider-thumb": {
    width: 8,
    height: 8,
    transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
    "&:before": {
      boxShadow: theme.custom.shadows[6],
    },
    "&:hover, &.Mui-focusVisible": {
      boxShadow: `0px 0px 0px 8px ${theme.custom.palette.semantic.sliderThumbOuterShadow}`,
    },
    "&.Mui-active": {
      width: 15,
      height: 15,
    },
  },
  "& .MuiSlider-rail": {
    opacity: 0.28,
  },
}));
