import { makeVar, useReactiveVar } from "@apollo/client";
import { telemetryChannelName } from "../util/constants";
import { useAnalyticsEvents } from "@atlaskit/analytics-next";

export const featureName = "progressView";
export const openActionType = "open";

const progressModalOpen = makeVar(false);
const progressModalParams = makeVar<{
  courseId?: number;
  courseUuid?: string;
  instanceId?: string;
}>({});
export function useProgressModal() {
  const { createAnalyticsEvent } = useAnalyticsEvents();

  const modalOpen = useReactiveVar(progressModalOpen);
  const setModalOpen = (open: boolean) => {
    progressModalOpen(open);
    createAnalyticsEvent({
      feature: featureName,
      action: openActionType,
    }).fire(telemetryChannelName);
  };

  const modalParams = useReactiveVar(progressModalParams);

  const openModal = (params: typeof modalParams) => {
    progressModalParams(params);
    setModalOpen(true);
  };

  const closeModal = () => setModalOpen(false);

  return {
    modalOpen,
    modalParams,
    openModal,
    closeModal,
  };
}
