import React, { useCallback } from "react";
import { StyledButton, StyledPlayButton } from "./Coursebook.elements";
import { useAppState } from "../../hooks/useAppState";
import { Box } from "@mui/material";
import { useReactiveVar } from "@apollo/client";
import { setIsPlaying } from "../Video/useVideo";
import {
  PauseCircleRounded,
  PlayCircleRounded,
  SkipNextRounded,
  SkipPreviousRounded,
} from "@mui/icons-material";

export function MiniControls(): JSX.Element | null {
  const { nextSlide, isLastSlide, slideIdx } = useAppState();
  const isPlaying = useReactiveVar(setIsPlaying);

  const handleSkip = (prev?: boolean) => () => {
    if ((slideIdx.index === 0 && prev) || (isLastSlide && !prev)) return;
    nextSlide(prev);
  };

  const togglePlayback = useCallback(() => setIsPlaying(!isPlaying), [isPlaying]);

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <StyledButton onClick={handleSkip(true)} aria-label="Previous slide">
        <SkipPreviousRounded fontSize="large" />
      </StyledButton>
      <StyledPlayButton onClick={togglePlayback} aria-label={isPlaying ? "Pause" : "Play"}>
        {isPlaying ? (
          <PauseCircleRounded sx={{ fontSize: "2.5em" }} />
        ) : (
          <PlayCircleRounded sx={{ fontSize: "2.5em" }} />
        )}
      </StyledPlayButton>
      <StyledButton onClick={handleSkip()} aria-label="Next slide">
        <SkipNextRounded fontSize="large" />
      </StyledButton>
    </Box>
  );
}
