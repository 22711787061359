import React, { useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Library } from "./pages/Library/Library";
import { AuthenticatedApolloProvider } from "./components/AuthenticatedApolloProvider/AuthenticatedApolloProvider";
import PlayerWrapper from "./components/PlayerWrapper/PlayerWrapper";
import { Authorizer } from "./pages/Authorizer/Authorizer";
import { Player } from "./pages/Player/Player";
import { ErrorWrapper } from "./components/Errors/ErrorWrapper";

import "./App.css";

function App(): JSX.Element {
  const handleContextMenu = (event: MouseEvent) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const debug = urlParams.get("debug") === "true";
    const parent = document.getElementById("zopim-widget");
    const target = event.target as HTMLElement;

    if ((!parent || (parent && target && !parent.contains(target))) && !debug) {
      event.preventDefault();
    } else {
      return true;
    }
  };

  useEffect(() => {
    document.getElementById("App")?.addEventListener("contextmenu", handleContextMenu);

    return () =>
      document.getElementById("App")?.removeEventListener("contextmenu", handleContextMenu);
  }, []);

  return (
    <div className="App" id="App" style={{ display: "flex" }}>
      <ErrorWrapper>
        <BrowserRouter>
          <Switch>
            <Route exact path="/">
              <AuthenticatedApolloProvider>
                <PlayerWrapper>
                  <Library />
                </PlayerWrapper>
              </AuthenticatedApolloProvider>
            </Route>
            <Route exact path="/authorize">
              <Authorizer />
            </Route>
            <Route path="/previewer">
              <AuthenticatedApolloProvider requiredRole="ondemandPreview">
                <PlayerWrapper>
                  <Player testMode={true} />
                </PlayerWrapper>
              </AuthenticatedApolloProvider>
            </Route>
            <Route path="/:courseId">
              <AuthenticatedApolloProvider>
                <PlayerWrapper>
                  <Player />
                </PlayerWrapper>
              </AuthenticatedApolloProvider>
            </Route>
          </Switch>
        </BrowserRouter>
      </ErrorWrapper>
    </div>
  );
}

export default App;
