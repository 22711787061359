import React, { useCallback, useRef, useEffect, useContext } from "react";
import { StyledButton } from "./Player.elements";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import NoteOutlinedIcon from "@mui/icons-material/NoteOutlined";
import { useAppState } from "../../hooks/useAppState";
import { useNotes } from "../../components/Notes/Notes.queries";
import { useBookmarks } from "../../components/Bookmarks/Bookmarks.queries";
import { useTabs } from "../../components/Course/useTabs";
import { useSearch } from "../../components/Search/Search.queries";
import { NotesContext, NotesContextType } from "../../context/notes-context";
import { Stack } from "@mui/material";
import { useTestMode } from "../../hooks/useTestMode";
import { getVideoProgress } from "../../hooks/useVideoProgress";

export function ViewportActions(): JSX.Element {
  const {
    slide,
    appState: { slideId },
  } = useAppState();
  const tabIndex = 1;
  const { editNote, notes } = useNotes();
  const { saveBookmark } = useBookmarks();
  const { changeTab } = useTabs();
  const { setShowSearchResults } = useSearch();
  const { setEditingName } = useContext(NotesContext) as NotesContextType;
  const { testMode } = useTestMode();

  const alertRef = useRef<HTMLDivElement>(null);
  const BOOKMARK_TAB_INDEX = 3;
  const NOTE_TAB_INDEX = 2;

  useEffect(() => {
    // Reset the screen reader alert text
    if (alertRef.current) {
      alertRef.current.textContent = "";
    }
  }, [slideId]);

  const handleAddBookmark = useCallback(() => {
    if (!slideId) {
      return;
    }

    const timestamp = getVideoProgress();

    setShowSearchResults(false);
    changeTab(BOOKMARK_TAB_INDEX);
    saveBookmark(slideId, timestamp);

    // Inject "bookmark saved" for screen reader
    if (alertRef.current) {
      alertRef.current.textContent = "bookmark saved";
    }
  }, [slideId, setShowSearchResults, changeTab, saveBookmark]);

  const handleAddNote = useCallback(() => {
    if (!slideId) {
      return;
    }

    setShowSearchResults(false);
    changeTab(NOTE_TAB_INDEX);
    editNote(slideId);
    setEditingName(`${slide?.fullNumber} ${slide?.name}`);
  }, [slideId, changeTab, editNote, setEditingName, slide, setShowSearchResults]);

  const getNoteBtnText = notes.some((note) => note.slideId === slideId) ? "Edit Note" : "Add Note";

  return (
    <Stack direction="row" flexWrap="wrap" gap={1} data-testid="action_buttons">
      <StyledButton
        startIcon={<BookmarkBorderIcon />}
        aria-label="Add Bookmark"
        onClick={handleAddBookmark}
        disabled={!slideId || testMode}
        tabIndex={tabIndex}
        id="bookmark_save_button"
      >
        <span className="button-text">Add Bookmark</span>
      </StyledButton>
      <StyledButton
        startIcon={<NoteOutlinedIcon />}
        aria-label={getNoteBtnText}
        onClick={handleAddNote}
        disabled={!slideId || testMode}
        id="note-add-button"
        tabIndex={tabIndex}
      >
        <span className="button-text">{getNoteBtnText}</span>
      </StyledButton>
      <div role="status" className="sr-only" ref={alertRef} />
    </Stack>
  );
}
