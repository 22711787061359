import React, { useCallback, useContext, useState } from "react";
import { CircularProgress, FormGroup, Grid, Typography } from "@mui/material";
import Email from "@mui/icons-material/Email";
import { HelpAndSupportHeader } from "./HelpAndSupportHeader";
import {
  PageContainer,
  SupportMessageTextField,
  Title,
  MessageActionButtons,
  MessageActionsContainer,
  StyledCheckbox,
  StyledFormLabel,
} from "./TechSupportMessageDialog.elements";
import { AutomatedMessage } from "./Common.elements";
import { CategoryType, useTechSupport } from "../../hooks/useTechSupport";
import { HasbotContext, HasbotContextType, TechSupportType } from "../../context/hasBot-context";
import { useUserInfo } from "@sans-tesseract/sdk-react";
import { useAppState } from "../../hooks/useAppState";
import { detect } from "detect-browser";

export function TechSupportMessageDialog(): JSX.Element {
  const [messageText, setMessageText] = useState("");
  const [messageHasBeenSent, setMessageHasBeenSent] = useState(false);
  const [error, setError] = useState(undefined);
  const [sendMessageCopy, setSendMessageCopy] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const techSupport = useTechSupport();
  const { techSupportActive } = useContext(HasbotContext) as HasbotContextType;
  const browser = detect();
  const userInfo = useUserInfo();
  const {
    course,
    module,
    slide,
    appState: { courseId },
  } = useAppState();

  const title =
    techSupportActive === TechSupportType.CSS
      ? "Customer Service Support"
      : "Subject Matter Expert Help";

  const handleCopyMeToggle = () => {
    setSendMessageCopy(!sendMessageCopy);
  };

  const handleKeyUp = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleCopyMeToggle();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) =>
    setMessageText(event.target.value);

  const sendMessage = useCallback(async () => {
    setIsSending(true);
    setError(undefined);

    const category =
      techSupportActive === TechSupportType.CSS
        ? CategoryType.Technical_Support
        : CategoryType.Content_Questions;

    const shortDescription =
      "SANS OnDemand - " + userInfo.given_name + " " + userInfo.family_name + " " + course?.name;

    const email = sendMessageCopy
      ? {
          attributes: {
            slideUuid: slide?.id ?? "00000000-0000-0000-0000-000000000000",
            courseId: courseId?.toString() ?? "Unknown",
            token: "",
          },
          body: messageText,
          subject: shortDescription,
          to: userInfo.email,
        }
      : undefined;

    const attributes = {
      agent: {
        app: {
          version: "OnDemand Web 2.0",
        },
        name: browser?.name ?? "Unknown",
        os: browser?.os ?? "Unknown",
        version: browser?.version ?? "Unknown",
      },
      course: {
        id: courseId ?? 0,
        name: course?.shortName ?? "Unknown",
        version: course?.version ?? "Unknown",
      },
      slide: {
        index: slide?.number ?? 0,
        name: slide?.name ?? "Quiz/Eval",
        uuid: slide?.id ?? "00000000-0000-0000-0000-000000000000",
      },
      module: {
        name: module?.name ?? "Unknown",
        number: module?.fullNumber ? +module?.fullNumber : 0,
        uuid: module?.id ?? "Unknown",
      },
    };

    const user = {
      email: userInfo.email,
      firstName: userInfo.given_name,
      id: userInfo["cognito:username"],
      lastName: userInfo.family_name,
    };

    const data = await techSupport.sendMessage({
      assignmentGroup: techSupportActive as TechSupportType,
      attributes,
      category,
      description: messageText,
      email,
      message: messageText,
      shortDescription: shortDescription,
      user,
    });

    if (data.error) {
      setError(data.error);
      setIsSending(false);
      return;
    }

    setMessageText("");
    setMessageHasBeenSent(true);
    setIsSending(false);
  }, [techSupportActive, messageText, sendMessageCopy, techSupport]);

  return (
    <Grid container flexDirection="column">
      <HelpAndSupportHeader>
        <div className="header-wrapper">
          <Title className="tech-support-header">{title}</Title>
        </div>
      </HelpAndSupportHeader>
      <PageContainer item>
        {messageHasBeenSent ? (
          <AutomatedMessage>
            Thank you for contacting us! We will get back to you within 48 hours.
          </AutomatedMessage>
        ) : (
          <>
            <AutomatedMessage>
              Once we receive your submission, we will respond by email within 2 hours during normal
              business hours and within 24 hours during non-business hours.
            </AutomatedMessage>
            <SupportMessageTextField
              autoFocus
              id="support-message"
              placeholder="Your Message Here"
              value={messageText}
              onChange={handleChange}
              aria-label="support message"
            />
            {error && (
              <Typography marginY={2} color="red" fontSize={14}>
                {error} Please try again.
              </Typography>
            )}

            <Grid
              item
              gap={3}
              container
              alignItems="center"
              justifyContent="flex-end"
              direction="row"
            >
              <MessageActionsContainer item sx={{ margin: 0 }}>
                <FormGroup>
                  <StyledFormLabel
                    control={
                      <StyledCheckbox
                        name="copyMe"
                        id="send-me-copy"
                        checked={sendMessageCopy}
                        onChange={handleCopyMeToggle}
                        onKeyUp={handleKeyUp}
                      />
                    }
                    label="Send me a copy"
                  />
                </FormGroup>
              </MessageActionsContainer>
              <Grid item>
                <MessageActionButtons
                  disabled={messageText.length <= 0 || isSending}
                  variant="contained"
                  startIcon={isSending ? <CircularProgress size={24} /> : <Email />}
                  onClick={sendMessage}
                  id="send-support-message"
                >
                  Send
                </MessageActionButtons>
              </Grid>
            </Grid>
          </>
        )}
      </PageContainer>
    </Grid>
  );
}
