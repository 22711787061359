import React, { useEffect, useContext } from "react";
import { useParams } from "react-router";
import { Helmet } from "react-helmet";
import { Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Header } from "../../components/Header/Header";
import { Course } from "../../components/Course/Course";
import { Progress } from "../../components/Progress/Progress";
import { useAppState } from "../../hooks/useAppState";
import {
  ContentContainer,
  StyledPaperCourse,
  CourseWrapper,
  ContentRight,
  ContentSkeleton,
} from "./Player.elements";
import { ViewportContent } from "./ViewportContent";
import { useViewport, View } from "../../components/Viewport/useViewport";
import { NotesProvider } from "../../context/notes-context";
import { useTestMode } from "../../hooks/useTestMode";
import { HelpAndSupport } from "./HelpAndSupport";
import ResizeWrapper from "./ResizeWrapper";
import { CourseContext } from "../../context/course-context";
import MuiSkeleton from "@mui/material/Skeleton";

export function Player({ testMode = false }: { testMode?: boolean }): JSX.Element | null {
  const { courseId } = useParams<{ courseId: string }>();
  const { setCourse, module, setSlide, setModule } = useAppState();
  const { changeView } = useViewport();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const { setTestMode } = useTestMode();
  const course = useContext(CourseContext);

  useEffect(() => {
    setTestMode(testMode);
    if (testMode) setTimeout(() => changeView(View.Test));
  }, [testMode, setTestMode, changeView]);

  useEffect(() => {
    if (!testMode) {
      setSlide();
      setModule();
    }
    setCourse(testMode ? 1000 : Number(courseId));
  }, [courseId]);

  const CourseContent = (
    <StyledPaperCourse data-testid="course_content">
      {!isTablet && <Progress />}
      <CourseWrapper>
        <Course />
      </CourseWrapper>
      {isTablet && (
        <Box p={2}>
          <HelpAndSupport />
        </Box>
      )}
    </StyledPaperCourse>
  );

  const CourseContentSkeleton = (
    <ContentSkeleton data-testid="content_skeleton">
      <MuiSkeleton animation="wave" variant="rectangular" />
      <MuiSkeleton animation="wave" variant="rectangular" />
    </ContentSkeleton>
  );

  const ContentWrapper = isTablet ? React.Fragment : ResizeWrapper;

  const getContentContainerClasses = () => {
    return !course || !module ? "skeleton" : "";
  };

  return (
    <NotesProvider>
      <Helmet>
        {course ? (
          <title>{course.shortName || course.name}: SANS OnDemand</title>
        ) : (
          <title>SANS OnDemand</title>
        )}
      </Helmet>
      <Header />
      <ContentContainer
        className={getContentContainerClasses()}
        container
        spacing={{ xs: 0 }}
        id="content_container"
        flexDirection={isTablet ? "column" : "row"}
      >
        <ContentWrapper>{course ? CourseContent : CourseContentSkeleton}</ContentWrapper>
        <ContentRight container>
          <ViewportContent />
        </ContentRight>
      </ContentContainer>
    </NotesProvider>
  );
}
