import React, { FC, useState } from "react";
import { CardContent, Typography, Box, Paper } from "@mui/material";
import { MyCoursesQuery_myCourses_rosterUsers } from "../../pages/Library/__generated__/MyCoursesQuery";
import { useCourseProgress } from "../../hooks/useCourseProgress";
import { useErrorHandler, ErrorSeverity } from "../../hooks/useErrorHandler";
import {
  LibraryButton,
  LibraryCardElement,
  SmallFont,
  InitContentWrapper,
  CourseName,
} from "./LibraryCard.elements";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { toDateAtTimeWithTz } from "../../util/time";
import {
  SetupStep,
  SetupStepWrapper,
  SetupLink,
} from "../../pages/Library/SetupInstructions.elements";
import { LibraryCard } from "./LibraryCard";

interface LibraryCardProps {
  course: MyCoursesQuery_myCourses_rosterUsers;
}
export const LibraryCardInit: FC<LibraryCardProps> = ({ course }) => {
  const { reportError } = useErrorHandler();
  const { startCourse } = useCourseProgress(
    course.course.trainingId,
    course.course.id,
    course.trainingInstance?.id,
  );
  const [startedCourse, setStartedCourse] = useState<MyCoursesQuery_myCourses_rosterUsers>();
  const [starting, setStarting] = useState(false);
  const start = async () => {
    setStarting(true);
    const {
      errors,
      data: { startCourse: startedCourse },
    } = await startCourse({ variables: { courseId: course.course.trainingId } });
    setStarting(false);
    if (errors) {
      return reportError(ErrorSeverity.LOW, "Failed to start course", { errors: [...errors] });
    } else {
      setStartedCourse(startedCourse);
    }
  };

  return startedCourse ? (
    <LibraryCard course={startedCourse} />
  ) : (
    <LibraryCardElement>
      <CardContent sx={{ width: "100%", padding: "0" }}>
        <Box>
          <CourseName>{course.course.name}</CourseName>
          <InitContentWrapper elevation={0}>
            <LibraryButton
              variant="contained"
              disableElevation
              onClick={() => start()}
              loading={starting}
            >
              Start Course
            </LibraryButton>
            <Box>
              <SmallFont>This course will expire 120 days after you start it.</SmallFont>
              <SmallFont>
                If you haven&apos;t started by {toDateAtTimeWithTz(course.registrationDeadline)},
                the course will start automatically.
              </SmallFont>
            </Box>
            {course.shippingTracking && (
              <SetupStepWrapper>
                <SetupStep>
                  <LocalShippingIcon className="icon" />
                  <div>
                    <SetupLink
                      target="_blank"
                      href={`https://www.ups.com/tracking/tracking.cgi?tracknum=${course.shippingTracking?.trackingNumber}`}
                    >
                      Track your course materials shipment
                    </SetupLink>
                    <span>&nbsp;&nbsp;(Status: {course.shippingTracking?.deliveryStatus})</span>
                  </div>
                </SetupStep>
              </SetupStepWrapper>
            )}
          </InitContentWrapper>
        </Box>
      </CardContent>
    </LibraryCardElement>
  );
};
