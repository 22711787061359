import { styled } from "@mui/material/styles";
import { Switch } from "@mui/material";

export const AutoplaySwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase": {
    "&.Mui-checked": {
      color: "#fff",
      "+.MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.grey[100],
      },
      "& .MuiSwitch-thumb": {
        backgroundColor: theme.palette.grey[100],

        "&:before": {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="${encodeURIComponent(
            theme.palette.grey[900],
          )}"><path d="M0 0h24v24H0z" fill="none"/><path d="M8 5v14l11-7z"/></svg>')`,
        },
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.grey[200],

    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "16px",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="${encodeURIComponent(
        theme.palette.grey[700],
      )}"><path d="M0 0h24v24H0z" fill="none"/><path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.grey[100],
    border: `2px solid ${theme.palette.grey[200]}`,
    borderRadius: 20 / 2,
  },
}));
