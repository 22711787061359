import { styled } from "@mui/material/styles";
import { Slider } from "@mui/material";

export const SliderWrapper = styled("div")(({ theme }) => ({
  padding: `${theme.spacing(2)}`,
  minWidth: "150px",
}));

export const StyledSlider = styled(Slider)(({ theme }) => ({
  ["& .MuiSlider-thumb"]: {
    height: 8,
    width: 8,
    backgroundColor: theme.palette.secondary.main,
  },
  ["& .MuiSlider-rail"]: {
    backgroundColor: theme.palette.grey[200],
    opacity: 1,
    height: "2px",
  },
  ["& .MuiSlider-track"]: {
    border: "none",
    backgroundColor: theme.palette.grey[200],
    height: "2px",
  },
}));
