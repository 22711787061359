import { styled } from "@mui/material/styles";
import { DialogContent as MuiDialogContent } from "@mui/material";

export const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  padding: theme.spacing(3),
  display: "flex",
  flexDirection: "column",
  height: "45rem",

  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2, 1),
  },
}));
