import { v4 } from "uuid";
import { SlideFragment } from "../hooks/__generated__/SlideFragment";
import { CourseFragment } from "../hooks/__generated__/CourseFragment";
import { TrainingInstanceFragment } from "../hooks/__generated__/TrainingInstanceFragment";
import { ModuleFragment } from "../hooks/__generated__/ModuleFragment";
import { moduleType } from "../__generated__/globalTypes";
import { ModuleFragment_lastView } from "../hooks/__generated__/ModuleFragment";
import { InstanceProgressFragment_progress } from "../hooks/__generated__/InstanceProgressFragment";

export const testSlide: SlideFragment = {
  id: "4e97d6c9-0c9e-40a4-b75f-89858126aeec",
  moduleId: "071cac06-5091-40a2-9f90-172acaf2ed34",
  name: "Slide 1",
  captionsUrl: null,
  thumbnailPath: "",
  bookmarks: null,
  number: 1,
  fullNumber: "1.1.1",
  duration: "00:00:00.000",
  videoPath: "",
  bookPath: "",
  lastView: null,
  __typename: "Slide",
};

export function generateSlide(number: number): SlideFragment {
  return {
    ...testSlide,
    id: v4(),
    number,
    fullNumber: `1.1.${number}`,
    name: `Slide ${number}`,
  };
}

export const testModule: ModuleFragment = {
  id: "071cac06-5091-40a2-9f90-172acaf2ed34",
  name: "Module 1",
  type: moduleType.CONTENT,
  confidenceRating: null,
  quizScore: null,
  quizInProgress: false,
  sectionId: "70e9ff5a-3b5a-483b-b5db-b12a1b24aafc",
  fullNumber: "1.1",
  baseUrl: "",
  cookies: [],
  slides: [testSlide],
  lastView: {} as ModuleFragment_lastView,
  __typename: "Module",
};

export const testCourse: CourseFragment = {
  id: "daf38e3b-220a-4845-a346-b00f4940035f",
  name: "Coursebook Test",
  shortName: "TEST",
  courseType: "Production",
  trainingId: 1000,
  complete: true,
  messages: [],
  availableResolutions: [],
  fullVersion: "TEST_1000",
  version: "T01",
  sections: [
    {
      modules: [testModule],
      __typename: "Section",
    },
  ],
  contributors: [],
  resources: [],
  __typename: "Course",
};

export const testInstance: TrainingInstanceFragment = {
  id: "16494baa-36a9-4786-9a55-72002398e3bf",
  expirationDateTime: "2022-02-22T18:27:20.000Z",
  __typename: "TrainingInstance",
  lastView: null,
  userId: 100001,
} as TrainingInstanceFragment;

export const emptyProgress: InstanceProgressFragment_progress = {
  viewedSlides: 0,
  totalSlides: 0,
  totalQuizzes: 0,
  passedQuizzes: 0,
  percentQuizzes: 0,
  percentSlides: 0,
  moduleProgress: [],
  hoursRemaining: 0,
  __typename: "CourseProgress",
};
