import React, { useState, useEffect, useCallback } from "react";

interface Props {
  ref: React.RefObject<HTMLElement>;
  size: number;
}

export const useFocus = ({
  ref,
  size,
}: Props): [number, React.Dispatch<React.SetStateAction<number>>] => {
  const [currentFocus, setCurrentFocus] = useState(0);

  const decrementIndex = useCallback((prevState: number) => (prevState + 1) % size, [size]);
  const incrementIndex = useCallback((prevState: number) => (prevState - 1 + size) % size, [size]);

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === "ArrowDown") {
        e.preventDefault();
        setCurrentFocus(decrementIndex);
      }

      if (e.key === "ArrowUp") {
        e.preventDefault();
        setCurrentFocus(incrementIndex);
      }
    },
    [decrementIndex, incrementIndex],
  );

  useEffect(() => {
    setCurrentFocus(0);
  }, [size]);

  useEffect(() => {
    const currentRef = ref.current;
    currentRef?.addEventListener("keydown", handleKeyDown, false);

    return () => {
      currentRef?.removeEventListener("keydown", handleKeyDown, false);
    };
  }, [handleKeyDown, ref]);

  return [currentFocus, setCurrentFocus];
};
