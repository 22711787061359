import React, { useRef, useEffect, useCallback, useMemo } from "react";
import { CheckCircle, RemoveCircle, Cancel, Quiz, QuestionMark } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { getReadableFromSeconds } from "../../util/time";
import { useAppState } from "../../hooks/useAppState";
import { Module, ConfidenceRating, Duration } from "./Outline.elements";
import { Rating } from "../../__generated__/globalTypes";
import { useViewport, View } from "../Viewport/useViewport";
import classNames from "classnames";
import { useProgress } from "../../hooks/useProgress";
import { FullCourseQuery_course_sections_modules } from "../../pages/Player/__generated__/FullCourseQuery";
import { CourseQuery_course_sections_modules } from "../../pages/Player/__generated__/CourseQuery";

type IconType =
  | typeof CheckCircle
  | typeof RemoveCircle
  | typeof Cancel
  | typeof Quiz
  | typeof QuestionMark;

interface OutlineModuleProps {
  focus: boolean;
  module: FullCourseQuery_course_sections_modules & CourseQuery_course_sections_modules;
  tabIndex: number;
  moduleIdx: number;
  sectionIdx: number;
  setFocus: React.Dispatch<React.SetStateAction<number>>;
}

interface ConfidenceRating {
  label: string;
  icon: IconType;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const OutlineModule = ({
  focus,
  module,
  tabIndex,
  setFocus,
  moduleIdx,
  sectionIdx,
}: OutlineModuleProps) => {
  const {
    appState: { moduleId },
    setModule,
    loading,
  } = useAppState();
  const { progressForModule } = useProgress();

  const moduleProgress = useMemo(
    () => progressForModule(module.id),
    [progressForModule, module.id],
  );

  const ref = useRef<HTMLButtonElement>(null);
  const { changeView } = useViewport();

  const typeNames: { [key: string]: string } = {
    QUIZ: "Quiz",
    SURVEY: "Evaluation",
  };

  const confidenceRatings: Record<string, ConfidenceRating> = {
    [Rating.CONFIDENT]: { label: "Yes", icon: CheckCircle },
    [Rating.SOMEWHAT_CONFIDENT]: { label: "Somewhat", icon: RemoveCircle },
    [Rating.NOT_CONFIDENT]: { label: "No", icon: Cancel },
    [Rating.UNANSWERED]: { label: "Skipped", icon: QuestionMark },
  };

  const moduleSelected = module.id === moduleId;

  const handleClick = useCallback(
    (moduleId: string) => () => {
      setModule(moduleId);
      setFocus(moduleIdx);
    },
    [moduleIdx, setModule, setFocus],
  );

  const handleConfidenceClick = () => {
    changeView(View.Confidence);
  };

  useEffect(() => {
    if (focus) {
      ref?.current?.focus();
    }
  }, [focus]);

  useEffect(() => {
    if (moduleSelected) {
      ref?.current?.scrollIntoView?.({ block: "center" });
    }
  }, [moduleSelected]);

  const duration = module.duration;

  const classnames = classNames({
    viewed: module.type === "CONTENT" && moduleProgress?.viewedSlides,
    selected: loading.module === module.id || (!loading.module && moduleSelected),
    moduleName: true,
  });

  const status = () => {
    if (module.type === "QUIZ") {
      let message = "";
      let icon;

      if (module?.quizScore && module.quizScore >= 80) {
        message = "Passed";
        icon = <CheckCircle aria-label="Quiz passed" className="passed" />;
      } else if (module?.quizScore && module.quizScore < 80) {
        message = "Did not pass";
        icon = <Cancel aria-label="Did not pass quiz" className="failed" />;
      } else {
        icon = <Quiz aria-label="Quiz" />;
      }

      return (
        <Duration>
          <Typography sx={{ fontSize: "0.625rem" }}>{message}</Typography>
          {icon}
        </Duration>
      );
    }
    if (module.type === "SURVEY") {
      return <Typography sx={{ fontSize: "0.625rem" }}>{typeNames[module.type]}</Typography>;
    }

    const viewed = moduleProgress && moduleProgress.viewedSlides === moduleProgress.totalSlides;

    return (
      <Duration>
        <Typography
          id="outline_progress"
          variant="body2"
          sx={{ fontSize: "0.625rem" }}
          aria-label={` ,${getReadableFromSeconds(duration, false)}`}
        >
          {getReadableFromSeconds(duration)}
        </Typography>
        <CheckCircle aria-label="Viewed" className={viewed ? "viewed" : ""} />
      </Duration>
    );
  };

  const CurrentRating = () => {
    const currentRating = module?.confidenceRating?.rating;
    if (!currentRating || module.id !== moduleId) return null;
    const { label, icon: Icon } = confidenceRatings[currentRating];
    return (
      <div style={{ marginLeft: "2em", fontSize: "0.8em" }}>
        ↪{" "}
        <ConfidenceRating
          className={label.toLowerCase()}
          tabIndex={2}
          onClick={handleConfidenceClick}
        >
          Confidence Rating: <Icon /> {label}
        </ConfidenceRating>
      </div>
    );
  };

  return (
    <>
      <Module
        ref={ref}
        tabIndex={tabIndex}
        role="link"
        className={classnames}
        onClick={handleClick(module.id)}
      >
        <div className="title">
          {module.type === "CONTENT" && `${sectionIdx + 1}.${moduleIdx + 1} `}
          {module.name}
        </div>
        {status()}
      </Module>
      <CurrentRating />
    </>
  );
};

export default OutlineModule;
