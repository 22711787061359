import React, { useEffect, useRef, useContext, useState } from "react";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
import Edit from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";
import classnames from "classnames";

import {
  NoteWrapper,
  NoteContainer,
  NoteTitle,
  NoteIconButton,
  NoteBody,
  NoteIconContainer,
} from "./Notes.elements";
import { useNotes, Note } from "./Notes.queries";
import { useAppState } from "../../hooks/useAppState";
import { NotesContext } from "../../context/notes-context";
import { screenReaderSlideTitle } from "../../util/screenReader";
import { isJSON } from "../../util/json";

interface NotesProps {
  note: Note;
  index: number;
  slideName: string;
  setShowConfirmModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export function IndividualNote({
  note,
  index,
  slideName,
  setShowConfirmModal,
}: NotesProps): JSX.Element | null {
  const [cardHeight, setCardHeight] = useState(230);
  const { setSlide, slide } = useAppState();
  const { editNote } = useNotes();
  const ref = useRef<null | HTMLDivElement>(null);

  const { setEditingName } = useContext(NotesContext);

  const isActive = slide?.id === note.slideId;

  const converter = new QuillDeltaToHtmlConverter(
    isJSON(note.content) ? JSON.parse(note.content)?.ops : null,
  );

  const handleTitleClick = (id: string) => () => setSlide(id);

  const handleEdit = (id: string, slideName: string) => () => {
    editNote(id);
    setEditingName(slideName);
  };

  const handleKeyDown = (slideId: string) => (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      setSlide(slideId);
    }
  };

  const confirmDelete = (id: string) => () => {
    setShowConfirmModal(true);
    setSlide(id);
  };

  useEffect(() => {
    if (isActive) {
      ref.current?.scrollIntoView();
    }
  }, [isActive]);

  useEffect(() => {
    setCardHeight(ref.current?.offsetHeight as number);
  }, []);

  const tabIndex = 2;
  const screenReaderTitle = screenReaderSlideTitle(slideName);

  const classes = classnames({
    overflow: !isActive && cardHeight > 230,
    active: note.slideId === slide?.id,
  });

  return (
    <NoteWrapper ref={ref} id={"note_container_" + index} className={classes}>
      <NoteContainer direction="row" justifyContent="space-between" alignItems="center">
        <NoteTitle
          tabIndex={tabIndex}
          aria-label={`Go to note: ${screenReaderTitle}`}
          onClick={handleTitleClick(note.slideId)}
          onKeyDown={handleKeyDown(note.slideId)}
          className={note.slideId === slide?.id ? "active" : ""}
        >
          {note.slideName}
        </NoteTitle>
        <NoteIconContainer>
          <NoteIconButton
            aria-label={`Edit note: ${screenReaderTitle}`}
            size="small"
            onClick={handleEdit(note.slideId, slideName)}
            tabIndex={tabIndex}
            id={`note_edit_button_` + index}
            className={note.slideId === slide?.id ? "active" : ""}
          >
            <Edit fontSize="small" />
          </NoteIconButton>
          <NoteIconButton
            aria-label={`Delete note: ${screenReaderTitle}`}
            size="small"
            onClick={confirmDelete(note.slideId)}
            tabIndex={tabIndex}
            id={`note_delete_button_` + index}
            className={note.slideId === slide?.id ? "active" : ""}
          >
            <Delete fontSize="small" />
          </NoteIconButton>
        </NoteIconContainer>
      </NoteContainer>
      <NoteBody
        className={note.slideId === slide?.id ? "active" : ""}
        dangerouslySetInnerHTML={{
          __html: converter.convert() || note.content,
        }}
      />
    </NoteWrapper>
  );
}
