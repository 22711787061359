import { ApolloClient, makeVar, useApolloClient, useReactiveVar } from "@apollo/client";
import gql from "graphql-tag";
import { useCallback } from "react";
import { useAppState } from "../../hooks/useAppState";
import { SaveNote } from "./__generated__/SaveNote";
import { ExportNotes } from "./__generated__/ExportNotes";
import { Notes } from "./__generated__/Notes";
import { NotesPdf } from "./__generated__/NotesPdf";
import { DeleteNote } from "./__generated__/DeleteNote";
import { useAnalyticsEvents } from "@atlaskit/analytics-next";
import stringify from "csv-stringify/lib/browser/sync";
import download from "downloadjs";
import { useErrorHandler } from "../../hooks/useErrorHandler";

export const EXPORT_NOTES_MUTATION = gql`
  mutation ExportNotes($courseId: Int!) {
    export(courseId: $courseId)
  }
`;

export const NOTES_PDF_QUERY = gql`
  query NotesPdf($pdfId: String!) {
    getNotesPDF(pdfId: $pdfId)
  }
`;

export const SAVE_NOTE_MUTATION = gql`
  mutation SaveNote(
    $instanceId: String!
    $courseId: Int!
    $slideId: String!
    $contentBase64: String!
  ) {
    saveNote(
      instanceId: $instanceId
      courseId: $courseId
      slideId: $slideId
      contentBase64: $contentBase64
    ) {
      slideId
      content
    }
  }
`;

export const DELETE_NOTE_MUTATION = gql`
  mutation DeleteNote($slideId: String!) {
    deleteNote(slideId: $slideId)
  }
`;

export const NOTES_QUERY = gql`
  query Notes($courseId: Int!) {
    course(courseId: $courseId) {
      trainingId
      sections {
        modules {
          slides {
            id
            fullNumber
            name
            notes {
              slideId
              content
            }
          }
        }
      }
    }
  }
`;

export interface Note {
  slideId: string;
  slideName: string;
  content: string;
}

const notesVar = makeVar<Note[]>([]);
const editingVar = makeVar<string | undefined>(undefined);

const stringContent = (content: string) => {
  try {
    const jsonContent = JSON.parse(content) as { ops: { insert: string }[] };
    if (jsonContent.ops)
      return jsonContent.ops
        .map((op) => op.insert)
        .join("")
        .trim();
    else throw new Error("Not quill delta");
  } catch (_) {
    return content;
  }
};

const notesAsRows = (
  client: ApolloClient<Object>,
  courseId: number | null | undefined,
  toPlainText = false,
) => {
  const queryResponse = client.readQuery<Notes>({
    query: NOTES_QUERY,
    variables: { courseId },
  });

  if (!queryResponse) return [];

  const courseNotes: Note[] = [];

  for (const section of queryResponse.course.sections) {
    for (const module of section.modules) {
      for (const slide of module.slides) {
        if (!slide.notes) continue;
        courseNotes.push({
          slideId: slide.id,
          slideName: `${slide.fullNumber}: ${slide.name}`,
          content: toPlainText ? stringContent(slide.notes.content) : slide.notes.content,
        });
      }
    }
  }
  return courseNotes;
};

export function useNotes() {
  const {
    appState: { courseId, instanceId },
  } = useAppState();
  const { createAnalyticsEvent } = useAnalyticsEvents();
  const { throwError } = useErrorHandler();

  const client = useApolloClient();

  const notes = useReactiveVar(notesVar);
  const editing = useReactiveVar(editingVar);

  const getNotes = useCallback(() => {
    notesVar(notesAsRows(client, courseId));
  }, [courseId, client]);

  const getNotesPdf = useCallback(
    async (pdfId: string) => {
      let notesPdf: string | null = null;

      while (!notesPdf) {
        await new Promise((resolve) => setTimeout(resolve, 500));
        const { data } = await client.query<NotesPdf>({
          query: NOTES_PDF_QUERY,
          variables: { pdfId },
          fetchPolicy: "network-only",
        });
        notesPdf = data.getNotesPDF;
      }

      return notesPdf;
    },
    [client],
  );

  const exportNotes = useCallback(
    async (downloadAsCSV: boolean): Promise<void> => {
      if (downloadAsCSV) {
        createAnalyticsEvent({
          feature: "notes",
          action: "export",
        }).fire("telemetry");

        const rows = notesAsRows(client, courseId, true) ?? [];

        download(
          stringify(rows.filter((row) => row.content).map((row) => [row.slideName, row.content])),
          "notes.csv",
          "text/csv",
        );
        return;
      }
      const { data } = await client.mutate<ExportNotes>({
        mutation: EXPORT_NOTES_MUTATION,
        variables: {
          courseId,
        },
      });

      if (data?.export) {
        window.open(await getNotesPdf(data.export), "_self");
      } else throwError("Error exporting notes");
    },
    [courseId, client, getNotesPdf, createAnalyticsEvent, throwError],
  );

  const saveNote = useCallback(
    async (slideId: string, content: string): Promise<void> => {
      createAnalyticsEvent({
        feature: "notes",
        action: "save",
      }).fire("telemetry");
      await client.mutate<SaveNote>({
        mutation: SAVE_NOTE_MUTATION,
        variables: {
          instanceId,
          courseId,
          slideId,
          contentBase64: Buffer.from(content).toString("base64"),
        },
        update: (cache, { data }) => {
          if (data?.saveNote) {
            const slideCacheId = cache.identify({ __typename: "Slide", id: slideId });

            if (slideCacheId) {
              cache.modify({
                id: slideCacheId,
                fields: {
                  notes(cachedNotes) {
                    if (cachedNotes) {
                      return (cachedNotes = { ...cachedNotes, ...data.saveNote });
                    }
                    return (cachedNotes = { ...data.saveNote, updated: "" });
                  },
                },
              });
              const slideIds = notes.map((note) => note.slideId);
              if (!slideIds.includes(slideId)) {
                createAnalyticsEvent({
                  feature: "notes",
                  action: "add",
                }).fire("telemetry");
              }
            }
          }
        },
      });
      getNotes();
    },
    [instanceId, courseId, client, getNotes, notes, createAnalyticsEvent],
  );

  const deleteNote = useCallback(
    async (slideId: string): Promise<void> => {
      createAnalyticsEvent({
        feature: "notes",
        action: "delete",
      }).fire("telemetry");
      await client.mutate<DeleteNote>({
        mutation: DELETE_NOTE_MUTATION,
        variables: { slideId },
        update: (cache, { data }) => {
          if (data?.deleteNote) {
            const slideCacheId = cache.identify({ __typename: "Slide", id: slideId });
            if (slideCacheId) {
              cache.modify({
                id: slideCacheId,
                fields: {
                  notes: () => null,
                },
              });
            }
            const cacheId = cache.identify({ __typename: "Notes", slideId });
            if (cacheId) {
              cache.evict({ id: cacheId });
              cache.gc();
            }
          }
        },
      });
      getNotes();
    },
    [client, getNotes, createAnalyticsEvent],
  );

  const editNote = useCallback(
    (slideId?: string): void => {
      editingVar(slideId);
      createAnalyticsEvent({
        feature: "notes",
        action: "edit",
      }).fire("telemetry");
    },
    [createAnalyticsEvent],
  );

  return { exportNotes, saveNote, deleteNote, notes, editNote, editing, getNotes };
}
