import { styled } from "@mui/material/styles";

export const SearchResults = styled("div")({ marginTop: "1rem", textAlign: "left" });
export const SearchField = styled("div")({
  margin: "1rem 0",
  fontWeight: 600,
  fontSize: "1rem",
  lineHeight: "22px",
});
export const SlideTitle = styled("div")({
  fontWeight: 600,
  fontSize: "1rem",
  lineHeight: "22px",
  margin: "0.5rem 0",
  cursor: "pointer",
});
export const Highlight = styled("div")({
  fontWeight: 400,
  fontSize: "0.875rem",
  lineHeight: "150%",
  marginBottom: "0.2rem",
});

export const SearchSectionContainer = styled("div")({
  textAlign: "left",
  padding: "8px 24px",
});

export const SlideField = styled("div")({
  marginBottom: "1rem",
});
