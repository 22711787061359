import React from "react";
import { DetailedProgressBarContainer } from "./ProgressModal.elements";
import { ProgressBar } from "../LibraryCard/LibraryCard.elements";

type DetailedProgressBarProps = {
  complete: number;
  total: number;
  compact: boolean;
  isExpired: boolean;
  isQuizProgress: boolean;
};

const DetailedProgressBar = ({
  complete,
  total,
  compact,
  isExpired,
  isQuizProgress,
}: DetailedProgressBarProps): JSX.Element => {
  const percent = total ? (complete / total) * 100 : 0;
  const ariaLabel = isQuizProgress
    ? `${Math.ceil(
        percent,
      )}% of quizzes passed, you have passed ${complete} out of ${total} quizzes`
    : `${Math.ceil(
        percent,
      )}% of module completed, you have completed ${complete} out of ${total} slides`;

  return (
    <DetailedProgressBarContainer compact={compact}>
      {!compact && (
        <p className="text" aria-label={ariaLabel}>
          {Math.ceil(percent)}% ({complete}/{total})
        </p>
      )}
      <ProgressBar variant="determinate" value={percent} isExpired={isExpired} color="primary" />
    </DetailedProgressBarContainer>
  );
};

export default DetailedProgressBar;
