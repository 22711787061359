import { Alert, Card, Typography, Link, Box, styled } from "@mui/material";
import { linearProgressClasses, Paper } from "@mui/material";
import { ProgressBar as OriginalProgressBar } from "../Progress/ProgressModal.elements";
import { LoadingButton } from "@mui/lab";

export const ProgressBar = styled(OriginalProgressBar, {
  shouldForwardProp: (prop) => prop !== "isexpired",
})<{ isExpired: boolean }>(({ isExpired, theme }) => ({
  height: "0.7em",
  width: "100%",
  marginTop: "0.5em",
  marginRight: "1em",
  [`&.${linearProgressClasses.root}`]: {
    backgroundColor: theme.custom.palette.semantic.linearProgressBg,
  },

  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: isExpired
      ? theme.custom.palette.semantic.greyExpired
      : theme.custom.palette.semantic.blue,
  },
}));

export const HeaderContentWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
  },
}));

export const LibraryButton = styled(LoadingButton)(({ theme }) => ({
  margin: "0",
  marginBottom: theme.spacing(2),
  alignSelf: "center",
  width: "100%",
  marginTop: theme.spacing(3),
  whiteSpace: "nowrap",
  minWidth: "135px",
  backgroundColor: theme.custom.palette.semantic.buttonContainedBg,
  color: theme.custom.palette.semantic.buttonContainedColor,
  border: "1px solid " + theme.custom.palette.semantic.buttonContainedBorder,

  [`&:hover`]: {
    backgroundColor: theme.custom.palette.semantic.buttonContainedHoverBg,
    color: theme.custom.palette.semantic.buttonContainedHoverColor,
    border: "1px solid " + theme.custom.palette.semantic.buttonContainedHoverBorder,
  },

  [`&:focus`]: {
    backgroundColor: theme.custom.palette.semantic.buttonContainedFocusBg,
    color: theme.custom.palette.semantic.buttonContainedFocusColor,
    border: "1px solid " + theme.custom.palette.semantic.buttonContainedFocusBorder,
  },

  [theme.breakpoints.up("sm")]: {
    width: "auto",
    marginTop: "0",
  },
}));

export const CourseName = styled(Typography)(({ theme }) => ({
  color: theme.custom.palette.semantic.blue,
  fontWeight: "600",

  [`&.expired`]: {
    color: theme.palette.text.primary,
  },
}));

export const SmallFont = styled(Typography)(({ theme }) => ({
  fontSize: "0.9em",
  fontWeight: "600",
  color: theme.custom.palette.semantic.greyDark,
}));

export const LibraryCardElement = styled(Card)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginTop: theme.spacing(3),
  textAlign: "left",
  padding: theme.spacing(3),
  paddingBottom: "0px",
  borderRadius: "8px",
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",

  [`& .icon`]: {
    color: theme.custom.palette.semantic.blue,
  },
}));

export const ExpirationLink = styled(Link)(() => ({
  textDecoration: "none",
  fontWeight: "600",
  fontSize: "0.9em",
  marginLeft: "0.5em",
}));

export const CertAlert = styled(Alert)(() => ({
  marginTop: "1em",
  textAlign: "center",
  ["& .MuiAlert-message"]: { width: "100%" },
}));

export const CertText = styled(Typography)(() => ({
  marginLeft: "0.5em",
  alignSelf: "center",
  fontWeight: "600",
  color: "#515D6A",
}));

export const CertLink = styled(Link)(() => ({
  textDecoration: "none",
  fontWeight: "600",
  marginLeft: "1em",
  alignSelf: "center",
  fontSize: "0.9em",
}));

export const DownloadCertLink = styled(Link)(() => ({
  cursor: "pointer",
}));

export const ExpirationText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: "0.9em",

  [`&.expired`]: {
    color: theme.palette.text.error,
  },
}));

export const InitContentWrapper = styled(Paper)(({ theme }) => ({
  border: `1px solid ${theme.custom.palette.semantic.grey}`,
  marginTop: theme.spacing(2),
  textAlign: "center",
  padding: theme.spacing(3),
}));
