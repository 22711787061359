/* eslint-disable sonarjs/cognitive-complexity */
import React, { useState, useEffect, useRef, useCallback, useContext } from "react";
import type ReactPlayer from "react-player";
import { useAnalyticsEvents } from "@atlaskit/analytics-next";
import screenfull from "screenfull";
import { useProgress } from "../../hooks/useProgress";
import { useAppState } from "../../hooks/useAppState";
import { getSecondsFromTimestamp, getTimestampFromSeconds } from "../../util/time";
import { usePersistedSettings } from "../../hooks/usePersistedSettings";
import { ErrorSeverity, useErrorHandler } from "../../hooks/useErrorHandler";
import { useViewport, View } from "../Viewport/useViewport";
import Cookies from "js-cookie";
import { makeVar, useReactiveVar } from "@apollo/client";
import { useKeyPress } from "../../hooks/useKeyPress";
import { useDebounce } from "use-debounce";
import { useDevice } from "../../hooks/useDevice";
import useCheckIfLibrary from "../../hooks/useCheckIfLibrary";
import { useMessages } from "../../hooks/useMessages";
import { useQCTicketModal } from "../../hooks/useQCTicketModal";
import { saveProgressInterval, speedModifier, telemetryChannelName } from "../../util/constants";
import { NotesContext } from "../../context/notes-context";
import { HasbotContext } from "../../context/hasBot-context";
import { setVideoProgress } from "../../hooks/useVideoProgress";
import { useCaptions } from "./useCaptions";

export const setIsPlaying = makeVar(false);
export const setTapToUnmute = makeVar(false);
export const setIsFirstRender = makeVar(true);
export const featureName = "playback";
export const changeRateActionType = "changeRate";
export const videoLoadedActionType = "videoLoaded";
export const muteToggledActionType = "muteToggled";
export const playActionType = "play";
export const pauseActionType = "pause";
export const enterFullscreenActionType = "enterFullscreen";
export const exitFullscreenActionType = "exitFullscreen";

export type ProgressState = {
  played: number;
  playedSeconds: number;
  loaded: number;
  loadedSeconds: number;
};

const isOneSecondVideo = (duration: number) => Math.floor(duration) === 1;

export default function useVideo(
  videoContainer: HTMLDivElement | null,
  videoPlayerWrapper: HTMLDivElement | null,
  videoPlayer: ReactPlayer | null,
) {
  const {
    volume,
    setVolume,
    autoplay: isAutoplayEnabled,
    setAutoplay: setIsAutoplayEnabled,
    captions: isCaptions,
    setCaptions: setIsCaptions,
    playback,
    setPlayback,
    muted,
    setMuted,
  } = usePersistedSettings();
  const PLAYBACK_RATES = [0.7, 1.0, 1.2, 1.5, 2.0];
  const [videoDimensions, setVideoDimensions] = useState({ width: 0, height: 0 });
  const isPlaying = useReactiveVar(setIsPlaying);
  const tapToUnmute = useReactiveVar(setTapToUnmute);
  const isFirstRender = useReactiveVar(setIsFirstRender);

  const [isSeeking, setIsSeeking] = useState(false);
  const [isSkipping, setIsSkipping] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isPIPEnabled, setIsPIPEnabled] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(playback);
  const [duration, setDuration] = useState(0);
  const [progress, setProgress] = useState<ProgressState>({
    played: 0,
    playedSeconds: 0,
    loaded: 0,
    loadedSeconds: 0,
  });
  const progressSeconds = useRef(0);

  const [loaded, setLoaded] = useState(false);
  const [buffering, setBuffering] = useState(false);
  const [ready, setReady] = useState(false);
  const [originalDuration, setOriginalDuration] = useState(0);
  const [lastSavedProgress, setLastSavedProgress] = useState(0);
  const { createAnalyticsEvent } = useAnalyticsEvents();

  const {
    slide,
    module,
    nextSlide,
    isLastSlide,
    appState: { slideId, slideTime, videoQuality },
    setSlide,
    updateVideoQuality,
  } = useAppState();

  const { changeView } = useViewport();
  const { isEditorFocused } = useContext(NotesContext);
  const { showDialog } = useContext(HasbotContext);
  const { modalOpen } = useQCTicketModal();
  const isLibrary = useCheckIfLibrary();
  const { modalOpen: qcTicketModalOpen } = useQCTicketModal();
  const { modalOpen: messagesModalOpen } = useMessages(isLibrary);
  const [videoTitle, setVideoTitle] = useState("");
  const [videoURL, setVideoURL] = useState<string>();
  const [captionsURL, setCaptionsURL] = useState<string | null>(null);
  const [controlsVisible, setControlsVisible] = useState(false);
  const [hasNoAudio, setHasNoAudio] = useState(false);
  const [activeVideoQuality, setActiveVideoQuality] = useState(videoQuality);
  const [autoplayNext, setAutoplayNext] = useState(false);
  const [playAfterSeek, setPlayAfterSeek] = useState(false);
  const [playerHovered, setPlayerHovered] = useState(false);
  const { loading: captionsLoading, text: captionsText } = useCaptions(
    isCaptions,
    captionsURL ?? undefined,
    progress.playedSeconds,
    hasNoAudio,
  );
  const [loading] = useDebounce(buffering || !loaded || captionsLoading, 500);

  const { isTouchDevice } = useDevice();

  useEffect(() => {
    if (isFirstRender) {
      if (isAutoplayEnabled) {
        setIsPlaying(false);
      }

      setIsFirstRender(false);
    }
  }, [isFirstRender, isAutoplayEnabled, setMuted]);

  const skipTimerRef = useRef<NodeJS.Timeout>();

  const { saveProgress, syncLocalSlideProgress } = useProgress();

  const { reportError } = useErrorHandler();

  const preventStandardHotKeyActions = (event: React.KeyboardEvent) => {
    event.stopPropagation();
    event.preventDefault();
  };

  // const playerProgress = useReactiveVar(videoProgressVar);

  type HandlerProps = {
    event: React.KeyboardEvent;
    isDocumentFocused: boolean;
    isPlayerActive: boolean;
  };

  const shortcutHandlers = [
    {
      key: " ",
      handler: ({ event, isDocumentFocused }: HandlerProps) => {
        const isVideoContainerFocused =
          document.activeElement === document.getElementById("video-control-container");

        preventStandardHotKeyActions(event);
        handleClickPlayPause();

        if (isDocumentFocused || isVideoContainerFocused) {
          showControls();
        }
      },
    },
    {
      key: "p",
      handler: ({ event }: HandlerProps) => {
        preventStandardHotKeyActions(event);
        handleClickPreviousVideo();
      },
    },
    {
      key: "n",
      handler: ({ event }: HandlerProps) => {
        preventStandardHotKeyActions(event);
        handleClickNextVideo();
      },
    },
    {
      key: "j",
      handler: ({ event }: HandlerProps) => {
        preventStandardHotKeyActions(event);
        handleClickReplay();
      },
    },
    {
      key: "<",
      handler: ({ event }: HandlerProps) => {
        preventStandardHotKeyActions(event);

        const playbackRateIndex = PLAYBACK_RATES.findIndex((val) => val === playback) - 1;
        const newPlaybackRate = PLAYBACK_RATES[playbackRateIndex < 0 ? 0 : playbackRateIndex];

        setPlaybackRate(
          isOneSecondVideo(originalDuration) ? newPlaybackRate * speedModifier : newPlaybackRate,
        );
        setPlayback(newPlaybackRate);
      },
    },
    {
      key: ">",
      handler: ({ event }: HandlerProps) => {
        preventStandardHotKeyActions(event);

        const playbackRateIndex = PLAYBACK_RATES.findIndex((val) => val === playback) + 1;
        const newPlaybackRate =
          PLAYBACK_RATES[
            playbackRateIndex > PLAYBACK_RATES.length - 1
              ? PLAYBACK_RATES.length - 1
              : playbackRateIndex
          ];

        setPlaybackRate(
          isOneSecondVideo(originalDuration) ? newPlaybackRate * speedModifier : newPlaybackRate,
        );
        setPlayback(newPlaybackRate);
      },
    },
    {
      key: "ArrowUp",
      handler: ({ event, isPlayerActive }: HandlerProps) => {
        if (isPlayerActive) {
          preventStandardHotKeyActions(event);

          const newVolume = volume + 0.1;
          setVolume(newVolume > 1 ? 1 : newVolume);
        }
      },
    },
    {
      key: "ArrowRight",
      handler: () => skip(10),
    },
    {
      key: "ArrowLeft",
      handler: () => skip(-10),
    },
    {
      key: "ArrowDown",
      handler: ({ event, isPlayerActive }: HandlerProps) => {
        if (isPlayerActive) {
          preventStandardHotKeyActions(event);

          const newVolume = volume - 0.1;
          setVolume(newVolume < 0 ? 0 : newVolume);
        }
      },
    },
    {
      key: "m",
      handler: ({ event }: HandlerProps) => {
        preventStandardHotKeyActions(event);

        handleClickMuteVolume();
        showControls();
      },
    },
    {
      key: "f",
      handler: ({ event }: HandlerProps) => {
        preventStandardHotKeyActions(event);

        handleClickFullscreen();
      },
    },
    {
      key: "a",
      handler: ({ event }: HandlerProps) => {
        preventStandardHotKeyActions(event);

        handleClickAutoplay(!isAutoplayEnabled);
        showControls();
      },
    },
    {
      key: "c",
      handler: ({ event }: HandlerProps) => {
        preventStandardHotKeyActions(event);

        handleClickCaptions();
        showControls();
      },
    },
  ];

  const onKeyPress = (event: React.KeyboardEvent, playerActive?: boolean) => {
    const { key } = event;
    const isModeDepressed = event.metaKey || event.ctrlKey || event.shiftKey || event.altKey;
    const isButtonFocused = document.activeElement?.tagName === "BUTTON";
    const found = shortcutHandlers.find((handler) => handler.key === key);
    const isPlayerActive =
      playerActive || videoContainer?.contains(document.activeElement as Node) || false;
    const isDocumentFocused = document.activeElement === document.body;
    const isInputFocused = document.activeElement?.tagName === "INPUT";

    if (
      found &&
      !isInputFocused &&
      !isButtonFocused &&
      !isEditorFocused &&
      !isModeDepressed &&
      !showDialog &&
      !qcTicketModalOpen &&
      !messagesModalOpen &&
      !modalOpen
    ) {
      found.handler({ event, isDocumentFocused, isPlayerActive });
    }
  };

  useKeyPress(
    shortcutHandlers.map(({ key }) => key),
    onKeyPress,
  );

  const save = useCallback(
    async (moduleRefresh: boolean, seconds = progress.playedSeconds) => {
      setLastSavedProgress(seconds);
      await saveProgress(moduleRefresh, seconds);
    },
    [progress.playedSeconds, saveProgress],
  );

  useEffect(() => {
    progressSeconds.current = progress.playedSeconds;
    if (progress.playedSeconds - lastSavedProgress > saveProgressInterval) save(true);
  }, [progress.playedSeconds, lastSavedProgress, save]);

  const setTime = useCallback(
    (seconds: number) => {
      if (!videoPlayer) return;

      setProgress((prevState) => ({
        ...prevState,
        played: seconds / duration,
        playedSeconds: seconds,
      }));

      setVideoProgress(progress);
      videoPlayer.seekTo(seconds);
    },
    [videoPlayer, duration],
  );

  useEffect(() => {
    setReady(false);
  }, [slideId]);

  useEffect(() => {
    if (!ready) {
      if (autoplayNext || isAutoplayEnabled) {
        setIsPlaying(!isFirstRender);
        setAutoplayNext(false);
      } else {
        setIsPlaying(false);
      }

      setReady(true);
    }
  }, [ready, autoplayNext, isAutoplayEnabled]);

  const updateProgress = useCallback(() => {
    setTime(slideTime ? getSecondsFromTimestamp(slideTime) : 0);
  }, [slideTime, setTime]);

  useEffect(() => {
    setLoaded(false);
  }, [slideId]);

  useEffect(() => {
    if (module?.baseUrl && slide?.videoPath) {
      setVideoTitle(`${slide.fullNumber}: ${slide.name}`);
      setVideoURL(`${module.baseUrl}${slide.videoPath}`);
      setCaptionsURL(slide.captionsUrl);
      updateProgress();
    }
  }, [
    module?.baseUrl,
    slide?.videoPath,
    slide?.fullNumber,
    slide?.name,
    slide?.captionsUrl,
    updateProgress,
  ]);

  // Save progress and change video quality
  useEffect(() => {
    if (videoQuality !== activeVideoQuality && slideId && setSlide) {
      (async () => {
        const seconds = progress?.playedSeconds ?? 0;
        setIsPlaying(false);
        setActiveVideoQuality(videoQuality);
        await updateVideoQuality();
        await setSlide(slideId, getTimestampFromSeconds(seconds));
        setTime(seconds);
        setIsPlaying(isPlaying);
      })();
    }
  }, [
    activeVideoQuality,
    videoQuality,
    slideId,
    save,
    isPlaying,
    setSlide,
    progress?.playedSeconds,
    updateVideoQuality,
    setTime,
  ]);

  useEffect(() => {
    if (!module?.cookies) return;
    for (const cookie of module.cookies) {
      const { key, value, path, domain, expires, secure } = cookie;
      Cookies.set(key, value, { path, domain, expires: new Date(expires), secure });
    }
  }, [module?.cookies]);

  useEffect(() => {
    if (!loaded || !duration || !slideId || slideId === slide?.id) return;
    setSlide(slideId);

    setTimeout(() => updateProgress());
  }, [loaded, duration, slideId, slide?.id, setSlide, updateProgress]);

  useEffect(() => {
    setVideoDimensions({
      width: videoPlayerWrapper?.clientWidth || 0,
      height: videoPlayerWrapper?.clientHeight || 0,
    });
  }, [videoPlayerWrapper]);

  const handleVideoProgress = (state: ProgressState) => {
    if (!isSeeking && slideId) {
      setProgress(state);
      setVideoProgress(state);

      if (isPlaying) {
        syncLocalSlideProgress(slideId, state.playedSeconds);
      }
    }
  };

  const handleVideoDuration = (duration: number) => {
    setOriginalDuration(duration);

    let videoDuration = duration;
    const customPlayback = Number((playback * speedModifier).toFixed(2));

    if (isOneSecondVideo(duration)) {
      videoDuration = 10;
      setPlaybackRate(customPlayback);
      setPlayback(playback);
      if (hasNoAudio) {
        setHasNoAudio(false);
      }
      setHasNoAudio(true);
    } else {
      setPlayback(playback);
      setPlaybackRate(playback);
      setHasNoAudio(false);
    }

    setDuration(videoDuration);
  };

  const handleVideoReady = () => {
    if (videoPlayer && !isSkipping && Math.trunc(progress.playedSeconds) >= Math.trunc(duration)) {
      setTime(0);
    }

    setLoaded(true);
    if (!loaded) save(true);

    createAnalyticsEvent({
      feature: featureName,
      action: videoLoadedActionType,
    }).fire(telemetryChannelName);
  };

  const handleVideoEnded = async () => {
    await save(true);

    if (isLastSlide) {
      changeView(View.Confidence);
    }

    if (!isAutoplayEnabled) {
      setAutoplayNext(true);
      setIsPlaying(false);
      return;
    }

    nextSlide();
  };

  const handleClickPlayPause = async () => {
    setIsPlaying(!isPlaying);
    // save, but don't refresh module; avoids playhead jumping forwards slightly
    if (isPlaying) await save(false);
    setControlsVisible(true);

    const action = !isPlaying ? playActionType : pauseActionType;
    createAnalyticsEvent({ feature: featureName, action }).fire(telemetryChannelName);

    if (progress.playedSeconds >= duration) {
      return nextSlide();
    }
    if (!isPlaying) videoPlayer?.getInternalPlayer()?.play?.();
  };

  const handleClickPreviousVideo = () => {
    save(true);
    nextSlide(true);
  };

  const handleClickNextVideo = () => {
    save(true);

    if (isLastSlide) {
      changeView(View.Confidence);
    } else {
      nextSlide();
    }
  };

  const handleChangeView = () => {
    save(true);
    changeView(View.Slides);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeProgressBar = (_: any, value: number | number[]) => {
    setProgress((prevState) => ({ ...prevState, played: value as number }));
    setVideoProgress(progress);
  };

  const handleMouseOutProgressBar = () => {
    if (isSeeking) {
      videoPlayer?.seekTo(progress.played);
      const initialIsPlaying = isPlaying;
      setIsPlaying(progress.played > 0 ? initialIsPlaying : false);

      document.addEventListener(
        "mouseup",
        () => {
          setIsPlaying(initialIsPlaying);
          setIsSeeking(false);
        },
        { once: true },
      );
    }
  };

  const handleMouseUpProgressBar = () => {
    setIsSeeking(false);
    videoPlayer?.seekTo(progress.played);
  };

  const handleMouseDownProgressBar = () => {
    setIsSeeking(true);
  };

  const handleChangeVolumeSlider = (event: Event, value: number | number[]) => {
    setVolume(value as number);
  };

  const handleClickAutoplay = (value: boolean) => {
    setIsAutoplayEnabled(value);
  };

  const handleClickReplay = () => {
    const currentTime = Math.floor(videoPlayer?.getCurrentTime() || 0);
    const newTime = currentTime > 30 ? currentTime - 30 : 0;
    setTime(newTime);
    save(true, newTime);
  };

  const handleClickPIP = () => {
    setIsPIPEnabled(!isPIPEnabled);
  };

  const handleClickPlaybackRate = (value: number) => {
    const playback = value;
    const customPlayback = isOneSecondVideo(originalDuration) ? playback * speedModifier : playback;

    setPlaybackRate(customPlayback);
    setPlayback(playback);

    createAnalyticsEvent({
      feature: featureName,
      action: changeRateActionType,
    }).fire(telemetryChannelName);
  };

  const handleClickMuteVolume = () => {
    const isMuted = volume === 0;

    setMuted(isMuted ? true : !muted);
    setTapToUnmute(false);

    if (muted && volume === 0) {
      setVolume(0.3);
    }
  };

  const handleClickCaptions = () => {
    setIsCaptions(!isCaptions);
  };

  const handleClickFullscreen = () => {
    setIsFullscreen((prevState) => !prevState);

    const action = !isFullscreen ? enterFullscreenActionType : exitFullscreenActionType;
    createAnalyticsEvent({ feature: featureName, action }).fire(telemetryChannelName);
  };

  useEffect(() => {
    if (videoContainer && screenfull.isEnabled) {
      screenfull.toggle(videoContainer);
    }
  }, [isFullscreen]);

  const handleError = (
    error: unknown,
    data?: unknown,
    hlsInstance?: unknown,
    hlsGlobal?: unknown,
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { code, message } = (error as any)?.target?.error ?? {};

    reportError(ErrorSeverity.LOW, "Error loading video", {
      error,
      data,
      hlsInstance,
      hlsGlobal,
      mediaError: code ? { code, message } : undefined,
    });
  };

  const timeoutRef: { current: NodeJS.Timeout | null } = useRef(null);
  const doubleClickTimeoutRef: { current: NodeJS.Timeout | null } = useRef(null);

  const playerHoveredRef = useRef(playerHovered);
  playerHoveredRef.current = playerHovered;

  const isPlayingRef = useRef(isPlaying);
  isPlayingRef.current = isPlaying;

  const loadingRef = useRef(loading);
  loadingRef.current = loading;

  const clearTimeoutRef = () => {
    clearTimeout(timeoutRef.current as NodeJS.Timeout);
  };
  const clearDoubleClickTimeoutRef = () => {
    clearTimeout(doubleClickTimeoutRef.current as NodeJS.Timeout);
  };

  const showControls = useCallback(() => {
    clearTimeoutRef();

    setControlsVisible(true);

    if (playAfterSeek) {
      setIsPlaying(false);
    }

    timeoutRef.current = setTimeout(() => {
      if (isPlayingRef.current && !loadingRef.current) {
        setControlsVisible(false);
      }

      if (playAfterSeek) {
        setIsPlaying(true);
        setPlayAfterSeek(false);
      }
    }, 3000);
  }, [setControlsVisible, playAfterSeek]);

  useEffect(() => {
    createAnalyticsEvent({
      feature: featureName,
      action: muteToggledActionType,
    }).fire(telemetryChannelName);
  }, [muted, createAnalyticsEvent]);

  const handleClickVideoContainer = (event: React.MouseEvent) => {
    const target = event.target as HTMLElement;
    const containerClick = target.id === "controls-center";
    if (isTouchDevice || !containerClick) return;

    if (doubleClickTimeoutRef.current) {
      clearDoubleClickTimeoutRef();
      doubleClickTimeoutRef.current = null;
      handleClickFullscreen();
    } else {
      doubleClickTimeoutRef.current = setTimeout(() => {
        doubleClickTimeoutRef.current = null;
        handleClickPlayPause();
      }, 250);
    }
  };

  const skip = (amount: number) => {
    clearTimeout(skipTimerRef.current);

    showControls();
    setIsSkipping(true);

    videoPlayer?.seekTo(progress.playedSeconds + amount);
    skipTimerRef.current = setTimeout(() => setIsSkipping(false), 1000);
  };

  if (screenfull.isEnabled) {
    screenfull.on("change", () => {
      if (!screenfull.isFullscreen && isFullscreen) {
        setIsFullscreen(false);
      }
    });
  }

  return {
    videoDimensions,
    videoTitle,
    videoURL,
    handleVideoProgress,
    handleVideoDuration,
    handleVideoEnded,
    handleClickPreviousVideo,
    handleClickNextVideo,
    isAutoplayEnabled,
    handleClickAutoplay,
    isPlaying,
    handleClickPlayPause,
    isSeeking,
    progress,
    handleMouseUpProgressBar,
    handleMouseDownProgressBar,
    handleMouseOutProgressBar,
    handleChangeProgressBar,
    handleClickReplay,
    isFullscreen,
    handleClickFullscreen,
    isPIPEnabled,
    handleClickPIP,
    playbackRate,
    handleClickPlaybackRate,
    volume,
    handleClickMuteVolume,
    handleChangeVolumeSlider,
    duration,
    handleVideoReady,
    handleError,
    captionsURL,
    captionsEnabled: isCaptions,
    handleClickCaptions,
    handleChangeView,
    controlsVisible,
    setControlsVisible,
    showControls,
    setIsFullscreen,
    PLAYBACK_RATES,
    hasNoAudio,
    setHasNoAudio,
    muted,
    handleClickVideoContainer,
    hasCustomDuration: duration !== originalDuration,
    onKeyPress,
    setDuration,
    updateProgress,
    setTime,
    setMuted,
    playAfterSeek,
    setPlayAfterSeek,
    skip,
    isSkipping,
    tapToUnmute,
    setTapToUnmute,
    playerHovered,
    setPlayerHovered,
    loading,
    setBuffering,
    isFirstRender,
    setReady,
    captionsText,
  };
}
