import React from "react";
import { Grid, Skeleton, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useAppState } from "../../hooks/useAppState";
import { getReadableFromSeconds, getSecondsFromTimestamp } from "../../util/time";
import { Select } from "./Select";
import { ModuleWrapper, StyledTypography } from "./Module.elements";
import { ViewportActions } from "../../pages/Player/ViewportActions";
import { View, useViewport } from "../Viewport/useViewport";

export function Module(): JSX.Element | null {
  const { module } = useAppState();
  const { view } = useViewport();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { loading } = useAppState();

  if (module?.type !== "CONTENT" || view === View.Confidence) return null;

  // May need replacing in future with a state value
  const calculateTotalTime = () => {
    const totalTime: Array<number> = [];
    module.slides.forEach((slide) => totalTime.push(getSecondsFromTimestamp(slide.duration)));
    return getReadableFromSeconds(
      totalTime.length
        ? totalTime.reduce((previousValue, currentValue) => previousValue + currentValue)
        : 0,
    );
  };

  const SelectHeader = () => (
    <>
      {!module || loading.module ? (
        <Skeleton variant="text" sx={{ fontSize: "0.875rem", flex: 1 }} />
      ) : (
        <>
          <Grid item xs={12} md={10}>
            <StyledTypography component="h3" bold>
              {`${module.fullNumber} ${module.name}`}
            </StyledTypography>
          </Grid>
          <Grid item>
            <StyledTypography component="p" bold>
              {calculateTotalTime()}
            </StyledTypography>
          </Grid>
        </>
      )}
    </>
  );

  return (
    <>
      {!isMobile && (
        <Grid
          container
          id="module_title_details"
          justifyContent="space-between"
          sx={{ marginBottom: theme.spacing(2) }}
        >
          <SelectHeader />
        </Grid>
      )}
      <ModuleWrapper>
        {loading.module ? (
          <Skeleton variant="rectangular" sx={{ height: "40px", flex: 1 }} />
        ) : (
          <>
            <Select data={module.slides} />
            <ViewportActions />
          </>
        )}
      </ModuleWrapper>
    </>
  );
}
