import { styled } from "@mui/material/styles";
import { Button, FormLabel, FormControl } from "@mui/material";

export const StyledFormControl = styled(FormControl)(({ theme }) => ({
  width: "70%",
  gap: "32px",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

export const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  height: "auto",
  fontSize: "16px",
  lineHeight: "24px",
  fontWeight: 600,
  color: theme.palette.text.primary,
  alignItems: "center",
}));

export const ChoicesWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
  placeContent: "space-between",
  alignSelf: "center",
}));

export const Choice = styled(Button)(({ theme }) => ({
  padding: "8px 16px",
  minWidth: "unset",
  fontSize: "16px",
  lineHeight: "24px",
  fontWeight: 600,
  background: theme.palette.primary.main,
}));

export const SmallText = styled("p")({
  fontSize: "12px",
  fontWeight: 600,
  lineHeight: "150%",
  margin: "0px",
});
