import React, { useCallback, useState } from "react";
import { Box } from "@mui/material";
import {
  Container,
  BookmarkWrapper,
  BookmarkContainer,
  BookmarkTitle,
  BookmarkIconButton,
  BookmarkIconContainer,
  BookmarkBody,
  BookmarkButton,
  ButtonWrapper,
} from "./Bookmarks.elements";
import Delete from "@mui/icons-material/Delete";
import { useBookmarks } from "./Bookmarks.queries";
import { useAppState } from "../../hooks/useAppState";
import { useSearch } from "../Search/Search.queries";
import { screenReaderSlideTitle } from "../../util/screenReader";
import AlertModal from "../Alerts/AlertModal";
import { Bookmark } from "@mui/icons-material";
import { moduleType } from "../../__generated__/globalTypes";
import { getVideoProgress } from "../../hooks/useVideoProgress";

export function Bookmarks({ active }: { active: boolean }): JSX.Element | null {
  const {
    setSlide,
    module,
    appState: { slideId },
  } = useAppState();
  const { bookmarks, saveBookmark, deleteBookmark } = useBookmarks();
  const { setShowSearchResults } = useSearch();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [deleteBookmarkSlideId, setDeleteBookmarkSlideId] = useState<string>("");
  const [deleteBookmarkTimestamp, setDeleteBookmarkTimestamp] = useState<string>("");
  const handleTitleClick = (id: string, timestamp: string) => () => {
    setSlide(id, timestamp);
  };

  const cancelDelete = () => setShowConfirmModal(false);

  const handleAddBookmark = useCallback(() => {
    if (!slideId) {
      return;
    }

    const timestamp = getVideoProgress();
    setShowSearchResults(false);
    saveBookmark(slideId, timestamp);
  }, [slideId, saveBookmark, setShowSearchResults]);

  const handleDeleteBookmark = useCallback(() => {
    deleteBookmark(deleteBookmarkSlideId, deleteBookmarkTimestamp);
    setShowConfirmModal(false);
  }, [deleteBookmark, deleteBookmarkSlideId, deleteBookmarkTimestamp]);

  const confirmDelete = (id: string, timestamp: string) => () => {
    if (!timestamp) {
      timestamp = "00:00:00";
    }
    setShowConfirmModal(true);
    setDeleteBookmarkSlideId(id);
    setDeleteBookmarkTimestamp(timestamp);
  };

  if (!active) return null;

  const isDisabled = module?.type !== moduleType.CONTENT && module?.type !== undefined;
  const tabIndex = 2;

  return (
    <>
      <AlertModal
        aria-role="dialog"
        open={showConfirmModal}
        confirm={{ label: "Delete Bookmark", onClick: handleDeleteBookmark }}
        cancel={{ onClick: cancelDelete }}
        message="Are you sure you want to delete this bookmark?"
      />
      <Container id="bookmark_container" data-testid="bookmarks">
        <ButtonWrapper direction="row">
          <BookmarkButton
            className="add-bookmark-button"
            variant="outlined"
            startIcon={<Bookmark />}
            aria-label="Add Bookmark"
            onClick={handleAddBookmark}
            disabled={isDisabled}
            tabIndex={tabIndex}
          >
            Add Bookmark
          </BookmarkButton>
        </ButtonWrapper>
        <Box>
          {Object.values(bookmarks).map((bookmark, index) => {
            const slideName = screenReaderSlideTitle(bookmark.slideName);

            return (
              <BookmarkWrapper
                id={"bookmark_item_" + index}
                key={bookmark.slideId + "_" + bookmark.timestamp}
              >
                <BookmarkContainer
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <BookmarkTitle
                    onClick={handleTitleClick(bookmark.slideId, bookmark.timestamp)}
                    tabIndex={tabIndex}
                    aria-label={`Go to bookmark: ${slideName}`}
                  >
                    {bookmark.slideName}
                  </BookmarkTitle>
                  <BookmarkIconContainer>
                    <BookmarkIconButton
                      aria-label={`Delete bookmark: ${slideName}`}
                      size="small"
                      tabIndex={tabIndex}
                      onClick={confirmDelete(bookmark.slideId, bookmark.timestamp)}
                      className="bookmark_delete_button"
                    >
                      <Delete />
                    </BookmarkIconButton>
                  </BookmarkIconContainer>
                </BookmarkContainer>
                <BookmarkBody>{bookmark.title}</BookmarkBody>
              </BookmarkWrapper>
            );
          })}
        </Box>
      </Container>
    </>
  );
}
