import { styled } from "@mui/material/styles";
import { FormLabel } from "@mui/material";

export const SurveyContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  minHeight: "435px",
  background: theme.palette.background.default,
}));

export const Label = styled(FormLabel)(({ theme }) => ({
  paddingBottom: "1em",
  color: theme.palette.text.primary,
}));

export const Buttons = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: "0.5em",
  alignSelf: "center",
  [" button"]: {
    width: "10em",
    margin: "0.3em",
  },
});
