import { ThemeOptions } from "@mui/material";
import { lightPalette } from "./lightPalette";
import { darkPalette } from "./darkPalette";
import {
  primary,
  secondary,
  blue,
  blueGrey,
  greyscale,
  success,
  error,
  grey,
  warning,
  white,
  black,
} from "./palette";

const commonPalette = {
  primary,
  secondary,
  warning,
  error,
  success,
  grey: greyscale,
};

const commonCustomPalette = {
  light: white,
  dark: black,
  white,
  black,
  blue,
  blueGrey,
  warning,
  success,
  grey,
  greyscale,
};

export const theme = (mode: "dark" | "light"): ThemeOptions => {
  const getPalette = () => {
    const modePalette = mode === "dark" ? darkPalette.palette : lightPalette.palette;
    return {
      ...commonPalette,
      ...modePalette,
      mode,
    };
  };

  const getCustomPalette = () => {
    const modeCustomPalette =
      mode === "dark" ? darkPalette.custom.palette : lightPalette.custom.palette;
    return {
      ...commonCustomPalette,
      ...modeCustomPalette,
    };
  };

  const modeGradients =
    mode === "dark" ? darkPalette.custom.gradients : lightPalette.custom.gradients;

  return {
    palette: getPalette(),
    components: mode === "dark" ? darkPalette.components : lightPalette.components,
    typography: {
      fontFamily: ["Open Sans", "Poppins", "sans-serif"].join(","),
    },
    custom: {
      palette: getCustomPalette(),
      shadows: [
        "none",
        "inset 0px -1px 0px #cedaeb",
        "0px 3px 6px 2px rgba(0, 0, 0, 0.1);",
        "0px 4px 4px rgba(0, 68, 168, 0.1);",
        "0px 12px 17px rgba(0, 0, 0, 0.14), 0px 5px 22px rgba(0, 0, 0, 0.12), 0px 7px 8px rgba(0, 0, 0, 0.2);",
        "0px 0px 17px rgba(0, 0, 0, 0.14), 0px 5px 22px rgba(0, 0, 0, 0.12), 0px 7px 8px rgba(0, 0, 0, 0.2);",
        "0 2px 12px 0 rgba(0,0,0,0.4)",
      ],
      radius: ["4px", "8px", "16px"],
      gradients: {
        blackToTransparent: "linear-gradient(0deg, rgb(0,0,0,0.8), rgba(0,0,0,0))",
        ...modeGradients,
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1000,
        lg: 1200,
        xl: 1536,
      },
    },
  };
};

export default theme;
