import React from "react";
import { Apps } from "@mui/icons-material";
import ButtonIconContainer from "../../../ButtonIconContainer/ButtonIconContainer";

type ButtonLayoutProps = {
  onClickLayout: () => void;
};

export default function ButtonLayout({ onClickLayout }: ButtonLayoutProps): JSX.Element {
  return (
    <ButtonIconContainer
      ariaLabel="Slide Thumbnails"
      tooltip="Slide Thumbnails"
      id="button_change_layout"
      onClick={onClickLayout}
    >
      <Apps />
    </ButtonIconContainer>
  );
}
