import { styled } from "@mui/material/styles";
import { Box, Paper, SpeedDial, ListItemIcon, ListItemText, Typography } from "@mui/material";

export const Container = styled(Box)(() => ({
  position: "relative",
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "flex-end",
  width: 40,
  height: 40,
}));

export const SettingsButtonContainer = styled(Box)(() => ({
  position: "absolute",
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "center",
}));

export const SettingsButton = styled(SpeedDial)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& .MuiSpeedDial-fab": {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  "& .MuiSpeedDial-fab:hover": {
    backgroundColor: "transparent",
  },
  "& .MuiButtonBase-root": {
    width: 40,
    height: 40,
    padding: 0,
    margin: 0,
  },
  "& .MuiSpeedDialAction-fab": {
    marginTop: theme.spacing(2),
    color: theme.palette.text.primary,
  },
  "& .MuiSvgIcon-root": {
    [theme.breakpoints.up("xs")]: {
      fontSize: "1.25rem",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.25rem",
    },
  },
}));

export const ButtonActionsContainer = styled("div")(({ theme }) => ({
  position: "absolute",
  zIndex: 9999,
  top: -70,
  [theme.breakpoints.down("sm")]: {
    top: -68,
  },
}));

export const ListContainer = styled(ButtonActionsContainer)(({ theme, id }) => ({
  top: id === "button_playback_speed" ? -236 : -153,
  width: "160px",
  [theme.breakpoints.down("sm")]: {
    top: id === "button_playback_speed" ? -222 : -145,
  },
}));

export const StyledPaper = styled(Paper)(({ theme }) => ({
  maxWidth: "100%",
  background: theme.palette.grey[800],
  opacity: "90%",
  borderRadius: "0px",
  [".MuiList-root"]: {
    textAlign: "left",
    padding: "0px",
    ["& li"]: {
      padding: "10px 16px",
      [theme.breakpoints.down("sm")]: {
        minHeight: "20px",
      },
    },
  },
}));

export const StyledListItemIcon = styled(ListItemIcon)({
  color: "white",
  minWidth: "25px",
  ["svg"]: {
    fontSize: "1.25rem",
  },
});

export const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  paddingRight: "8px",
  color: "white",
  ["span"]: {
    fontSize: "0.875rem",
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.75rem",
    },
  },
}));

export const StyledTypography = styled(Typography)({
  color: "white",
  ["svg"]: {
    fontSize: "0.875rem",
  },
});
