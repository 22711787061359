import React from "react";
import { Button, Dialog, DialogContent, DialogActions } from "@mui/material";

interface Action {
  label?: string;
  onClick: () => void;
}

interface AlertModalProps {
  cancel: Action;
  confirm: Action;
  message: string;
  open: boolean;
}

const AlertModal = ({ open = false, message, confirm, cancel }: AlertModalProps) => {
  return (
    <Dialog open={open}>
      <DialogContent>{message}</DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Button variant="outlined" onClick={cancel.onClick}>
          {cancel.label || "Cancel"}
        </Button>
        <Button variant="contained" onClick={confirm.onClick}>
          {confirm.label || "Ok"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertModal;
