/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Completion reason
 */
export enum CompletionReason {
  MANUAL_OVERRIDE = "MANUAL_OVERRIDE",
  PORTED_FROM_LEGACY = "PORTED_FROM_LEGACY",
  QUIZZES_PASSED = "QUIZZES_PASSED",
  SLIDES_VIEWED = "SLIDES_VIEWED",
}

/**
 * Confidence rating
 */
export enum Rating {
  CONFIDENT = "CONFIDENT",
  NOT_CONFIDENT = "NOT_CONFIDENT",
  SOMEWHAT_CONFIDENT = "SOMEWHAT_CONFIDENT",
  UNANSWERED = "UNANSWERED",
}

/**
 * User type
 */
export enum UserType {
  ActiveUser = "ActiveUser",
  ExpiredUser = "ExpiredUser",
  InitUser = "InitUser",
}

/**
 * Video Resolution
 */
export enum VideoResolution {
  FHD = "FHD",
  HD = "HD",
  SD = "SD",
  VBR = "VBR",
}

/**
 * Module type
 */
export enum moduleType {
  CONTENT = "CONTENT",
  INTRO = "INTRO",
  QUIZ = "QUIZ",
  SURVEY = "SURVEY",
}

export interface CourseProgressInput {
  complete: boolean;
  courseId: number;
  instanceId: string;
  lastView?: LastViewInput | null;
  moduleProgress: ModuleProgressInput[];
  passedQuizzes: number;
  totalQuizzes: number;
  totalSeconds: number;
  totalSlides: number;
  userId: number;
  viewedSeconds: number;
  viewedSlides: number;
}

export interface LastViewInput {
  dateTimeViewed: string;
  resumeTime: string;
  slideId: string;
}

export interface ModuleProgressInput {
  confidenceRating?: Rating | null;
  fullNumber: string;
  id: string;
  name: string;
  quizScore?: number | null;
  totalSlides: number;
  type: moduleType;
  viewedSlides: number;
}

export interface RosterUserStartAfter {
  expires?: string | null;
  id?: number | null;
  userType: UserType;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
