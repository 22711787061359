export const copyToClipboard = (text: string, callback?: (success: boolean) => void): void => {
  if (!text || !navigator.clipboard) {
    callback?.(false);
    return;
  }

  navigator.clipboard
    .writeText(text)
    .then(() => callback?.(true))
    .catch(() => callback?.(false));
};
