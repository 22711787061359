import React, { FC } from "react";
import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

export const QuizBox = styled("div")(({ theme }) => ({
  padding: theme.spacing(3),
  textAlign: "left",
  backgroundColor: theme.palette.mode === "dark" ? "#393C45" : "white",
  height: "100%",
  [" #quizQuestionInfo"]: {
    display: "flex",
    justifyContent: "space-between",
    fontWeight: 600,
  },
  [" #quizQuestionsCount"]: {
    display: "flex",
  },
  [" #quizFooter"]: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [" .return"]: {
      margin: "0.5em",
    },
  },
  [" #questionsCorrect"]: {
    marginLeft: "2em",
    color: theme.palette.text.success,
  },
  [" #questionsIncorrect"]: {
    marginLeft: "2em",
    color: theme.palette.text.error,
  },
  [" #answerStatus"]: {
    display: "flex",
  },
  [" #explanation"]: {
    alignSelf: "flex-start",
    marginBottom: "1em",
    [" #explanationText"]: {
      marginTop: "0.5em",
      marginLeft: "1.5em",
    },
  },
  [" #seeWhy"]: {
    display: "flex",
    alignItems: "flex-end",
    color: theme.palette.text.titlePrimary,
    cursor: "pointer",
    [" svg"]: {
      marginTop: "-0.1em",
      color: "#333",
    },
  },
  [" #correctText"]: {
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
    marginRight: "0.2em",
    [">svg"]: {
      marginTop: "-2px",
    },
    ["&.correct"]: {
      color: theme.palette.text.success,
      ["> svg"]: {
        color: theme.palette.text.success,
      },
    },
    ["&.incorrect"]: {
      color: theme.palette.text.error,
      ["> svg"]: {
        stroke: theme.palette.text.error,
      },
    },
  },
  [" #quizSummary"]: {
    textAlign: "center",
    fontSize: "1.3rem",
    display: "flex",
    flexDirection: "column",
  },
  [" #return-to-course-btn"]: {
    fontWeight: 600,
    fontSize: "1rem",
    [" svg"]: {
      marginRight: "12px",
      fontSize: "1.125rem",
    },
  },
  [" #passingScore"]: {
    fontSize: "0.875rem",
  },
  ["#emptyFormMessage"]: {
    display: "block",
    width: "100%",
    fontWeight: "bold",
    color: theme.palette.text.error,
  },
  [".seeWhyWrapper"]: {
    margin: theme.spacing(2, 0, 4),
    padding: theme.spacing(3),
    background: "rgba(0,0,0,0.1)",
    borderRadius: 16,

    "& strong": {
      display: "block",
      marginBottom: theme.spacing(1),
    },
  },
}));

export const PercentCircle = styled("div")(({ theme }) => ({
  alignSelf: "center",
  marginTop: "1rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%",
  width: "8rem",
  height: "8rem",
  fontSize: "2em",
  fontWeight: 500,
  color: "white",
  ["&.passed"]: {
    backgroundColor: theme.palette.success[500],
  },
  ["&.failed"]: {
    backgroundColor: theme.custom.palette.semantic.darkBg,
  },
}));

export const Assessment = styled("div")(() => ({
  "&#learnosity_assess": {
    background: "transparent",
    minHeight: "auto",
    margin: 0,
    padding: 0,

    ".col-xs-12": {
      padding: 0,
    },
  },

  [".lrn.lrn_font_size_normal"]: {
    fontSize: "1rem !important",
  },
  [" .lrn-right-region, .lrn-group-top, .lrn-group-bottom, .progress"]: {
    display: "none !important",
  },
  [" .lrn-input"]: {
    width: "1em",
    height: "1em",
  },
  [" .lrn-label"]: { paddingTop: "0.25em !important" },
  [" .lrn_correct, .lrn_incorrect, .lrn_selected"]: {
    backgroundColor: "transparent !important",
    borderLeftColor: "transparent !important",
    [" .lrn-label"]: { fontWeight: "bold !important" },
    [">label.lrn-label:before"]: {
      position: "absolute",
      left: "1.2em",
    },
  },
  [" .lrn_correct, .lrn_incorrect"]: {
    [">.lrn-input"]: {
      display: "none !important",
    },
  },
  [" .lrn_question"]: {
    fontFamily: "Open Sans",
  },
  [" .lrn-response-validate-wrapper"]: {
    fontFamily: "Open Sans",
  },
}));

export const QHeader = styled("div")(({ theme }) => ({
  marginBottom: "0.5em",
  ["#questionNo"]: {
    fontWeight: "bold",
    marginRight: "0.5em",
  },
  ["#isCorrect"]: {
    ["&.correct"]: {
      color: theme.palette.success[500],
    },
    ["&.incorrect"]: {
      color: theme.palette.error[500],
    },
  },
}));

export const AssessmentWrapper = styled("div")(({ theme }) => ({
  minHeight: "580px",
  padding: theme.spacing(6),
  paddingBottom: "0px",

  "& .lrn": {
    color: theme.palette.text.primary,
  },

  "& .lrn_correct": {
    color: theme.palette.success[500],
  },

  "& .lrn_incorrect": {
    color: theme.palette.error[500],
  },
}));

export const UuidContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  width: "100%",
  marginTop: theme.spacing(8),

  "& button": {
    "& svg": {
      fill: theme.custom.palette.semantic.buttonIconColor,
    },
  },

  "& p": {
    margin: "0px",
    paddingRight: "10px",
  },

  "& button:hover": {
    "& svg": {
      fill: theme.custom.palette.semantic.buttonIconHoverColor,
    },
  },

  "& button:focus, & button:active": {
    "& svg": {
      fill: theme.custom.palette.semantic.buttonIconFocusColor,
    },
  },
}));

export const QuestionHeader: FC<{
  index: number;
  total: number;
  correct: boolean;
}> = ({ index, total, correct }) => (
  <QHeader id="questionHeader">
    <span id="questionNo">
      Question {index} of {total}
    </span>
    <span id="isCorrect" className={correct ? "correct" : "incorrect"}>
      {correct ? "Correct" : "Incorrect"}
    </span>
  </QHeader>
);

export const QuizButton = styled(Button)(({ theme }) => ({
  boxShadow: "unset",
  backgroundColor: theme.palette.primary.main,
  fontWeight: 600,
  fontSize: "1rem",
  padding: "8px 16px",
  height: "fit-content",
}));

export const QuizResultsActions = styled("div")`
  display: flex;
  justify-content: space-between;
`;

export const SeeWhyStyledButton = styled(Button)(({ theme }) => ({
  marginLeft: "1rem",
  color: theme.palette.mode === "dark" ? theme.custom.palette.light : theme.palette.secondary.main,

  ["&:hover"]: {
    color:
      theme.palette.mode === "dark" ? theme.custom.palette.light : theme.palette.secondary.dark,
  },
}));
