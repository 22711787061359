import React, { MouseEventHandler, useState } from "react";
import { getSansSDK, logout, useUserInfo } from "@sans-tesseract/sdk-react";
import { Divider, MenuItem } from "@mui/material";
import {
  AccountCircleOutlined,
  DarkMode,
  LightMode,
  SupervisedUserCircleOutlined,
} from "@mui/icons-material";
import { MenuButton, MenuLink, StyledMenu } from "./UserMenu.elements";
import { accountDashboardUrl } from "../../util/constants";
import { usePersistedSettings } from "../../hooks/usePersistedSettings";
import { ModeType } from "../../apollo/typePolicies/LocalStorage";
import { useHistory } from "react-router";

export function UserMenu({ library }: { library: boolean }): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { mode, setMode } = usePersistedSettings();
  const userInfo = useUserInfo() as unknown as Record<string, string> | undefined;
  const history = useHistory();

  const open = Boolean(anchorEl);
  const openMenu: MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const unimpersonate = () => {
    getSansSDK().api.tokens.refreshTokens();
    window.location.reload();
  };

  const links = [
    {
      label: "Account dashboard",
      href: accountDashboardUrl,
      id: "menu-account-dashboard",
    },
  ];

  const modeIcons = {
    dark: <DarkMode fontSize="small" />,
    light: <LightMode fontSize="small" />,
  };

  const toggleMode = () => {
    const updatedMode = mode === "light" ? "dark" : "light";
    setMode(updatedMode);
  };

  return (
    <>
      <MenuButton
        tabIndex={1}
        size="medium"
        edge="start"
        color="inherit"
        aria-label="Account profile menu"
        id="menu-account-profile"
        onClick={openMenu}
      >
        {userInfo?.assumedBy ? (
          <SupervisedUserCircleOutlined fontSize="large" />
        ) : (
          <AccountCircleOutlined fontSize="large" />
        )}
      </MenuButton>
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {!library && (
          <MenuItem
            id="menu-my-courses"
            onClick={() => history.push("/")}
            sx={{ display: "flex", gap: 1 }}
          >
            My courses
          </MenuItem>
        )}
        {links.map((link, idx) => (
          <MenuItem onClick={handleClose} key={idx}>
            <MenuLink id={link.id} href={link.href}>
              {link.label}
            </MenuLink>
          </MenuItem>
        ))}
        <MenuItem id="menu-dark-mode" onClick={toggleMode} sx={{ display: "flex", gap: 1 }}>
          {mode && modeIcons[mode as ModeType]} Dark Mode {mode === "dark" ? "On" : "Off"}
        </MenuItem>
        <Divider />
        {userInfo?.assumedBy ? (
          <MenuItem id="menu-unimpersonate" onClick={unimpersonate}>
            Unimpersonate
          </MenuItem>
        ) : (
          <MenuItem id="menu-logout" onClick={logout}>
            Logout
          </MenuItem>
        )}
      </StyledMenu>
    </>
  );
}
