import React from "react";
import { Replay10 } from "@mui/icons-material";
import ButtonIconContainer from "../../../ButtonIconContainer/ButtonIconContainer";

type ButtonSeekBackProps = {
  skip: (amount: number) => void;
};

export default function ButtonSkipBack({ skip }: ButtonSeekBackProps): JSX.Element {
  const handleClick = () => skip(-10);

  return (
    <ButtonIconContainer
      onClick={handleClick}
      ariaLabel="Skip back 10 seconds (left arrow)"
      id="button_skip_back"
      size="large"
      tooltip="Skip back 10 seconds (left arrow)"
    >
      <Replay10 />
    </ButtonIconContainer>
  );
}
