import React, { useRef, useState, useEffect } from "react";

export enum TechSupportType {
  CSS = "CSS NA",
  SME = "OLT SME Team",
}

export type HasbotContextType = {
  showDialog: boolean;
  setShowDialog: React.Dispatch<React.SetStateAction<boolean>>;
  techSupportActive?: TechSupportType;
  setZopimChatActive: React.Dispatch<React.SetStateAction<boolean>>;
  zopimChatActive: boolean;
  setTechSupportActive: React.Dispatch<React.SetStateAction<TechSupportType | undefined>>;
  openDialogBtnRef: React.RefObject<HTMLButtonElement>;
  unreadChatMessages: number;
};

export const HasbotContext = React.createContext<HasbotContextType>({} as HasbotContextType);

export const HasbotProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [zopimChatActive, setZopimChatActive] = useState(false);
  const [unreadChatMessages, setUnreadChatMessages] = useState(0);
  const [techSupportActive, setTechSupportActive] = useState<TechSupportType | undefined>();
  const openDialogBtnRef = useRef<HTMLButtonElement>(null);

  const handleMessages = () => {
    if (!showDialog) {
      setUnreadChatMessages((prevState) => prevState + 1);
    } else {
      setUnreadChatMessages(0);
    }
  };

  useEffect(() => {
    setUnreadChatMessages(0);
  }, [showDialog]);

  useEffect(() => {
    window.addEventListener("newZopimMessage", handleMessages, false);
  }, []);

  return (
    <HasbotContext.Provider
      value={{
        showDialog,
        setShowDialog,
        techSupportActive,
        setTechSupportActive,
        zopimChatActive,
        setZopimChatActive,
        openDialogBtnRef,
        unreadChatMessages,
      }}
    >
      {children}
    </HasbotContext.Provider>
  );
};
