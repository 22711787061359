import { styled } from "@mui/material/styles";
import { IconButton, Menu } from "@mui/material";

export const StyledMenu = styled(Menu)(({ theme }) => ({
  [".MuiPaper-root"]: {
    background: theme.custom.palette.semantic.menuBg,
  },
}));

export const MenuButton = styled(IconButton)(({ theme }) => ({
  fontSize: "1.5em",
  color: theme.custom.palette.semantic.buttonIconSecondaryColor,
  marginLeft: "0",
}));

export const MenuLink = styled("a")(({ theme }) => ({
  textDecoration: "none",
  color: theme.custom.palette.semantic.menuHoverBg,
}));
