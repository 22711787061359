import TelemetryException from "./TelemetryException";
import { telemetryApiUrl } from "../util/constants";
import type { Telemetry } from "./TelemetryModel";
import { Sans } from "../util/sdk";

export const store = async (telemetry: Telemetry): Promise<Telemetry> => {
  const tokens = await Sans.sdk.core.api.tokens.freshTokens();
  const response = await fetch(telemetryApiUrl, {
    method: "POST",
    body: JSON.stringify(telemetry),
    headers: {
      "Content-Type": "application/json",
      "X-ACCESS-TOKEN": `Bearer ${tokens.id}`,
    },
  });

  const json = await response.json();
  if (json.message) {
    throw new TelemetryException(json.message);
  }

  return telemetry;
};
