import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

export const TapToUnmuteButton = styled(Button)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(3),
  left: "50%",
  transform: "translatex(-50%)",
  display: "flex",
  fontWeight: theme.typography.fontWeightBold,
  background: "white",
  boxShadow: theme.custom.shadows[1],
  zIndex: theme.zIndex.modal,

  "&:hover": {
    backgroundColor: theme.palette.grey[100],
  },
}));
