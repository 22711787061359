import React from "react";
import type { ProgressState } from "../../Video/useVideo";
import {
  ButtonsContainer,
  ProgressBar,
  LeftSideControls,
  RightSideControls,
  ControlsBottomContainer,
  ControlsBottomBackground,
  AdditionalControlsPopover,
} from "./ControlsBottom.elements";
import ButtonPlayPause from "./ButtonPlayPause/ButtonPlayPause";
import ButtonReplay from "./ButtonReplay/ButtonReplay";
import ButtonVolume from "./ButtonVolume/ButtonVolume";
import VideoTimestamp from "./Timestamp/Timestamp";
import ButtonAutoplay from "./ButtonAutoplay/ButtonAutoplay";
import ButtonClosedCaptions from "./ButtonClosedCaptions/ButtonClosedCaptions";
import ButtonSettings from "./ButtonSettings/ButtonSettings";
import ButtonLayout from "./ButtonLayout/ButtonLayout";
import ButtonFullscreen from "./ButtonFullscreen/ButtonFullscreen";
import { IconButton, Stack } from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";
import ButtonSkipBack from "../ControlsCenter/ButtonSkipBack/ButtonSkipBack";
import ButtonSkipForward from "../ControlsCenter/ButtonSkipForward/ButtonSkipForward";

type ControlsBottomProps = {
  isPlaying: boolean;
  isAutoplayEnabled: boolean;
  handleClickAutoplay: (value: boolean) => void;
  handleClickPlayPause: () => void;
  progress: ProgressState;
  handleMouseUpProgressBar: () => void;
  handleMouseDownProgressBar: () => void;
  handleMouseOutProgressBar: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleChangeProgressBar: (_: any, value: number | number[]) => void;
  handleClickReplay: () => void;
  isFullscreen: boolean;
  handleClickFullscreen: () => void;
  handleClickPIP: () => void;
  playbackRate: number;
  handleClickPlaybackRate: (value: number) => void;
  volume: number;
  handleClickMuteVolume: () => void;
  handleChangeVolumeSlider: (event: Event, value: number | number[]) => void;
  duration: number;
  handleClickCaptions: () => void;
  captionsEnabled: boolean;
  handleChangeView: () => void;
  muted: boolean;
  hasCustomDuration: boolean;
  skip: (amount: number) => void;
};

export default function ControlsBottom({
  isPlaying,
  isAutoplayEnabled,
  handleClickAutoplay,
  handleClickPlayPause,
  progress,
  handleMouseUpProgressBar,
  handleMouseDownProgressBar,
  handleMouseOutProgressBar,
  handleChangeProgressBar,
  handleClickReplay,
  isFullscreen,
  handleClickFullscreen,
  handleClickPIP,
  playbackRate,
  handleClickPlaybackRate,
  volume,
  handleClickMuteVolume,
  handleChangeVolumeSlider,
  duration,
  handleClickCaptions,
  captionsEnabled,
  handleChangeView,
  muted,
  hasCustomDuration,
  skip,
}: ControlsBottomProps): JSX.Element {
  const AdditionalWrapper = Stack;

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "additional_controls" : undefined;

  const additionalControls = (
    <>
      <ButtonAutoplay isAutoplayEnabled={isAutoplayEnabled} onClickAutoplay={handleClickAutoplay} />
      <ButtonClosedCaptions
        captionsEnabled={captionsEnabled}
        onClickCaptions={handleClickCaptions}
      />
      <ButtonSettings
        className="settings"
        playbackRate={playbackRate}
        onClickPlaybackRate={handleClickPlaybackRate}
        onClickPIP={handleClickPIP}
        hasCustomDuration={hasCustomDuration}
      />
      <ButtonLayout data-targetid="layout" onClickLayout={handleChangeView} />
      <ButtonFullscreen isFullscreen={isFullscreen} onClickFullscreen={handleClickFullscreen} />
    </>
  );

  return (
    <ControlsBottomContainer>
      <ControlsBottomBackground />
      <ProgressBar
        aria-label={`video progress bar, current progress ${Math.floor(
          progress.playedSeconds,
        )} seconds`}
        size="small"
        value={progress.played}
        min={0}
        max={0.999999}
        step={0.000001}
        onMouseDown={handleMouseDownProgressBar}
        onMouseUp={handleMouseUpProgressBar}
        onChange={handleChangeProgressBar}
        onMouseOut={handleMouseOutProgressBar}
        tabIndex={1}
        id="progress_bar"
        data-testid="progress_bar"
      />
      <ButtonsContainer>
        <LeftSideControls>
          <ButtonReplay onClickReplay={handleClickReplay} />
          <ButtonSkipBack skip={skip} />

          <ButtonPlayPause onClickPlayPause={handleClickPlayPause} isPlaying={isPlaying} />
          <ButtonSkipForward skip={skip} />

          <ButtonVolume
            volume={volume}
            muted={muted}
            onClickMuteVolume={handleClickMuteVolume}
            onChangeVolumeSlider={handleChangeVolumeSlider}
          />
          <VideoTimestamp progress={progress} duration={duration} />
        </LeftSideControls>
        <RightSideControls className="rightSideControls">
          <ButtonSettings
            className="condensed"
            playbackRate={playbackRate}
            onClickPlaybackRate={handleClickPlaybackRate}
            onClickPIP={handleClickPIP}
            hasCustomDuration={hasCustomDuration}
          />

          <IconButton className="condensed" onClick={handleClick}>
            <MoreHoriz />
          </IconButton>

          <AdditionalControlsPopover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Stack alignItems="center" direction="row">
              {additionalControls}
            </Stack>
          </AdditionalControlsPopover>

          <AdditionalWrapper alignItems="center" direction="row" className="additional">
            {additionalControls}
          </AdditionalWrapper>
        </RightSideControls>
      </ButtonsContainer>
    </ControlsBottomContainer>
  );
}
