import { makeVar } from "@apollo/client";
import { getTimestampFromSeconds } from "../util/time";

export interface VideoProgressState {
  played: number;
  playedSeconds: number;
  loaded: number;
  loadedSeconds: number;
}

export const videoProgressVar = makeVar<VideoProgressState>({
  played: 0,
  playedSeconds: 0,
  loaded: 0,
  loadedSeconds: 0,
});

export function setVideoProgress(_progressObj: any) {
  videoProgressVar(_progressObj);
}

export function getVideoProgress() {
  const videoProgress = videoProgressVar();
  return getTimestampFromSeconds(videoProgress.playedSeconds);
}
