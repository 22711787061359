import { Typography } from "@mui/material";
import React from "react";
import { CalendarToday } from "@mui/icons-material";
import { useProgress } from "../../hooks/useProgress";
import {
  CourseExpiration,
  DetailsLink,
  ProgressBar,
  ProgressHeader,
  ProgressText,
  ProgressTextWrapper,
  ProgressHorizontalLine,
  ProgressContainer,
} from "./Progress.elements";
import { getTimeDescription } from "../../util/time";
import { useProgressModal } from "../../hooks/useProgressModal";
import { useAppState } from "../../hooks/useAppState";

export function Progress(): JSX.Element | null {
  const { progress, expiration } = useProgress();
  const { openModal: openModalWith } = useProgressModal();
  const {
    appState: { courseId, instanceId },
    course,
  } = useAppState();

  if (!progress) {
    return null;
  }

  const { percentSlides, hoursRemaining } = progress;

  const percentCompleted = Math.ceil(percentSlides);

  const openModal = () => {
    courseId &&
      instanceId &&
      course?.id &&
      openModalWith({ courseId, courseUuid: course.id, instanceId });
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    const actionMap = {
      Enter: openModal,
    } as const;

    type Key = keyof typeof actionMap;
    const key = event.key as Key;

    return actionMap[key]?.();
  };

  return (
    <ProgressContainer role="complementary" aria-label="Course progress">
      <ProgressHeader>
        <div className="progress-percent">
          <Typography variant="h5" component="h2" aria-label={`${percentCompleted}% completed`}>
            {`Progress: ${percentCompleted}%`}
          </Typography>
        </div>
        <DetailsLink
          tabIndex={2}
          onClick={openModal}
          onKeyPress={handleKeyPress}
          aria-label="View progress details"
          id="view_progress_link"
        >
          View Course Progress
        </DetailsLink>
      </ProgressHeader>
      <ProgressTextWrapper>
        <ProgressText
          aria-label={`approximately ${getTimeDescription(hoursRemaining, "hours")} remaining`}
        >
          {`~${getTimeDescription(hoursRemaining, "hours", true)} remaining`}
        </ProgressText>
      </ProgressTextWrapper>
      <ProgressBar variant="determinate" value={percentSlides} />
      <CourseExpiration>
        <CalendarToday fontSize="small" />
        <Typography>
          <div>{expiration?.expirationText}</div>
          <div>{expiration?.expirationDate}</div>
        </Typography>
      </CourseExpiration>
      <ProgressHorizontalLine />
    </ProgressContainer>
  );
}
