import { Theme } from "@mui/system";

interface PaddedBox {
  theme: Theme;
  centerText?: boolean;
}

export const paddedBox = ({ theme, centerText = false }: PaddedBox) => ({
  textAlign: centerText ? "center" : "left",
  backgroundColor: theme.palette.mode === "dark" ? "#252830" : "#F5F9FF",
  border:
    theme.palette.mode === "dark"
      ? `1px dashed ${theme.palette.grey["700"]}`
      : `1px dashed #829AB1`,
  borderRadius: "6px",
  padding: theme.spacing(3),
  color: theme.palette.mode === "dark" ? theme.palette.text.secondary : "#627D98",
});
