import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Tab, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTabs, icons } from "./useTabs";
import {
  CourseWrapper,
  CourseContent,
  CourseTabs,
  SearchField,
  SearchContainer,
  SearchResultText,
  StyledSearchIcon,
} from "./Course.elements";
import { useSearch } from "../Search/Search.queries";
import debounce from "lodash.debounce";
import ResultSection from "../Search/SearchResult";
import { useTestMode } from "../../hooks/useTestMode";

/* eslint-disable react/display-name */
export function Course(): JSX.Element {
  const { tabIdx, changeTab, tabs } = useTabs();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const [query, setQuery] = useState("");
  const { search, results, resultsCount, showSearchResults, setShowSearchResults } = useSearch();
  const { testMode } = useTestMode();

  const debouncedSearch = useMemo(() => debounce((query) => search(query), 500), [search]);

  const handleOnChange = useCallback(
    (_: React.SyntheticEvent, idx: number) => {
      changeTab(idx);
      setShowSearchResults(false);
    },
    [changeTab, setShowSearchResults],
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.code === "Enter" && !!query) {
        debouncedSearch(query);
        setShowSearchResults(true);
      }
    },
    [query, debouncedSearch, setShowSearchResults],
  );

  useEffect(() => {
    if (query && !testMode) {
      debouncedSearch(query);
      return setShowSearchResults(true);
    }

    setShowSearchResults(false);
  }, [debouncedSearch, query, setShowSearchResults, testMode]);

  return (
    <CourseWrapper>
      <SearchContainer role="search">
        <StyledSearchIcon id="search-icon" />
        <SearchField
          aria-label="Search field"
          data-testid="search-input"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Search"
          id="search_field"
          type="search"
        />
      </SearchContainer>
      <CourseTabs
        value={showSearchResults ? false : tabIdx}
        onChange={handleOnChange}
        variant="scrollable"
        scrollButtons={false}
        aria-label="Course navigation"
      >
        {tabs.map((tab, idx) => {
          const Icon = tab.icon ? icons[tab.icon] : null;
          let id = "tab_" + tab.label;
          id = id.toLowerCase();

          return (
            <Tab
              className="courseTab"
              key={idx}
              label={tab.label}
              tabIndex={idx === tabIdx ? 2 : -1}
              color="blue"
              data-testid={id}
              icon={isTablet && Icon ? <Icon /> : <></>}
            />
          );
        })}
      </CourseTabs>
      <CourseContent>
        <div hidden={showSearchResults}>
          {tabs.map((tab, idx) => (
            <tab.component key={tab.label} active={idx == tabIdx} />
          ))}
        </div>
        <div
          role="tabpanel"
          aria-label={
            resultsCount > 0 ? `${resultsCount} Search Results` : "No search results found."
          }
          hidden={!showSearchResults}
          data-testid="search-results-panel"
        >
          {resultsCount > 0 ? (
            <>
              <SearchResultText aria-live="polite">{resultsCount} Search Results</SearchResultText>
              {results?.foundFields.map((field, i) => (
                <ResultSection
                  key={field.fieldName}
                  field={field}
                  query={query}
                  index={i}
                  data-testid="results-section"
                />
              ))}
            </>
          ) : (
            <SearchResultText aria-live="polite">
              No search results found. Please try again
            </SearchResultText>
          )}
        </div>
      </CourseContent>
    </CourseWrapper>
  );
}
