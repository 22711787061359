import { makeVar, useReactiveVar } from "@apollo/client";
import { useCallback } from "react";
import { reportErrorToNR } from "../util/reportErrorToNR";

export enum ErrorSeverity {
  HIGH = "high",
  MEDIUM = "medium",
  LOW = "low",
}

export type ErrorReporter = (
  severity: ErrorSeverity,
  message: string,
  errorMeta?: Record<string, unknown>,
) => void;

const errorVar = makeVar<{ message: string; severity: ErrorSeverity } | undefined>(undefined);

export const useErrorHandler = (severity?: ErrorSeverity) => {
  const error = useReactiveVar(errorVar);

  const simplifyErrorMeta = (
    errorMeta?: Record<string, unknown>,
  ): Record<string, string> | undefined => {
    if (!errorMeta) {
      return undefined;
    }

    return Object.keys(errorMeta).reduce((acc, key) => {
      if (typeof errorMeta[key] === "object") {
        acc[key] = JSON.stringify(errorMeta[key]);
      } else {
        acc[key] = String(errorMeta[key]);
      }
      return acc;
    }, {} as Record<string, string>);
  };

  const throwError = useCallback(
    async (error: string | Error, errorMeta: Record<string, unknown> = {}): Promise<void> => {
      reportErrorToNR(error, simplifyErrorMeta(errorMeta));
    },
    [],
  );

  const reportError = useCallback(
    async (
      severity: ErrorSeverity,
      message: string,
      errorMeta: Record<string, unknown> = {},
    ): Promise<void> => {
      throwError(message, errorMeta);
      errorVar({ message, severity });
    },
    [throwError],
  );

  return {
    reportError,
    error: error?.severity === severity ? error?.message : undefined,
    throwError,
  };
};
