import { makeVar, ReactiveVar } from "@apollo/client";

export const getCleanValueForStorage = (value: unknown): string => {
  return typeof value === "string" ? value : JSON.stringify(value);
};

export const makeVarPersisted = <T>(initialValue: T, storageName: string): ReactiveVar<T> => {
  let value = initialValue;

  const previousValue = localStorage.getItem(storageName);

  if (previousValue !== null) {
    const parsed = JSON.parse(previousValue);
    value = parsed;
  }

  const reactiveVariable = makeVar<T>(value);

  const onNext = (newValue: T | undefined) => {
    // Try to add the value to local storage
    if (newValue === undefined) {
      localStorage.removeItem(storageName);
    } else {
      localStorage.setItem(storageName, JSON.stringify(newValue));
    }

    // Re-register for the next change
    reactiveVariable.onNextChange(onNext);
  };

  // Register for the first change
  reactiveVariable.onNextChange(onNext);

  return reactiveVariable;
};
