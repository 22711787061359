import { styled } from "@mui/material/styles";
import { Stack, Skeleton, IconButton } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

export const DialogWrapper = styled("div")(({ theme }) => ({
  position: "fixed",
  right: theme.spacing(2),
  bottom: theme.spacing(2),
  background: theme.custom.palette.semantic.modalBg,
  width: "440px",
  borderRadius: theme.custom.radius[2],
  boxShadow: theme.shadows[4],
  zIndex: 99999999,
  overflow: "hidden",
  height: "auto",
  minHeight: "618px",
  opacity: "1",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    left: 0,
    top: 0,
    bottom: 0,
    boxShadow: "unset",
    borderRadius: "0px",
  },
  ["@media screen and (max-height: 618px)"]: {
    height: "95vh",
    overflow: "scroll",
  },
  ["@media screen and (max-height: 300px)"]: {
    bottom: "4px",
  },
  "&.hidden": {
    width: "1px",
    height: "1px",
    opacity: "0",
    right: theme.spacing(-2),
    bottom: theme.spacing(-2),
  },
}));

/* SKELETON STYLED COMPONENTS */
export const SkeletonContainer = styled(Stack)(({ theme }) => ({
  height: "564px",
  borderRadius: theme.custom.radius[2],
}));

export const SkeletonHeader = styled(Skeleton)(({ theme }) => ({
  borderRadius: `${theme.custom.radius[2]} ${theme.custom.radius[2]} 0px 0px`,
  marginTop: "0px!important",
  [theme.breakpoints.down("sm")]: {
    borderRadius: "0px",
  },
}));

export const SkeletonCloseBtn = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  zIndex: 9,
  right: "21px",
  top: "21px",
  color: theme.palette.grey[200],
  ["svg"]: {
    fontSize: "1.375rem",
  },
}));

export const ChatButton = styled(LoadingButton)(({ theme }) => ({
  "&:disabled": {
    color: theme.palette.grey[500],
    backgroundColor: theme.palette.grey[200],
  },
}));
