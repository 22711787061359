import { TypePolicy } from "@apollo/client";
import { lastView, sumProgress } from "../../util/cache";

export default {
  merge: true,
  fields: {
    progress: {
      read: (_, { readField }) => sumProgress("modules", readField, true),
    },
    lastView: {
      read: (_, { readField }) => lastView("modules", readField),
    },
  },
} as TypePolicy;
