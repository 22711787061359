import React from "react";
import { Tooltip } from "@mui/material";
import { SkipPrevious } from "@mui/icons-material";
import { useAppState } from "../../../../hooks/useAppState";
import { SkipButton } from "../../Controls.elements";

interface ButtonPreviousProps {
  handleClickPreviousVideo: () => void;
  disabled: boolean;
}

export default function ButtonPrevious({
  handleClickPreviousVideo,
  disabled,
}: ButtonPreviousProps): JSX.Element {
  const { slideIdx, moduleIdx, nextModule } = useAppState();
  const isFirstSlide = slideIdx.index === 0 && moduleIdx?.index === 1;
  const isFirstSlideInModule = slideIdx.index === 0;

  const goToPrev = () => nextModule(true);

  return (
    <Tooltip title={`Previous slide (p)`}>
      <SkipButton
        disableFocusRipple={true}
        onClick={isFirstSlideInModule ? goToPrev : handleClickPreviousVideo}
        aria-label="Go to previous slide"
        id="button_previous_slide"
        disabled={isFirstSlide || disabled}
        className={isFirstSlide ? "hidden" : ""}
        sx={{
          mr: "auto",
        }}
      >
        <SkipPrevious />
      </SkipButton>
    </Tooltip>
  );
}
