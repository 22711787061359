import { gql } from "@apollo/client";
export default gql`
  type Progress {
    total: Int!
    viewed: Int!
    percent: Int
    totalSlides: Int!
    viewedSlides: Int!
    percentSlides: Int
    isViewed: Boolean!
    passedQuizzes: Int!
    totalQuizzes: Int!
    percentQuizzes: Int
  }
  extend type Query {
    courseProgress: Progress!
    volume: Int!
    autoplay: Boolean!
    captions: Boolean!
    playback: Int!
    quality: String!
    coursebookFontSize: Int!
    courseTab: Int!
    muted: Boolean!
    mode: String!
    dismissedMessages: [String]
    sidebarWidth: Int!
  }
  extend type Course {
    progress: Progress!
    lastView: SlideView!
  }
  type Expiration {
    expirationText: String!
    expirationDate: String!
    isExpired: Boolean!
  }
  extend type TrainingInstance {
    expiration: Expiration!
  }
  extend type RosterUser {
    expiration: Expiration!
  }
  extend type Section {
    progress: Progress!
    lastView: SlideView!
  }
  extend type Module {
    progress: Progress!
    lastView: SlideView!
  }
  extend type Slide {
    progress: Progress!
  }
  extend type CourseProgress {
    percentSlides: Int!
    percentQuizzes: Int!
    hoursRemaining: Int!
  }
`;
