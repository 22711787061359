import { gql, useMutation } from "@apollo/client";
import { CreateCaptionsMutation } from "./__generated__/CreateCaptionsMutation";
export const CREATE_CAPTIONS_MUTATION = gql`
  mutation CreateCaptionsMutation($learningObjectUuid: String!, $slideNumbers: [Int!]!) {
    createCaptions(learningObjectUuid: $learningObjectUuid, slideNumbers: $slideNumbers)
  }
`;

export function useCaptioner() {
  const [createCaptions, { loading, data, error }] =
    useMutation<CreateCaptionsMutation>(CREATE_CAPTIONS_MUTATION);

  async function caption(learningObjectUuid: string, slideNumber: number) {
    createCaptions({
      variables: { learningObjectUuid, slideNumbers: [slideNumber] },
      errorPolicy: "ignore",
    });
  }

  return {
    caption,
    captionsLoading: loading,
    captionsStarted: !!data?.createCaptions,
    captionsError: error || data?.createCaptions === false,
  };
}
