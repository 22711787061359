import React from "react";
import { StyledSlider, SliderWrapper } from "./FontSlider.elements";
import { Stack } from "@mui/material";
import TitleIcon from "@mui/icons-material/Title";

interface FontSlideProps {
  handleChangeFontSize: (fontSize: number) => void;
  fontSize: number;
}

export function FontSlider({ handleChangeFontSize, fontSize }: FontSlideProps): JSX.Element | null {
  function ariaValueText(value: number) {
    return `${value}px`;
  }

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    handleChangeFontSize(newValue as number);
  };

  return (
    <SliderWrapper>
      <Stack spacing={2} direction="row" alignItems="center">
        <TitleIcon sx={{ fontSize: "12px" }} />
        <StyledSlider
          aria-label="Font size"
          value={fontSize}
          onChange={handleSliderChange}
          max={34}
          min={14}
          getAriaValueText={ariaValueText}
        />
        <TitleIcon sx={{ fontSize: "20px" }} />
      </Stack>
    </SliderWrapper>
  );
}
