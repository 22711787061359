import React, { useContext } from "react";
import { Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MinimizeIcon from "@mui/icons-material/Minimize";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { PageHeader, StyledHeaderIconButton } from "./Common.elements";
import { HasbotContext, HasbotContextType } from "../../context/hasBot-context";

type HelpAndSupportHeaderProps = {
  children: React.ReactElement;
};

export function HelpAndSupportHeader({ children }: HelpAndSupportHeaderProps) {
  const { setTechSupportActive, setZopimChatActive, setShowDialog, openDialogBtnRef } = useContext(
    HasbotContext,
  ) as HasbotContextType;

  const dispatchHoursEvent = () => {
    const zopimHours = new Event("updateHours");
    document.dispatchEvent(zopimHours);
  };

  const handleClick = () => {
    setTechSupportActive(undefined);
    setZopimChatActive(false);
    dispatchHoursEvent();
  };

  const closeDialog = () => {
    setTechSupportActive(undefined);
    setZopimChatActive(false);
    setShowDialog(false);
    openDialogBtnRef.current?.focus();
    const endChat = new Event("endZopimChat");
    document.dispatchEvent(endChat);
    dispatchHoursEvent();
  };

  const minimizeDialog = () => {
    setShowDialog(false);
    openDialogBtnRef.current?.focus();
  };

  return (
    <PageHeader
      item
      container
      id="header"
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Grid justifyContent="flex-start">
        <StyledHeaderIconButton
          className="loading-button"
          onClick={handleClick}
          aria-label="Go back to help and support main page"
          sx={{
            marginRight: "38px",
          }}
        >
          <KeyboardArrowLeftIcon />
        </StyledHeaderIconButton>
      </Grid>
      <Grid item container xs={6} justifyContent="center" alignItems="center" aria-live="assertive">
        {children}
      </Grid>
      <Grid justifyContent="flex-end">
        <StyledHeaderIconButton
          id="minimize-help-dialog"
          aria-label="Minimize help dialog"
          className="chat-minimize-button"
          onClick={minimizeDialog}
        >
          <MinimizeIcon />
        </StyledHeaderIconButton>
        <StyledHeaderIconButton
          id="close-help-dialog"
          aria-label="Close help dialog"
          className="chat-close-button"
          onClick={closeDialog}
        >
          <CloseIcon />
        </StyledHeaderIconButton>
      </Grid>
    </PageHeader>
  );
}
