// colors
export const primary = {
  light: "#0D6EFF",
  main: "#0058DB",
  dark: "#0044A8",
};

export const secondary = {
  light: "#DBEAFF",
  main: "#546A80",
  dark: "#435165",
};

export const blue = {
  50: "#F5F9FF",
  100: "#DBEAFF",
  200: "#A8CBFF",
  300: "#75ADFF",
  400: "#428EFF",
  500: "#0058DB",
  600: "#0044A8",
  700: "#002F75",
  800: "#001B42",
};

export const blueGrey = {
  50: "#F0F4F8",
  100: "#D9E2EC",
  200: "#BCCCDC",
  300: "#9FB3C8",
  400: "#829AB1",
  500: "#627D98",
  600: "#546A80",
  700: "#334E68",
};

export const greyscale = {
  50: "#F2F2F2",
  100: "#E6E8EB",
  200: "#DADDE0",
  300: "#B4BAC2",
  400: "#8E96A1",
  500: "#6B7785",
  600: "#515D6A",
  700: "#3F4751",
  800: "#282F36",
};

export const test = {
  50: "#ccc",
};

export const grey = {
  50: "#383838",
  100: "#B0B1B4",
  200: "#989EA9",
};

export const success = {
  100: "#DAF9D5",
  300: "#91E091",
  500: "#51A95D",
  700: "#367550",
  900: "#224F41",
};

export const error = {
  100: "#F9E3D8",
  300: "#EA9C8C",
  500: "#D44040",
  700: "#982C3D",
  900: "#660919",
};

export const warning = {
  100: "#FCF4D2",
  300: "#F2D47D",
  500: "#DEA23B",
  700: "#9E6925",
  900: "#683E13",
};

export const white = "#FFFFFF";

export const black = "#000000";

export const gradients = {};
