import { useApolloClient, useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useCallback, useEffect, useState } from "react";
import { LearnosityInit } from "../../../types/learnosity";
import { useAppState } from "../../hooks/useAppState";
import { QuestionCount } from "./__generated__/QuestionCount";
import { QuizInit } from "./__generated__/QuizInit";
import { useProgress } from "../../hooks/useProgress";

export const QUIZ_INIT_QUERY = gql`
  query QuizInit(
    $courseId: Int!
    $sectionId: String!
    $moduleId: String!
    $domain: String
    $instanceId: String
  ) {
    getLearnosityInit(
      courseId: $courseId
      sectionId: $sectionId
      moduleId: $moduleId
      domain: $domain
      instanceId: $instanceId
    )
  }
`;

export const QUESTION_COUNT_QUERY = gql`
  query QuestionCount($sectionId: String!) {
    getQuizQuestionCount(sectionId: $sectionId)
  }
`;

export const SAVE_SCORE_MUTATION = gql`
  mutation QuizScore($sessionId: String!, $courseId: Int!, $moduleId: String!, $score: Int!) {
    saveQuizScore(sessionId: $sessionId, courseId: $courseId, moduleId: $moduleId, score: $score)
  }
`;
export function useQuizQuery() {
  const client = useApolloClient();
  const [init, setInit] = useState<LearnosityInit>();
  const { saveCourseProgress } = useProgress();

  const {
    appState: { courseId, sectionId, moduleId, instanceId },
  } = useAppState();

  const [runQuery, { data, refetch }] = useLazyQuery<QuizInit>(QUIZ_INIT_QUERY, {
    variables: { courseId, sectionId, moduleId, domain: location.hostname, instanceId },
    fetchPolicy: "no-cache",
  });

  const [runQuestionCount, { data: qcData }] = useLazyQuery<QuestionCount>(QUESTION_COUNT_QUERY, {
    variables: { sectionId },
    fetchPolicy: "no-cache",
  });

  const questionCount = qcData?.getQuizQuestionCount;

  useEffect(() => {
    if (data?.getLearnosityInit) setInit(JSON.parse(data.getLearnosityInit));
  }, [data]);

  const initialize = useCallback(async () => {
    setInit(undefined);
    await refetch();
    client.cache.modify({
      id: client.cache.identify({ __typename: "Module", id: moduleId }),
      fields: {
        quizInProgress: () => true,
      },
    });
  }, [client, moduleId, refetch]);

  const saveQuizScore = useCallback(
    async (score: number) => {
      if (!init) return;
      await client.mutate({
        mutation: SAVE_SCORE_MUTATION,
        variables: { sessionId: init.request.session_id, courseId, moduleId, score },
        update: (cache) =>
          cache.modify({
            id: cache.identify({ __typename: "Module", id: moduleId }),
            fields: {
              quizScore: (oldScore) => (oldScore ? Math.max(oldScore, score) : score),
              quizInProgress: () => false,
            },
          }),
      });
      await saveCourseProgress(true);
    },
    [init, client, moduleId, courseId, saveCourseProgress],
  );

  return { init, initialize, saveQuizScore, runQuestionCount, questionCount };
}
