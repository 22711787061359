export const getFirstSectionIndex = (sections: any) => {
  const gettingStartedText = "Getting Started With SANS OnDemand";

  return sections.length > 1 &&
    sections[0].name === gettingStartedText &&
    sections[0].modules.length === 1
    ? 1
    : 0;
};

export const getFirstModuleIndex = (modules: any) => {
  const welcomeText = "Welcome to Your SANS OnDemand Course!";
  const introFlag = "INTRO";

  return modules.length > 1 && modules[0]?.name === welcomeText && modules[0]?.type === introFlag
    ? 1
    : 0;
};
