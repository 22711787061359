import { Reference, StoreObject } from "@apollo/client";
import { ReadFieldFunction } from "@apollo/client/cache/core/types/common";
import { moduleType } from "../__generated__/globalTypes";

export interface Progress {
  total: number;
  viewed: number;
  totalSlides: number;
  viewedSlides: number;
  percent?: number;
  percentQuizzes?: number;
  percentSlides?: number;
  isViewed: boolean;
  passedQuizzes: number;
  totalQuizzes: number;
}

interface LastView {
  resumeTime: string | null;
  slideId: string;
  dateTimeViewed: string;
}

export function sumProgress(
  childrenField: string,
  readField: ReadFieldFunction,
  isSection?: boolean,
): Progress {
  const children: readonly StoreObject[] = readField(childrenField) ?? [];
  const sums = children.reduce<Progress>(
    (sums, child) => {
      const progress = readField("progress", child) as Readonly<Progress>;

      sums.isViewed = sums.isViewed && progress.isViewed;

      if (progress.total) {
        sums.total += progress.total;
        sums.viewed += progress.viewed;
      }

      sums.totalSlides += progress.totalSlides;
      sums.viewedSlides += progress.viewedSlides;
      sums.passedQuizzes += progress.passedQuizzes;
      sums.totalQuizzes += progress.totalQuizzes;

      return sums;
    },
    {
      viewed: 0,
      total: 0,
      totalSlides: 0,
      viewedSlides: 0,
      isViewed: true,
      passedQuizzes: 0,
      totalQuizzes: 0,
    },
  );

  const quizScore: number | undefined = !isSection ? readField("quizScore") : undefined;
  const isQuiz = readField("type") === moduleType.QUIZ;

  return {
    ...sums,
    passedQuizzes: quizScore ? (quizScore >= 80 ? 1 : 0) : sums.passedQuizzes,
    totalQuizzes: isQuiz ? 1 : sums.totalQuizzes,
    percent: sums.total && (sums.viewed / sums.total) * 100,
    percentSlides: sums.totalSlides && (sums.viewedSlides / sums.totalSlides) * 100,
    percentQuizzes: sums.totalQuizzes && (sums.passedQuizzes / sums.totalQuizzes) * 100,
  };
}

export function lastView(childrenField: string, readField: ReadFieldFunction): LastView | null {
  const children: readonly StoreObject[] = readField(childrenField) ?? [];

  return children.reduce<LastView | null>((lastView, childRef) => {
    const childField = readField<LastView>("lastView", childRef) as
      | Readonly<LastView>
      | Reference
      | undefined;
    let childLastView: LastView | undefined = undefined;

    if ((childField as Reference)?.__ref) {
      childLastView = {
        dateTimeViewed: readField<string>("dateTimeViewed", childField) ?? "",
        resumeTime: readField<string>("resumeTime", childField) ?? null,
        slideId: readField<string>("slideId", childField) ?? "",
      };
    } else if (childField) {
      childLastView = childField as LastView;
    }

    if (!lastView || (childLastView && childLastView.dateTimeViewed > lastView.dateTimeViewed)) {
      return childLastView ?? null;
    }
    return lastView;
  }, null);
}
