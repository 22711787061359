import { styled } from "@mui/material/styles";
import { Stack } from "@mui/material";

export const StyledColorPicker = styled(Stack)(() => ({
  padding: 8,
}));

export const StyledColorWrapper = styled("div")(() => ({
  display: "grid",
  gridTemplateColumns: "repeat(4, 1fr)",
  gap: 8,
}));

export const StyledColorOption = styled("button")<{ color: string; active: boolean }>(
  ({ color, active, theme }) => ({
    height: 18,
    width: 18,
    background: color,
    borderRadius: 1,
    border: theme.palette.mode === "light" && color === "#ffffff" ? "1px solid #ccc" : "none",
    outline: active ? `1px solid ${theme.custom.palette.semantic.greyLight}` : "none",
    outlineOffset: 2,

    "&:focus": {
      outline: `1px solid ${theme.custom.palette.white}`,
    },
  }),
);
