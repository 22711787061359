import React, { useState, useEffect, useContext } from "react";
import { Stack } from "@mui/material";
import ReactQuill from "react-quill";

import { useNotes } from "./Notes.queries";
import { StyledBox, StyledButtons, Title } from "./EditNote.elements";
import ReactQuillToolbar, { modules, formats } from "./Toolbar";
import { NotesContext } from "../../context/notes-context";
import { isJSON } from "../../util/json";

import "react-quill/dist/quill.snow.css";

interface EditNoteProps {
  handleClose: () => void;
  slideId?: string;
}

export function EditNote({ handleClose, slideId }: EditNoteProps): JSX.Element | null {
  const { notes, saveNote } = useNotes();
  const [content, setContent] = useState<any>("");

  const { editingName, setIsEditorFocused, editorRef } = useContext(NotesContext);

  useEffect(() => {
    const note = notes.find((note) => note.slideId === slideId);

    if (note) {
      const state = isJSON(note.content) ? JSON.parse(note.content) : note.content;
      setContent(state);
    } else {
      setContent("");
    }
  }, [notes, slideId]);

  useEffect(() => {
    editorRef?.current?.focus();
  }, []);

  const save = () => {
    if (!slideId) return;

    const delta = editorRef?.current?.editor?.getContents();
    saveNote(slideId, JSON.stringify(delta));
    setIsEditorFocused(false);
    handleClose();
  };

  const handleCancel = () => {
    setIsEditorFocused(false);
    handleClose();
  };

  const handleQuillChange = () => {
    setContent(editorRef?.current?.editor?.getContents());
  };

  const handleQuillFocus = () => setIsEditorFocused(true);

  const handleQuillBlur = () => setIsEditorFocused(false);

  if (!slideId) return null;

  return (
    <div>
      <StyledBox>
        <Title variant="body2" textAlign="left">
          {editingName}
        </Title>
        <div className="text-editor">
          <ReactQuillToolbar />
          <ReactQuill
            preserveWhitespace
            theme="snow"
            ref={editorRef}
            value={content}
            modules={modules}
            formats={formats}
            onBlur={handleQuillBlur}
            onFocus={handleQuillFocus}
            onChange={handleQuillChange}
            aria-label="slide note"
          />
        </div>
        <Stack spacing={1} direction="row" justifyContent="right">
          <StyledButtons id="note_cancel_button" variant="outlined" onClick={handleCancel}>
            Cancel
          </StyledButtons>
          <StyledButtons id="note_save_button" variant="contained" onClick={save}>
            Save
          </StyledButtons>
        </Stack>
      </StyledBox>
    </div>
  );
}
