import React, { useEffect } from "react";
import { processLocation, login } from "@sans-tesseract/sdk-react";
import { Sans } from "../../util/sdk";

import { useHistory } from "react-router-dom";
import { sansHomeUrl } from "../../util/constants";

export function Authorizer(): JSX.Element | null {
  const history = useHistory<{ redirectPath: string }>();

  useEffect(() => {
    processLocation({}).then(({ queryArgs: { state, logout } }) => {
      const { id } = Sans.sdk.core.api.tokens;
      if (id) {
        history.push((state as string) || "/");
      } else if (logout) {
        window.open(sansHomeUrl, "_self");
      } else {
        login({ state: history.location.state?.redirectPath });
      }
    });
  }, [history]);

  return <div>Redirecting...</div>;
}
