import { styled } from "@mui/material/styles";
import { Box, Button, Typography } from "@mui/material";
import "material-icons/iconfont/material-icons.css";

const QUILL_ICON_COLOR = "#444";
const QUILL_HOVER_COLOR = "#06c";

export const StyledBox = styled(Box)(({ theme }) => ({
  padding: "0px 24px",
  paddingBottom: "24px",
  marginTop: theme.spacing(3),

  "& button": {
    fontSize: "0.625rem",
    fontWeight: 600,
    lineHeight: "16px",

    [theme.breakpoints.down("md")]: {
      fontSize: "0.875rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.625rem",
    },
  },

  ".text-editor": {
    border: `1px solid ${theme.palette.grey[theme.palette.mode === "dark" ? 800 : 100]}`,
    margin: theme.spacing(2, 0),
    background: theme.custom.palette.semantic.inputBg,
  },

  ".ql-toolbar": {
    border: "none",
    background: "none",
    display: "flex",

    button: {
      height: 22,
      "&.ql-active, &:hover": {
        svg: {
          fill: QUILL_HOVER_COLOR,
        },
      },
    },

    ".button-group": {
      "& button": {
        paddingLeft: 0,
        paddingRight: 0,
        width: "auto",
      },

      "& .chevron-down": {
        marginLeft: "-6px",
      },
    },

    "img, svg, span:after": {
      fill: QUILL_ICON_COLOR,
      color: QUILL_ICON_COLOR,
      filter: `invert(${theme.palette.mode === "dark" ? 1 : 0})`,
    },

    ".ql-formats": {
      marginRight: 0,
    },

    ".ql-separator": {
      padding: 0,
      borderRight: `1px solid ${theme.palette.grey[theme.palette.mode === "dark" ? 800 : 100]}`,
      width: 1,
      margin: theme.spacing(0, 1),
    },

    ".ql-size": {
      width: "auto",

      ".ql-picker-item": {
        textAlign: "left",
      },

      ".ql-picker-label": {
        paddingLeft: 0,
        paddingBottom: 8,
        paddingTop: 0,
        marginTop: -2,

        svg: {
          display: "none",
        },

        "&:before": {
          display: "none",
        },

        "&:after": {
          fontFamily: "Material Icons",
          fontSize: 18,
          color: QUILL_ICON_COLOR,
          content: '"format_size"',
        },
      },
    },
  },

  ".ql-editor": {
    "ol, ul, li": {
      paddingLeft: theme.spacing(1),
    },
  },

  ".ql-container": {
    border: "none",
  },
}));

export const Title = styled(Typography)(() => ({
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
}));

export const StyledButtons = styled(Button)(({ theme, variant }) => ({
  ...(variant === "contained" && {
    boxShadow: "none",
    background: theme.custom.palette.semantic.buttonContainedBg,
    border: `1px solid ${theme.custom.palette.semantic.buttonContainedBorder}`,
    color: theme.custom.palette.semantic.buttonContainedColor,
    ["&:hover"]: {
      boxShadow: "none",
      background: theme.custom.palette.semantic.buttonContainedHoverBg,
      border: `1px solid ${theme.custom.palette.semantic.buttonContainedHoverBorder}`,
      color: theme.custom.palette.semantic.buttonContainedHoverColor,
    },
    ["&:focus"]: {
      background: theme.custom.palette.semantic.buttonContainedFocusBg,
      border: `1px solid ${theme.custom.palette.semantic.buttonContainedFocusBorder}`,
      color: theme.custom.palette.semantic.buttonContainedFocusColor,
    },
  }),
  ...(variant === "outlined" && {
    background: "transparent",
    border: `1px solid ${theme.custom.palette.semantic.buttonOutlinedBorder}`,
    color: theme.custom.palette.semantic.buttonOutlinedColor,
    ["&:hover"]: {
      background: theme.custom.palette.semantic.buttonOutlinedHoverBg,
      border: `1px solid ${theme.custom.palette.semantic.buttonOutlinedHoverBorder}`,
      color: theme.custom.palette.semantic.buttonOutlinedHoverColor,
    },
    ["&:focus"]: {
      background: theme.custom.palette.semantic.buttonOutlinedFocusBg,
      border: `1px solid ${theme.custom.palette.semantic.buttonOutlinedFocusBorder}`,
      color: theme.custom.palette.semantic.buttonOutlinedFocusColor,
    },
  }),
}));
