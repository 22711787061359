import React from "react";
import { OndemandVideoOutlined } from "@mui/icons-material";
import { Button, Tooltip } from "@mui/material";
import { useViewport, View } from "../../Viewport/useViewport";
import { useAppState } from "../../../hooks/useAppState";

export default function ButtonExitSlides(): JSX.Element {
  const { changeView } = useViewport();
  const {
    setSlide,
    appState: { slideId },
  } = useAppState();
  const handleChangeView = () => {
    changeView(View.Video);
    if (slideId) {
      setSlide(slideId);
    }
  };
  return (
    <div style={{ display: "block", position: "absolute", right: 0, bottom: 0 }}>
      <Tooltip title="Change Layout to Video Player">
        <Button
          id="button_exit_slides"
          variant="outlined"
          startIcon={<OndemandVideoOutlined />}
          onClick={handleChangeView}
          tabIndex={1}
        >
          Video
        </Button>
      </Tooltip>
    </div>
  );
}
