import React, { useState } from "react";
import { useAnalyticsEvents } from "@atlaskit/analytics-next";
import { MenuItem, MenuList } from "@mui/material";
import { Settings } from "@mui/icons-material";
import SlowMotionVideoIcon from "@mui/icons-material/SlowMotionVideo";
import {
  ButtonActionsContainer,
  Container,
  SettingsButton,
  SettingsButtonContainer,
  StyledListItemIcon,
  StyledListItemText,
  StyledPaper,
} from "./ButtonSettings.elements";
import Hd from "@mui/icons-material/Hd";
import Sd from "@mui/icons-material/Sd";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useAppState } from "../../../../hooks/useAppState";

import SettingsMenu from "./SettingsMenu";
import { VideoResolution } from "../../../../__generated__/globalTypes";
import { usePersistedSettings } from "../../../../hooks/usePersistedSettings";
import { speedModifier } from "../../../../util/constants";

type ButtonSettingsProps = {
  playbackRate: number;
  onClickPlaybackRate: (value: number) => void;
  onClickPIP: () => void;
  hasCustomDuration: boolean;
  className?: string;
};

export interface SettingsValue {
  display: string;
  value: string | number;
}

export interface SettingsAction {
  icon: JSX.Element;
  name: string;
  displayName: string;
  handler: (arg: SettingsValue) => void;
  id: string;
  ariaLabel: string;
  divider: boolean;
  values: SettingsValue[];
  selectedValue: string;
}

export const videoQualities = [
  {
    display: "720",
    value: "HD",
  },
  {
    display: "480",
    value: "SD",
  },
  {
    display: "Auto",
    value: "auto",
  },
];

export const playbackRates = [
  {
    display: "0.7",
    value: 0.7,
  },
  {
    display: "1.0",
    value: 1.0,
  },
  {
    display: "1.2",
    value: 1.2,
  },
  {
    display: "1.5",
    value: 1.5,
  },
  {
    display: "2.0",
    value: 2.0,
  },
];

export default function ButtonSettings({
  playbackRate,
  onClickPlaybackRate,
  hasCustomDuration,
  className,
}: ButtonSettingsProps): JSX.Element {
  const {
    setVideoQuality,
    appState: { videoQuality },
  } = useAppState();
  const { quality } = usePersistedSettings();

  const { createAnalyticsEvent } = useAnalyticsEvents();
  const [showQualities, setShowQualities] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [selectedQuality, setSelectedQuality] = useState(
    videoQualities.find((qual) => qual.value === quality)?.display || "auto",
  );
  const [selectedPlayback, setSelectedPlayback] = useState(
    playbackRate?.toLocaleString("en-US", { minimumFractionDigits: 1 }),
  );
  const [selectedSetting, setSelectedSetting] = useState("");

  const handleShowSettings = (setting: string) => {
    setShowSettings(false);
    setShowQualities(true);
    setSelectedSetting(setting);
  };

  const handleChangeQuality = ({ value, display }: { value: string; display: string }) => {
    setSelectedQuality(display);
    setVideoQuality(value as VideoResolution);

    const action =
      value === VideoResolution.FHD
        ? "setVeryHighQuality"
        : value === VideoResolution.HD
        ? "setHighQuality"
        : value === VideoResolution.SD
        ? "setLowQuality"
        : "setAutoQuality";

    createAnalyticsEvent({
      feature: "playback",
      action,
    }).fire("telemetry");

    setTimeout(() => {
      setShowQualities(false);
    }, 600);
  };

  const handlePlaybackChange = (playback: { value: number; display: string }) => {
    setSelectedPlayback(playback.display);
    onClickPlaybackRate(playback.value);

    setTimeout(() => {
      setShowQualities(false);
    }, 400);
  };

  const handleSettingsClick = () => {
    if (showQualities) {
      setShowQualities(false);
      setShowSettings(false);
      return;
    }
    setShowSettings(!showSettings);
  };

  const handleSettingsMenuBack = () => {
    setShowSettings(true);
    setShowQualities(false);
  };

  const actions = [
    {
      icon: <SlowMotionVideoIcon />,
      name: `${hasCustomDuration ? (playbackRate / speedModifier).toFixed(1) : playbackRate}x`,
      displayName: "Playback Rate",
      handler: handlePlaybackChange,
      id: "button_playback_speed",
      ariaLabel: `Playback speed ${playbackRate}X`,
      divider: true,
      values: playbackRates,
      selectedValue: selectedPlayback,
    } as SettingsAction,
    {
      icon: videoQuality === "HD" ? <Hd /> : <Sd />,
      name: "Quality",
      displayName: "Quality",
      handler: handleChangeQuality,
      id: "button_video_quality",
      ariaLabel: `Video quality, ${videoQuality}`,
      divider: false,
      values: videoQualities,
      selectedValue: selectedQuality,
    } as SettingsAction,
  ];

  const currentMenuValues = actions.filter((setting) => setting.displayName === selectedSetting);

  return (
    <Container className={className}>
      <SettingsButtonContainer>
        <SettingsButton
          ariaLabel="Video settings"
          icon={<Settings />}
          FabProps={{ tabIndex: 1 }}
          onClick={() => handleSettingsClick()}
        />
        {showSettings && (
          <ButtonActionsContainer>
            <StyledPaper>
              <MenuList>
                {actions.map((action) => (
                  <MenuItem
                    key={action.name}
                    onClick={() => handleShowSettings(action.displayName)}
                    divider={action.divider}
                    aria-label={action.ariaLabel}
                  >
                    <StyledListItemIcon>{action.icon}</StyledListItemIcon>
                    <StyledListItemText>{action.name}</StyledListItemText>
                    <ArrowForwardIosIcon sx={{ color: "white", fontSize: "0.75rem" }} />
                  </MenuItem>
                ))}
              </MenuList>
            </StyledPaper>
          </ButtonActionsContainer>
        )}
        {showQualities && (
          <SettingsMenu
            setting={currentMenuValues}
            handleSettingsMenuBack={handleSettingsMenuBack}
          />
        )}
      </SettingsButtonContainer>
    </Container>
  );
}
