import { TypePolicy } from "@apollo/client";
import { expirationInfo } from "../../util/expiration";

export default {
  fields: {
    expiration: {
      read(_, { readField }) {
        return expirationInfo(readField("expires") as string);
      },
    },
  },
} as TypePolicy;
