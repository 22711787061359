import React from "react";
import { PlayArrow, Pause } from "@mui/icons-material";
import ButtonIconContainer from "../../../ButtonIconContainer/ButtonIconContainer";

type ButtonPlayPauseProps = {
  isPlaying: boolean;
  onClickPlayPause: () => void;
};

export default function ButtonPlayPause({
  isPlaying,
  onClickPlayPause,
}: ButtonPlayPauseProps): JSX.Element {
  return (
    <ButtonIconContainer
      onClick={onClickPlayPause}
      ariaLabel={isPlaying ? "pause (space)" : "play (space)"}
      tooltip={isPlaying ? "Pause (space)" : "Play (space)"}
      id="button_play_pause"
    >
      {isPlaying ? <Pause /> : <PlayArrow />}
    </ButtonIconContainer>
  );
}
