import { Button, FormControl } from "@mui/material";
import React from "react";
import { useAppState } from "../../hooks/useAppState";
import { useErrorHandler } from "../../hooks/useErrorHandler";
import { Buttons, Label, SurveyContainer } from "./Survey.elements";

export const Survey = (): JSX.Element => {
  const { section, nextModule } = useAppState();
  const { throwError } = useErrorHandler();

  const openEval = (): void => {
    if (section?.surveyUrl) {
      window.open(section.surveyUrl, "_blank");
      nextModule(false, true);
    } else {
      throwError("Couldn't find section");
    }
  };

  return (
    <SurveyContainer>
      <FormControl sx={{ width: "50%" }}>
        <Label id="eval-label">We want to hear from you!</Label>
        <Label>
          Please take a moment to evaluate your experience and let us know what we did right or
          where we can improve.
        </Label>
        <Label>
          The SANS Institute is committed to continuously improving the quality of the courseware
          and instructions to ensure our students have the best experience possible.
        </Label>
        <Buttons>
          <Button
            color="primary"
            variant="contained"
            style={{ width: "10em" }}
            onClick={() => openEval()}
          >
            Start Evaluation
          </Button>
        </Buttons>
      </FormControl>
    </SurveyContainer>
  );
};
