import React from "react";
import { MenuItem, MenuList } from "@mui/material";
import {
  ListContainer,
  StyledListItemText,
  StyledPaper,
  StyledTypography,
} from "./ButtonSettings.elements";
import DoneIcon from "@mui/icons-material/Done";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { SettingsAction } from "./ButtonSettings";

type SettingMenuProps = {
  setting: SettingsAction[];
  handleSettingsMenuBack: () => void;
};

export default function SettingsMenu({
  setting,
  handleSettingsMenuBack,
}: SettingMenuProps): JSX.Element {
  return (
    <ListContainer id={setting[0].id}>
      <StyledPaper>
        <MenuList>
          <MenuItem key={setting[0].displayName} onClick={handleSettingsMenuBack}>
            <ArrowBackIosIcon sx={{ color: "white", fontSize: "0.75rem" }} />
            <StyledListItemText sx={{ paddingLeft: "8px" }}>
              {setting[0].displayName}
            </StyledListItemText>
          </MenuItem>
          {setting[0].values.map((value) => (
            <MenuItem key={value.display} onClick={() => setting[0].handler(value)}>
              <StyledListItemText>{value.display}</StyledListItemText>
              {setting[0].selectedValue === value.display && (
                <StyledTypography>
                  <DoneIcon />
                </StyledTypography>
              )}
            </MenuItem>
          ))}
        </MenuList>
      </StyledPaper>
    </ListContainer>
  );
}
