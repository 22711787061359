import React from "react";
import { Grid } from "@mui/material";
import { HelpAndSupportHeader } from "./HelpAndSupportHeader";
import { ZopimContainer, Title } from "./ZopimChat.elements";
import { useUserInfo } from "@sans-tesseract/sdk-react";
import { useAppState } from "../../hooks/useAppState";

export const stripCommas = (str: string) => {
  return str.replace(/,/g, "");
};

export function ZopimChat() {
  const userInfo = useUserInfo();
  const { course, module, slide } = useAppState();

  const zopimTags = {
    firstName: userInfo.given_name,
    lastName: userInfo.family_name,
    email: userInfo.email,
    shortName: course?.shortName,
    version: course?.fullVersion,
    loOrder: module?.fullNumber,
    slideTitle: stripCommas(slide?.name || ""),
    slideIndex: slide?.number,
  };

  localStorage.setItem("zopimTags", JSON.stringify(zopimTags));

  if (!userInfo) return null;

  return (
    <>
      <Grid container flexDirection="row">
        <HelpAndSupportHeader>
          <div className="header-wrapper">
            <Title className="tech-support-header">Chat</Title>
          </div>
        </HelpAndSupportHeader>
      </Grid>
      <ZopimContainer container flexDirection="row">
        <div id="zopim-widget"></div>
      </ZopimContainer>
    </>
  );
}
