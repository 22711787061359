import React, { useState } from "react";
import { Skeleton } from "@mui/material";
import { useAppState } from "../../hooks/useAppState";
import { InstructorModal } from "./InstructorModal";

import { InstructorContainer, InstructorButton } from "./Instructor.elements";

export type Resource = {
  title: string;
  linkJson: string;
  resourceHost: string;
  __typename: string;
};

export const getResourceUrl = (resource: Resource) => JSON.parse(resource.linkJson).url;

export function Instructor(): JSX.Element {
  const {
    appState: { course },
  } = useAppState();
  const [modalOpen, setModalOpen] = useState(false);

  if (!course?.contributors) {
    return (
      <Skeleton variant="rectangular" height={50} aria-label="Loading instructor information" />
    );
  }

  return (
    <InstructorContainer data-testid="instructor">
      <InstructorButton variant="text" onClick={() => setModalOpen(true)}>
        Instructor Information and Course Resources
      </InstructorButton>
      <InstructorModal modalOpen={modalOpen} setModalOpen={setModalOpen} course={course} />
    </InstructorContainer>
  );
}
