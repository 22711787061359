import React, { useRef } from "react";
import { useAppState } from "../../hooks/useAppState";
import { screenReaderSlideTitle } from "../../util/screenReader";
import { useTabs } from "../Course/useTabs";
import { useFocus } from "../../hooks/useFocus";
import {
  Highlight,
  SearchField as SectionTitle,
  SearchSectionContainer,
  SlideField,
} from "./Search.elements";
import SlideTitle from "./SlideTitle";
import { useTouched } from "../../hooks/useTouched";
import { SearchQuery_search_foundFields } from "./__generated__/SearchQuery";
import { sanitize } from "../../util/html";

interface SearchResultProps {
  field: SearchQuery_search_foundFields;
  index: number;
  query: string;
}

const ResultSection = ({ field, index, query }: SearchResultProps) => {
  const { setSlide } = useAppState();
  const { changeTab } = useTabs();
  const ref = useRef<HTMLDivElement>(null);

  const [touched] = useTouched({ ref, resetTrigger: query });
  const [focus, setFocus] = useFocus({ ref, size: field.slides.length });

  const fieldNameMap: { [key: string]: string } = {
    slide_notes: "Coursebook",
    slide_text: "Slide text",
    content: "Notes",
  };

  const fieldTabMap: { [key: string]: number } = {
    slide_notes: 1,
    slide_text: -1,
    content: 2,
  };

  const openSlide = (slideId: string, fieldName: string) => {
    setSlide(slideId);
    const tab = fieldTabMap[fieldName];

    if (tab >= 0) changeTab(tab);
  };

  const handleClick = (slideId: string, fieldName: string) => () => {
    openSlide(slideId, fieldName);
  };

  const handleKeyDown = (slideId: string, fieldName: string) => (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      openSlide(slideId, fieldName);
    }
  };

  return (
    <SearchSectionContainer key={field.fieldName}>
      {index > 0 && <hr />}
      <SectionTitle>{fieldNameMap[field.fieldName]}</SectionTitle>
      <div ref={ref}>
        {field.slides.map((slide, i) => {
          return (
            <SlideField key={slide.slideId}>
              <SlideTitle
                index={i}
                setFocus={setFocus}
                focus={touched && focus === i}
                tabIndex={focus === i ? 2 : -1}
                onKeyDown={handleKeyDown(slide.slideId, field.fieldName)}
                onClick={handleClick(slide.slideId, field.fieldName)}
                ariaLabel={`Go to slide: ${screenReaderSlideTitle(
                  slide.number + ": " + slide.name,
                )}`}
              >
                Slide {slide.number}: {slide.name}
              </SlideTitle>
              <Highlight dangerouslySetInnerHTML={{ __html: sanitize(slide.highlight) }} />
            </SlideField>
          );
        })}
      </div>
    </SearchSectionContainer>
  );
};

export default ResultSection;
