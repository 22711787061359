import { styled } from "@mui/material/styles";
import { Box, Typography, IconButton, Stack } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

export const Container = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

export const NoteWrapper = styled(Box)(({ theme }) => ({
  padding: "16px 24px",
  position: "relative",

  "&.overflow": {
    maxHeight: 230,
    overflow: "hidden",

    "&:after": {
      content: "''",
      position: "absolute",
      left: 0,
      bottom: 0,
      height: 100,
      width: "100%",
      background: theme.custom.gradients.rowBgFade,
    },
  },

  "&:nth-of-type(2n+1)": {
    backgroundColor: theme.custom.palette.semantic.rowAlternatingBg,

    "&.overflow:after": {
      background: theme.custom.gradients.rowAlternatingBgFade,
    },
  },
  "&.active": {
    backgroundColor: theme.custom.palette.semantic.rowActiveBg,
    "& p.MuiTypography-root": {
      color: theme.custom.palette.white,
    },
    "& button.MuiButtonBase-root": {
      color: theme.custom.palette.white,
    },
  },
}));

export const NoteContainer = styled(Stack)(() => ({
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-start",
}));

export const NoteTitle = styled(Typography)(({ theme }) => ({
  textAlign: "left",
  color: theme.custom.palette.semantic.blue,
  cursor: "pointer",
  fontSize: "0.875rem",
  fontWeight: 600,
  display: "-webkit-box",
  "-webkit-line-clamp": "2",
  "-webkit-box-orient": "vertical",
  overflow: "hidden",
}));

export const NoteIconContainer = styled(Stack)(() => ({
  flexDirection: "row",
  display: "flex",
}));

export const NoteIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.custom.palette.semantic.buttonIconColor,
  marginBottom: theme.spacing(2),
  "&:hover": {
    color: theme.custom.palette.semantic.buttonIconHoverColor,
  },
  "&:focus": {
    color: theme.custom.palette.semantic.buttonIconFocusColor,
  },
  "&:disabled": {
    color: theme.custom.palette.semantic.buttonIconDisabledColor,
  },
  "> svg": {
    fontSize: "16px",
  },
}));

export const NoteBody = styled(Typography)(({ theme }) => ({
  fontSize: "0.875rem",
  textAlign: "left",
  color: theme.palette.text.primary,
  fontWeight: 400,
  whiteSpace: "pre-wrap",

  p: {
    margin: theme.spacing(1, 0),
  },
}));

export const NotesButton = styled(LoadingButton)(({ theme }) => ({
  fontSize: "0.625rem",
  color: theme.custom.palette.semantic.buttonOutlinedColor,
  borderColor: theme.custom.palette.semantic.buttonOutlinedBorder,
  marginBottom: theme.spacing(2),
  "&:hover": {
    color: theme.custom.palette.semantic.buttonOutlinedHoverColor,
    borderColor: theme.custom.palette.semantic.buttonOutlinedHoverBorder,
  },
  "&:focus": {
    color: theme.custom.palette.semantic.buttonOutlinedFocusColor,
    borderColor: theme.custom.palette.semantic.buttonOutlinedFocusBorder,
  },
  "&:disabled": {
    color: theme.custom.palette.semantic.buttonOutlinedDisabledColor,
    borderColor: theme.custom.palette.semantic.buttonOutlinedDisabledBorder,
  },
}));

export const ButtonWrapper = styled(Stack)(({ theme }) => ({
  paddingLeft: "24px",
  paddingRight: "24px",
  justifyContent: "flex-start",
  gap: "8px",
  [theme.breakpoints.down("md")]: {
    justifyContent: "flex-end",
  },
}));
