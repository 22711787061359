import { makeVarPersisted } from "../utils";
import { TypePolicy } from "@apollo/client";

export const volume = makeVarPersisted(50, "PLAYER_VOLUME");
export const autoplay = makeVarPersisted(false, "PLAYER_AUTOPLAY");
export const captions = makeVarPersisted(false, "PLAYER_CAPTIONS");
export const playback = makeVarPersisted(1, "PLAYER_PLAYBACK");
export const quality = makeVarPersisted("auto", "PLAYER_QUALITY");
export const coursebookFontSize = makeVarPersisted(14, "COURSEBOOK_FONTSIZE");
export const courseTab = makeVarPersisted(0, "COURSE_TAB");
export const muted = makeVarPersisted(false, "MUTED");
export const dismissedMessages = makeVarPersisted<string[]>([], "DISMISSED_MESSAGES");
export const mode = makeVarPersisted("", "MODE");
export const sidebarWidth = makeVarPersisted(0, "SIDEBAR_WIDTH");

export type ModeType = "light" | "dark";

export const setVolume = (vol: number): void => {
  volume(vol);
};

export const setAutoplay = (isEnabled: boolean): void => {
  autoplay(isEnabled);
};

export const setCaptions = (caps: boolean): void => {
  captions(caps);
};

export const setPlayback = (rate: number): void => {
  playback(rate);
};

export const setQuality = (qual: string): void => {
  quality(qual);
};

export const setCoursebookFontSize = (fontSize: number): void => {
  coursebookFontSize(fontSize);
};

export const setCourseTab = (tab: number): void => {
  courseTab(tab);
};

export const setMuted = (isMuted: boolean): void => {
  muted(isMuted);
};

export const setDismissedMessages = (messages: string[]): void => {
  dismissedMessages(messages);
};

export const setMode = (value: ModeType): void => {
  mode(value);
};

export const setSidebarWidth = (value: number): void => {
  sidebarWidth(value);
};

export default {
  fields: {
    volume: {
      read() {
        return volume();
      },
    },
    autoplay: {
      read() {
        return autoplay();
      },
    },
    captions: {
      read() {
        return captions();
      },
    },
    playback: {
      read() {
        return playback();
      },
    },
    quality: {
      read() {
        return quality();
      },
    },
    coursebookFontSize: {
      read() {
        return coursebookFontSize();
      },
    },
    courseTab: {
      read() {
        return courseTab();
      },
    },
    muted: {
      read() {
        return muted();
      },
    },
    dismissedMessages: {
      read() {
        return dismissedMessages();
      },
    },
    mode: {
      read() {
        return mode();
      },
    },
    sidebarWidth: {
      read() {
        return sidebarWidth();
      },
    },
  },
} as TypePolicy;
