import React from "react";
import { Replay } from "@mui/icons-material";
import ButtonIconContainer from "../../../ButtonIconContainer/ButtonIconContainer";

type ButtonReplayProps = {
  onClickReplay: () => void;
};

export default function ButtonReplay({ onClickReplay }: ButtonReplayProps): JSX.Element {
  return (
    <ButtonIconContainer
      onClick={onClickReplay}
      ariaLabel="replay"
      tooltip="Replay (j)"
      id="button_replay"
    >
      <Replay />
    </ButtonIconContainer>
  );
}
