import React, { useContext } from "react";
import { DialogWrapper } from "./HelpAndSupportDialog.elements";
import { ZopimChat } from "./HelpAndSupportPages/ZopimChat";
import { HelpAndSupportMainPage } from "./HelpAndSupportPages/HelpAndSupportMainPage";
import { TechSupportMessageDialog } from "./HelpAndSupportPages/TechSupportMessageDialog";
import { HasbotContext, HasbotContextType } from "../context/hasBot-context";
import FocusLock from "react-focus-lock";

interface HelpAndSupportDialogProps {
  className?: string;
}

function HelpAndSupportDialog({ className }: HelpAndSupportDialogProps): JSX.Element {
  const { techSupportActive, zopimChatActive } = useContext(HasbotContext) as HasbotContextType;

  return (
    <FocusLock>
      <DialogWrapper
        id="DIALOG-WRAPPER"
        role="dialog"
        aria-label="Help & Support"
        className={className}
      >
        {techSupportActive ? (
          <TechSupportMessageDialog />
        ) : zopimChatActive ? (
          <ZopimChat />
        ) : (
          <HelpAndSupportMainPage />
        )}
      </DialogWrapper>
    </FocusLock>
  );
}

export default HelpAndSupportDialog;
