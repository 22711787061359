import React from "react";
import { ClosedCaption, ClosedCaptionOff } from "@mui/icons-material";
import ButtonIconContainer from "../../../ButtonIconContainer/ButtonIconContainer";

export default function ButtonClosedCaptions({
  captionsEnabled,
  onClickCaptions,
}: {
  captionsEnabled: boolean;
  onClickCaptions: () => void;
}): JSX.Element {
  return (
    <ButtonIconContainer
      ariaLabel={`Closed captions, ${captionsEnabled ? "enabled" : "disabled"}`}
      tooltip="Closed Captions (c)"
      onClick={onClickCaptions}
      id="button_closed_captions"
    >
      {captionsEnabled ? <ClosedCaption /> : <ClosedCaptionOff />}
    </ButtonIconContainer>
  );
}
