import { styled } from "@mui/material/styles";
import { Box, IconButton } from "@mui/material";

export const Container = styled(Box)(({ theme }) => ({
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  position: "absolute",
  padding: theme.spacing(0),
  width: "100%",
  height: "100%",
  zIndex: 1,
  opacity: 1,
  transition: "0.5s",
  "&.hidden": {
    opacity: 0,
    transition: "0.5s",
  },
  "&:before": {
    content: "''",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  "&:focus-visible": {
    outline: "none",
  },
}));

export const SkipButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: `rgba(0, 0, 0, 0.15)`,
  border: `1px solid rgba(255, 255, 255, 0.2)`,
  borderRadius: 0,
  padding: theme.spacing(1),
  color: `rgba(255, 255, 255, 1)`,
  transition: "0.2s",

  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(0.5),
  },

  "&:hover, &:focus": {
    backgroundColor: `rgba(128, 128, 128, 0.7)`,
  },

  "&.hidden": {
    visibility: "hidden",
    flexShrink: 0,
  },
}));
