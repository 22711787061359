import React, { useContext, useEffect } from "react";
import { FreeFocusInside } from "react-focus-lock";
import QuestionMarkOutlinedIcon from "@mui/icons-material/QuestionMarkOutlined";
import { HasbotContext } from "../../context/hasBot-context";
import { HelpAndSupportApp } from "../../haswidget/HelpAndSupportApp";
import { useProgress } from "../../hooks/useProgress";
import { HelpButton, HelpContainer, HelpText, Wrapper } from "./HelpAndSupport.elements";

export function HelpAndSupport() {
  const { saveProgressServiceWorker } = useProgress();

  const { showDialog, setShowDialog, openDialogBtnRef, unreadChatMessages } = useContext(HasbotContext);

  const toggleDialog = () => setShowDialog((prevState: boolean) => !prevState);

  const handleClick = () => {
    toggleDialog();
    const zopimHours = new Event("updateHours");
    document.dispatchEvent(zopimHours);
  };

  const handleKeyUp = (event: React.KeyboardEvent) => {
    const actionMap = {
      Enter: toggleDialog,
    } as const;

    type Key = keyof typeof actionMap;
    const key = event.key as Key;

    return actionMap[key]?.();
  };

  useEffect(() => {
    const save = () => saveProgressServiceWorker();

    window.addEventListener("beforeunload", save);

    return () => {
      window.removeEventListener("beforeunload", save);
    };
  }, [saveProgressServiceWorker]);

  return (
    <Wrapper>
      <FreeFocusInside className="focusLockWrapper">
        <HelpContainer>
          <HelpButton
            id="helpDialog"
            aria-label="Open Help Dialog"
            onClick={handleClick}
            onKeyUp={handleKeyUp}
            ref={openDialogBtnRef}
            data-unread-messages={unreadChatMessages}
          >
            <div
              id="unreadMessages"
              className={unreadChatMessages > 0 && !showDialog ? "hasMessage" : "noMessage"}
            >
              {unreadChatMessages}
            </div>
            <QuestionMarkOutlinedIcon id="helpDialogIcon" style={{ fontSize: "20px" }} />
            <HelpText>Help</HelpText>
          </HelpButton>
        </HelpContainer>
      </FreeFocusInside>
      <HelpAndSupportApp />
    </Wrapper>
  );
}
