export const errorMsg = "Error storing telemetry";

class TelemetryException extends Error {
  name: string;
  message: string;

  constructor(message: string) {
    super();

    this.name = "TelemetryException";
    this.message = message;
  }
}

export default TelemetryException;
