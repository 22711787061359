import { styled } from "@mui/material/styles";
import { Toolbar, Typography, Button } from "@mui/material";

const spaceBetween = "space-between";

export const HeaderWrapper = styled("header")(({ theme }) => ({
  background: theme.palette.mode === "dark" ? "#16181F" : theme.custom.palette.light,
  boxShadow: theme.custom.shadows[1],
  padding: "0.5rem 1.5rem",
}));

export const ToolbarWrapper = styled(Toolbar)({
  display: "flex",
  justifyContent: spaceBetween,
  padding: "0 !important",
});

export const ToolbarInner = styled("div")({
  display: "flex",
});

export const ImageWrapper = styled("div")(({ theme }) => ({
  marginRight: "2rem",
  alignItems: "center",
  display: "flex",
  "& > svg path": {
    fill: theme.palette.mode === "dark" ? theme.custom.palette.light : null,
  },
}));

export const TypeWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: spaceBetween,
  alignItems: "center",
  "& > h5": {
    textAlign: "left",
    marginRight: "1rem",
    "& .ondemand-text": {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  },
}));

export const HeaderButton = styled(Button)(({ theme }) => ({
  marginRight: "16px",
  height: "2.5rem",
  alignSelf: "center",
  fontWeight: 600,
  fontSize: "1rem",
  lineHeight: "24px",
  minWidth: "155px",
  borderColor: theme.custom.palette.semantic.buttonOutlinedBorder,
  color: theme.custom.palette.semantic.buttonOutlinedColor,

  "&:hover": {
    borderColor: theme.custom.palette.semantic.buttonOutlinedHoverBorder,
    color: theme.custom.palette.semantic.buttonOutlinedHoverColor,
  },

  "&:focus": {
    borderColor: theme.custom.palette.semantic.buttonOutlinedFocusBorder,
    color: theme.custom.palette.semantic.buttonOutlinedFocusColor,
  },

  "& .message-text": {
    whiteSpace: "nowrap",
  },

  [theme.breakpoints.down("sm")]: {
    minWidth: "55px",
    marginRight: "15px",
    "& .message-text": {
      display: "none",
    },
  },
}));

export const MessageButton = styled(HeaderButton)(({ theme }) => ({
  "&.notificationsButton": {
    [theme.breakpoints.down("sm")]: {
      border: "none",
      padding: "8px",

      "& .message-text": {
        display: "none",
      },

      "& span": {
        marginRight: "0",
        overflow: "visible",
      },

      "& svg": {
        width: "auto",
        height: "2em",
      },
    },
  },
}));

interface CourseNameProps {
  component: string;
  variant: string;
}

export const CourseName = styled(Typography)<CourseNameProps>(({ theme }) => ({
  fontFamily: "Poppins, sans-serif",
  fontSize: "1.125rem",
  fontWeight: 400,
  flexGrow: 1,
  textAlign: "left",
  marginRight: "1rem",
  color: theme.custom.palette.semantic.blueGrey,
  lineHeight: "24px",
  "& .ondemand-course-name__text": {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  "& .ondemand-course-number__text": {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  "& .ondemand-course-library": {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

export const HeaderSkeleton = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: spaceBetween,
  minHeight: "51px",
});
