import React from "react";
import { Avatar, Box, Modal } from "@mui/material";
import { InsertDriveFile } from "@mui/icons-material";
import { useAnalyticsEvents } from "@atlaskit/analytics-next";

import { ModalHeader, StyledBox, CloseButton, ButtonContainer } from "./InstructorModal.elements";
import { CourseFragment } from "../../hooks/__generated__/CourseFragment";
import {
  BioContainer,
  ResourceContainer,
  ResourceItem,
  StyledLink,
  StyledName,
} from "./Instructor.elements";
import { getResourceUrl } from "./Instructor";

type InstructorModalProps = {
  modalOpen: boolean;
  setModalOpen: (value: boolean) => void;
  course: CourseFragment;
};

export const InstructorModal = ({
  modalOpen,
  setModalOpen,
  course,
}: InstructorModalProps): JSX.Element => {
  const { createAnalyticsEvent } = useAnalyticsEvents();

  const closeModal = () => setModalOpen(false);

  const handleResourceClick = () => {
    createAnalyticsEvent({
      feature: "resource",
      action: "downloadHandout",
    }).fire("telemetry");
  };

  const resources = course.resources || null;

  return (
    <Modal open={modalOpen} onClose={closeModal}>
      <StyledBox id="instructor-modal" data-testid="instructor-modal">
        <Box>
          <ModalHeader>Instructor</ModalHeader>
          {course.contributors.map((contrib) => (
            <BioContainer key={contrib.profile.name}>
              <Avatar
                src={contrib.profile.image}
                alt={contrib.profile.name}
                sx={{ width: 48, height: 48 }}
              />
              <Box>
                <StyledName>{contrib.profile.name}</StyledName>
                {contrib?.profile?.link && (
                  <StyledLink
                    onClick={() => window.open(`${contrib.profile.link}`)}
                    rel="noreferrer noopener"
                    target="_blank"
                    id="instructor-profile"
                  >
                    View Profile
                  </StyledLink>
                )}
              </Box>
            </BioContainer>
          ))}
        </Box>
        <hr />
        <Box>
          <ModalHeader>Course Resources</ModalHeader>
          <ResourceContainer>
            <ul id="course-resources" data-testid="course-resources">
              {resources?.map((resource) => {
                const resourceTitle = resource.title;
                const resourceLink = getResourceUrl(resource);
                const resourceId = resource.title.replace(/\s+/g, "-").toLowerCase();

                return (
                  <ResourceItem key={resourceTitle} onClick={handleResourceClick}>
                    <InsertDriveFile />
                    <a id={resourceId} href={resourceLink} target="_blank" rel="noreferrer">
                      {resourceTitle}
                    </a>
                  </ResourceItem>
                );
              })}
            </ul>
          </ResourceContainer>
        </Box>
        <ButtonContainer>
          <CloseButton variant="contained" onClick={closeModal}>
            Close
          </CloseButton>
        </ButtonContainer>
      </StyledBox>
    </Modal>
  );
};
