import { ApolloCache, gql, useApolloClient, useLazyQuery } from "@apollo/client";
import { ErrorSeverity, useErrorHandler } from "../../hooks/useErrorHandler";
import { CourseFragment } from "../../hooks/__generated__/CourseFragment";
import {
  COURSE_FRAGMENT_QUERY,
  TRAINING_INSTANCE_FRAGMENT,
  useAppState,
} from "../../hooks/useAppState";
import { CourseDetailsQuery } from "./__generated__/CourseDetailsQuery";
import { TrainingInstanceFragment } from "../../hooks/__generated__/TrainingInstanceFragment";
import { InstanceDetailsQuery } from "./__generated__/InstanceDetailsQuery";

export const COURSE_DETAILS_QUERY = gql`
  query CourseDetailsQuery($courseId: Int!) {
    course(courseId: $courseId) {
      id
      courseType
      shortName
      name
      trainingId
      availableResolutions
      fullVersion
      version
      complete
      sections {
        id
        name
        number
        surveyUrl
        modules {
          id
          name
          type
          sectionId
          fullNumber
          duration
          slides {
            id
            moduleId
            name
            number
            fullNumber
            duration
          }
        }
      }
    }
  }
`;

export const INSTANCE_DETAILS_QUERY = gql`
  query InstanceDetailsQuery($courseId: Int!) {
    instance(courseId: $courseId) {
      id
      expirationDateTime
      userId
      progress {
        instanceId
        viewedSeconds
        totalSeconds
        viewedSlides
        totalSlides
        totalQuizzes
        passedQuizzes
        complete
        lastView {
          slideId
          resumeTime
          dateTimeViewed
        }
        moduleProgress {
          id
          name
          type
          fullNumber
          viewedSlides
          totalSlides
          quizScore
        }
      }
    }
  }
`;

function updateCourse(course: CourseFragment, cache: ApolloCache<object>): void {
  cache.writeQuery({
    id: `Course${course.trainingId}`,
    query: COURSE_FRAGMENT_QUERY,
    data: { course },
  });
}

function updateInstance(instance: TrainingInstanceFragment, cache: ApolloCache<object>): void {
  cache.writeFragment({
    id: `TrainingInstance${instance.id}`,
    fragment: TRAINING_INSTANCE_FRAGMENT,
    data: { instance },
  });
}

export function useCourseDetails(courseId: number, instanceId?: string) {
  const { reportError } = useErrorHandler();
  const client = useApolloClient();
  const { setInstance } = useAppState();

  const [runCourseDetailsQuery, { data: courseData, loading: courseLoading }] =
    useLazyQuery<CourseDetailsQuery>(COURSE_DETAILS_QUERY, {
      errorPolicy: "all",
      variables: { courseId },
      onError: (error) => {
        reportError(ErrorSeverity.LOW, "Failed to fetch course details", { errors: error });
      },
    });

  const [runInstanceDetailsQuery, { data: instanceData, loading: instanceLoading }] =
    useLazyQuery<InstanceDetailsQuery>(INSTANCE_DETAILS_QUERY, {
      errorPolicy: "all",
      variables: { courseId },
      onError: (error) => {
        reportError(ErrorSeverity.LOW, "Failed to fetch instance details", { errors: error });
      },
    });

  const getCourseDetails = async () => {
    const { data: details } = await runCourseDetailsQuery();
    if (details) {
      updateCourse({ ...details.course, messages: [] }, client.cache);
    }
    if (!instanceId) {
      const { data: instanceDetails } = await runInstanceDetailsQuery();
      if (instanceDetails) {
        updateInstance(instanceDetails.instance, client.cache);
        setInstance(instanceDetails.instance.id);
      }
    }
  };

  return {
    getCourseDetails,
    gotDetails: courseData && (instanceId || instanceData),
    loadingDetails: courseLoading || instanceLoading,
  };
}
