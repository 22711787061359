import { useApolloClient } from "@apollo/client";
import gql from "graphql-tag";
import { useCallback, useEffect, useState } from "react";
import { useAppState } from "../../hooks/useAppState";
import { GetBook } from "./__generated__/GetBook";
import { useTestMode } from "../../hooks/useTestMode";

export const GET_BOOK_QUERY = gql`
  query GetBook($moduleId: String!, $slideNo: Int!) {
    bookText(moduleId: $moduleId, slideNo: $slideNo)
  }
`;

export function useBook(): {
  bookText: string;
  loading: boolean;
} {
  const { slide, module } = useAppState();
  const [bookText, setBookText] = useState("");
  const [loading, setLoading] = useState(false);
  const client = useApolloClient();

  const { testMode, testBooks } = useTestMode();

  useEffect(() => {
    if (testMode) {
      if (!testBooks) setLoading(true);
      else setLoading(false);
    }
  }, [testMode, testBooks]);

  useEffect(() => {
    if (testMode) setBookText(testBooks[(slide?.number ?? 1) - 1]);
  }, [testMode, testBooks, slide]);

  const getBookText = useCallback(async () => {
    if (testMode) return;
    if (!slide || !module) return setBookText("");

    setLoading(true);
    const { data } = await client.query<GetBook>({
      query: GET_BOOK_QUERY,
      variables: { moduleId: module.id, slideNo: slide.number },
    });

    if (data) {
      setLoading(false);
      setBookText(data.bookText);
    }
  }, [client, slide, testMode]);

  useEffect(() => {
    getBookText();
  }, [getBookText]);

  return { bookText, loading };
}
