import React from "react";
import { Stack, AccordionDetails, AccordionSummary, Typography } from "@mui/material";

import DomainVerificationIcon from "@mui/icons-material/DomainVerification";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";

import {
  SetupAccordion,
  SetupStep,
  SetupLink,
  SetupStepContent,
} from "./SetupInstructions.elements";
import { MyCoursesQuery_myCourses_rosterUsers_shippingTracking } from "./__generated__/MyCoursesQuery";

const SetupInstructions = ({
  shortName,
  hasMyLabs,
  shippingTracking,
  quickstartGuideUrl,
}: {
  shortName?: string;
  hasMyLabs: boolean;
  shippingTracking: MyCoursesQuery_myCourses_rosterUsers_shippingTracking | null;
  quickstartGuideUrl?: string;
}) => {
  return (
    <SetupAccordion defaultExpanded elevation={0} variant="outlined">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Setup Instructions</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack gap={1} direction="column">
          {shortName && (
            <SetupStep>
              <DomainVerificationIcon className="icon" />
              <SetupStepContent>
                <SetupLink
                  target="_blank"
                  href={`https://sans.org/${shortName}#laptop-requirements`}
                >
                  Review the system requirements
                </SetupLink>{" "}
                for this course and make sure you have a properly configured system.
              </SetupStepContent>
            </SetupStep>
          )}
          {quickstartGuideUrl && (
            <SetupStep>
              <PictureAsPdfIcon className="icon" />
              <SetupStepContent>
                <SetupLink target="_blank" href={quickstartGuideUrl}>
                  Download the Quickstart guide
                </SetupLink>{" "}
                for help with the setup process.
              </SetupStepContent>
            </SetupStep>
          )}
          {shippingTracking && (
            <SetupStep>
              <LocalShippingIcon className="icon" />
              <SetupStepContent>
                {shippingTracking?.trackingNumber ? (
                  <>
                    <SetupLink
                      target="_blank"
                      href={`https://www.ups.com/tracking/tracking.cgi?tracknum=${shippingTracking.trackingNumber}`}
                    >
                      Track your course materials shipment
                    </SetupLink>
                    (Status: {shippingTracking?.deliveryStatus})
                  </>
                ) : (
                  <>Tracking information not available yet.</>
                )}
              </SetupStepContent>
            </SetupStep>
          )}
          <SetupStep>
            <DownloadForOfflineIcon className="icon" />
            <SetupStepContent>
              <SetupLink target="_blank" href="https://www.sans.org/account/course-materials/">
                Download your course materials
              </SetupLink>
              {hasMyLabs && " and complete the lab setup instructions."}
            </SetupStepContent>
          </SetupStep>
        </Stack>
      </AccordionDetails>
    </SetupAccordion>
  );
};

export default SetupInstructions;
