import { expiresIn, toDateAtTimeWithTz } from "./time";

export function expirationInfo(expires: string) {
  const expirationText = expiresIn(expires);
  const expirationDate = toDateAtTimeWithTz(expires);
  const isExpired = new Date(expires) < new Date();

  return {
    expirationText,
    expirationDate,
    isExpired,
  };
}
