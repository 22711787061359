import React from "react";
import { useCourseQuery } from "../pages/Player/Player.queries";
import { CourseQuery_course } from "../pages/Player/__generated__/CourseQuery";

export type CourseContextType = CourseQuery_course | undefined;
export const CourseContext = React.createContext<CourseContextType>({} as CourseContextType);

export const CourseProvider: React.FC = ({ children }) => {
  const course = useCourseQuery();

  return <CourseContext.Provider value={course}>{children}</CourseContext.Provider>;
};
