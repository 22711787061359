import { makeVar, useApolloClient, useReactiveVar } from "@apollo/client";
import { useAnalyticsEvents } from "@atlaskit/analytics-next";
import { useAppState } from "./useAppState";
import { usePersistedSettings } from "./usePersistedSettings";
import { Md5 } from "ts-md5";
import { telemetryChannelName } from "../util/constants";
import { ALL_MESSAGES } from "../pages/Library/Library.queries";
import React, { useEffect, useMemo, useState } from "react";
import { Message, groupAllMessages } from "../pages/Library/utils";
import { useKeyPress } from "./useKeyPress";

interface HandlerProps {
  event: React.KeyboardEvent;
  isDocumentFocused: boolean;
}

type ShortcutHandler = {
  key: string;
  handler: (props: HandlerProps) => void;
};

const messageModalOpen = makeVar(false);
export const featureName = "importantMessages";
export const openActionType = "open";

export function useMessages(isLibrary: boolean): {
  messages: Message[];
  modalOpen: boolean;
  setModalOpen: (open: boolean) => void;
  dismissMessage: (hash: string) => void;
  unreadCount: number;
} {
  const client = useApolloClient();
  const { course } = useAppState();
  const { createAnalyticsEvent } = useAnalyticsEvents();
  const [messages, setMessages] = useState<any>([]);
  const { dismissedMessages, setDismissedMessages } = usePersistedSettings();

  const modalOpen = useReactiveVar(messageModalOpen);

  const getFocusElements = () => {
    const focusableSelectors = ["h1", "h2", "div.messageWrapper", "button.closeNotificationModal"];
    const modalContainer = document.querySelector(".notificationsModal");

    return modalContainer
      ? Array.from(modalContainer.querySelectorAll(focusableSelectors.join(",")))
      : [];
  };

  const focusPreviousElement = () => {
    const focusableElements = getFocusElements();
    const currentIndex = focusableElements.indexOf(document.activeElement as HTMLElement);
    const prevIndex = currentIndex < focusableElements.length - 1 ? currentIndex + 1 : 0;

    focusableElements[prevIndex]?.focus();
  };

  const focusNextElement = () => {
    const focusableElements = getFocusElements();
    const currentIndex = focusableElements.indexOf(document.activeElement as HTMLElement);
    const nextIndex = currentIndex > 0 ? currentIndex - 1 : focusableElements.length - 1;

    focusableElements[nextIndex]?.focus();
  };

  const shortcutHandlers: ShortcutHandler[] = [
    {
      key: "ArrowUp",
      handler: () => {
        focusPreviousElement();
      },
    },
    {
      key: "ArrowDown",
      handler: () => {
        focusNextElement();
      },
    },
  ];

  const setModalOpen = (open: boolean) => {
    messageModalOpen(open);
    open &&
      createAnalyticsEvent({
        feature: featureName,
        action: openActionType,
      }).fire(telemetryChannelName);
  };

  const onKeyPress = (event: React.KeyboardEvent) => {
    const { key } = event;
    const isModeDepressed = event.metaKey || event.ctrlKey || event.shiftKey || event.altKey;
    const found = shortcutHandlers.find((handler) => handler.key === key);
    const isDocumentFocused = document.activeElement === document.body;

    if (found && !isModeDepressed && modalOpen) {
      found.handler({ event, isDocumentFocused });
    }
  };

  useKeyPress(
    shortcutHandlers.map(({ key }) => key),
    onKeyPress,
  );

  useEffect(() => {
    const getMessages = async () => {
      if (isLibrary) {
        const { data } = await client.query({
          query: ALL_MESSAGES,
        });
        const allCourseMessages = groupAllMessages(data?.messages || []);
        return setMessages(allCourseMessages);
      }

      setMessages(course?.messages);
    };

    getMessages();
  }, [isLibrary, course]);

  const filteredMessages = useMemo(
    () =>
      (messages || []).map((m: Message) => ({
        ...m,
        read: dismissedMessages?.includes(Md5.hashStr(m?.text)),
      })),
    [messages, dismissedMessages],
  );

  const dismissMessage = (message: string) =>
    setDismissedMessages([...dismissedMessages, Md5.hashStr(message)]);

  const unreadCount = filteredMessages.filter((m: { read: boolean }) => !m.read).length;

  return { messages: filteredMessages, dismissMessage, modalOpen, setModalOpen, unreadCount };
}
