import React from "react";
import { Container, ImageWrapper, InnerContainer } from "./SlideThumbnails.elements";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ButtonExitSlides from "./ButtonExitSlides/ButtonExitSlides";
import { useAppState } from "../../hooks/useAppState";
import { useViewport, View } from "../Viewport/useViewport";

export const SlideThumbnails = (): JSX.Element => {
  const { module, setSlide, slide: currentSlide } = useAppState();

  const { changeView } = useViewport();

  if (!module) return <div>No module selected.</div>;
  if (module.type !== "CONTENT") return <div>{module.type}</div>;

  const gotoSlide = (slideId: string) => {
    setSlide(slideId);
    changeView(View.Video);
  };

  const handleSlideClick = (slideId: string) => () => gotoSlide(slideId);

  return (
    <Container data-testid="slide-thumbnails">
      <InnerContainer>
        <ImageList
          style={{ gridTemplateColumns: "repeat(auto-fit, minmax(165px,1fr))", width: "100%" }}
        >
          {module.slides.map((slide, index) => (
            <ImageListItem key={slide.id}>
              <ImageWrapper
                className={currentSlide?.id === slide.id ? "selected" : ""}
                tabIndex={1}
                onClick={handleSlideClick(slide.id)}
                autoFocus={index === 0}
                aria-label={`Video ${index + 1} of ${module.slides.length}: ${slide.name}`}
              >
                <img
                  src={`${slide.thumbnailPath}`}
                  alt={slide.name}
                  loading="lazy"
                  style={{ cursor: "pointer" }}
                />
              </ImageWrapper>
            </ImageListItem>
          ))}
        </ImageList>
      </InnerContainer>
      <ButtonExitSlides></ButtonExitSlides>
    </Container>
  );
};
