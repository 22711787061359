import { styled } from "@mui/material/styles";
import { Dialog, DialogContentText, Button } from "@mui/material";

export const TicketDialog = styled(Dialog)({
  "& .react-draggable > svg": {
    bottom: "0px",
    right: "0px",
    position: "absolute",
    transform: "rotate(45deg)",
    cursor: "grab",
  },
});

export const ContentWrapper = styled(DialogContentText)({
  maxWidth: "100%",
  minWidth: "300px",
  minHeight: "300px",
});

export const StyledButton = styled(Button)(({ theme, variant }) => ({
  ...(variant === "contained" && {
    boxShadow: "none",
    background: theme.custom.palette.semantic.buttonContainedBg,
    border: `1px solid ${theme.custom.palette.semantic.buttonContainedBorder}`,
    color: theme.custom.palette.semantic.buttonContainedColor,
    ["&:hover"]: {
      boxShadow: "none",
      background: theme.custom.palette.semantic.buttonContainedHoverBg,
      border: `1px solid ${theme.custom.palette.semantic.buttonContainedHoverBorder}`,
      color: theme.custom.palette.semantic.buttonContainedHoverColor,
    },
    ["&:focus"]: {
      background: theme.custom.palette.semantic.buttonContainedFocusBg,
      border: `1px solid ${theme.custom.palette.semantic.buttonContainedFocusBorder}`,
      color: theme.custom.palette.semantic.buttonContainedFocusColor,
    },
  }),
  ...(variant === "outlined" && {
    background: "transparent",
    border: `1px solid ${theme.custom.palette.semantic.buttonOutlinedBorder}`,
    color: theme.custom.palette.semantic.buttonOutlinedColor,
    ["&:hover"]: {
      background: theme.custom.palette.semantic.buttonOutlinedHoverBg,
      border: `1px solid ${theme.custom.palette.semantic.buttonOutlinedHoverBorder}`,
      color: theme.custom.palette.semantic.buttonOutlinedHoverColor,
    },
    ["&:focus"]: {
      background: theme.custom.palette.semantic.buttonOutlinedFocusBg,
      border: `1px solid ${theme.custom.palette.semantic.buttonOutlinedFocusBorder}`,
      color: theme.custom.palette.semantic.buttonOutlinedFocusColor,
    },
  }),
}));
