import { styled } from "@mui/material/styles";
import { Grid, Paper } from "@mui/material";

export const MainHeader = styled("div")(({ theme }) => ({
  height: "126px",
  width: "100%",
  background: theme.palette.primary.main,
  borderRadius: `${theme.custom.radius[2]} ${theme.custom.radius[2]} 0px 0px`,
  zIndex: -1,
  position: "absolute",
  [theme.breakpoints.down("sm")]: {
    borderRadius: "0px",
    height: "161px",
  },
}));

export const ContentContainer = styled(Grid)({
  padding: "24px",
});

export const TitleContainer = styled(Grid)(({ theme }) => ({
  color: theme.custom.palette.light,
  marginBottom: theme.spacing(1),
}));

export const Title = styled("h2")({
  fontSize: "1.125rem",
  lineHeight: "1.375rem",
  margin: "0px",
});

export const PaperContainer = styled(Paper)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: theme.spacing(3),
  boxShadow: theme.custom.shadows[2],
  border: theme.palette.mode === "dark" ? `1px solid ${theme.palette.grey[500]}` : 0,
  backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[700] : "white",
}));

export const SectionTitle = styled("h3")(({ theme }) => ({
  fontSize: "0.875rem",
  fontWeight: 600,
  color: theme.palette.text.primary,
  margin: "0px",
}));

interface SectionTextProps {
  [key: string]: string;
}
export const SectionText = styled("p")<SectionTextProps>(({ theme, topMargin }) => ({
  fontSize: "0.75rem",
  textAlign: "left",
  color: theme.palette.text.primary,
  marginTop: topMargin && theme.spacing(2),
}));

export const HoursText = styled("div")(({ theme }) => ({
  fontSize: "0.75rem",
  textAlign: "left",
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(2),

  "& > span": {
    display: "block",
  },
}));
