import { CourseFragment_messages } from "../../../hooks/__generated__/CourseFragment";
import { UserType } from "../../../__generated__/globalTypes";
import { MyCoursesQuery_myCourses_rosterUsers } from "../__generated__/MyCoursesQuery";

type CourseArray = MyCoursesQuery_myCourses_rosterUsers[];

interface FilteredCourses {
  active: CourseArray;
  expired: CourseArray;
}

export const filterCourses = (courses: CourseArray = []) =>
  courses?.reduce(
    (filtered: FilteredCourses, course) => {
      const expired = course.userType === UserType.ExpiredUser;
      filtered[expired ? "expired" : "active"].push(course);

      return filtered;
    },
    { active: [], expired: [] },
  );

export interface Message extends CourseFragment_messages {
  read: boolean;
}

export const groupAllMessages = (messages: Message[] = []) => {
  const groupedMessages: Message[] = [];
  messages.forEach((message: Message) => {
    if (!groupedMessages.find((m: Message) => m.text === message.text)) {
      groupedMessages.push(message);
    }
  });
  return groupedMessages;
};
