import { useCallback } from "react";
import { Sans } from "../util/sdk";
import { TechSupportType } from "../context/hasBot-context";
import { useAppState } from "./useAppState";

export enum CategoryType {
  Technical_Support = "Technical Support",
  Content_Questions = "Technical Course Content Questions",
}

interface SendMessage {
  assignmentGroup: TechSupportType;
  attributes: {
    agent: {
      app: {
        version: string;
      };
      name: string;
      os: string;
      version: string;
    };
    course: {
      id: number;
      name: string;
      version: string;
    };
    module: {
      name: string;
      number?: number;
      uuid: string;
    };
    slide: {
      index: number;
      name: string;
      uuid: string;
    };
  };
  category: CategoryType;
  description: string;
  email?: {
    attributes: {
      courseId: string;
      slideUuid: string;
      token: string;
    };
    body: string;
    subject: string;
  };
  message: string;
  shortDescription: string;
  user: {
    email: string;
    firstName: string;
    id: string;
    lastName: string;
  };
}

export const featureName = "techSupportMessages";
export const sendMessageActionType = "send";

export const useTechSupport = () => {
  const {
    course,
    module,
    slide,
    appState: { courseId },
  } = useAppState();

  const sendMessage = useCallback(
    async (data: SendMessage) => {
      const { id } = await Sans.sdk.core.api.tokens.freshTokens();
      const url = `${process.env.REACT_APP_HELP_SUPPORT_URL}/tickets/create`;
      if (data.email) {
        data.email.attributes.token = id || "";
      }
      const body = {
        ...data,
      };

      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": `Bearer ${id}`,
          },
          body: JSON.stringify(body),
        });

        if (!response.ok) {
          return { error: "Something went wrong." };
        }

        return await response.json();
      } catch (error) {
        console.warn({ error });
        return { error: "Something went wrong." };
      }
    },
    [course, courseId, module, slide],
  );

  return { sendMessage };
};
