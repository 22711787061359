import React from "react";
import { DialogTitle, DialogActions, DialogContent, Box, Link, Stack } from "@mui/material";
import { useMessages } from "../../hooks/useMessages";
import { Campaign, MarkEmailRead } from "@mui/icons-material";
import {
  EmptyMessages,
  ImportantMessage,
  MarkAsReadBtn,
  MessageWrapper,
  StyledDialog,
  StyledButton,
} from "./MessagesModal.elements";

export const MessagesModal = ({
  isLibrary,
  testModalOpen = false,
}: {
  isLibrary: boolean;
  testModalOpen?: boolean;
}): JSX.Element => {
  const { messages, dismissMessage, modalOpen, setModalOpen, unreadCount } = useMessages(isLibrary);
  const dialogAriaLabel = `Notifications, you have ${unreadCount} new ${
    unreadCount > 2 ? "notifications" : "notification"
  }`;
  const handleDismissClick = (message: string) => () => dismissMessage(message);
  const closeModal = () => setModalOpen(false);

  if (testModalOpen) {
    setModalOpen(true);
  }

  return (
    <StyledDialog className="notificationsModal" open={modalOpen} onClose={closeModal}>
      <DialogTitle tabIndex={0} aria-label={dialogAriaLabel} sx={{ pb: 0, pt: 3, mb: 2 }}>
        <Campaign sx={{ verticalAlign: "sub", mr: 1 }} />
        Notifications {unreadCount > 0 && `(${unreadCount})`}
      </DialogTitle>
      <DialogContent>
        <Stack gap={3}>
          {messages.length ? (
            messages.map(({ text, link, read }) => (
              <MessageWrapper
                tabIndex={0}
                className="messageWrapper"
                key={`message-${text}`}
                read={read}
              >
                <ImportantMessage>
                  <>
                    <Box>{text}</Box>
                    {link && (
                      <Box sx={{ marginTop: "1em", overflowWrap: "anywhere" }}>
                        <Link target="_blank" href={link}>
                          {link}
                        </Link>
                      </Box>
                    )}
                  </>
                </ImportantMessage>
                <DialogActions>
                  {!read && (
                    <MarkAsReadBtn onClick={handleDismissClick(text)}>
                      <MarkEmailRead fontSize="small" />
                      Mark as read
                    </MarkAsReadBtn>
                  )}
                </DialogActions>
              </MessageWrapper>
            ))
          ) : (
            <EmptyMessages>No messages to show</EmptyMessages>
          )}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 3, pt: 0, pb: 2 }}>
        <StyledButton
          className="closeNotificationModal"
          variant="contained"
          onClick={closeModal}
          autoFocus
        >
          Close
        </StyledButton>
      </DialogActions>
    </StyledDialog>
  );
};
