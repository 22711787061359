function pad(string: string) {
  return ("0" + string).slice(-2);
}

export function getDateTime(seconds: number): string {
  return `P${Math.round(seconds)}S`;
}
export function getFormattedSeconds(seconds: number): string {
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours().toString();
  const mm = date.getUTCMinutes().toString();
  const ss = pad(date.getUTCSeconds().toString());
  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`;
  }
  return `${mm}:${ss}`;
}
