import { styled } from "@mui/material/styles";
import { Button, Dialog, DialogContentText } from "@mui/material";

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  [".MuiDialog-paper"]: {
    background: theme.custom.palette.semantic.modalBg,
  },
}));

export const ImportantMessage = styled(DialogContentText)(({ theme }) => ({
  color: theme.palette.text.primary,
  borderRadius: "0.2em",
  padding: theme.spacing(2),
  overflowWrap: "break-word",
  whiteSpace: "pre-wrap",

  a: {
    color: theme.custom.palette.semantic.blue,
  },
}));

export const MessageWrapper = styled("div")<{ read?: boolean }>(({ read, theme }) => ({
  border: read ? `1px solid ${theme.custom.palette.semantic.grey}` : "none",
  borderRadius: "0.2em",

  backgroundColor: read ? "none" : theme.custom.palette.semantic.readMessage,
}));

export const EmptyMessages = styled(MessageWrapper)(({ theme }) => ({
  width: 500,
  padding: theme.spacing(4),
  textAlign: "center",
}));

export const MarkAsReadBtn = styled(Button)(({ theme }) => ({
  color:
    theme.palette.mode === "dark" ? theme.palette.secondary.light : theme.custom.palette.blue[700],
  display: "flex",
  gap: theme.spacing(1),
  fontWeight: 600,
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  background: theme.custom.palette.semantic.buttonContainedBg,
  border: `1px solid ${theme.custom.palette.semantic.buttonContainedBorder}`,
  color: theme.custom.palette.semantic.buttonContainedColor,
  ["&:hover"]: {
    background: theme.custom.palette.semantic.buttonContainedHoverBg,
    border: `1px solid ${theme.custom.palette.semantic.buttonContainedHoverBorder}`,
    color: theme.custom.palette.semantic.buttonContainedHoverColor,
  },
  ["&:focus"]: {
    background: theme.custom.palette.semantic.buttonContainedFocusBg,
    border: `1px solid ${theme.custom.palette.semantic.buttonContainedFocusBorder}`,
    color: theme.custom.palette.semantic.buttonContainedFocusColor,
  },
}));
