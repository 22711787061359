import { useApolloClient, makeVar, useReactiveVar } from "@apollo/client";
import { useAnalyticsEvents } from "@atlaskit/analytics-next";
import gql from "graphql-tag";
import { useCallback, useState, useEffect } from "react";
import { useAppState } from "../../hooks/useAppState";
import { SearchQuery } from "./__generated__/SearchQuery";
import { telemetryChannelName } from "../../util/constants";

const showResultsVar = makeVar(false);

export const SEARCH_QUERY = gql`
  query SearchQuery($query: String!, $courseId: Int!) {
    search(query: $query, courseId: $courseId) {
      foundFields {
        __typename
        fieldName
        slides {
          slideId
          highlight
          name
          number
        }
      }
    }
  }
`;

interface SearchResult {
  foundFields: {
    __typename: "FoundField";
    fieldName: string;
    slides: {
      __typename: "FoundSlide";
      slideId: string;
      highlight: string;
      name: string;
      number: string;
    }[];
  }[];
}

export const featureName = "search";
export const searchActionType = "search";

export function useSearch(): {
  search: (query: string) => Promise<void>;
  showSearchResults: boolean;
  setShowSearchResults: (showResults: boolean) => void;
  resultsCount: number;
  results: SearchResult | undefined;
} {
  const [results, setResults] = useState<SearchResult>();
  const [count, setCount] = useState<number>(0);
  const {
    appState: { courseId },
  } = useAppState();

  const { createAnalyticsEvent } = useAnalyticsEvents();

  const client = useApolloClient();

  const showSearchResults = useReactiveVar(showResultsVar);

  const setShowSearchResults = useCallback((showResults: boolean) => {
    showResultsVar(showResults);
  }, []);

  const search = useCallback(
    async (query: string) => {
      createAnalyticsEvent({
        feature: featureName,
        action: searchActionType,
      }).fire(telemetryChannelName);

      const { data } = await client.query<SearchQuery>({
        query: SEARCH_QUERY,
        variables: { courseId, query },
        fetchPolicy: "network-only",
      });
      setResults(data?.search);

      query != "" ? setShowSearchResults(true) : setShowSearchResults(false);
    },
    [courseId, client, createAnalyticsEvent, setShowSearchResults],
  );

  const calculateResultsCount = useCallback(() => {
    const foundFields = results?.foundFields;
    if (foundFields?.length === 0) {
      setCount(0);
    } else {
      const resultsCount = foundFields?.reduce((acc, field) => acc + field.slides.length, 0) || 0;
      setCount(resultsCount);
    }
  }, [results]);

  useEffect(() => {
    calculateResultsCount();
  }, [calculateResultsCount]);

  return { search, showSearchResults, setShowSearchResults, results, resultsCount: count };
}
