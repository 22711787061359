import { useMutation, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useCallback } from "react";
import { useAppState } from "../../hooks/useAppState";
import { useErrorHandler } from "../../hooks/useErrorHandler";
import { moduleType } from "../../__generated__/globalTypes";
import { SendTicket } from "./__generated__/SendTicket";
import { TicketCategories } from "./__generated__/TicketCategories";

export const TICKET_CATEGORIES_QUERY = gql`
  query TicketCategories {
    ticketCategories
  }
`;

export const SEND_TICKET_MUTATION = gql`
  mutation SendTicket(
    $slideId: String!
    $category: String!
    $content: String!
    $highPriority: Boolean!
    $moduleId: String!
    $moduleNo: String!
    $moduleTitle: String!
    $playerVersion: String!
    $slideIdx: Int!
    $slideTitle: String!
    $subject: String!
    $courseId: Int!
  ) {
    sendATicket(
      slideId: $slideId
      category: $category
      content: $content
      highPriority: $highPriority
      moduleId: $moduleId
      moduleNo: $moduleNo
      moduleTitle: $moduleTitle
      playerVersion: $playerVersion
      slideIdx: $slideIdx
      slideTitle: $slideTitle
      subject: $subject
      courseId: $courseId
    )
  }
`;
export function useQCTicketQuery() {
  const { data: categoriesData, loading: categoriesLoading } =
    useQuery<TicketCategories>(TICKET_CATEGORIES_QUERY);
  const [sendATicket, { data: sendData, loading: sendLoading }] =
    useMutation<SendTicket>(SEND_TICKET_MUTATION);

  const { slide, module, course } = useAppState();
  const { throwError } = useErrorHandler();

  const sendTicket = useCallback(
    (category: string, subject: string, content: string, highPriority: boolean) => {
      if (!module || !course) return throwError("Missing module or course");
      return sendATicket({
        variables: {
          slideId: slide?.id,
          category,
          content,
          highPriority,
          moduleId: module.id,
          moduleNo: module.fullNumber,
          moduleTitle: module.name,
          playerVersion: "2.0.0",
          slideIdx: (slide?.number ?? 1) - 1,
          slideTitle:
            slide?.name ??
            (module.type === moduleType.QUIZ
              ? "Quiz"
              : module.type === moduleType.SURVEY
              ? "Survey"
              : "Unknown"),
          subject,
          courseId: course.trainingId,
        },
      });
    },
    [sendATicket, slide, module, course, throwError],
  );

  return {
    categoriesLoading,
    categories: categoriesData?.ticketCategories,
    sendTicket,
    sendLoading,
    ticketNumber: sendData?.sendATicket,
  };
}
