import { Stack, styled } from "@mui/material";

export const CourseAssetsWrapper = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(3),
  background: theme.custom.palette.semantic.wrapperBgDark,
  color: theme.palette.text.titleGrey,
  gap: theme.spacing(2),
  borderRadius: "6px",
  border: `1px solid ${theme.custom.palette.semantic.grey}`,
}));

export const Handout = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  flexBasis: "100%",
  color: theme.palette.text.link,

  ["& svg"]: {
    marginTop: "2px",
  },

  ["& a"]: {
    color: theme.palette.text.link,
  },

  ["& a:hover"]: {
    color: theme.palette.text.linkHover,
  },
}));
