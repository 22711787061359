import React from "react";
import { Typography } from "@mui/material";
import type { ProgressState } from "../../../Video/useVideo";
import VideoDuration from "../VideoDuration/VideoDuration";

type VideoTimestampProps = {
  progress: ProgressState;
  duration: number;
};

export default function VideoTimestamp({ progress, duration }: VideoTimestampProps): JSX.Element {
  const seconds = duration * progress.played;

  // Time Watched
  const minutesWatched = Math.floor(seconds / 60);
  const secondsWatched = Math.floor(seconds - minutesWatched * 60);
  const progressWatched = isNaN(progress.played) ? 0 : duration * progress.played;
  // Duration
  const minutesDuration = Math.floor(duration / 60);
  const secondsDuration = Math.floor(duration - minutesDuration * 60);

  const timeLabel = `${minutesWatched} minutes, ${secondsWatched} seconds watched of ${minutesDuration} minutes, ${secondsDuration} seconds video duration`;

  return (
    <Typography
      variant="body2"
      component="span"
      color="grey.50"
      tabIndex={1}
      aria-label={timeLabel}
      id="timestamp"
      sx={{ fontSize: { xs: "0.75rem", sm: "0.85rem" } }}
    >
      <VideoDuration seconds={progressWatched} /> / <VideoDuration seconds={duration} />
    </Typography>
  );
}
