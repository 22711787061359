import { useCallback, useEffect, useState } from "react";
import { Cue, NodeCue, NodeList, parseSync } from "subtitle";
import { ErrorSeverity, useErrorHandler } from "../../hooks/useErrorHandler";

export function useCaptions(
  captionsEnabled = false,
  captionsURL?: string,
  position = 0,
  hasNoAudio = false,
) {
  const [parsed, setParsed] = useState<NodeList>();
  const [text, setText] = useState("\n\n");
  const [loading, setLoading] = useState(false);
  const { reportError } = useErrorHandler();

  const isActive = useCallback(
    (cue: NodeCue): boolean => {
      const positionMs = position * 1000;
      return positionMs >= cue.data.start && positionMs < cue.data.end;
    },
    [position],
  );

  function getText(cue: Cue): string {
    return cue.text;
  }

  useEffect(() => {
    if (hasNoAudio) return setText("[This slide intentionally has no audio.]");

    const cue: NodeCue | undefined = parsed?.find(
      (node) => node.type === "cue" && isActive(node),
    ) as NodeCue;
    if (cue) {
      const activeText = getText(cue.data);
      if (activeText !== text) setText(activeText);
    } else {
      setText("\n\n");
    }
  }, [position, parsed, isActive, text, hasNoAudio]);

  const loadCaptions = useCallback(
    async (captionsURL: string) => {
      try {
        setLoading(true);
        setParsed(undefined);
        const response = await fetch(captionsURL);
        const subtitles = await response.text();

        if (subtitles.trim().length === 0) {
          reportError(ErrorSeverity.LOW, "Caption string is empty", { captionsURL });
        }

        setParsed(parseSync(subtitles));
      } catch (e) {
        reportError(ErrorSeverity.LOW, "Failed to load captions", { captionsURL });
      } finally {
        setLoading(false);
      }
    },
    [reportError],
  );

  useEffect(() => {
    if (captionsEnabled && !hasNoAudio && captionsURL) {
      loadCaptions(captionsURL);
    }
  }, [captionsEnabled, loadCaptions, hasNoAudio, captionsURL]);

  return { text, loadCaptions, loading };
}
