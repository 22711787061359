import React, { createContext, Dispatch } from "react";
import * as locales from "../locale/index";

export type SupportedLocales = keyof typeof locales;

interface Context {
  locale: SupportedLocales;
  setLocale: Dispatch<React.SetStateAction<SupportedLocales>> | null;
}

const context: Context = {
  locale: "enUS",
  setLocale: null,
};

export const LocalizationContext = createContext(context);

export const LocalizationProvider = LocalizationContext.Provider;
