import { styled } from "@mui/material/styles";
import { IconButton, Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.custom.palette.light,
  fontSize: "1.375rem",
  padding: "8px",
}));

export const StyledHeaderIconButton = styled(StyledIconButton)({
  ["svg"]: {
    fontSize: "1.375rem",
  },
});

export const ChatActionButton = styled(LoadingButton)(({ theme }) => ({
  fontSize: "0.875rem",
  fontWeight: 700,
  lineHeight: "16px",
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  borderRadius: theme.custom.radius[1],
  background: theme.custom.palette.semantic.buttonContainedBg,
  border: `1px solid ${theme.custom.palette.semantic.buttonContainedBorder}`,
  color: theme.custom.palette.semantic.buttonContainedColor,
  ["&:hover"]: {
    background: theme.custom.palette.semantic.buttonContainedHoverBg,
    border: `1px solid ${theme.custom.palette.semantic.buttonContainedHoverBorder}`,
    color: theme.custom.palette.semantic.buttonContainedHoverColor,
  },
  ["&:focus"]: {
    background: theme.custom.palette.semantic.buttonContainedFocusBg,
    border: `1px solid ${theme.custom.palette.semantic.buttonContainedFocusBorder}`,
    color: theme.custom.palette.semantic.buttonContainedFocusColor,
  },
  ["& svg"]: {
    fontSize: "1rem",
  },
}));

interface AutomatedMessageProps {
  [key: string]: string;
}
export const AutomatedMessage = styled("p")<AutomatedMessageProps>(({ theme, bottomMargin }) => ({
  fontSize: "0.875rem",
  fontWeight: 400,
  color: theme.palette.text.primary,
  marginTop: "0px",
  marginBottom: bottomMargin && "24px",
}));

export const PageHeader = styled(Grid)(({ theme }) => ({
  height: "74px",
  width: "100%",
  background: theme.palette.primary.main,
  borderRadius: `${theme.spacing(1)} ${theme.spacing(1)} 0px 0px`,
  [theme.breakpoints.down("sm")]: {
    borderRadius: "0px",
    height: "96px",
  },

  paddingLeft: "24px",
  paddingRight: "24px",
}));

export const HeaderButton = styled(StyledIconButton)(() => ({
  margin: "0px",
}));
