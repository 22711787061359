import { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";

interface Props {
  ref: React.RefObject<HTMLElement>;
  resetTrigger?: string | boolean;
}

export const useTouched = ({
  ref,
  resetTrigger,
}: Props): [boolean, React.Dispatch<React.SetStateAction<boolean>>] => {
  const [touched, setTouched] = useState(false);

  const handleFocus = useCallback(
    debounce(() => {
      setTouched(true);
    }, 500),
    [],
  );

  useEffect(() => {
    setTouched(false);
  }, [resetTrigger]);

  useEffect(() => {
    const currentRef = ref.current;
    currentRef?.addEventListener("focusin", handleFocus, false);

    return () => {
      currentRef?.removeEventListener("focusin", handleFocus, false);
    };
  }, [handleFocus]);

  return [touched, setTouched];
};
