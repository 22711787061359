import React, { useContext, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import MinimizeIcon from "@mui/icons-material/Minimize";
import Email from "@mui/icons-material/Email";
import Send from "@mui/icons-material/Send";
import { Grid } from "@mui/material";

import {
  HoursText,
  SectionText,
  SectionTitle,
  TitleContainer,
  ContentContainer,
  MainHeader,
  Title,
  PaperContainer,
} from "./HelpAndSupportMainPage.elements";
import { StyledIconButton, ChatActionButton } from "./Common.elements";
import { HasbotContext, HasbotContextType, TechSupportType } from "../../context/hasBot-context";

export function HelpAndSupportMainPage() {
  // @todo: add a loading state
  const [loading] = useState(false);
  const [zopimStatus, setZopimStatus] = useState({
    timestamp: "",
    status: "offline",
    hours: [],
  });

  const { setTechSupportActive, setZopimChatActive, setShowDialog, openDialogBtnRef } = useContext(
    HasbotContext,
  ) as HasbotContextType;

  const dispatchHoursEvent = () => {
    const zopimHours = new Event("updateHours");
    document.dispatchEvent(zopimHours);
  };

  const handleEmailClick = (type: TechSupportType) => () => {
    setTechSupportActive(type);
  };

  const handleChatClick = () => {
    setZopimChatActive(true);
    dispatchHoursEvent();
  };

  const closeDialog = () => {
    setShowDialog(false);
    openDialogBtnRef.current?.focus();
    const endChat = new Event("endZopimChat");
    document.dispatchEvent(endChat);
    dispatchHoursEvent();
  };

  const minimizeDialog = () => {
    setShowDialog(false);
    openDialogBtnRef.current?.focus();
  };

  const handleHours = (schedule: string | null) => {
    if (schedule) {
      const newSchedule = JSON.parse(schedule);
      setZopimStatus(newSchedule);
    }
  };

  useEffect(() => {
    handleHours(localStorage.getItem("zopimSchedule"));
    window.addEventListener(
      "zopimSchedule",
      () => {
        handleHours(localStorage.getItem("zopim_schedule_update"));
      },
      false,
    );
  }, []);

  return (
    <>
      <MainHeader />
      <ContentContainer
        container
        direction="column"
        alignContent="center"
        justifyContent="space-between"
      >
        <TitleContainer item container justifyContent="space-between">
          <Grid
            item
            container
            xs={8}
            justifyContent="flex-start"
            alignItems="center"
            aria-live="assertive"
          >
            <Title>Help &amp; Support</Title>
          </Grid>
          <Grid justifyContent="flex-end">
            <StyledIconButton
              id="minimize-help-dialog"
              aria-label="Minimize Help Dialog"
              size="small"
              onClick={minimizeDialog}
            >
              <MinimizeIcon fontSize="inherit" />
            </StyledIconButton>
            <StyledIconButton
              id="close-help-dialog"
              aria-label="Close Help Dialog"
              size="small"
              onClick={closeDialog}
            >
              <CloseIcon fontSize="inherit" />
            </StyledIconButton>
          </Grid>
        </TitleContainer>
        <PaperContainer>
          <SectionTitle>Subject Matter Expert Help</SectionTitle>
          <SectionText>
            Ask our Subject Matter Experts any course content, lab or quiz related questions
            instantly in a live chat. Hours of availability:
          </SectionText>
          {zopimStatus.status === "online" ? (
            <HoursText>
              {zopimStatus.hours.map((hours: string) => (
                <span key={hours}>{hours}</span>
              ))}
            </HoursText>
          ) : (
            <>
              <SectionText>
                Chat is not available at this time. The chat feature is available:
              </SectionText>
              <HoursText>
                {zopimStatus.hours
                  ? zopimStatus.hours.map((hours: string) => <span key={hours}>{hours}</span>)
                  : handleHours(localStorage.getItem("zopimSchedule"))}
              </HoursText>
            </>
          )}
          <ChatActionButton
            autoFocus={zopimStatus.status === "online" ? true : false}
            id="chat-with-us"
            variant="contained"
            startIcon={<Send />}
            onClick={handleChatClick}
            loading={loading}
            disabled={zopimStatus.status !== "online"}
          >
            Chat with us
          </ChatActionButton>
          <SectionText>No time to chat? Email the SME team below.</SectionText>
          <ChatActionButton
            autoFocus={zopimStatus.status === "online" ? true : false}
            id="ask-a-sme"
            variant="contained"
            startIcon={<Email />}
            onClick={handleEmailClick(TechSupportType.SME)}
            loading={loading}
          >
            Ask a SME question
          </ChatActionButton>
        </PaperContainer>
        <PaperContainer sx={{ marginBottom: "0" }}>
          <SectionTitle>Customer Service Support</SectionTitle>
          <SectionText>
            Contact our Customer Support team for questions related to finding your VM(s) / ISO,
            VMWare licensing, Player or Mobile App issues, questions about your GIAC exam,
            scheduling or practice tests or other non-technical questions.
          </SectionText>
          <ChatActionButton
            autoFocus={zopimStatus.status === "online" ? true : false}
            id="ask-support-question"
            variant="contained"
            startIcon={<Email />}
            onClick={handleEmailClick(TechSupportType.CSS)}
            loading={loading}
          >
            Ask a support question
          </ChatActionButton>
        </PaperContainer>
      </ContentContainer>
    </>
  );
}
