import React from "react";
import { VolumeOff } from "@mui/icons-material";

import { TapToUnmuteButton } from "./TapToUnmute.elements";

interface TapToUnmuteProps {
  onClick: () => void;
}

const TapToUnmute = ({ onClick }: TapToUnmuteProps) => {
  return (
    <TapToUnmuteButton color="secondary" onClick={onClick}>
      <VolumeOff /> TAP TO UNMUTE
    </TapToUnmuteButton>
  );
};

export default TapToUnmute;
