import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const Container = styled(Box)(({ theme }) => ({
  position: "relative",
  paddingTop: 50,
  height: "100%",
  display: "flex",
  gap: theme.spacing(5),
  justifyContent: "center",
  alignItems: "center",

  [theme.breakpoints.down("lg")]: {
    gap: theme.spacing(2),
  },
  [theme.breakpoints.down("lg")]: {
    gap: theme.spacing(1.5),
  },
  svg: {
    filter: "drop-shadow(0 1px 2px rgb(0,0,0, 0.35))",
    fontSize: 48,

    [theme.breakpoints.down("sm")]: {
      fontSize: 32,
    },
  },
}));
