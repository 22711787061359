import React, { useEffect, useCallback, useRef } from "react";
import { SlideTitle } from "./Search.elements";

interface TitleProps {
  focus?: boolean;
  index: number;
  onClick: (e: React.KeyboardEvent) => void;
  tabIndex: number;
  setFocus: React.Dispatch<React.SetStateAction<number>>;
  onKeyDown: (e: React.KeyboardEvent) => void;
  ariaLabel: string;
}

const Title: React.FC<TitleProps> = ({
  focus,
  index,
  onClick,
  tabIndex,
  setFocus,
  children,
  onKeyDown,
  ariaLabel,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const handleSelect = useCallback(
    (e) => {
      onClick(e);
      setFocus(index);
    },
    [index, setFocus, onClick],
  );

  useEffect(() => {
    if (focus) {
      ref?.current?.focus();
    }
  }, [focus]);

  return (
    <SlideTitle
      ref={ref}
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      aria-label={ariaLabel}
      onClick={handleSelect}
    >
      {children}
    </SlideTitle>
  );
};

export default Title;
