import React from "react";
import type { ProgressState } from "../Video/useVideo";
import { Container } from "./Controls.elements";
import ControlsCenter from "./ControlsCenter/ControlsCenter";
import ControlsBottom from "./ControlsBottom/ControlsBottom";

type ControlsProps = {
  videoTitle: string;
  isPlaying: boolean;
  handleClickPreviousVideo: () => void;
  handleClickNextVideo: () => void;
  isAutoplayEnabled: boolean;
  handleClickAutoplay: (value: boolean) => void;
  handleClickPlayPause: () => void;
  progress: ProgressState;
  handleMouseUpProgressBar: () => void;
  handleMouseDownProgressBar: () => void;
  handleMouseOutProgressBar: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleChangeProgressBar: (_: any, value: number | number[]) => void;
  handleClickReplay: () => void;
  isFullscreen: boolean;
  handleClickFullscreen: () => void;
  handleClickPIP: () => void;
  playbackRate: number;
  handleClickPlaybackRate: (value: number) => void;
  volume: number;
  handleClickMuteVolume: () => void;
  handleChangeVolumeSlider: (event: Event, value: number | number[]) => void;
  duration: number;
  handleClickCaptions: () => void;
  captionsEnabled: boolean;
  handleChangeView: () => void;
  visible: boolean;
  muted: boolean;
  hasCustomDuration: boolean;
  skip: (amount: number) => void;
  handleClickVideoContainer: (event: React.MouseEvent) => void;
  loading: boolean;
};

const Controls = ({
  isPlaying,
  handleClickPreviousVideo,
  handleClickNextVideo,
  isAutoplayEnabled,
  handleClickAutoplay,
  handleClickPlayPause,
  progress,
  handleMouseUpProgressBar,
  handleMouseDownProgressBar,
  handleMouseOutProgressBar,
  handleChangeProgressBar,
  handleClickReplay,
  isFullscreen,
  handleClickFullscreen,
  handleClickPIP,
  playbackRate,
  handleClickPlaybackRate,
  volume,
  handleClickMuteVolume,
  handleChangeVolumeSlider,
  duration,
  handleClickCaptions,
  captionsEnabled,
  handleChangeView,
  visible,
  muted,
  hasCustomDuration,
  skip,
  handleClickVideoContainer,
  loading,
}: ControlsProps): JSX.Element => {
  return (
    <Container
      className={visible ? "visible" : "hidden"}
      sx={{ cursor: visible ? "initial" : "none", pointerEvents: visible ? "auto" : "none" }}
      tabIndex={-1}
      id="video-control-container"
    >
      <ControlsCenter
        isPlaying={isPlaying}
        handleClickPlayPause={handleClickPlayPause}
        handleClickVideoContainer={handleClickVideoContainer}
        handleClickPreviousVideo={handleClickPreviousVideo}
        handleClickNextVideo={handleClickNextVideo}
        loading={loading}
      />
      <ControlsBottom
        isPlaying={isPlaying}
        isAutoplayEnabled={isAutoplayEnabled}
        handleClickAutoplay={handleClickAutoplay}
        handleClickPlayPause={handleClickPlayPause}
        progress={progress}
        handleMouseUpProgressBar={handleMouseUpProgressBar}
        handleMouseDownProgressBar={handleMouseDownProgressBar}
        handleChangeProgressBar={handleChangeProgressBar}
        handleMouseOutProgressBar={handleMouseOutProgressBar}
        handleClickReplay={handleClickReplay}
        isFullscreen={isFullscreen}
        handleClickFullscreen={handleClickFullscreen}
        handleClickPIP={handleClickPIP}
        playbackRate={playbackRate}
        handleClickPlaybackRate={handleClickPlaybackRate}
        volume={volume}
        handleClickMuteVolume={handleClickMuteVolume}
        handleChangeVolumeSlider={handleChangeVolumeSlider}
        duration={duration}
        handleClickCaptions={handleClickCaptions}
        captionsEnabled={captionsEnabled}
        handleChangeView={handleChangeView}
        muted={muted}
        hasCustomDuration={hasCustomDuration}
        skip={skip}
      />
    </Container>
  );
};

export default Controls;
