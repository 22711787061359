/* eslint-disable sonarjs/cognitive-complexity */
import { useReactiveVar, makeVar, useApolloClient } from "@apollo/client";
import { useAnalyticsEvents } from "@atlaskit/analytics-next";
import { useMediaQuery } from "@mui/material";
import gql from "graphql-tag";
import { useCallback, useMemo } from "react";
import { useViewport, View } from "../components/Viewport/useViewport";
import { moduleType, VideoResolution } from "../__generated__/globalTypes";
import { usePersistedSettings } from "./usePersistedSettings";
import { CourseFragment } from "./__generated__/CourseFragment";
import { TrainingInstanceFragment } from "./__generated__/TrainingInstanceFragment";
import { ModuleFragment } from "./__generated__/ModuleFragment";
import { ModuleQuery } from "./__generated__/ModuleQuery";
import { SectionFragment } from "./__generated__/SectionFragment";
import { SlideFragment } from "./__generated__/SlideFragment";
import { useTheme } from "@mui/material/styles";
import { useErrorHandler } from "./useErrorHandler";
import { detect } from "detect-browser";
import { testCourse, testModule } from "../util/fakeCourse";
import { useTestMode } from "./useTestMode";
import { setIsPlaying } from "../components/Video/useVideo";
import { FullCourseFragment } from "./__generated__/FullCourseFragment";
import { ChangeSlideFragment } from "./__generated__/ChangeSlideFragment";

export interface AppState {
  courseId?: number | null;
  instanceId?: string | null;
  sectionId?: string | null;
  moduleId?: string | null;
  slideId?: string | null;
  slideTime?: string | null;
  videoQuality?: VideoResolution | "auto";
  course?: CourseFragment | FullCourseFragment;
  instance?: TrainingInstanceFragment;
  module?: ModuleFragment;
  slide?: SlideFragment;
}

export const stateVar = makeVar<AppState>({});
export const loadingVar = makeVar<{ module: string | undefined }>({ module: undefined });

export const MODULE_QUERY = gql`
  query ModuleQuery($moduleId: String!, $quality: String!, $mp4: Boolean!) {
    module(moduleId: $moduleId) {
      sectionId
      id
      name
      type
      baseUrl
      quizInProgress
      quizScore
      slides {
        id
        name
        number
        duration
        thumbnailPath
        captionsUrl
        videoPath(quality: $quality, mp4: $mp4)
        lastView {
          slideId
          resumeTime
        }
      }
      lastView @client {
        slideId
        resumeTime
      }
      cookies {
        key
        value
        path
        domain
        expires
        secure
      }
    }
  }
`;

export const VIDEOPATH_QUERY = gql`
  query VideoPathQuery($moduleId: String!, $quality: String!, $mp4: Boolean!) {
    module(moduleId: $moduleId) {
      id
      slides {
        id
        videoPath(quality: $quality, mp4: $mp4)
      }
    }
  }
`;

export const CHANGE_SLIDE_FRAGMENT = gql`
  fragment ChangeSlideFragment on Slide {
    moduleId
  }
`;

export const SLIDE_FRAGMENT = gql`
  fragment SlideFragment on Slide {
    id
    name
    number
    fullNumber
    duration
    thumbnailPath
    captionsUrl
    videoPath(quality: $quality, mp4: $mp4)
    moduleId
    lastView {
      resumeTime
    }
  }
`;

export const MODULE_FRAGMENT = gql`
  fragment ModuleFragment on Module {
    sectionId
    id
    name
    type
    baseUrl
    fullNumber
    quizInProgress
    quizScore
    slides {
      id
      name
      number
      duration
      thumbnailPath
      videoPath(quality: $quality, mp4: $mp4)
      captionsUrl
      lastView {
        resumeTime
      }
    }
    lastView @client {
      slideId
      resumeTime
    }
    cookies {
      key
      value
      path
      domain
      expires
      secure
    }
  }
`;

export const SECTION_FRAGMENT = gql`
  fragment SectionFragment on Section {
    id
    name
    surveyUrl
  }
`;

export const TRAINING_INSTANCE_FRAGMENT = gql`
  fragment TrainingInstanceFragment on TrainingInstance {
    id
    expirationDateTime
    userId
    progress {
      instanceId
      viewedSeconds
      totalSeconds
      viewedSlides
      totalSlides
      totalQuizzes
      passedQuizzes
      complete
      lastView {
        slideId
        resumeTime
        dateTimeViewed
      }
      moduleProgress {
        id
        name
        fullNumber
        viewedSlides
        totalSlides
        quizScore
      }
    }
  }
`;

export const COURSE_FRAGMENT_QUERY = gql`
  query CourseFragmentQuery($courseId: Int!) {
    course(courseId: $courseId) {
      id
      trainingId
      name
      courseType
      shortName
      messages {
        text
        link
      }
      fullVersion
      version
      sections {
        id
        name
        modules {
          id
          name
        }
      }
    }
  }
`;

export const COURSE_FRAGMENT = gql`
  fragment CourseFragment on Course {
    id
    trainingId
    name
    courseType
    shortName
    messages {
      text
      link
    }
    fullVersion
    version
    sections {
      id
      name
      surveyUrl
      modules {
        id
        name
        type
        duration
      }
    }
  }
`;

export const FULL_COURSE_FRAGMENT = gql`
  fragment FullCourseFragment on Course {
    id
    trainingId
    name
    courseType
    shortName
    messages {
      text
      link
    }
    contributors {
      id
      bio {
        firstName
        lastName
      }
      profile {
        name
        image
        text
        link
      }
    }
    resources {
      title
      resourceHost
      linkJson
    }
    fullVersion
    version
    sections {
      id
      name
      surveyUrl
      modules {
        id
        name
        type
        duration
        quizScore
        confidenceRating {
          moduleId
          rating
        }
      }
    }
  }
`;

export function setAppState(newState: Partial<AppState>): void {
  stateVar({ ...stateVar(), ...newState });
}

function setLoading(newState: any): void {
  loadingVar({ ...loadingVar(), ...newState });
}

export function useAppState() {
  const { testMode, testSlides } = useTestMode();
  const client = useApolloClient();
  const appState = useReactiveVar(stateVar);
  const loading = useReactiveVar(loadingVar);
  const { changeView } = useViewport();
  const { createAnalyticsEvent } = useAnalyticsEvents();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const { quality, setQuality } = usePersistedSettings();
  const { throwError } = useErrorHandler();

  const useMp4 = useMemo(() => {
    const browser = detect();
    return browser?.name === "safari" || browser?.os === "iOS";
  }, []);

  const module = useMemo(() => {
    if (!testMode && !appState.moduleId) return null;

    return testMode
      ? { ...testModule, slides: Object.values(testSlides) }
      : appState.module ?? undefined;
  }, [appState.moduleId, testMode, testSlides, appState.module]);

  const userId = useMemo(() => {
    return appState.instance?.userId;
  }, [appState.instance]);

  const slide = useMemo(() => {
    if (appState.slideId === undefined || !module) return null;

    return testMode ? testSlides[appState.slideId] : appState.slide;
  }, [appState.slideId, module, testMode, testSlides, appState.slide]);

  const section = useMemo(() => {
    if (!appState.sectionId) return null;

    return client.readFragment<SectionFragment>({
      id: `Section:${appState.sectionId}`,
      fragment: SECTION_FRAGMENT,
    });
  }, [appState.sectionId, client]);

  const course = useMemo(() => {
    return appState.course;
  }, [appState.course]);

  const slideIdx = useMemo(() => {
    return {
      index: module?.slides.findIndex((slide) => slide.id === appState.slideId),
      slidesLength: module?.slides.length,
    };
  }, [appState.slideId, module]);

  const moduleIdx = useMemo(() => {
    const modules = course?.sections.map((section) => section.modules).flat();

    return {
      index: modules?.findIndex((module) => module.id === appState.moduleId),
      modulesLength: modules?.length,
    };
  }, [course, appState.moduleId]);

  const setCourse = useCallback(
    (courseId?: number): void => {
      setAppState({
        courseId,
        course: testMode
          ? testCourse
          : client.readFragment<CourseFragment>({
              id: `Course:{"trainingId":${courseId}}`,
              fragment: FULL_COURSE_FRAGMENT,
            }) ??
            client.readFragment<CourseFragment>({
              id: `Course:{"trainingId":${courseId}}`,
              fragment: COURSE_FRAGMENT,
            }) ??
            undefined,
      });
    },
    [client, testMode],
  );

  const setVideoQuality = (userQuality: VideoResolution | "auto" = quality): void => {
    setAppState({ videoQuality: userQuality });
  };

  const videoQuality = useMemo((): VideoResolution => {
    const userQuality = appState.videoQuality ?? quality ?? "auto";
    setQuality(userQuality);

    let videoQuality: VideoResolution;

    if (userQuality === "auto") {
      if (isTablet) {
        videoQuality = VideoResolution.SD;
      } else {
        videoQuality = VideoResolution.HD;
      }
    } else {
      videoQuality = userQuality;
    }

    return videoQuality;
  }, [appState.videoQuality, isTablet, setQuality, quality]);

  const updateVideoQuality = useCallback(async () => {
    if (appState.moduleId) {
      await client.query<ModuleQuery>({
        query: VIDEOPATH_QUERY,
        variables: { moduleId: appState.moduleId, quality: videoQuality, mp4: useMp4 },
      });
    }

    const module =
      client.readFragment<ModuleFragment>({
        id: `Module:${appState.moduleId}`,
        fragment: MODULE_FRAGMENT,
        variables: { quality: videoQuality, mp4: useMp4 },
      }) ?? undefined;

    setAppState({ module });
  }, [client, appState.moduleId, videoQuality, useMp4]);

  const setInstance = useCallback(
    (instanceId: string): void => {
      const instance =
        client.readFragment<TrainingInstanceFragment>({
          id: `TrainingInstance:${instanceId}`,
          fragment: TRAINING_INSTANCE_FRAGMENT,
        }) ?? undefined;
      setAppState({ instanceId, instance });
    },
    [client],
  );

  const refreshModule = useCallback(async () => {
    const module =
      client.readFragment<ModuleFragment>({
        id: `Module:${appState.moduleId}`,
        fragment: MODULE_FRAGMENT,
        variables: { quality: videoQuality, mp4: useMp4 },
      }) ?? undefined;

    const slide =
      client.readFragment<SlideFragment>({
        id: `Slide:${appState.slideId}`,
        fragment: SLIDE_FRAGMENT,
        variables: { quality: videoQuality, mp4: useMp4 },
      }) ?? undefined;

    setAppState({ module, slide });
    if (appState.courseId) setCourse(appState.courseId);
  }, [
    client,
    appState.courseId,
    appState.moduleId,
    appState.slideId,
    videoQuality,
    useMp4,
    setCourse,
  ]);

  const setModule = useCallback(
    async (moduleId?: string, updateSlide = true, prev = false, wasEval = false): Promise<void> => {
      setAppState({ moduleId });
      if (updateSlide) setAppState({ slideId: undefined, slide: undefined });
      if (!moduleId) return;

      setLoading({ module: moduleId });

      if (!testMode)
        await client.query<ModuleQuery>({
          query: MODULE_QUERY,
          variables: { moduleId, quality: videoQuality, mp4: useMp4 },
        });

      const module = testMode
        ? { ...testModule, slides: Object.values(testSlides) }
        : client.readFragment<ModuleFragment>({
            id: `Module:${moduleId}`,
            fragment: MODULE_FRAGMENT,
            variables: { quality: videoQuality, mp4: useMp4 },
          });

      if (!module) {
        return throwError("Module not found");
      }

      const { sectionId, slides } = module;

      const newAppState: Partial<AppState> = { moduleId, sectionId, module };

      if (updateSlide) {
        if (module.type === moduleType.QUIZ) changeView(View.Quiz);
        else if (module.type === moduleType.SURVEY) changeView(View.Survey);
        else {
          changeView(testMode ? View.Test : View.Video);
          if (!slides.length) {
            newAppState.slideId = null;
            newAppState.slideTime = null;
          } else {
            newAppState.slideId = slides[prev ? slides.length - 1 : 0].id;
            newAppState.slideTime = module.lastView ? module.lastView.resumeTime : "00:00:00";
            setSlide(newAppState.slideId, newAppState.slideTime ?? undefined);
          }
        }
      }

      createAnalyticsEvent({
        feature: "playback",
        action: "changeSlide",
      }).fire("telemetry");

      setAppState(newAppState);
      setLoading({ module: undefined });

      if (wasEval) {
        setIsPlaying(false);
      }
    },
    [
      client,
      changeView,
      videoQuality,
      throwError,
      createAnalyticsEvent,
      useMp4,
      testMode,
      testSlides,
    ],
  );

  const setSlide = useCallback(
    async (slideId?: string, setTime: string | boolean = false): Promise<void> => {
      if (!slideId || testMode) return setAppState({ slideId });

      const changeSlide = client.readFragment<ChangeSlideFragment>({
        id: `Slide:${slideId}`,
        fragment: CHANGE_SLIDE_FRAGMENT,
      });

      if (!changeSlide) return;

      createAnalyticsEvent({
        feature: "playback",
        action: "changeSlide",
      }).fire("telemetry");

      setAppState({ slideId });
      changeView(View.Video);

      if (changeSlide.moduleId !== appState.moduleId) {
        await setModule(changeSlide.moduleId, false);
      }

      const slide = client.readFragment<SlideFragment>({
        id: `Slide:${slideId}`,
        fragment: SLIDE_FRAGMENT,
        variables: {
          quality: videoQuality,
          mp4: useMp4,
        },
      });

      if (!slide) throw new Error("Slide not found");

      const slideTime =
        typeof setTime == "string" ? setTime : setTime ? slide?.lastView?.resumeTime : null;

      setAppState({ slide, slideTime });
    },
    [
      client,
      createAnalyticsEvent,
      changeView,
      testMode,
      appState.moduleId,
      setModule,
      useMp4,
      videoQuality,
    ],
  );

  const isLastSlide = useMemo(() => {
    if (!appState.slideId || !module) return false;
    const slideIdx = module?.slides.findIndex((slide) => slide.id === appState.slideId);
    return module.slides[slideIdx + 1] === undefined;
  }, [appState.slideId, module]);

  const nextSlide = useCallback(
    (prev = false): void => {
      if (appState.slideId === undefined || !module) return;

      const nextSlide = module.slides[slideIdx.index! + (prev ? -1 : 1)];

      setSlide(nextSlide?.id);
    },
    [module, setSlide, appState.slideId, slideIdx.index],
  );

  const preloadVideoPaths = useMemo(() => {
    const preloadPaths: string[] = [];
    if (!appState.slideId || !module?.slides || !module?.baseUrl) return preloadPaths;
    const slideIdx = module?.slides.findIndex((slide) => slide.id === appState.slideId);
    const nextSlide = module.slides[slideIdx + 1];
    const slideAfter = module.slides[slideIdx + 2];

    if (nextSlide)
      preloadPaths.push(...[`${module?.baseUrl}${nextSlide.videoPath}`, nextSlide.captionsUrl]);
    if (slideAfter)
      preloadPaths.push(...[`${module?.baseUrl}${slideAfter.videoPath}`, slideAfter.captionsUrl]);

    return preloadPaths;
  }, [appState.slideId, module]);

  const nextModule = useCallback(
    async (prev = false, wasEval = false): void => {
      if (!appState.moduleId || !course) return;

      const modules = course.sections.map((section) => section.modules).flat();
      const nextModule = modules[moduleIdx.index! + (prev ? -1 : 1)];

      if (nextModule) {
        await setModule(nextModule.id, true, prev, wasEval);
      }
    },
    [setModule, appState.moduleId, course, moduleIdx.index],
  );

  return {
    appState,
    nextSlide,
    setSlide,
    setModule,
    setCourse,
    setInstance,
    setVideoQuality,
    slide,
    module,
    section,
    course,
    isLastSlide,
    nextModule,
    userId,
    slideIdx,
    moduleIdx,
    preloadVideoPaths,
    loading,
    updateVideoQuality,
    refreshModule,
  };
}
