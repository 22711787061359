import {
  primary,
  secondary,
  blue,
  blueGrey,
  greyscale,
  success,
  error,
  grey,
  warning,
  white,
  black,
} from "./palette";
import { hexToRgba } from "../util/hexToRgba";

export const lightPalette = {
  palette: {
    background: {
      default: "#F6F9FC",
    },
    text: {
      primary: greyscale[700],
      secondary: grey[100],
      link: blue[500],
      linkHover: blue[700],
      disabled: greyscale[400],
      error: error[500],
      success: success[500],
      highContrast: black,
      book: greyscale[800],
      title: greyscale[700],
      titleGrey: greyscale[700],
      titlePrimary: primary.main,
    },
  },
  components: {
    MuiPaper: { styleOverrides: { root: { background: white } } },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: greyscale[700],
        },
      },
    },
    MuiLoadingButton: {
      styleOverrides: { root: { color: primary.main, borderColor: primary.main } },
    },
    MuiCssBaseline: {
      styleOverrides: `
        input {
          background: ${white};
          color: ${greyscale[700]};
          border: 1px solid ${blueGrey[100]};
        },
        input::placeholder {
          color: ${greyscale[500]};
        }
      `,
    },
  },
  custom: {
    palette: {
      semantic: {
        blue: blue[500],
        blueGrey: secondary.main,
        grey: greyscale[100],
        greyLight: greyscale[400],
        greyDark: greyscale[600],
        greyExpired: greyscale[200],
        rowAlternatingBg: blue[50],
        rowActiveBg: primary.main,
        sliderThumbOuterShadow: hexToRgba(black, 0.16),
        modalBg: white,
        modalTitleColor: blueGrey[500],
        modalHeaderColor: greyscale[600],
        menuBg: white,
        menuHoverBg: greyscale[800],
        inputIconColor: blueGrey[300],
        inputBg: white,
        inputBgOpaque: hexToRgba(white, 0.35),
        accordionBorder: greyscale[100],
        accordionBg: white,
        iconFailedColor: greyscale[600],
        iconSomewhatColor: blueGrey[500],
        iconHoverColor: blue[50],
        iconColor: greyscale[100],
        linearProgressBg: greyscale[100],
        rule: "#eee",
        darkBg: greyscale[500],
        wrapperBgDark: greyscale[50],
        readMessage: warning[100],
        buttonContainedBg: primary.main,
        buttonContainedBorder: primary.main,
        buttonContainedColor: white,
        buttonContainedIcon: white,
        buttonContainedFocusBg: primary.main,
        buttonContainedFocusBorder: blue[700],
        buttonContainedFocusColor: white,
        buttonContainedFocusIcon: white,
        buttonContainedHoverBg: blue[700],
        buttonContainedHoverBorder: blue[700],
        buttonContainedHoverColor: white,
        buttonContainedHoverIcon: white,
        buttonContainedDisabledBg: blueGrey[50],
        buttonContainedDisabledBorder: blueGrey[50],
        buttonContainedDisabledColor: blueGrey[400],
        buttonContainedDisabledIcon: blueGrey[400],
        buttonOutlinedBg: white,
        buttonOutlinedBorder: primary.main,
        buttonOutlinedColor: primary.main,
        buttonOutlinedIcon: primary.main,
        buttonOutlinedFocusBg: white,
        buttonOutlinedFocusBorder: blue[700],
        buttonOutlinedFocusColor: primary.main,
        buttonOutlinedFocusIcon: primary.main,
        buttonOutlinedHoverBg: blueGrey[50],
        buttonOutlinedHoverBorder: primary.main,
        buttonOutlinedHoverColor: primary.main,
        buttonOutlinedHoverIcon: primary.main,
        buttonOutlinedDisabledBg: white,
        buttonOutlinedDisabledBorder: blueGrey[400],
        buttonOutlinedDisabledColor: blueGrey[400],
        buttonOutlinedDisabledIcon: blueGrey[400],
        buttonIconColor: primary.main,
        buttonIconHoverColor: blue[700],
        buttonIconFocusColor: blue[600],
        buttonIconDisabledColor: blueGrey[400],
        buttonIconSecondaryColor: secondary.main,
        buttonIconSecondaryHoverColor: secondary.main,
        buttonIconSecondaryFocusColor: secondary.main,
      },
    },
    gradients: {
      rowBgFade: `linear-gradient(to top, ${white}, ${hexToRgba(white, 0)})`,
      rowAlternatingBgFade: `linear-gradient(to top, ${blue[50]}, ${hexToRgba(blue[50], 0)})`,
    },
  },
};
