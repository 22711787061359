import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";

export const MainHeader = styled("div")(({ theme }) => ({
  height: "74px",
  width: "100%",
  background: theme.palette.primary.main,
  borderRadius: `${theme.custom.radius[2]} ${theme.custom.radius[2]} 0px 0px`,
  zIndex: -1,
  position: "absolute",
  [theme.breakpoints.down("sm")]: {
    borderRadius: "0px",
    height: "161px",
  },
}));

export const Title = styled("h3")(({ theme }) => ({
  color: theme.custom.palette.white,
  fontSize: "1.125rem",
  fontWeight: 600,
  lineHeight: "22px",
}));

export const ZopimContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(0),
  minHeight: "400px",
  borderRadius: theme.custom.radius[2],

  textarea: {
    minHeight: 240,
    borderColor: "#8E96A1",
  },

  ".input": {
    minHeight: 0,
    height: 60,
    lineHeight: "20px",
    borderBottomLeftRadius: "17px",
    border: "1px solid #fff",
  },

  ".input:focus": {
    border: "1px solid #8E96A1",
  },

  "label.label": {
    fontSize: 14,
    color: "#2e2e2e",
    fontFamily: "Open Sans",
    paddingBottom: theme.spacing(3),
  },

  ".card-icon": {
    display: "none",
  },

  ".widget-container": {
    height: "calc(100% - 74px)",
    ".avatar svg": {
      margin: "0 auto",
    },
  },

  ".card-content": {
    borderStyle: "none",
    padding: theme.spacing(0, 2),
  },

  ".button-container": {
    textAlign: "right",
  },

  ".action-button.button-send": {
    display: "inline-block",
    width: "auto",
    fontSize: 14,
    fontWeight: "bold",
    fontFamily: "Open Sans",
    position: "relative",
    overflow: "hidden",
  },

  ".spinner-wrapper": {
    color: "#2e2e2e",
  },

  ".action-button.button-send:focus": {
    "&::before": {
      content: "''",
      position: "absolute",
      opacity: ".50",
      display: "inline-block",
      width: "50px",
      height: "50px",
      top: "-8px",
      left: "0",
      overflow: "hidden",
      borderRadius: "50%",
      backgroundColor: "#ffffff",
      animation: "ripple 2.5s infinite ease-in-out",
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.9)",
    },
    "50%": {
      transform: "scale(1)",
    },
    "100%": {
      transform: "scale(.9)",
    },
  },
}));
