import { TypePolicies } from "@apollo/client";
import Slide from "./typePolicies/Slide";
import Course from "./typePolicies/Course";
import Module from "./typePolicies/Module";
import Section from "./typePolicies/Section";
import SlideView from "./typePolicies/SlideView";
import TrainingInstance from "./typePolicies/TrainingInstance";
import Notes from "./typePolicies/Notes";
import Bookmark from "./typePolicies/Bookmark";
import LocalStorage from "./typePolicies/LocalStorage";
import ConfidenceRating from "./typePolicies/ConfidenceRating";
import CourseProgress from "./typePolicies/CourseProgress";
import RosterUser from "./typePolicies/RosterUser";

export default {
  SlideView,
  Slide,
  Module,
  Section,
  Course,
  TrainingInstance,
  Notes,
  Bookmark,
  ConfidenceRating,
  /** @todo put these fields under Player, not Query */
  Query: LocalStorage,
  CourseProgress,
  RosterUser,
} as TypePolicies;
