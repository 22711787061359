import React from "react";
import { Grid, FormControl, InputLabel } from "@mui/material";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useAppState } from "../../hooks/useAppState";
import { getReadableFromSeconds, getSecondsFromTimestamp } from "../../util/time";
import {
  SelectBox,
  StyledSelect,
  StyledTypography,
  ViewedIcon,
  Duration,
  MenuOption,
} from "./Module.elements";
import { useViewport, View } from "../Viewport/useViewport";
import { ModuleFragment_slides } from "../../hooks/__generated__/ModuleFragment";

function readableDuration(duration: string | undefined): string {
  let seconds = getSecondsFromTimestamp(duration);
  if (Math.floor(seconds) === 1) seconds = 10;
  return getReadableFromSeconds(seconds, true, true);
}

export function Select(data: { data: ModuleFragment_slides[] }): JSX.Element | null {
  const { slide, setSlide } = useAppState();
  const { changeView, view } = useViewport();
  if (!slide) return null;

  const moduleLength = data.data.length;

  const SelectRenderItem = () => (
    <SelectBox sx={{ display: "flex", gap: 1 }}>
      <Grid id="slide_select_info" container justifyContent="space-between">
        <OndemandVideoIcon className="MuiSelect-icon" />
        <StyledTypography id="slide_select_title" component="p" truncate>
          Slide {slide?.number} of {moduleLength}: {slide?.name}
        </StyledTypography>
        <StyledTypography id="slide_select_duration" bold>
          {readableDuration(slide?.duration)}
        </StyledTypography>
      </Grid>
    </SelectBox>
  );
  const SelectMenuItem = (item: ModuleFragment_slides, index: number) => (
    <MenuOption
      id={"select_slide_" + index}
      value={item.id}
      key={item.id}
      onClick={() => {
        if (view === View.Confidence) {
          changeView(View.Video);
        }
        return item.id && setSlide(item.id);
      }}
    >
      <Grid container justifyContent="space-between">
        <StyledTypography id={"select_slide_" + index + "title"} component="p" truncate>
          {item?.number}: {item?.name}
        </StyledTypography>
        <Duration>
          <StyledTypography id={"select_slide_" + index + "duration"} bold>
            {readableDuration(item?.duration)}
          </StyledTypography>
          <ViewedIcon aria-label="Viewed" className={item?.lastView ? "viewed" : ""} />
        </Duration>
      </Grid>
    </MenuOption>
  );

  return (
    <FormControl sx={{ flex: 1 }}>
      <InputLabel sx={{ display: "none" }}>Select Slide</InputLabel>
      <StyledSelect
        aria-label="Choose your slide"
        size="small"
        value={slide?.id}
        IconComponent={KeyboardArrowDownIcon}
        renderValue={() => <SelectRenderItem />}
      >
        {data.data.map((item, index) => SelectMenuItem(item, index))}
      </StyledSelect>
    </FormControl>
  );
}
