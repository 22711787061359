import React from "react";
import { Forward10 } from "@mui/icons-material";
import ButtonIconContainer from "../../../ButtonIconContainer/ButtonIconContainer";

type ButtonSeekForwardProps = {
  skip: (amount: number) => void;
};

export default function ButtonSKipForward({ skip }: ButtonSeekForwardProps): JSX.Element {
  const handleClick = () => skip(10);

  return (
    <ButtonIconContainer
      onClick={handleClick}
      ariaLabel="Skip forward 10 seconds (right arrow)"
      id="button_skip_forward"
      size="large"
      tooltip="Skip forward 10 seconds (right arrow)"
    >
      <Forward10 />
    </ButtonIconContainer>
  );
}
