import { TypePolicy } from "@apollo/client";
import { getSecondsFromTimestamp } from "../../util/time";

export default {
  merge: true,
  fields: {
    progress: {
      read(_, { readField }) {
        const viewedTimestamp: string | undefined = readField("resumeTime", readField("lastView"));
        const viewedSeconds = viewedTimestamp ? getSecondsFromTimestamp(viewedTimestamp) : 0;
        const totalSeconds = getSecondsFromTimestamp(readField("duration"));
        return {
          total: totalSeconds,
          viewed: viewedTimestamp !== undefined ? totalSeconds : 0,
          totalSlides: 1,
          viewedSlides: viewedTimestamp !== undefined ? 1 : 0,
          percent: totalSeconds ? Math.round((viewedSeconds / totalSeconds) * 100) : 0,
          isViewed: viewedTimestamp !== undefined,
          totalQuizzes: 0,
          passedQuizzes: 0,
        };
      },
    },
  },
} as TypePolicy;
