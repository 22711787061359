import React, { useContext } from "react";
import { useTheme } from "@mui/material/styles";
import { Stack, useMediaQuery } from "@mui/material";

import { GridViewport, InlineContainer, StyledPaper } from "./Player.elements";
import { Viewport } from "../../components/Viewport/Viewport";
import { useViewport, View } from "../../components/Viewport/useViewport";
import { Module } from "../../components/Module/Module";
import { Instructor } from "../../components/Instructor/Instructor";
import { HelpAndSupport } from "./HelpAndSupport";
import { InstructorWrapper, ViewportSkeleton, ModuleSkeleton } from "./ViewportContent.elements";
import { useAppState } from "../../hooks/useAppState";
import { CourseContext } from "../../context/course-context";
import MuiSkeleton from "@mui/material/Skeleton";

export function ViewportContent(): JSX.Element | null {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const { view } = useViewport();
  const course = useContext(CourseContext);
  const { module } = useAppState();
  const showInstructor = view !== View.Quiz && view !== View.Survey;

  const getViewportClasses = () => {
    const classes = ["viewport"];
    if (!course || !module) {
      classes.push("skeleton");
    }
    if (view === View.Quiz) {
      classes.push("fullView");
    }
    return classes.join(" ");
  };

  const getModuleWrapperClasses = () => {
    const classes = ["moduleWrapper"];
    if (!module) {
      classes.push("skeleton");
    }
    return classes.join(" ");
  };

  return (
    <Stack
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <GridViewport className={getViewportClasses()}>
        {course && module ? (
          <Viewport />
        ) : (
          <ViewportSkeleton className="viewportSkeleton" animation="wave" variant="rectangular" />
        )}
      </GridViewport>
      {!isTablet && showInstructor ? (
        <StyledPaper className={getModuleWrapperClasses()}>
          {module ? (
            <>
              <Module />
              <InstructorWrapper>
                <Instructor />
                <HelpAndSupport />
              </InstructorWrapper>
            </>
          ) : (
            <ModuleSkeleton>
              <MuiSkeleton animation="wave" variant="rectangular" />
              <MuiSkeleton animation="wave" variant="rectangular" />
            </ModuleSkeleton>
          )}
        </StyledPaper>
      ) : (
        <InlineContainer className={getModuleWrapperClasses()} id="inline">
          {module ? <Module /> : null}
        </InlineContainer>
      )}
    </Stack>
  );
}
