import { styled } from "@mui/material/styles";
import { Fab } from "@mui/material";

export const Wrapper = styled("div")(() => ({
  display: "flex",
  justifyContent: "flex-end",

  ".focusLockWrapper": {
    width: "auto",
  },
}));

export const HelpContainer = styled("div")(({ theme }) => ({
  display: "flex",
  placeItems: "center",
  flexDirection: "column",
  paddingTop: theme.spacing(0.5),
}));

export const HelpButton = styled(Fab)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "48px",
  height: "48px",
  background: theme.custom.palette.semantic.buttonContainedBg,
  border: `1px solid ${theme.custom.palette.semantic.buttonContainedBorder}`,
  color: theme.custom.palette.semantic.buttonContainedColor,
  ["&:hover"]: {
    background: theme.custom.palette.semantic.buttonContainedHoverBg,
    border: `1px solid ${theme.custom.palette.semantic.buttonContainedHoverBorder}`,
    color: theme.custom.palette.semantic.buttonContainedHoverColor,
  },
  ["&:focus"]: {
    background: theme.custom.palette.semantic.buttonContainedFocusBg,
    border: `1px solid ${theme.custom.palette.semantic.buttonContainedFocusBorder}`,
    color: theme.custom.palette.semantic.buttonContainedFocusColor,
  },

  ["& > .hasMessage"]: {
    position: "absolute",
    top: "-0.5rem",
    right: "-0.5rem",
    background: "red",
    color: "white",
    fontSize: ".85rem",
    fontWeight: "bold",
    height: "1.5rem",
    width: "1.5rem",
    lineHeight: "1.5rem",
    textAlign: "center",
    borderRadius: "50%",
  },
  ["& > .noMessage"]: {
    display: "none",
  },
}));

export const HelpLabel = styled("label")(({ theme }) => ({
  fontSize: "0.75rem",
  marginBottom: theme.spacing(1),
}));

export const HelpText = styled("span")(() => ({
  fontSize: "0.875rem",
  fontWeight: 600,
  fontStyle: "normal",
  lineHeight: "20px",
}));

export const CourseWrapper = styled("div")({
  flexGrow: 1,
  position: "relative",
  width: "100%",
});
