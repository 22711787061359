import { useState, useEffect } from "react";

export const useDevice = () => {
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  useEffect(() => {
    const isTouchDevice = "ontouchstart" in window || navigator.maxTouchPoints > 0;
    setIsTouchDevice(isTouchDevice);
  }, []);

  return { isTouchDevice };
};
