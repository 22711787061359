import { useApolloClient } from "@apollo/client";
import gql from "graphql-tag";
import { useCallback } from "react";
import { Rating } from "../../__generated__/globalTypes";
import { useAppState } from "../../hooks/useAppState";
import { SaveConfidence } from "./__generated__/SaveConfidence";
import { telemetryChannelName } from "../../util/constants";
import { useAnalyticsEvents } from "@atlaskit/analytics-next";

export const SAVE_CONFIDENCE_MUTATION = gql`
  mutation SaveConfidence(
    $courseId: Int!
    $moduleId: String!
    $notes: String!
    $instanceId: String!
    $rating: Rating!
  ) {
    rateConfidence(
      courseId: $courseId
      moduleId: $moduleId
      notes: $notes
      instanceId: $instanceId
      rating: $rating
    ) {
      moduleId
      rating
    }
  }
`;

export function useConfidence(): {
  saveConfidence: (rating: Rating) => void;
} {
  const { createAnalyticsEvent } = useAnalyticsEvents();
  const {
    setCourse,
    appState: { courseId, moduleId, instanceId },
  } = useAppState();

  const client = useApolloClient();

  const saveConfidence = useCallback(
    async (rating: Rating): Promise<void> => {
      await client.mutate<SaveConfidence>({
        mutation: SAVE_CONFIDENCE_MUTATION,
        variables: {
          courseId,
          moduleId,
          instanceId,
          notes: "",
          rating,
        },
        update: (cache, { data }) => {
          if (data?.rateConfidence) {
            const moduleCacheId = cache.identify({ __typename: "Module", id: moduleId });
            if (moduleCacheId) {
              cache.modify({
                id: moduleCacheId,
                fields: {
                  confidenceRating: () => data.rateConfidence,
                },
              });
            }
            setCourse(courseId ?? undefined);
          }
        },
      });

      createAnalyticsEvent({
        feature: "confidence",
        action: rating === Rating.UNANSWERED ? "skip" : "save",
      }).fire(telemetryChannelName);
    },
    [courseId, instanceId, moduleId, client, createAnalyticsEvent],
  );

  return { saveConfidence };
}
