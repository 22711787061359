import React, { useCallback, useState } from "react";
import { Button, Dialog, DialogContent, DialogActions, TextField, Box } from "@mui/material";
import { useTestMode } from "../../hooks/useTestMode";

const LoadCoursebookModal = () => {
  const { modalOpen, setModalOpen, loadTestBooks, booksPath } = useTestMode();
  const [coursebookPath, setCoursebookPath] = useState(booksPath);
  const [pathMissing, setPathMissing] = useState<boolean>();

  const loadBooks = useCallback(() => {
    setModalOpen(false);
    loadTestBooks(coursebookPath);
  }, [loadTestBooks, setModalOpen, coursebookPath]);

  return (
    <Dialog open={modalOpen}>
      <DialogContent>
        <Box>Enter the Dropbox path of the course you want to load:</Box>
        <TextField
          autoFocus
          fullWidth
          id="ticket-subject"
          label="Path"
          type="text"
          variant="standard"
          value={coursebookPath}
          onChange={(e) => {
            setCoursebookPath(e.target.value);
            setPathMissing(!e.target.value);
          }}
          error={pathMissing === true}
        />
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Button variant="outlined" onClick={() => setModalOpen(false)}>
          Cancel
        </Button>
        <Button variant="contained" onClick={() => loadBooks()} disabled={!coursebookPath}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LoadCoursebookModal;
