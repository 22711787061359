export const quizPracticeLink = "https://www.giac.org/frequently-asked-questions/#practice";
export const v1PlayerUrl = "https://olt-content.sans.org/production/latest/ProxGwtApp.html";
export const myCoursesUrl = "https://ondemand.sans.org/";
export const accountDashboardUrl = "https://www.sans.org/account";
export const logoutUrl = "https://www.sans.org/account/logout";
export const sansHomeUrl = "https://www.sans.org/";
export const privacyPolicyUrl = "https://www.sans.org/privacy/";
export const telemetryApiUrl: string = process.env.REACT_APP_TELEMETRY_ENDPOINT as string;
export const telemetryChannelName = "telemetry";
export const playerModality = "web2";
export const speedModifier = 0.1;
export const timeoutSeconds = 90;
export const saveProgressInterval = 10;
export const dropboxOauthUrl = "https://www.dropbox.com/oauth2/authorize";
