import React, { useEffect, useMemo, useState } from "react";
import { createTheme, CssBaseline, useMediaQuery, ThemeProvider } from "@mui/material";
import customTheme from "../../styles/theme";
import * as locales from "../../locale/index";
import { HasbotProvider } from "../../context/hasBot-context";
import { TelemetryListener } from "../../telemetry/TelemetryLayer";
import { usePersistedSettings } from "../../hooks/usePersistedSettings";
import { LocalizationProvider, SupportedLocales } from "../../context/localization-context";
import { ProgressModal } from "../Progress/ProgressModal";
import { CourseProvider } from "../../context/course-context";

const PlayerWrapper: React.FC = ({ children }) => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [locale, setLocale] = useState<SupportedLocales>("enUS");
  const { mode, setMode } = usePersistedSettings();

  const preferredMode = prefersDarkMode ? "dark" : "light";

  const theme = useMemo(
    () => createTheme(customTheme(mode || preferredMode), locales[locale]),
    [prefersDarkMode, locale, mode],
  );

  useEffect(() => {
    if (!mode) {
      setMode(preferredMode);
    }
  }, [mode, preferredMode]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LocalizationProvider value={{ locale, setLocale }}>
        <TelemetryListener>
          <ProgressModal />
          <CourseProvider>
            <HasbotProvider>{children}</HasbotProvider>
          </CourseProvider>
        </TelemetryListener>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default PlayerWrapper;
