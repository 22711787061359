import { makeVar, useReactiveVar } from "@apollo/client";
import { useCallback } from "react";

export enum View {
  Video,
  Confidence,
  Slides,
  Quiz,
  Survey,
  Test,
}

const viewportVar = makeVar(View.Video);

export function useViewport(): {
  view: View;
  changeView: (newView: View) => void;
} {
  const view = useReactiveVar(viewportVar);

  const changeView = useCallback((newView: View) => {
    viewportVar(newView);
  }, []);

  return { view, changeView };
}
