import { Box, Button } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "700px",
  backgroundColor: theme.custom.palette.semantic.modalBg,
  borderRadius: "6px",
  boxShadow: "10px 10px 10px rgba(0, 0, 0, 0.1)",
  padding: "1rem",
  "& ul": {
    paddingLeft: "1.2rem",
  },
  "& hr": {
    height: "1px",
    backgroundColor: theme.custom.palette.greyscale[200],
    border: "none",
    margin: "16px 0",
  },
}));

export const ModalHeader = styled("h4")(({ theme }) => ({
  fontSize: "0.875rem",
  fontWeight: 600,
  lineHeight: "22px",
  marginTop: 0,
  color: theme.custom.palette.semantic.modalHeaderColor,
}));

export const ButtonContainer = styled("div")(() => ({
  display: "flex",
  justifyContent: "flex-end",
  marginTop: "1rem",
}));

export const CloseButton = styled(Button)(({ theme }) => ({
  fontWeight: 600,
  background: theme.custom.palette.semantic.buttonContainedBg,
  border: `1px solid ${theme.custom.palette.semantic.buttonContainedBorder}`,
  color: theme.custom.palette.semantic.buttonContainedColor,
  ["&:hover"]: {
    background: theme.custom.palette.semantic.buttonContainedHoverBg,
    border: `1px solid ${theme.custom.palette.semantic.buttonContainedHoverBorder}`,
    color: theme.custom.palette.semantic.buttonContainedHoverColor,
  },
  ["&:focus"]: {
    background: theme.custom.palette.semantic.buttonContainedFocusBg,
    border: `1px solid ${theme.custom.palette.semantic.buttonContainedFocusBorder}`,
    color: theme.custom.palette.semantic.buttonContainedFocusColor,
  },
}));
