import { Box, Container } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useTestMode } from "../../hooks/useTestMode";
import LoadCoursebookModal from "./LoadCoursebookModal";
import { Button } from "@mui/material";
import { useAppState } from "../../hooks/useAppState";
import { dropboxOauthUrl } from "../../util/constants";
import { SlideButtonBox } from "./TestView.elements";

const CLIENT_ID = process.env.REACT_APP_DROPBOX_CLIENT_ID;
const REDIRECT_URI = `${location.origin}/previewer`;

export const TestView = () => {
  const { booksPath, setModalOpen, checkSessionActive, loading, refreshTestBooks, loaded } =
    useTestMode();

  const [sessionActive, setSessionActive] = useState(false);

  const {
    setModule,
    setSlide,
    appState: { moduleId, slideId },
    nextSlide,
  } = useAppState();

  useEffect(() => {
    if (moduleId && loaded) setModule(moduleId);
  }, [moduleId, loaded, setModule]);

  const getSessionActive = useCallback(async () => {
    setSessionActive(await checkSessionActive());
  }, [checkSessionActive]);

  useEffect(() => {
    getSessionActive();
  }, [getSessionActive]);

  const refresh = useCallback(async () => {
    await refreshTestBooks();
    if (slideId) setSlide(slideId);
  }, [refreshTestBooks, setSlide, slideId]);

  const connectToDropbox = () => {
    const authUrl = `${dropboxOauthUrl}?client_id=${CLIENT_ID}&response_type=code&redirect_uri=${REDIRECT_URI}`;
    window.location.href = authUrl;
  };

  return (
    <Container>
      <Box sx={{ width: "50%", margin: "auto", paddingTop: "12em" }}>
        {loading ? (
          <p>Loading...</p>
        ) : loaded ? (
          <p>Loaded path: {booksPath}</p>
        ) : (
          <p>No coursebook loaded.</p>
        )}
        {!loading && sessionActive && (
          <>
            <Button variant="outlined" onClick={() => setModalOpen(true)} id="test-mode-btn">
              <span id="test-mode" className="message-text">
                {loaded ? "Change path" : "Load coursebook"}
              </span>
            </Button>
            {loaded && (
              <>
                <Button
                  variant="outlined"
                  onClick={() => refresh()}
                  id="test-mode-btn"
                  sx={{ marginLeft: "1em" }}
                >
                  <span id="test-mode" className="message-text">
                    Refresh
                  </span>
                </Button>

                <SlideButtonBox>
                  <Button
                    variant="contained"
                    onClick={() => nextSlide(true)}
                    id="prev-test-slide-btn"
                  >
                    <span id="prev-test-slide" className="message-text">
                      Previous slide
                    </span>
                  </Button>
                  <Button variant="contained" onClick={() => nextSlide()} id="next-test-slide-btn">
                    <span id="next-test-slide" className="message-text">
                      Next slide
                    </span>
                  </Button>
                </SlideButtonBox>
              </>
            )}
          </>
        )}
        {!sessionActive && (
          <Button onClick={connectToDropbox} id="test-mode-btn" variant="outlined">
            <span id="test-mode" className="message-text">
              Connect to Dropbox
            </span>
          </Button>
        )}
        <LoadCoursebookModal />
      </Box>
    </Container>
  );
};
