import React from "react";
import { CaptionsText } from "./Captions.elements";
import { sanitize } from "../../util/html";

export default function Captions({ text }: { text: string }): JSX.Element | null {
  return (
    <CaptionsText
      data-testid="captions-text"
      dangerouslySetInnerHTML={{ __html: sanitize(text) }}
    ></CaptionsText>
  );
}
