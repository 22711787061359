import React from "react";
import { Tooltip } from "@mui/material";
import { SkipNext } from "@mui/icons-material";
import { SkipButton } from "../../Controls.elements";

interface ButtonNextProps {
  handleClickNextVideo: () => void;
  disabled: boolean;
}

export default function ButtonNext({
  handleClickNextVideo,
  disabled,
}: ButtonNextProps): JSX.Element {
  return (
    <Tooltip title={`Next slide (n)`}>
      <SkipButton
        disabled={disabled}
        disableFocusRipple={true}
        onClick={handleClickNextVideo}
        aria-label="Go to next slide"
        id="button_next_slide"
        sx={{
          ml: "auto",
        }}
      >
        <SkipNext />
      </SkipButton>
    </Tooltip>
  );
}
