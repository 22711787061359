import React, { useCallback, useEffect, useState } from "react";
import { CardContent, Typography, Box, Avatar, Alert, Stack } from "@mui/material";
import { Launch } from "@mui/icons-material";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

import { MyCoursesQuery_myCourses_rosterUsers } from "../../pages/Library/__generated__/MyCoursesQuery";
import { useCourseProgress } from "../../hooks/useCourseProgress";
import SetupInstructions from "../../pages/Library/SetupInstructions";

import {
  CertLink,
  CertText,
  DownloadCertLink,
  LibraryButton,
  LibraryCardElement,
  ExpirationText,
  CourseName,
  HeaderContentWrapper,
} from "./LibraryCard.elements";
import CourseAssets from "./CourseAssets";
import ProgressOverview from "../Progress/ProgressOverview";
import { useProgressModal } from "../../hooks/useProgressModal";
import { useHistory } from "react-router-dom";
import { useAppState } from "../../hooks/useAppState";
import { useCourseDetails } from "../../pages/Library/useCourseDetails";

interface LibraryCardProps {
  course: MyCoursesQuery_myCourses_rosterUsers;
}

export const LibraryCard: React.FC<LibraryCardProps> = ({ course }) => {
  const { setInstance, setCourse } = useAppState();
  const { progress, expiration, completion, openCert, updateProgressState } = useCourseProgress(
    course.course.trainingId,
    course.course.id,
    course.trainingInstance?.id,
    course.id,
  );

  const { getCourseDetails, gotDetails } = useCourseDetails(
    course.course.trainingId,
    course.trainingInstance?.id,
  );
  const [opening, setOpening] = useState(false);

  useEffect(() => {
    updateProgressState();
  }, [updateProgressState]);

  const history = useHistory();

  const { openModal } = useProgressModal();

  const openProgress = useCallback(() => {
    openModal({
      courseId: course.course.trainingId,
      courseUuid: course.course.id,
      instanceId: course.trainingInstance?.id,
    });
  }, [course.course.id, course.course.trainingId, course.trainingInstance?.id, openModal]);

  const openCourse = useCallback(() => {
    if (course.trainingInstance?.id) setInstance(course.trainingInstance?.id);
    setCourse(course.course.trainingId);
    history.push(`/${course.course.trainingId}`);
  }, [course.course.trainingId, course.trainingInstance?.id, history, setCourse, setInstance]);

  useEffect(() => {
    if (opening && gotDetails) {
      openCourse();
      setOpening(false);
    }
  }, [opening, gotDetails, openCourse]);

  return (
    <LibraryCardElement onMouseEnter={() => !expiration?.isExpired && getCourseDetails()}>
      <CardContent sx={{ width: "100%", padding: "0" }}>
        <Stack gap={2}>
          <HeaderContentWrapper className="headerContentWrapper">
            <Box sx={{ flexGrow: 1 }}>
              <CourseName
                fontWeight="600"
                component={expiration?.isExpired ? "h3" : "h2"}
                className={expiration?.isExpired ? "expired" : undefined}
                onClick={!expiration?.isExpired ? openCourse : undefined}
                sx={{ cursor: !expiration?.isExpired ? "pointer" : undefined }}
              >
                {course.course.name}
              </CourseName>
              {expiration && (
                <ExpirationText className={expiration?.isExpired ? "expired" : ""}>
                  <TimelapseIcon sx={{ width: "0.7em", height: "0.7em", verticalAlign: "sub" }} />{" "}
                  {expiration?.expirationText} ({expiration?.expirationDate})
                </ExpirationText>
              )}
            </Box>
            <LibraryButton
              variant="contained"
              disabled={expiration?.isExpired || opening}
              disableElevation
              startIcon={
                !expiration?.isExpired ? <PlayArrowIcon sx={{ marginTop: "0.1em" }} /> : undefined
              }
              onClick={!expiration?.isExpired ? () => setOpening(true) : undefined}
            >
              {!course.trainingInstance
                ? "Start Course"
                : !expiration.isExpired
                ? "Open Course"
                : "Expired"}
            </LibraryButton>
          </HeaderContentWrapper>
          {completion && (
            <Alert
              severity="success"
              icon={false}
              sx={{ textAlign: "center", justifyContent: "center" }}
            >
              <Typography sx={{ marginBottom: "0.5em" }} fontWeight="700">
                Congratulations on completing your course!
              </Typography>
              <DownloadCertLink onClick={openCert}>
                Download your certificate of completion
              </DownloadCertLink>
            </Alert>
          )}
          {progress && course.trainingInstance && expiration && (
            <Box>
              <ProgressOverview
                compact
                title="Progress"
                progress={progress}
                expiration={expiration}
                openProgress={openProgress}
              />

              {course.giacPurchased && course.certification && (
                <Box sx={{ display: "flex", marginTop: "1em" }}>
                  <Avatar
                    src={course.certification.imageUrl ?? undefined}
                    sx={{ height: "1.8em", width: "1.8em" }}
                  >
                    G
                  </Avatar>{" "}
                  <CertText>
                    {course.certification.name} ({course.certification.initials})
                  </CertText>
                  <CertLink href="https://exams.giac.org/">
                    Manage Exam Attempt{" "}
                    <Launch sx={{ height: "0.8em", width: "0.8em", verticalAlign: "sub" }} />
                  </CertLink>
                </Box>
              )}
            </Box>
          )}

          <CourseAssets
            hasMyLabs={course.course.hasMyLabs}
            handouts={course.course.handouts}
            licenses={course.course.licenses}
          />

          <SetupInstructions
            hasMyLabs={course.course.hasMyLabs}
            shortName={course.course.shortName ?? undefined}
            shippingTracking={course.shippingTracking}
            quickstartGuideUrl={course.course.quickstartGuideUrl ?? undefined}
          />
        </Stack>
      </CardContent>
    </LibraryCardElement>
  );
};
