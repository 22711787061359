import { useCallback, useEffect, useLayoutEffect, useRef } from "react";

export const useKeyPress = (
  keys: string[],
  callback: Function,
  node: HTMLDivElement | null = null,
) => {
  const callbackRef = useRef(callback);

  useLayoutEffect(() => {
    callbackRef.current = callback;
  });

  const handleKeyPress = useCallback(
    (event) => {
      if (!event.repeat && keys.some((key) => event.key === key)) {
        callbackRef.current(event);
      }
    },
    [keys],
  );

  const handleModeKeyUp = useCallback(
    (event) => {
      if (
        !event.repeat &&
        keys.some((key) => event.key === key) &&
        (event.key === "Control" ||
          event.key === "Meta" ||
          event.key === "Alt" ||
          event.key === "Shift")
      ) {
        callbackRef.current(event);
      }
    },
    [keys],
  );

  useEffect(() => {
    const targetNode = node ?? document;

    targetNode && targetNode.addEventListener("keydown", handleKeyPress);

    return () => targetNode && targetNode.removeEventListener("keydown", handleKeyPress);
  }, [handleKeyPress, node]);

  useEffect(() => {
    const targetNode = node ?? document;

    targetNode && targetNode.addEventListener("keyup", handleModeKeyUp);

    return () => targetNode && targetNode.removeEventListener("keyup", handleModeKeyUp);
  }, [handleModeKeyUp, node]);
};
