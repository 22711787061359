import React from "react";
import { OutlineWrapper } from "./Outline.elements";
import OutlineSection from "./OutlineSection";
import { useAppState } from "../../hooks/useAppState";
import { getFirstSectionIndex } from "../../util/course";

export function Outline({ active }: { active: boolean }): JSX.Element | null {
  const { course: outline } = useAppState();

  if (!outline || !active) return null;

  const firstSectionIndex = getFirstSectionIndex(outline.sections);

  return (
    <OutlineWrapper id="course_outline" role="complementary" aria-label="Course Outline">
      {outline.sections.slice(firstSectionIndex).map((section, sectionIdx) => (
        <OutlineSection key={section.id} section={section} sectionIdx={sectionIdx} />
      ))}
    </OutlineWrapper>
  );
}
