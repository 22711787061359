import { styled } from "@mui/material/styles";

export const CaptionsText = styled("div")(({ theme }) => ({
  padding: theme.spacing(2),
  color: theme.custom.palette.white,
  fontSize: "1.4em",
  textAlign: "center",
  width: "100%",
  height: "118px",
  zIndex: 998,
  background: theme.custom.palette.black,
  whiteSpace: "pre-line",

  [theme.breakpoints.up("md")]: {
    fontSize: "1.6em",
  },

  [theme.breakpoints.up("lg")]: {
    fontSize: "1.8em",
  },
}));
