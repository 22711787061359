import React from "react";
import { Grid } from "@mui/material";
import { Rating } from "../../__generated__/globalTypes";
import { useAppState } from "../../hooks/useAppState";
import { useConfidence } from "./Confidence.queries";
import {
  StyledFormControl,
  StyledFormLabel,
  ChoicesWrapper,
  Choice,
  SmallText,
} from "./Confidence.elements";

export const Confidence = (): JSX.Element => {
  const { module, nextModule } = useAppState();
  const { saveConfidence } = useConfidence();

  const confidenceChoices = [
    {
      value: Rating.CONFIDENT,
      label: "Yes",
    },
    {
      value: Rating.SOMEWHAT_CONFIDENT,
      label: "Somewhat",
    },
    {
      value: Rating.NOT_CONFIDENT,
      label: "No",
    },
    {
      value: Rating.UNANSWERED,
      label: "Skipped",
    },
  ];

  const save = (rating: Rating): void => {
    nextModule();
    saveConfidence(rating);
  };

  return (
    <Grid container justifyContent="center" sx={{ height: "488px" }} alignItems="center">
      <StyledFormControl>
        <StyledFormLabel id="demo-row-radio-buttons-group-label">
          Do you feel confident that you understand Module {module?.fullNumber}: {module?.name}?
        </StyledFormLabel>
        <ChoicesWrapper>
          {confidenceChoices.map((choice) => {
            if (choice.label !== "Skipped") {
              return (
                <Choice
                  key={choice.value}
                  color="primary"
                  variant="contained"
                  onClick={() => save(choice.value)}
                  aria-label={choice.label}
                >
                  {choice.label}
                </Choice>
              );
            }
          })}
        </ChoicesWrapper>
        <div style={{ paddingBottom: "1em" }}>
          <Choice
            key={Rating.UNANSWERED}
            color="primary"
            variant="contained"
            onClick={() => save(Rating.UNANSWERED)}
            aria-label="skip"
          >
            Skip
          </Choice>
        </div>
        <SmallText>(You can come back later)</SmallText>
      </StyledFormControl>
    </Grid>
  );
};
