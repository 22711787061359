import React, { useEffect, useMemo, useState } from "react";
import { ApolloProvider } from "@apollo/client";
import { createClient } from "../../apollo/client";
import { useHistory } from "react-router-dom";
import { useIdToken } from "@sans-tesseract/sdk-react";
import { useErrorHandler } from "../../hooks/useErrorHandler";
import { Sans } from "../../util/sdk";

export function AuthenticatedApolloProvider({
  requiredRole,
  children,
}: {
  requiredRole?: string;
  children: JSX.Element;
}): JSX.Element | null {
  const [authorized, setAuthorized] = useState<boolean>();
  const { loading, token: authToken } = useIdToken();

  const history = useHistory();
  const { reportError } = useErrorHandler();

  const client = useMemo(() => createClient(reportError), [reportError]);

  async function authorizeRole(role: string) {
    setAuthorized(await Sans.sdk.isAuthorized(role));
  }

  useEffect(() => {
    if (!loading && !authToken) {
      const redirectPath = location.pathname;
      history.push("/authorize", { redirectPath });
    } else if (!loading && requiredRole) {
      authorizeRole(requiredRole);
    } else if (!requiredRole) {
      setAuthorized(true);
    }
  }, [loading, authToken, history, requiredRole]);

  if (!authToken || !client || authorized === undefined) {
    return <div>Loading...</div>;
  } else if (!authorized) {
    return <div>Unauthorized</div>;
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
