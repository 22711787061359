import React, { useContext, useEffect, useMemo, useState } from "react";
import { Quill } from "quill";
import classNames from "classnames";

import { Popover, useTheme } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import BorderColorIcon from "@mui/icons-material/BorderColor";

import { NotesContext } from "../../context/notes-context";
import ColorPicker, { ColorType } from "./ColorPicker";

import { formatTextSelection, getFormat, setContrastColor } from "./utils";

export const modules = {
  toolbar: {
    container: "#toolbar",
  },
};

export const formats = [
  "bold",
  "italic",
  "size",
  "background",
  "separator",
  "list",
  "bullet",
  "color",
];

const palette = {
  color: ["#000000", "#ffffff", "#d44040", "#0b24fb", "#51a95d"],
  background: ["#fffd38", "#29fd2e", "#2cfffe", "#fc29fc", "#0b24fb", "#000000", "#ffffff"],
};

const defaults = {
  background: "#fffd38",
  color: {
    light: "#000000",
    dark: "#ffffff",
  },
};

const ReactQuillToolbar = () => {
  const [color, setColor] = useState<ColorType>(false);
  const [colorActive, setColorActive] = useState(false);
  const [background, setBackground] = useState<ColorType>(defaults.background);
  const [backgroundActive, setBackgroundActive] = useState(false);

  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { editorRef } = useContext(NotesContext);

  const editor: Quill | undefined = editorRef.current?.getEditor();

  const formatType = useMemo(() => {
    const attr = anchorEl?.getAttribute("aria-label");
    return attr?.match(/text/i) ? "color" : attr?.match(/highlight/i) ? "background" : null;
  }, [anchorEl]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openColorPicker = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const toggleHighlight = () => {
    if (!editor) return;

    const { format } = getFormat(editor);

    formatTextSelection(editor, "background", format.background ? false : background);
    setContrastColor(editor);
  };

  const setHighlightColor = (value: ColorType) => {
    setBackground(value);

    if (!editor) return;

    setBackgroundActive(!!value);
    value && setContrastColor(editor);
    formatTextSelection(editor, "background", value);
  };

  const setTextColor = (value?: ColorType) => {
    if (!editor) return;

    setColorActive(!!value);
    value && setColor(value);
    formatTextSelection(editor, "color", typeof value !== "undefined" ? value : color);
  };

  const handleColorClick = () => setTextColor();

  useEffect(() => {
    setColor(defaults.color[theme.palette.mode]);
  }, [theme.palette.mode]);

  useEffect(() => {
    if (!editor) return;

    editor.on("selection-change", () => {
      const { format } = getFormat(editor);

      setColorActive(!!format.color);
      setBackgroundActive(!!format.background);

      setBackground((prevState) => prevState || defaults.background);
    });
  }, [editor]);

  const open = Boolean(anchorEl);

  const highlightClassnames = classNames("highlight-text", {
    "ql-active": backgroundActive,
  });

  const textColorClassnames = classNames("test", {
    "ql-active": colorActive,
  });

  return (
    <>
      <Popover
        id="color-picker"
        role="dialog"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{ p: 1 }}
        transitionDuration={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <ColorPicker
          active={formatType === "color" ? color : background}
          onColorClick={formatType === "color" ? setTextColor : setHighlightColor}
          colors={palette[formatType as keyof typeof palette]}
        />
      </Popover>

      <div id="toolbar">
        <span className="ql-formats">
          <button title="Bold" className="ql-bold" />
          <button title="Italic" className="ql-italic" />
          <select title="Font Size" className="ql-size ql-picker ql-size-picker" />
        </span>

        <span>
          <span className="ql-separator" />
        </span>

        <span className="ql-formats">
          <span className="button-group">
            <button
              className={highlightClassnames}
              aria-label="Toggle highlight"
              onClick={toggleHighlight}
              title="Toggle Highlight"
            >
              <BorderColorIcon />
            </button>
            <button
              className="chevron-down"
              id="Highlight color picker"
              aria-label="Highlight color picker"
              onClick={openColorPicker}
              title="Highlight Color"
            >
              <ArrowDropDownIcon />
            </button>
          </span>

          <span className="button-group">
            <button className={textColorClassnames} onClick={handleColorClick}>
              <FormatColorTextIcon />
            </button>
            <button
              title="Text Color"
              className="chevron-down"
              aria-label="Text color picker"
              onClick={openColorPicker}
            >
              <ArrowDropDownIcon />
            </button>
          </span>
        </span>

        <span>
          <span className="ql-separator" />
        </span>

        <span className="ql-formats">
          <button title="Ordered List" className="ql-list" value="ordered" />
          <button title="Bulleted Color" className="ql-list" value="bullet" />
        </span>
      </div>
    </>
  );
};

export default ReactQuillToolbar;
