import React from "react";
import { IconButton } from "@mui/material";
import { Container } from "./ControlsCenter.elements";
import ButtonNext from "./ButtonNext/ButtonNext";
import ButtonPrevious from "./ButtonPrevious/ButtonPrevious";
import { Spinner } from "../../Video/Video.elements";

type ControlsCenterProps = {
  isPlaying: boolean;
  handleClickPlayPause: () => void;
  handleClickVideoContainer: (event: React.MouseEvent) => void;
  handleClickPreviousVideo: () => void;
  handleClickNextVideo: () => void;
  loading: boolean;
};

export default function ControlsCenter({
  isPlaying,
  handleClickPlayPause,
  handleClickVideoContainer,
  handleClickNextVideo,
  handleClickPreviousVideo,
  loading,
}: ControlsCenterProps): JSX.Element {
  const IconButtonStyles = {
    transform: "scale(2)",
  };

  return (
    <Container
      data-testid="controls-center"
      id="controls-center"
      onClick={handleClickVideoContainer}
    >
      <ButtonPrevious disabled={loading} handleClickPreviousVideo={handleClickPreviousVideo} />
      <IconButton
        aria-label={isPlaying ? "pause video  " : "play video"}
        size="large"
        sx={IconButtonStyles}
        tabIndex={1}
        disabled={loading}
        onClick={handleClickPlayPause}
      >
        {loading && <Spinner />}
      </IconButton>
      <ButtonNext disabled={loading} handleClickNextVideo={handleClickNextVideo} />
    </Container>
  );
}
