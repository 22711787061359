import React, { ReactNode } from "react";
import { ButtonProps, IconButton, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";

interface ButtonIconContainerProps extends ButtonProps {
  children: ReactNode;
  ariaLabel: string;
  tooltip: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (arg0: any) => void;
  id: string;
  tabIndex?: number;
  fontSize?: number;
}

const StyledIconButton = styled(IconButton)<{ fontSize?: number }>(({ theme, fontSize }) => ({
  color: theme.palette.grey[50],
  padding: "8px",
  ["& .MuiSvgIcon-root"]: {
    [theme.breakpoints.up("xs")]: {
      fontSize: "1.25rem",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.25rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.5rem",
    },

    fontSize: fontSize ? `${fontSize}rem !important` : "initial",
  },
}));

const ButtonIconContainer = ({
  ariaLabel,
  tooltip,
  children,
  onClick,
  id,
  tabIndex = 1,
  fontSize,
  ...rest
}: ButtonIconContainerProps): JSX.Element => (
  <Tooltip title={tooltip}>
    <span>
      <StyledIconButton
        onClick={onClick}
        aria-label={ariaLabel}
        size="large"
        id={id}
        tabIndex={tabIndex}
        fontSize={fontSize}
        {...rest}
      >
        {children}
      </StyledIconButton>
    </span>
  </Tooltip>
);

export default ButtonIconContainer;
