import React, { FC } from "react";
import { ErrorBoundary } from "./ErrorBoundary";
import { ErrorModal } from "./ErrorModal";
import { ErrorToast } from "./ErrorToast";
import { ErrorSeverity, useErrorHandler } from "../../hooks/useErrorHandler";
import { ErrorPage } from "./ErrorPage";

export const ErrorWrapper: FC = ({ children }) => {
  const { error } = useErrorHandler(ErrorSeverity.HIGH);

  return (
    <ErrorBoundary>
      <ErrorModal />
      <ErrorToast />
      <ErrorPage />

      <div
        style={{
          display: error ? "none" : "flex",
          flexDirection: "column",
          width: "100%",
          flex: 1,
        }}
      >
        {children}
      </div>
    </ErrorBoundary>
  );
};
