import React from "react";
import { Dialog, IconButton } from "@mui/material";
import { MiniControls } from "./MiniControls";
import { Coursebook } from "./Coursebook";
import { Close } from "@mui/icons-material";
import { DialogContent } from "./CoursebookModal.elements";

export function CoursebookModal({
  open,
  setOpen,
  handleChangeFontSize,
  fontSize,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  handleChangeFontSize(fontSize: number): void;
  fontSize: number;
}): JSX.Element | null {
  const toggleModal = (open: boolean) => () => setOpen(open);

  return (
    <Dialog
      open={open}
      onClose={toggleModal(false)}
      maxWidth="md"
      PaperProps={{ sx: { width: "1000px" } }}
    >
      <IconButton
        aria-label="close"
        onClick={toggleModal(false)}
        sx={{
          position: "absolute",
          right: "0.5em",
          top: "0.5em",
          color: (theme) => theme.palette.grey[300],
        }}
      >
        <Close />
      </IconButton>
      <DialogContent>
        <Coursebook
          isModal
          titleSize={6}
          fontSize={fontSize}
          handleChangeFontSize={handleChangeFontSize}
          customControl={<MiniControls />}
        />
      </DialogContent>
    </Dialog>
  );
}
