import React, { FC, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Header } from "../../components/Header/Header";
import { Box, Container, Divider, Link, Skeleton, Stack } from "@mui/material";
import { useMyCoursesQuery } from "./Library.queries";
import {
  StyledFooter,
  HelpLink,
  TypeContainer,
  PaperContainer,
  LoadMoreButton,
} from "./Library.elements";
import { privacyPolicyUrl } from "../../util/constants";
import { LibraryCard } from "../../components/LibraryCard/LibraryCard";
import { LibraryCardInit } from "../../components/LibraryCard/LibraryCardInit";
import { UserType } from "../../__generated__/globalTypes";
import { filterCourses } from "./utils";
import { useAppState } from "../../hooks/useAppState";

export const Library: FC = () => {
  const { setCourse, setModule, setSlide } = useAppState();
  const { courses, loading, loadMore, hasMore } = useMyCoursesQuery();

  useEffect(() => {
    setCourse();
    setModule();
    setSlide();
  }, [setCourse, setModule, setSlide]);

  const hasIncompleteCourses = courses?.some(
    (course) => course.userType !== UserType.ExpiredUser && !course.course.completion,
  );

  const filteredCourses = filterCourses(courses);

  return (
    <>
      <Helmet>
        <title>SANS OnDemand - Course Library</title>
      </Helmet>
      <Header library={true} />
      <Stack>
        <Container sx={{ minHeight: "calc(100% - 10em)", paddingTop: "1em" }}>
          {courses ? (
            <>
              <main>
                {hasIncompleteCourses && (
                  <PaperContainer sx={{ marginTop: "1em" }}>
                    <TypeContainer>
                      You will receive your certificate of completion when you view 80% of the
                      slides or complete 80% of the quizzes with a score of 80% or higher.
                    </TypeContainer>
                  </PaperContainer>
                )}
                {filteredCourses.active.map((course) =>
                  course.userType === UserType.InitUser ? (
                    <LibraryCardInit key={course.id} course={course} />
                  ) : (
                    <LibraryCard key={course.id} course={course} />
                  ),
                )}
              </main>
              {!!filteredCourses.expired.length && (
                <section aria-label="Expired courses">
                  <TypeContainer textAlign="left" variant="h6" component="h2" mt={6}>
                    Expired Courses
                  </TypeContainer>

                  {filteredCourses.expired.map((course) => (
                    <LibraryCard key={course.id} course={course} />
                  ))}
                </section>
              )}
            </>
          ) : null}
          <Box sx={{ marginTop: "2em" }}>
            {loading ? (
              <Skeleton variant="rectangular" height={300} />
            ) : hasMore ? (
              <LoadMoreButton variant="contained" disableElevation onClick={loadMore}>
                Load more
              </LoadMoreButton>
            ) : null}
          </Box>
          <PaperContainer>
            <TypeContainer>
              If you need help, you can contact us directly through the Help and Support feature,
              email us at <HelpLink href="mailto:ondemand@sans.org">ondemand@sans.org</HelpLink>,
              call us at <HelpLink href="tel:+13016547267">(301) 654-7267</HelpLink> (Mon-Fri,
              9am–8pm ET), or search our{" "}
              <HelpLink href="https://www.sans.org/frequently-asked-questions/?categories=ondemand-training">
                FAQs
              </HelpLink>
              .
            </TypeContainer>
          </PaperContainer>

          <section aria-label="Mobile app">
            <Link href="https://www.sans.org/mlp/ondemand-mobile-app/" display="block" mt={5}>
              <img
                style={{ width: 355, maxWidth: "100%" }}
                src="/assets/app_banner.png"
                alt={"OnDemand Mobile App"}
              ></img>
            </Link>
          </section>
        </Container>
        <StyledFooter>
          <span>© {new Date().getFullYear()} SANS™ Institute</span>
          <Divider orientation="vertical" flexItem sx={{ margin: "0 1em" }} />
          <Link href={privacyPolicyUrl}>Privacy Policy</Link>
        </StyledFooter>
      </Stack>
    </>
  );
};
