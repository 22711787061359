import { AnalyticsListener, UIAnalyticsEvent } from "@atlaskit/analytics-next";
import React from "react";
import TelemetryModel from "./TelemetryModel";
import { useAppState } from "../hooks/useAppState";
import { usePersistedSettings } from "../hooks/usePersistedSettings";
import { telemetryChannelName, playerModality } from "../util/constants";
import { sessionId } from "../util/sessionId";
import packageJson from "../../package.json";
import { useErrorHandler } from "../hooks/useErrorHandler";

export const TelemetryListener = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  const {
    appState: { courseId, moduleId, slideId },
    userId,
  } = useAppState();
  const { volume, autoplay, quality, playback, muted } = usePersistedSettings();
  const { throwError } = useErrorHandler();

  const onEvent = async (event: UIAnalyticsEvent): Promise<void> => {
    const {
      payload: { action, feature },
    } = event;

    const dataLoaded: boolean = !!courseId && !!moduleId && !!userId;
    if (!dataLoaded) {
      return;
    }

    try {
      await TelemetryModel({
        action,
        feature,
        course_id: courseId as number,
        lo_uuid: moduleId as string,
        slide_uuid: slideId ?? undefined,
        videoresolution: quality,
        autoplay,
        volumepercent: volume,
        playbackspeed: playback,
        student_session: sessionId(),
        player_modality: playerModality,
        player_version: packageJson.version,
        student_id: userId as number,
        mute: muted,
      }).save();
    } catch (error) {
      throwError(error as Error);
    }
  };

  return (
    <AnalyticsListener channel={telemetryChannelName} onEvent={onEvent}>
      {children}
    </AnalyticsListener>
  );
};
