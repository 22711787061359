import { styled } from "@mui/material/styles";
import { Grid, TextareaAutosize, Checkbox, FormControlLabel } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

export const Title = styled("h3")(({ theme }) => ({
  color: theme.custom.palette.white,
  fontSize: "1.125rem",
  fontWeight: 600,
  lineHeight: "22px",
}));

export const PageContainer = styled(Grid)(({ theme }) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
}));

export const SupportMessageTextField = styled(TextareaAutosize)(({ theme }) => ({
  width: "100%",
  minHeight: "240px",
  color: theme.palette.text.highContrast,
  borderColor:
    theme.palette.mode === "dark" ? theme.custom.palette.blue[250] : theme.palette.grey[200],
  padding: theme.spacing(1),
  resize: "none",
  borderRadius: "4px",
  background: theme.custom.palette.semantic.inputBg,

  "&::placeholder": {
    color: theme.custom.palette.semantic.greyLight,
  },
}));

export const MessageActionsContainer = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(3),
}));

export const StyledFormLabel = styled(FormControlLabel)(({ theme }) => ({
  marginRight: 0,

  "& span": {
    marginRight: 0,
    fontSize: "0.875rem",
    fontWeight: 400,
    color: theme.palette.text.primary,
  },
}));

export const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main,
  marginRight: theme.spacing(1),
}));

export const MessageActionButtons = styled(LoadingButton)(({ theme, variant }) => ({
  padding: "6px 10px",
  fontSize: "0.875rem",
  fontWeight: 700,
  lineHeight: "16px",
  borderRadius: theme.custom.radius[1],
  ...(variant === "contained" && {
    background: theme.custom.palette.semantic.buttonContainedBg,
    color: theme.custom.palette.semantic.buttonContainedColor,

    "&:hover": {
      background: theme.custom.palette.semantic.buttonContainedHoverBg,
      color: theme.custom.palette.semantic.buttonContainedHoverColor,
    },

    "&:focus": {
      background: theme.custom.palette.semantic.buttonContainedFocusBg,
      color: theme.custom.palette.semantic.buttonContainedFocusColor,
    },

    "&:disabled": {
      background: theme.custom.palette.semantic.buttonContainedDisabledBg,
      color: theme.custom.palette.semantic.buttonContainedDisabledColor,
    },
  }),
  ...(variant !== "contained" && {
    background: theme.custom.palette.semantic.buttonOutlinedBg,
    color: theme.custom.palette.semantic.buttonOutlinedColor,

    "&:hover": {
      background: theme.custom.palette.semantic.buttonOutlinedHoverBg,
      color: theme.custom.palette.semantic.buttonOutlinedHoverColor,
    },

    "&:focus": {
      background: theme.custom.palette.semantic.buttonOutlinedFocusBg,
      color: theme.custom.palette.semantic.buttonOutlinedFocusColor,
    },

    "&:disabled": {
      background: theme.custom.palette.semantic.buttonOutlinedDisabledBg,
      color: theme.custom.palette.semantic.buttonOutlinedDisabledColor,
    },
  }),
}));
