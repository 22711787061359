import React, { useMemo, useRef, useState } from "react";
import { Box, useMediaQuery, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import { Section, ProgressBar, SectionTitle, SectionWrapper } from "./Outline.elements";
import OutlineModule from "./OutlineModule";
import { useFocus } from "../../hooks/useFocus";
import { useTouched } from "../../hooks/useTouched";
import { useProgress } from "../../hooks/useProgress";
import { FullCourseQuery_course_sections } from "../../pages/Player/__generated__/FullCourseQuery";
import { CourseQuery_course_sections } from "../../pages/Player/__generated__/CourseQuery";

const OutlineSection = ({
  section,
  sectionIdx,
}: {
  section: FullCourseQuery_course_sections & CourseQuery_course_sections;
  sectionIdx: number;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState<string | boolean>(false);
  const { progressForSection } = useProgress();

  const [touched] = useTouched({ ref });
  const [focus, setFocus] = useFocus({ ref, size: section.modules.length });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const sectionNumber = `Section ${sectionIdx + 1}`;
  const sectionTitle = section.name;

  const sectionProgress = useMemo(
    () => progressForSection(section.id),
    [progressForSection, section.id],
  );

  const handleChange = (panel: string) => (_event: object, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const expandIcon =
    expanded === section.id ? (
      <RemoveCircleOutline color="secondary" />
    ) : (
      <AddCircleOutline color="secondary" />
    );

  return (
    <SectionWrapper key={section.id} ref={ref} role="group" aria-label={sectionTitle}>
      <Section>
        <Box className="title">{sectionNumber}:</Box>
        {section.modules && (
          <ProgressBar
            variant="determinate"
            value={sectionProgress.percentSlides ?? undefined}
            aria-label={`Progress for section ${sectionIdx + 1}`}
          />
        )}
      </Section>

      {isMobile ? (
        <Accordion onChange={handleChange(section.id)} sx={{ padding: "8px 24px" }}>
          <AccordionSummary expandIcon={expandIcon}>
            <SectionTitle>{sectionTitle}</SectionTitle>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              {section.modules.map((module, moduleIdx: number) => (
                <div key={module.id}>
                  <OutlineModule
                    module={module}
                    setFocus={setFocus}
                    moduleIdx={moduleIdx}
                    sectionIdx={sectionIdx}
                    focus={touched && focus === moduleIdx}
                    tabIndex={focus === moduleIdx ? 2 : -1}
                  />
                </div>
              ))}
            </Box>
          </AccordionDetails>
        </Accordion>
      ) : (
        <>
          <SectionTitle>{sectionTitle}</SectionTitle>
          {section.modules.map((module: ReadOutline_course_sections_modules, moduleIdx: number) => (
            <OutlineModule
              key={module.id}
              module={module}
              setFocus={setFocus}
              moduleIdx={moduleIdx}
              sectionIdx={sectionIdx}
              focus={touched && focus === moduleIdx}
              tabIndex={focus === moduleIdx ? 2 : -1}
            />
          ))}
        </>
      )}
    </SectionWrapper>
  );
};

export default OutlineSection;
