import { TypePolicy } from "@apollo/client";

export const fieldName = "expirationDateTime";

export default {
  keyFields: ["instanceId"],
  fields: {
    percentSlides: {
      read(_, { readField }) {
        const viewedSlides = Number(readField("viewedSlides"));
        const totalSlides = Number(readField("totalSlides"));
        return Math.ceil((viewedSlides / totalSlides) * 100);
      },
    },
    percentQuizzes: {
      read(_, { readField }) {
        const passedQuizzes = Number(readField("passedQuizzes"));
        const totalQuizzes = Number(readField("totalQuizzes"));
        return Math.ceil((passedQuizzes / totalQuizzes) * 100);
      },
    },
    hoursRemaining: {
      read(_, { readField }) {
        const viewedSeconds = Number(readField("viewedSeconds"));
        const totalSeconds = Number(readField("totalSeconds"));
        const remainingSeconds = totalSeconds - viewedSeconds;
        return Math.floor(remainingSeconds / 3600);
      },
    },
  },
} as TypePolicy;
