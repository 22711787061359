import { styled } from "@mui/material/styles";
import { Button, Typography } from "@mui/material";

export const LandingPageWrapper = styled("div")(({ theme }) => ({
  color: theme.palette.text.highContrast,
  backgroundColor: theme.palette.mode === "dark" ? "#393C45" : "#fff",
  height: "100%",
  textAlign: "left",
  padding: theme.spacing(3),
}));

export const Wrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(10),
  textAlign: "center",
  maxWidth: 1400,
  margin: "0 auto",
  "& a": {
    color: theme.palette.text.titlePrimary,
  },
}));

export const StyledTextButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.titlePrimary,
  fontWeight: 600,
  fontSize: "1rem",
}));

export const StyledTypography = styled(Typography)({
  fontSize: "1rem",
  fontWeight: 600,
  marginBottom: "40px",
});
