import moment from "moment";
import { CompletionReason } from "../__generated__/globalTypes";

/* eslint-disable sonarjs/no-duplicate-string */
export const module = {
  id: "071cac06-5091-40a2-9f90-172acaf2ed34",
  name: "Introduction to Comprehensive Pen Test Planning, Scoping, and Recon",
  type: "CONTENT",
  confidenceRating: null,
  quizScore: null,
  quizInProgress: false,
  sectionId: "70e9ff5a-3b5a-483b-b5db-b12a1b24aafc",
  fullNumber: "1.1",
  baseUrl: "ondemand",
  duration: 100,
  cookies: [
    {
      key: "CloudFront-Policy",
      value:
        "eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9vbHQtY29udGVudC5zYW5zLm9yZy8wNzFjYWMwNi01MDkxLTQwYTItOWY5MC0xNzJhY2FmMmVkMzQvKiIsIkNvbmRpdGlvbiI6eyJEYXRlTGVzc1RoYW4iOnsiQVdTOkVwb2NoVGltZSI6MTYzNjU2ODg0Mn19fV19",
      path: "/071cac06-5091-40a2-9f90-172acaf2ed34",
      domain: ".sans.org",
      expires: "2021-11-10T18:27:22.000Z",
      secure: true,
      __typename: "Cookie",
    },
    {
      key: "CloudFront-Key-Pair-Id",
      value: "APKAJ7CMX2GMBBBHEH5A",
      path: "/071cac06-5091-40a2-9f90-172acaf2ed34",
      domain: ".sans.org",
      expires: "2021-11-10T18:27:22.000Z",
      secure: true,
      __typename: "Cookie",
    },
    {
      key: "CloudFront-Signature",
      value:
        "ODiZmq7791BMJ0VabinRmzIV5wj6MU-iFhdC5ODmrxvtgUewsBk4wtnGWAmecT~We-Go42i9ANzLh9~G90-YVr96SRzHXlI3qSAK2~E58DqWgj5AN0OgYXfX8hZhoIILZGAjLBDYTlxrZD63J9TpilYKwcOw6oFLZ3vnc4skVvrYLs7lsnh9yESiiZYQx14WeTL-reuNZAjXjm4lpvtdg2y2Los0D4xOhZ~AcC09y8UQ~XLfUEIyOJlgnm6Id7nstqoCP~OJgus4GD6iFFwTYI9ROTKoAwmN3L~SlzJD2-JBEo-rtca7NxcZnPfKN3aIdgWz8eFa85L46PntCyvSQQ__",
      path: "/071cac06-5091-40a2-9f90-172acaf2ed34",
      domain: ".sans.org",
      expires: "2021-11-10T18:27:22.000Z",
      secure: true,
      __typename: "Cookie",
    },
  ],
  progress: {
    viewedSlides: 2,
    totalSlides: 3,
  },
  slides: [
    {
      id: "4e97d6c9-0c9e-40a4-b75f-89858126aeec",
      moduleId: "071cac06-5091-40a2-9f90-172acaf2ed34",
      name: "Comprehensive Pen Test Planning, Scoping, and Recon",
      captionsUrl: null,
      thumbnailPath: "",
      bookmarks: [
        {
          slideId: "4e97d6c9-0c9e-40a4-b75f-89858126aeec",
          timestamp: "00:00:00",
        },
      ],
      notes: {
        content: "hello!",
        updated: "2021-12-03T16:18:08.000Z",
        slideId: "4e97d6c9-0c9e-40a4-b75f-89858126aeec",
      },
      number: 1,
      fullNumber: "1.1.1",
      duration: "02:15:00.000",
      videoPath: "/video/001-720.mp4",
      bookPath: "/notes/001.html",
      lastView: {
        dateTimeViewed: "2021-12-03T16:18:08.000Z",
        slideId: "4e97d6c9-0c9e-40a4-b75f-89858126aeec",
        resumeTime: "01:00:00.000",
        __typename: "SlideView",
      },
      __typename: "Slide",
    },
    {
      id: "d0096366-a688-4c7a-b5af-0a6c49ca76fd",
      moduleId: "071cac06-5091-40a2-9f90-172acaf2ed34",
      name: "Table of Contents (1)",
      captionsUrl: null,
      thumbnailPath: "",
      bookmarks: null,
      notes: {
        content: "goodbye",
        updated: "2021-12-03T16:18:08.000Z",
        slideId: "4e97d6c9-0c9e-40a4-b75f-89858126aeec",
      },
      number: 1,
      fullNumber: "1.1.1",
      duration: "00:29:00.000",
      videoPath: "/video/002-480.mp4",
      bookPath: "/notes/001.html",
      lastView: {
        dateTimeViewed: "2021-12-03T16:18:08.000Z",
        slideId: "d0096366-a688-4c7a-b5af-0a6c49ca76fd",
        resumeTime: "00:01:00.000",
        __typename: "SlideView",
      },
      __typename: "Slide",
    },
    {
      id: "66fa892c-6cac-47a7-a7e8-fadf5111631f",
      moduleId: "071cac06-5091-40a2-9f90-172acaf2ed34",
      name: "Table of Contents (2)",
      captionsUrl: null,
      thumbnailPath: "",
      bookmarks: null,
      notes: null,
      number: 1,
      fullNumber: "1.1.1",
      duration: "00:31:00.000",
      videoPath: "/video/001-480.mp4",
      bookPath: "/notes/001.html",
      lastView: null,
      __typename: "Slide",
    },
  ],
  __typename: "Module",
};

export const module2 = {
  id: "54bcd41b-437b-4efa-b98b-f0590b0794cd",
  name: "Pen Test Planning",
  type: "CONTENT",
  confidenceRating: null,
  quizScore: null,
  quizInProgress: false,
  sectionId: "70e9ff5a-3b5a-483b-b5db-b12a1b24aafc",
  fullNumber: "1.2",
  baseUrl: "ondemand",
  duration: 100,
  cookies: [
    {
      key: "CloudFront-Policy",
      value:
        "eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9vbHQtY29udGVudC5zYW5zLm9yZy8wNzFjYWMwNi01MDkxLTQwYTItOWY5MC0xNzJhY2FmMmVkMzQvKiIsIkNvbmRpdGlvbiI6eyJEYXRlTGVzc1RoYW4iOnsiQVdTOkVwb2NoVGltZSI6MTYzNjU2ODg0Mn19fV19",
      path: "/54bcd41b-437b-4efa-b98b-f0590b0794cd",
      domain: ".sans.org",
      expires: "2021-11-10T18:27:22.000Z",
      secure: true,
      __typename: "Cookie",
    },
    {
      key: "CloudFront-Key-Pair-Id",
      value: "APKAJ7CMX2GMBBBHEH5A",
      path: "/54bcd41b-437b-4efa-b98b-f0590b0794cd",
      domain: ".sans.org",
      expires: "2021-11-10T18:27:22.000Z",
      secure: true,
      __typename: "Cookie",
    },
    {
      key: "CloudFront-Signature",
      value:
        "ODiZmq7791BMJ0VabinRmzIV5wj6MU-iFhdC5ODmrxvtgUewsBk4wtnGWAmecT~We-Go42i9ANzLh9~G90-YVr96SRzHXlI3qSAK2~E58DqWgj5AN0OgYXfX8hZhoIILZGAjLBDYTlxrZD63J9TpilYKwcOw6oFLZ3vnc4skVvrYLs7lsnh9yESiiZYQx14WeTL-reuNZAjXjm4lpvtdg2y2Los0D4xOhZ~AcC09y8UQ~XLfUEIyOJlgnm6Id7nstqoCP~OJgus4GD6iFFwTYI9ROTKoAwmN3L~SlzJD2-JBEo-rtca7NxcZnPfKN3aIdgWz8eFa85L46PntCyvSQQ__",
      path: "/54bcd41b-437b-4efa-b98b-f0590b0794cd",
      domain: ".sans.org",
      expires: "2021-11-10T18:27:22.000Z",
      secure: true,
      __typename: "Cookie",
    },
  ],
  progress: {
    viewedSlides: 0,
    totalSlides: 1,
  },
  slides: [
    {
      id: "fed1d5bc-b881-4674-8f08-20a93a73b3b2",
      moduleId: "54bcd41b-437b-4efa-b98b-f0590b0794cd",
      name: "Course Roadmap (1)",
      captionsUrl: null,
      thumbnailPath: "",
      bookmarks: null,
      notes: null,
      number: 1,
      fullNumber: "1.2.1",
      duration: "00:00:05.000",
      videoPath: "/video/002-480.mp4",
      bookPath: "/notes/001.html",
      lastView: null,
      __typename: "Slide",
    },
  ],
  __typename: "Module",
};

export default {
  data: {
    course: {
      id: "daf38e3b-220a-4845-a346-b00f4940035f",
      name: "SEC560: Network Penetration Testing and Ethical Hacking (E01_14_SH_CM_6380)",
      shortName: "SEC560",
      courseType: "Production",
      trainingId: 1000,
      completion: {
        reason: CompletionReason.SLIDES_VIEWED,
        dateTimeCompleted: "2021-11-10T18:27:22.000Z",
      },
      complete: true,
      messages: [{ text: "This is a test message", link: "https://www.sans.org" }],
      availableResolutions: ["HD", "SD"],
      fullVersion: "E01_14_SH_CM_6380",
      version: "E01",
      sections: [
        {
          id: "70e9ff5a-3b5a-483b-b5db-b12a1b24aafc",
          name: "Comprehensive Pen Test Planning, Scoping, and Recon",
          number: 1,
          surveyUrl: "/",
          modules: [module, module2],
          __typename: "Section",
        },
      ],
      contributors: [
        {
          id: "a6274b66-e013-11eb-b03f-0ab6df170bbb",
          bio: {
            firstName: "Tim",
            lastName: "Medin",
            __typename: "Bio",
          },
          profile: {
            name: "Tim Medin",
            image:
              "https://images.contentstack.io/v3/assets/blt36c2e63521272fdc/blte45cbe4b42f3af7f/5e9d123c4c47dc64d2a64677/370x370_Tim-Medin.jpg",
            text: '<p>Tim is the Founder and CEO of <a href="https://www.redsiege.com/">Red Siege Information Security</a> and is also&nbsp;a Senior&nbsp;Instructor, Course Author, and MSISE Program Director at SANS Institute. Through the course of his career, Tim has performed penetration tests on a wide range of organizations and technologies. He has gained information security experience in a variety of industries including previous positions in control systems, higher education, financial services, and manufacturing. Tim is an experienced international speaker, having presented to organizations around the world. Tim is the creator of the Kerberoasting, a widely utilized Red Team penetration test technique to extract kerberos tickets in order to offline attack the password of enterprise service accounts. Tim earned his MBA through the University of Texas.</p>',
            link: "https://sans.org/profiles/tim-medin",
            __typename: "ContributorProfile",
          },
          __typename: "CourseContributor",
        },
      ],
      resources: [
        {
          title: "VMware License Handout",
          resourceHost: "STATIC_HTTP",
          linkJson:
            '{"name":"VMware License Handout","url":"https:\\/\\/olt-content.sans.org\\/handouts\\/VMware+Software+License+Handout\\/VMware+Software+License+Handout_v21.2.pdf"}',
          __typename: "CourseResource",
        },
        {
          title: "My Labs",
          resourceHost: "STATIC_HTTP",
          linkJson: '{"name":"My Labs","url":"https:\\/\\/connect.labs.sans.org\\/"}',
          __typename: "CourseResource",
        },
        {
          title: "Quick Start Guide",
          resourceHost: "STATIC_HTTP",
          linkJson:
            '{"name":"Quick Start Guide","url":"https:\\/\\/olt-content.sans.org\\/handouts\\/SEC560_F01\\/SEC560_OD_QuickStartGuide_F01_08.pdf"}',
          __typename: "CourseResource",
        },
        {
          title: "VMware Software License Handout",
          resourceHost: "STATIC_HTTP",
          linkJson:
            '{"name":"VMware Software License Handout","url":"https:\\/\\/olt-content.sans.org\\/handouts\\/VMware+Software+License+Handout_v21.10.pdf"}',
          __typename: "CourseResource",
        },
        {
          title: "VMware Software License Handout",
          resourceHost: "STATIC_HTTP",
          linkJson:
            '{"name":"VMware Software License Handout","url":"https:\\/\\/olt-content.sans.org\\/handouts\\/VMware+Software+License+Handout_v21.11.pdf"}',
          __typename: "CourseResource",
        },
        {
          title: "VMware Software License Handout",
          resourceHost: "STATIC_HTTP",
          linkJson:
            '{"name":"VMware Software License Handout","url":"https:\\/\\/olt-content.sans.org\\/handouts\\/VMware+Software+License+Handout_v21.11.pdf"}',
          __typename: "CourseResource",
        },
        {
          title: "Quick Start Guide",
          resourceHost: "STATIC_HTTP",
          linkJson:
            '{"name":"Quick Start Guide","url":" https:\\/\\/olt-content.sans.org\\/handouts\\/SEC560_F01\\/SEC560_OD_QuickStartGuide_F01_09.pdf"}',
          __typename: "CourseResource",
        },
      ],
      __typename: "Course",
    },
    instance: {
      id: "16494baa-36a9-4786-9a55-72002398e3bf",
      expirationDateTime: moment().add(1, "day").toISOString(),
      __typename: "TrainingInstance",
      lastView: null,
      userId: 400001,
      progress: {
        instanceId: "16494baa-36a9-4786-9a55-72002398e3bf",
        viewed: 80,
        total: 100,
        viewedSlides: 8,
        totalSlides: 10,
        percentSlides: 80,
        totalQuizzes: 10,
        passedQuizzes: 8,
        percentQuizzes: 80,
        viewedSeconds: 80,
        totalSeconds: 100,
        complete: true,
        lastView: {
          slideId: "4e97d6c9-0c9e-40a4-b75f-89858126aeec",
          resumeTime: "01:00:00",
          dateTimeViewed: "2021-11-10T18:27:22.000Z",
          __typename: "LastView",
        },
        moduleProgress: [
          {
            id: "071cac06-5091-40a2-9f90-172acaf2ed34",
            name: "Introduction to Comprehensive Pen Test Planning, Scoping, and Recon",
            type: "CONTENT",
            fullNumber: "1.1",
            viewedSlides: 2,
            totalSlides: 4,
            quizScore: null,
          },
        ],
        __typename: "CourseProgress",
      },
    },
  },
};
