import React from "react";
import { Stack, Typography, Link, Divider, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import BadgeIcon from "@mui/icons-material/Badge";
import ScienceIcon from "@mui/icons-material/Science";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";

import { Handout, CourseAssetsWrapper } from "./CourseAssets.elements";

interface Asset {
  title: string;
  url: string;
}

interface CourseMaterialsSectionProps {
  data: Asset[];
}

const CourseAssetsLinks = ({ data }: CourseMaterialsSectionProps) => {
  return data.length ? (
    <Grid container spacing={2}>
      {data.map((item) => (
        <Grid key={item.title} item xs={12} md={6} lg={4}>
          <Handout>
            <BadgeIcon className="icon" fontSize="small" />
            <Link target="_blank" href={item.url}>
              {item.title}
            </Link>
          </Handout>
        </Grid>
      ))}
    </Grid>
  ) : null;
};

const CourseAssets = ({
  licenses = [],
  handouts = [],
  hasMyLabs,
}: {
  licenses: Asset[];
  handouts: Asset[];
  hasMyLabs: boolean;
}) => {
  const theme = useTheme();
  const myLabsLink = (
    <Link target="_blank" href="https://connect.labs.sans.org/" color={theme.palette.text.link}>
      My Labs
    </Link>
  );

  const headingWithoutLicenses = <>Go to {myLabsLink} when prompted.</>;

  const headingWithLicenses = (
    <>
      Go to {myLabsLink} and obtain the following <strong>software licenses</strong> when prompted:
    </>
  );

  const sharedTextProps = {
    display: "flex",
    alignItems: "center",
    gap: 1,
  };

  const includesAssets = hasMyLabs || !!licenses.length || !!handouts.length;
  const hasHandoutsAndLabs = hasMyLabs && !!handouts.length;

  return includesAssets ? (
    <CourseAssetsWrapper gap={2}>
      {(hasMyLabs || !!licenses.length) && (
        <>
          <Stack gap={2}>
            <Typography fontWeight={600} {...sharedTextProps}>
              <ScienceIcon /> This course includes lab access
            </Typography>
            {hasMyLabs && (
              <Typography data-testid="myLabsSubheading" variant="body2">
                {licenses.length ? headingWithLicenses : headingWithoutLicenses}
              </Typography>
            )}
          </Stack>
          <CourseAssetsLinks data={licenses} />
        </>
      )}
      {hasHandoutsAndLabs && <Divider />}
      {!!handouts.length && (
        <>
          <Stack gap={2}>
            <Typography fontWeight={600} {...sharedTextProps}>
              <LibraryBooksIcon /> This course includes handouts
            </Typography>
            <Typography data-testid="myLabsSubheading" variant="body2" {...sharedTextProps}>
              Download these handouts for use in your course:
            </Typography>
          </Stack>
          <CourseAssetsLinks data={handouts} />
        </>
      )}
    </CourseAssetsWrapper>
  ) : null;
};

export default CourseAssets;
