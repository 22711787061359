import React, { useState, useEffect } from "react";
import { AutoplaySwitch } from "./ButtonAutoplay.elements";
import ButtonIconContainer from "../../../ButtonIconContainer/ButtonIconContainer";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

type ButtonAutoplayProps = {
  isAutoplayEnabled: boolean;
  onClickAutoplay: (value: boolean) => void;
};

export default function ButtonAutoplay({
  isAutoplayEnabled,
  onClickAutoplay,
}: ButtonAutoplayProps): JSX.Element {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const [checked, setChecked] = useState(isAutoplayEnabled);

  useEffect(() => {
    setChecked(isAutoplayEnabled);
  }, [isAutoplayEnabled]);

  const updateCheckedState = (prevState: boolean) => {
    onClickAutoplay(!prevState);
    return !prevState;
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      setChecked(updateCheckedState);
    }
  };

  const handleClick = () => {
    setChecked(updateCheckedState);
  };

  return (
    <ButtonIconContainer
      ariaLabel={checked ? "disable autoplay" : "enable autoplay"}
      tooltip={`Autoplay is ${checked ? "on" : "off"} (a)`}
      id="button_autoplay"
      tabIndex={-1}
      onClick={handleClick}
    >
      <AutoplaySwitch
        tabIndex={1}
        checked={checked}
        size={isDesktop ? undefined : "small"}
        onKeyPress={handleKeyPress}
        inputProps={{ "aria-label": "Autoplay switch" }}
      />
    </ButtonIconContainer>
  );
}
