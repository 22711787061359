import React from "react";
import { styled } from "@mui/material/styles";
import { Select, Box, Typography, Stack, MenuItem } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";

export const SelectBox = styled(Box)(({ theme }) => ({
  "& #slide_select_title": {
    width: "unset",
    paddingLeft: "8px",
    flex: 1,
  },

  "& #slide_select_duration": {
    flex: "0 0 20%",
    textAlign: "right",

    [theme.breakpoints.down("sm")]: {
      flex: "0 0 25%",
    },
  },
}));

export const StyledSelect = styled(Select)(({ theme }) => ({
  background: theme.custom.palette.semantic.inputBg,
  "& .MuiSelect-select": {
    border: `1px solid ${theme.custom.palette.semantic.buttonContainedBorder}`,
    "&:hover": {
      border: `1px solid ${theme.custom.palette.semantic.buttonContainedBorder}`,
    },
  },
  "& .MuiSelect-icon": {
    color: theme.custom.palette.semantic.buttonIconSecondaryColor,
  },
}));

export const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "bold" && prop !== "truncate",
})<{ bold?: boolean; component?: React.ElementType; truncate?: boolean }>(({ bold, truncate }) => ({
  textAlign: "left",
  fontWeight: bold ? 600 : 400,
  width: truncate ? "80%" : "unset",
  overflow: truncate ? "hidden" : "unset",
  textOverflow: truncate ? "ellipsis" : "unset",
  fontSize: "0.875rem",
}));

export const Duration = styled(Box)(() => ({
  display: "inline-flex",
  alignItems: "center",
}));

export const ViewedIcon = styled(CheckCircle)(({ theme }) => ({
  marginLeft: "5px",
  fontSize: "1rem",
  color: theme.custom.palette.semantic.iconColor,

  [`&.viewed`]: {
    color: theme.custom.palette.success[500],
  },
}));

export const MenuOption = styled(MenuItem)(({ theme }) => ({
  [`&:hover svg`]: {
    color: theme.custom.palette.semantic.iconHoverColor,
  },

  [`&:hover svg.viewed`]: {
    color: theme.custom.palette.success[500],
  },
}));

export const ModuleWrapper = styled(Stack)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(1),
  containerType: "inline-size",
  display: "flex",
  flexDirection: "row",
  gap: theme.spacing(1),

  "@container container (width < 600px)": {
    "& .button-text": {
      display: "none",
    },

    "& .MuiButton-startIcon": {
      margin: "0px",
    },
  },

  "@container container (width < 500px)": {
    flexDirection: "column",

    button: {
      flex: 1,
    },
  },

  [theme.breakpoints.up("md")]: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));
