import React, { useContext } from "react";
import HelpAndSupportDialog from "./HelpAndSupportDialog";
import { HasbotContext, HasbotContextType } from "../context/hasBot-context";

export function HelpAndSupportApp() {
  const { showDialog, zopimChatActive } = useContext(HasbotContext) as HasbotContextType;

  if (showDialog) {
    return <HelpAndSupportDialog />;
  } else if (zopimChatActive) {
    return <HelpAndSupportDialog className="hidden" />;
  } else {
    return null;
  }
}
