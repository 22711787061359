import React, { useRef, useEffect } from "react";
import ReactPlayer from "react-player";
import classnames from "classnames";

import Controls from "../Controls/Controls";
import useVideo from "./useVideo";
import Captions from "../Captions/Captions";
import { NoAudioToast } from "./NoAudioToast";
import TapToUnmute from "./TapToUnmute";

import { Container, CaptionsContainer, PlayerContainer } from "./Video.elements";
import { useAppState } from "../../hooks/useAppState";
import { Skeleton } from "@mui/material";

export function Video(): JSX.Element | null {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const playerWrapperRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<ReactPlayer>(null);
  const timerId = useRef<NodeJS.Timeout>();

  const { loading } = useAppState();

  const {
    videoURL,
    videoTitle,
    isPlaying,
    handleVideoProgress,
    handleVideoDuration,
    handleVideoEnded,
    handleClickPreviousVideo,
    handleClickNextVideo,
    isAutoplayEnabled,
    handleClickAutoplay,
    handleClickPlayPause,
    progress,
    handleMouseUpProgressBar,
    handleMouseDownProgressBar,
    handleMouseOutProgressBar,
    handleChangeProgressBar,
    handleClickReplay,
    isFullscreen,
    handleClickFullscreen,
    isPIPEnabled,
    handleClickPIP,
    playbackRate,
    handleClickPlaybackRate,
    volume,
    handleClickMuteVolume,
    handleChangeVolumeSlider,
    duration,
    handleVideoReady,
    handleError,
    captionsURL,
    handleClickCaptions,
    captionsEnabled,
    handleChangeView,
    controlsVisible,
    showControls,
    hasNoAudio,
    muted,
    setMuted,
    handleClickVideoContainer,
    hasCustomDuration,
    setPlayAfterSeek,
    skip,
    tapToUnmute,
    setTapToUnmute,
    loading: videoLoading,
    setBuffering,
    setControlsVisible,
    captionsText,
  } = useVideo(wrapperRef.current, playerWrapperRef.current, playerRef.current);

  const handleBufferEnd = () => setBuffering(false);

  const handleTouchMove = () => {
    clearTimeout(timerId.current);
    setPlayAfterSeek(true);
    showControls();

    timerId.current = setTimeout(() => {
      setControlsVisible(false);
    }, 3000);
  };

  const handleMouseLeave = () => setControlsVisible(false);

  const handleTapToUnmute = () => {
    setMuted(false);
    setTapToUnmute(false);
  };

  const handleMouseMove = () => {
    clearTimeout(timerId.current);
    showControls();

    timerId.current = setTimeout(() => {
      setControlsVisible(false);
    }, 3000);
  };

  const containerClassnames = classnames({
    fullscreen: isFullscreen,
    "captions-enabled": captionsEnabled && captionsURL,
  });

  useEffect(() => {
    const container = wrapperRef.current;
    container?.addEventListener("mousemove", handleMouseMove);

    return () => {
      container?.removeEventListener("mousemove", handleMouseMove);
      clearTimeout(timerId.current);
    };
  }, []);

  if (!videoURL || loading.module) {
    return <Skeleton variant="rectangular" sx={{ height: "100%", aspectRatio: "16/9" }} />;
  }

  return (
    <>
      <NoAudioToast show={hasNoAudio} />
      <Container
        sx={{
          cursor: controlsVisible ? "initial" : "none",
        }}
        ref={wrapperRef}
        onMouseMove={handleMouseMove}
        onFocus={showControls}
        onTouchMove={handleTouchMove}
        onMouseLeave={handleMouseLeave}
        id="video-player"
        data-testid="video-player"
      >
        {tapToUnmute && <TapToUnmute onClick={handleTapToUnmute} />}

        <Controls
          videoTitle={videoTitle}
          isPlaying={isPlaying}
          handleClickPreviousVideo={handleClickPreviousVideo}
          handleClickNextVideo={handleClickNextVideo}
          isAutoplayEnabled={isAutoplayEnabled}
          handleClickAutoplay={handleClickAutoplay}
          handleClickPlayPause={handleClickPlayPause}
          progress={progress}
          handleMouseUpProgressBar={handleMouseUpProgressBar}
          handleMouseDownProgressBar={handleMouseDownProgressBar}
          handleMouseOutProgressBar={handleMouseOutProgressBar}
          handleChangeProgressBar={handleChangeProgressBar}
          handleClickReplay={handleClickReplay}
          isFullscreen={isFullscreen}
          handleClickFullscreen={handleClickFullscreen}
          handleClickPIP={handleClickPIP}
          playbackRate={playbackRate}
          handleClickPlaybackRate={handleClickPlaybackRate}
          volume={volume > 1 ? 0.5 : volume}
          handleClickMuteVolume={handleClickMuteVolume}
          handleChangeVolumeSlider={handleChangeVolumeSlider}
          duration={duration}
          handleClickCaptions={handleClickCaptions}
          captionsEnabled={captionsEnabled}
          handleChangeView={handleChangeView}
          visible={controlsVisible}
          muted={muted}
          hasCustomDuration={hasCustomDuration}
          skip={skip}
          handleClickVideoContainer={handleClickVideoContainer}
          loading={videoLoading}
        />

        <PlayerContainer
          ref={playerWrapperRef}
          id="player-container"
          className={containerClassnames}
          data-muted={muted}
        >
          <ReactPlayer
            pip={isPIPEnabled}
            url={videoURL}
            ref={playerRef}
            id="video_player"
            playbackRate={playbackRate}
            volume={volume > 1 ? 0.5 : volume}
            playing={isPlaying}
            onDuration={handleVideoDuration}
            progressInterval={100}
            onProgress={handleVideoProgress}
            onEnded={handleVideoEnded}
            onReady={handleVideoReady}
            onBuffer={() => isPlaying && setBuffering(true)}
            onBufferEnd={handleBufferEnd}
            onError={handleError}
            muted={muted}
            config={{
              file: {
                hlsOptions: {
                  progressive: true,
                  startFragPrefetch: true,
                  xhrSetup: function (xhr: { withCredentials: boolean }) {
                    xhr.withCredentials = true; // send cookies
                  },
                },
              },
            }}
          />
        </PlayerContainer>
        {captionsEnabled && (
          <CaptionsContainer id="captions-container">
            <Captions text={captionsText} />
          </CaptionsContainer>
        )}
      </Container>
    </>
  );
}
