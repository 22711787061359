import { makeVar, useReactiveVar } from "@apollo/client";

const ticketModalOpen = makeVar(false);

export function useQCTicketModal() {
  const modalOpen = useReactiveVar(ticketModalOpen);
  const setModalOpen = (open: boolean) => {
    ticketModalOpen(open);
  };

  const openModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };

  return {
    modalOpen,
    openModal,
    closeModal,
  };
}
