import React, { useEffect, useState } from "react";
import { ErrorSeverity, useErrorHandler } from "../../hooks/useErrorHandler";
import { BigError } from "./BigError";

export function ErrorPage(): JSX.Element | null {
  const { error } = useErrorHandler(ErrorSeverity.HIGH);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (error) {
      setMessage(error);
    }
  }, [error]);

  if (!message) return null;

  return <BigError message={message} />;
}
