import { useCallback } from "react";
import { useTheme } from "@mui/material/styles";
import { Translation } from "../locale/translations";

const useTranslation = (): { translate: (str: keyof Translation) => string } => {
  const { translations } = useTheme();

  const translate = useCallback(
    (str: keyof Translation) => {
      if (!translations || !translations[str]) {
        console.error("No translation available.");
        return str;
      }
      return translations[str];
    },
    [translations],
  );

  return { translate };
};

export default useTranslation;
