import { configureReact } from "@sans-tesseract/sdk-react";
import Core, { CoreService } from "@sans-service-tier/sdk";
import { AuthorizationService } from "@sans-service-tier/authorization-service-sdk";
import { BaseDecodedToken } from "@sans-tesseract/sdk";

export class Sans {
  core: CoreService;
  auth: AuthorizationService;

  constructor() {
    const sdkEnv = process.env.REACT_APP_SDK_ENV ?? "sandbox";
    const remoteConfigUri = process.env.REACT_APP_REMOTE_CONFIG_URI;

    if (!remoteConfigUri) {
      throw new Error("REACT_APP_REMOTE_CONFIG_URI must be set");
    }

    this.core = this.setupSdk(sdkEnv, remoteConfigUri);
    this.auth = new AuthorizationService(this.core);
  }

  setupSdk(sdkEnv: string, remoteConfigUri: string) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    configureReact(Core as any, {
      window,
      initialEnvironment: {
        [sdkEnv]: {
          uri: remoteConfigUri,
        },
      },
    });

    return Core;
  }

  async isAuthorized(type: string, id = "app", action = "read"): Promise<boolean> {
    try {
      const { authorized } = await this.auth.authorized({
        type,
        id,
        action,
      });

      return authorized;
    } catch (_) {
      return false;
    }
  }

  async getUserId() {
    const userInfo = (await this.core.api.tokens.userInfo()) as BaseDecodedToken & {
      "custom:sans_idp_account_id": string;
    };
    return userInfo["custom:sans_idp_account_id"];
  }

  static _sdk?: Sans;

  static get sdk(): Sans {
    if (!this._sdk) {
      this._sdk = new Sans();
    }

    return this._sdk;
  }

  static initializeSdk(): void {
    this._sdk = new Sans();
  }
}
