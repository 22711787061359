/* eslint-disable sonarjs/cognitive-complexity */
import { makeVar, useApolloClient, useReactiveVar } from "@apollo/client";
import { useAnalyticsEvents } from "@atlaskit/analytics-next";
import gql from "graphql-tag";
import { useCallback, useEffect } from "react";
import { useAppState } from "../../hooks/useAppState";
import { Bookmarks } from "./__generated__/Bookmarks";
import { DeleteBookmark } from "./__generated__/DeleteBookmark";
import { SaveBookmark, SaveBookmark_saveBookmark } from "./__generated__/SaveBookmark";
import { telemetryChannelName } from "../../util/constants";

export const SAVE_BOOKMARK_MUTATION = gql`
  mutation SaveBookmark(
    $courseId: Int!
    $slideId: String!
    $timestamp: String!
    $instanceId: String!
  ) {
    saveBookmark(
      courseId: $courseId
      slideId: $slideId
      timestamp: $timestamp
      instanceId: $instanceId
    ) {
      slideId
      timestamp
      instanceId
    }
  }
`;

export const DELETE_BOOKMARK_MUTATION = gql`
  mutation DeleteBookmark($slideId: String!, $timestamp: String!) {
    deleteBookmark(slideId: $slideId, timestamp: $timestamp)
  }
`;

export const BOOKMARKS_QUERY = gql`
  query Bookmarks($courseId: Int!) {
    course(courseId: $courseId) {
      trainingId
      sections {
        modules {
          slides {
            id
            fullNumber
            name
            bookmarks {
              slideId
              timestamp
            }
          }
        }
      }
    }
  }
`;

export interface Bookmark {
  timestamp: string;
  slideId: string;
  slideName: string;
  title: string;
}

type BookmarkCollection = { [key: string]: Bookmark };

const bookmarksVar = makeVar<Record<string, Bookmark>>({});

export function sortBookmarks(bookmarks: BookmarkCollection): BookmarkCollection {
  const extractSlideHierarchy = (slideName: string): number[] => {
    const regex = /^(\d+(?:\.\d+)*)/;
    const match = regex.exec(slideName);
    return match ? match[1].split(".").map(Number) : [];
  };

  const compareBookmarks = (bookmarkA: Bookmark, bookmarkB: Bookmark) => {
    const hierarchyA = extractSlideHierarchy(bookmarkA.slideName);
    const hierarchyB = extractSlideHierarchy(bookmarkB.slideName);

    for (let i = 0; i < Math.max(hierarchyA.length, hierarchyB.length); i++) {
      if ((hierarchyA[i] || 0) !== (hierarchyB[i] || 0)) {
        return (hierarchyA[i] || 0) - (hierarchyB[i] || 0);
      }
    }

    return bookmarkA.timestamp.localeCompare(bookmarkB.timestamp);
  };

  const bookmarksArray = Object.entries(bookmarks);
  bookmarksArray.sort(([, bookmarkA], [, bookmarkB]) => compareBookmarks(bookmarkA, bookmarkB));

  return bookmarksArray.reduce((acc, [key, bookmark]) => {
    acc[key] = bookmark;
    return acc;
  }, {} as BookmarkCollection);
}

export function dedupeBookmarks(cachedBookmarks: [], newBookmark: SaveBookmark_saveBookmark) {
  const filteredBookmarks = cachedBookmarks.filter((bookmark: { slideId: string }) => {
    return bookmark.slideId !== newBookmark.slideId;
  });
  return [...filteredBookmarks, newBookmark];
}

export const featureName = "bookmarks";
export const saveActionType = "save";

export function useBookmarks(): {
  saveBookmark: (slideId: string, timestamp: string) => void;
  deleteBookmark: (slideId: string, timestamp: string) => void;
  bookmarks: Record<string, Bookmark>;
} {
  const { createAnalyticsEvent } = useAnalyticsEvents();

  const {
    appState: { courseId, instanceId },
    course,
  } = useAppState();

  const client = useApolloClient();

  const bookmarks = useReactiveVar(bookmarksVar);

  const getBookmarks = useCallback(() => {
    const queryResponse = client.readQuery<Bookmarks>({
      query: BOOKMARKS_QUERY,
      variables: { courseId },
    });

    if (!queryResponse) return [];

    const courseBookmarks: Record<string, Bookmark> = {};

    for (const section of queryResponse.course.sections) {
      for (const module of section.modules) {
        for (const [index, slide] of module.slides.entries()) {
          if (!slide.bookmarks) continue;

          const bookmarksArr = !Array.isArray(slide.bookmarks)
            ? [slide.bookmarks]
            : slide.bookmarks;

          for (const bookmark of bookmarksArr) {
            const parsedSlideId = bookmark.slideId.split("_");
            const timeStamp = bookmark.timestamp || "00:00:00";

            courseBookmarks[bookmark.slideId] = {
              title: `Slide ${index + 1} - ${timeStamp}`,
              slideId: parsedSlideId[0],
              slideName: `${slide.fullNumber}: ${slide.name}`,
              timestamp: `${timeStamp}`,
            };
          }
        }
      }
    }

    const sortedBookmarks = sortBookmarks(courseBookmarks);
    bookmarksVar(sortedBookmarks);
  }, [courseId, client]);

  useEffect(() => {
    getBookmarks();
  }, [getBookmarks, course]);

  const saveBookmark = useCallback(
    async (slideId: string, timestamp: string): Promise<void> => {
      createAnalyticsEvent({
        feature: featureName,
        action: saveActionType,
      }).fire(telemetryChannelName);

      await client.mutate<SaveBookmark>({
        mutation: SAVE_BOOKMARK_MUTATION,
        variables: {
          courseId,
          slideId,
          timestamp,
          instanceId,
        },
        update: (cache, { data }) => {
          if (data?.saveBookmark) {
            const slideCacheId = cache.identify({ __typename: "Slide", id: slideId });
            if (slideCacheId) {
              cache.modify({
                id: slideCacheId,
                fields: {
                  bookmarks(cachedBookmarks) {
                    if (cachedBookmarks) {
                      return dedupeBookmarks(cachedBookmarks, data.saveBookmark);
                    }
                    return [{ ...data.saveBookmark }];
                  },
                },
              });
            }
          }
        },
      });
      getBookmarks();
    },
    [courseId, instanceId, client, getBookmarks, createAnalyticsEvent],
  );

  const deleteBookmark = useCallback(
    async (slideId: string, timestamp: string): Promise<void> => {
      const combinedId = timestamp && timestamp != "00:00:00" ? slideId + "_" + timestamp : slideId;

      await client.mutate<DeleteBookmark>({
        mutation: DELETE_BOOKMARK_MUTATION,
        variables: { slideId, timestamp },
        update: (cache, { data }) => {
          if (data?.deleteBookmark) {
            const slideCacheId = cache.identify({ __typename: "Slide", id: slideId });
            if (slideCacheId) {
              cache.modify({
                id: slideCacheId,
                fields: {
                  bookmarks: (cachedBookmarks = []) =>
                    cachedBookmarks.filter((bookmark: string) => {
                      const bookmarkProps = Object.values(bookmark);
                      return !bookmarkProps.some((prop) => String(prop) === combinedId);
                    }),
                },
              });
            }
            const cacheId = `Bookmark:{"slideId":"${combinedId}"}`;
            if (cacheId) {
              cache.evict({ id: cacheId });
              cache.gc();
            }
          }
        },
      });
      getBookmarks();
    },
    [client, getBookmarks],
  );

  return { saveBookmark, deleteBookmark, bookmarks };
}
