import { Accordion, styled, Link } from "@mui/material";

export const SetupStepWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  paddingTop: theme.spacing(2),
  borderTop:
    theme.palette.mode === "dark"
      ? "1px solid " + theme.palette.grey[600]
      : "1px solid " + theme.palette.grey[200],
  justifyContent: "left",
  textAlign: "left",
}));

export const SetupAccordion = styled(Accordion)(() => ({
  "&.Mui-expanded": {
    margin: 0,
  },

  borderRadius: "4px",
}));

export const SetupStepContent = styled("div")(() => ({
  display: "inline",
}));

export const SetupLink = styled(Link)(({ theme }) => ({
  color: theme.custom.palette.semantic.blue,
}));

export const SetupStep = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  width: "100%",
  paddingTop: theme.spacing(1),

  ["&:first-child"]: {
    paddingTop: "0",
  },

  ["& svg"]: {
    marginTop: "2px",
  },

  ["& a"]: {
    color: theme.custom.palette.semantic.blue,
  },
}));
